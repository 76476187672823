import { BehaviorSubject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { ClientLeadsRoutes } from '@leads/client-facing/client-facing.routes';
import { leadRequestTypeOptions, leadStatusOptions } from '@leads/client-facing/models/domain/lead-request.domain';
import { LeadStatusBadgeComponent } from '@leads/customer-facing/components/lead-status-badge/lead-status-badge.component';
import { LeadRequestType, LeadStatus } from '@leads/shared/models/domain/lead-request.domain';
import { ILeadRequest } from '@leads/shared/models/firebase/lead-request.model';
import { DropdownControlComponent, IDropdownOption } from '@shared/components/forms/dropdown-control/dropdown-control.component';
import { RadioGroupControlComponent } from '@shared/components/forms/radio-group-control/radio-group-control.component';
import { SearchControlComponent } from '@shared/components/forms/search-control/search-control.component';
import { LoadingStateComponent } from '@shared/components/loading-state/loading-state.component';
import { IRadioOption } from '@shared/models/form-controls';
import { NullDefaultPipe } from '@shared/pipes/null-default.pipe';

@Component({
	selector: 'app-mobile-lead-request-list',
	standalone: true,
	imports: [
		CommonModule,
		ReactiveFormsModule,
		SearchControlComponent,
		RadioGroupControlComponent,
		LoadingStateComponent,
		DropdownControlComponent,
		RouterLink,
		LeadStatusBadgeComponent,
		NullDefaultPipe,
	],
	templateUrl: './mobile-lead-request-list.component.html',
	styleUrl: './mobile-lead-request-list.component.scss',
	host: {
		class: 'h-100',
	},
})
export class MobileLeadRequestListComponent implements OnInit, OnDestroy {
	@Input() isLoading: boolean;
	@Input() leads: Array<ILeadRequest>;
	@Input() searchTerm$: BehaviorSubject<string>;
	@Input() leadStatus$: BehaviorSubject<Array<LeadStatus>>;
	@Input() leadRequestType$: BehaviorSubject<LeadRequestType | null>;
	@Output() onClick = new EventEmitter<ILeadRequest>();

	form: FormGroup<{ leadStatus: FormControl; leadRequestType: FormControl }>;
	searchControl = new FormControl('', { nonNullable: true });
	destroyed$ = new EventEmitter<void>();

	LeadRequestType = LeadRequestType;
	leadStatusOptions: Array<IDropdownOption> = leadStatusOptions;
	leadRequestTypeOptions: Array<IRadioOption> = leadRequestTypeOptions;

	protected readonly ClientLeadsRoutes = ClientLeadsRoutes;

	get leadRequestType(): FormControl<LeadRequestType> {
		return this.form.controls.leadRequestType;
	}

	get leadStatus(): FormControl<Array<LeadStatus>> {
		return this.form.controls.leadStatus;
	}

	ngOnInit(): void {
		this.isLoading = false;

		this.form = new FormGroup({
			leadStatus: new FormControl<Array<LeadStatus>>(this.leadStatus$.value),
			leadRequestType: new FormControl<LeadRequestType | null>(this.leadRequestType$.value),
		});

		this.searchControl.valueChanges.pipe(takeUntil(this.destroyed$), debounceTime(500), distinctUntilChanged()).subscribe((value) => {
			this.searchTerm$.next(value);
		});
		this.leadStatus.valueChanges.pipe(takeUntil(this.destroyed$), distinctUntilChanged()).subscribe((value) => {
			this.leadStatus$.next(value);
		});
		this.leadRequestType.valueChanges.pipe(takeUntil(this.destroyed$), distinctUntilChanged()).subscribe((value) => {
			this.leadRequestType$.next(value);
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}

	onLeadItemClick(lead: ILeadRequest) {
		this.onClick.emit(lead);
	}
}
