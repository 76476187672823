<div class="modal-body text-primary">
	<div class="text-primary text-container">
		<h5>{{ title }}</h5>
		<div>{{ contentText }}</div>
	</div>
	<div class="button-container mt-2">
		<button type="button" class="btn btn-sm btn-primary" (click)="closeModal()"><i class="bi bi-arrow-left"></i> Back</button>
		<button type="button" class="btn btn-sm btn-outline-danger" (click)="confirm()">Confirm</button>
	</div>
</div>
