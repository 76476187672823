import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IClientForm } from '@clients/models/clients';
import { StandardControlComponent } from '@shared/components/forms/standard-control/standard-control.component';
import { TextareaControlComponent } from '@shared/components/forms/textarea-control/textarea-control.component';
import { PageSubtitleComponent } from '@shared/components/sections/page-subtitle/page-subtitle.component';

@Component({
	selector: 'app-desktop-client-details',
	standalone: true,
	imports: [CommonModule, PageSubtitleComponent, FormsModule, ReactiveFormsModule, StandardControlComponent, TextareaControlComponent],
	templateUrl: './desktop-client-details.component.html',
	styleUrl: './desktop-client-details.component.scss',
})
export class DesktopClientDetailsComponent {
	@Input() clientForm: FormGroup<IClientForm>;
}
