import { IBusinessHours } from '@organisations/models/organisations';

export enum NgbDayNumber {
	SUNDAY = 0,
	MONDAY = 1,
	TUESDAY = 2,
	WEDNESDAY = 3,
	THURSDAY = 4,
	FRIDAY = 5,
	SATURDAY = 6,
}

export interface IDisabledDaysOfTheWeek {
	sunday: boolean;
	monday: boolean;
	tuesday: boolean;
	wednesday: boolean;
	thursday: boolean;
	friday: boolean;
	saturday: boolean;
}

export function convertBusinessHoursToDisabledDaysOfTheWeek(businessHours: IBusinessHours): IDisabledDaysOfTheWeek {
	return Object.fromEntries(
		Object.entries(businessHours).map(([key, value]: [string, boolean]) => [key, !value]),
	) as unknown as IDisabledDaysOfTheWeek;
}
