import { Routes } from '@angular/router';
import { OrganisationCreateComponent } from './pages/organisation-create/organisation-create.component';
import { OrganisationComponent } from './pages/organisation/organisation.component';
import { OrganisationDetailsComponent } from './pages/organisation-details/organisation-details.component';
import { OrganisationUsersComponent } from './pages/organisation-users/organisation-users.component';
import { accessLevelGuard } from './guards/access-level/access-level.guard';
import { OrganisationBillingComponent } from './pages/organisation-billing/organisation-billing.component';

export enum OrganisationRoutes {
	CREATE = 'create',
	DETAILS = 'details',
	USERS = 'users',
	BILLING = 'billing',
}

export const organisationRoutes: Routes = [
	{
		path: OrganisationRoutes.CREATE,
		component: OrganisationCreateComponent,
		title: 'ServeOx | Create Organisation',
	},
	{
		path: '',
		component: OrganisationComponent,
		canActivate: [accessLevelGuard],
		title: 'ServeOx | Organisation',
		children: [
			{
				path: '',
				redirectTo: OrganisationRoutes.DETAILS,
				pathMatch: 'full',
			},
			{
				path: OrganisationRoutes.DETAILS,
				component: OrganisationDetailsComponent,
			},
			{
				path: OrganisationRoutes.USERS,
				component: OrganisationUsersComponent,
			},
			{
				path: OrganisationRoutes.BILLING,
				component: OrganisationBillingComponent,
			},
			{
				path: '**',
				redirectTo: OrganisationRoutes.DETAILS,
			},
		],
	},
	{
		path: '**',
		redirectTo: OrganisationRoutes.CREATE,
	},
];
