<div class="subpage-container">
	<app-alert
		content="Welcome to {{
			organisation.companyName
		}}, powered by ServeOx. Please follow the steps below to book an appointment with one
				of our experts. Click on the information blocks for guidance on what to do and how to fill out the form correctly."
	></app-alert>

	<form [formGroup]="form">
		<div class="form-container">
			<div class="form-column">
				<app-header-popover heading="Mobile Number" [infoPopOver]="infoPopOver"></app-header-popover>

				<app-standard-control
					type="text"
					name="mobileNumber"
					[formGroup]="form"
					label="Mobile Number"
					placeholder="Mobile Number"
					patternMessage="Mobile Number must be a valid phone number."
				></app-standard-control>
			</div>
		</div>
	</form>
</div>
