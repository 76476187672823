import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { LeadRequestTypeComponent } from '@leads/customer-facing/components/forms/lead-request-type/lead-request-type.component';
import { LeadRequestFormService } from '@leads/customer-facing/services/lead-request-form/lead-request-form.service';
import { ILeadRequestForm } from '@leads/customer-facing/models/lead-request';
import { CustomerLeadRequestRoutes } from '@leads/leads.routes';
import { takeUntil } from 'rxjs';
import { LeadOnSiteVisitDateTimeType, LeadRequestType } from '@leads/shared/models/lead.models';

@Component({
	selector: 'app-lead-request-request-type',
	standalone: true,
	imports: [CommonModule, RouterLink, LeadRequestTypeComponent],
	templateUrl: './lead-request-request-type.component.html',
	styleUrl: './lead-request-request-type.component.scss',
})
export class LeadRequestRequestTypeComponent implements OnInit, OnDestroy {
	private leadRequestFormService = inject(LeadRequestFormService);
	private router = inject(Router);
	private route = inject(ActivatedRoute);

	destroyed$ = new EventEmitter<void>();
	leadRequestForm: FormGroup<ILeadRequestForm>;
	CustomerLeadRequestRoutes = CustomerLeadRequestRoutes;

	ngOnInit(): void {
		this.leadRequestForm = this.leadRequestFormService.getForm();
		this.validatePreviousStep();
		this.validateForm();
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}

	get requestType(): FormControl<LeadRequestType | null> {
		return this.leadRequestForm.controls.requestType;
	}

	validatePreviousStep(): void {
		if (this.leadRequestForm.controls.mobileNumber.invalid) {
			this.router.navigate(['../' + CustomerLeadRequestRoutes.NEW], { relativeTo: this.route });
		}
	}

	validateForm(): void {
		// ToDo: Just enable/disable form controls
		this.requestType.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((requestType) => {
			if (requestType === LeadRequestType.ON_SITE_VISIT) {
				this.leadRequestForm.controls.callAssessment.clearValidators();
				this.leadRequestForm.controls.callAssessment.reset();

				this.leadRequestForm.controls.installationAddress.controls.city.setValidators([
					Validators.required,
					Validators.minLength(3),
				]);
				this.leadRequestForm.controls.installationAddress.controls.country.setValidators([
					Validators.required,
					Validators.minLength(3),
				]);
				this.leadRequestForm.controls.installationAddress.controls.fullAddress.setValidators([
					Validators.required,
					Validators.minLength(3),
				]);
				this.leadRequestForm.controls.installationAddress.controls.postalCode.setValidators([
					Validators.required,
					Validators.minLength(3),
				]);
				this.leadRequestForm.controls.installationAddress.controls.province.setValidators([
					Validators.required,
					Validators.minLength(3),
				]);

				this.leadRequestForm.controls.onsiteAssessment.controls.dateType.setValidators([Validators.required]);
				this.leadRequestForm.controls.onsiteAssessment.controls.requestedDate.setValidators([Validators.required]);
				this.leadRequestForm.controls.onsiteAssessment.controls.requestedTimeWindow.setValidators([Validators.required]);
				this.leadRequestForm.controls.onsiteAssessment.controls.serviceType.setValidators([Validators.required]);

				this.leadRequestForm.controls.onsiteAssessment.controls.dateType.patchValue(LeadOnSiteVisitDateTimeType.REQUEST);
			}

			if (requestType === LeadRequestType.CALL) {
				this.leadRequestForm.controls.installationAddress.reset();
				this.leadRequestForm.controls.onsiteAssessment.reset();

				this.leadRequestForm.controls.installationAddress.controls.city.clearValidators();
				this.leadRequestForm.controls.installationAddress.controls.country.clearValidators();
				this.leadRequestForm.controls.installationAddress.controls.fullAddress.clearValidators();
				this.leadRequestForm.controls.installationAddress.controls.postalCode.clearValidators();
				this.leadRequestForm.controls.installationAddress.controls.province.clearValidators();

				this.leadRequestForm.controls.onsiteAssessment.controls.dateType.clearValidators();
				this.leadRequestForm.controls.onsiteAssessment.controls.requestedDate.clearValidators();
				this.leadRequestForm.controls.onsiteAssessment.controls.requestedTimeWindow.clearValidators();
				this.leadRequestForm.controls.onsiteAssessment.controls.serviceType.clearValidators();
			}

			this.leadRequestForm.controls.installationAddress.controls.city.updateValueAndValidity();
			this.leadRequestForm.controls.installationAddress.controls.country.updateValueAndValidity();
			this.leadRequestForm.controls.installationAddress.controls.fullAddress.updateValueAndValidity();
			this.leadRequestForm.controls.installationAddress.controls.postalCode.updateValueAndValidity();
			this.leadRequestForm.controls.installationAddress.controls.province.updateValueAndValidity();

			this.leadRequestForm.controls.onsiteAssessment.controls.dateType.updateValueAndValidity();
			this.leadRequestForm.controls.onsiteAssessment.controls.requestedDate.updateValueAndValidity();
			this.leadRequestForm.controls.onsiteAssessment.controls.requestedTimeWindow.updateValueAndValidity();
			this.leadRequestForm.controls.onsiteAssessment.controls.serviceType.updateValueAndValidity();
		});
	}
}
