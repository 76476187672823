<div class="subpage-container">
	<app-alert
		*ngIf="leadRequest.status === LeadStatus.REJECTED"
		[content]="alertContent"
		[alertType]="AlertTypes.DANGER"
		[marginBottom]="0"
	></app-alert>
	<ng-template #alertContent>
		This lead request has been marked as rejected and changes are no longer possible. If you need to make changes, please recover the
		request.
	</ng-template>

	<div class="section-group">
		<div class="section">
			<div class="section-column">
				<div class="section-header">
					<h6 class="text-primary">Contact Information</h6>
					<button class="btn btn-sm btn-primary" type="button" (click)="viewClient()">
						<i class="bi bi-arrow-up-right-circle"> </i>
						View Client
					</button>
				</div>
				<div class="section-information">
					<div class="label">
						<div class="name">Customer Name</div>
						<div class="value">{{ client.clientName }}</div>
					</div>
					<ng-container *ngIf="client.properties.length">
						<div class="label">
							<div class="name">Properties</div>
							<div class="value">
								<ng-container *ngFor="let property of client.properties">
									<app-link-location [property]="property"></app-link-location>
								</ng-container>
							</div>
						</div>
					</ng-container>
					<div class="label">
						<div class="name">Phone</div>
						<div class="value">({{ client.clientNumber.countryCode }}) {{ client.clientNumber.number }}</div>
					</div>
					<div class="label">
						<div class="name">Email</div>
						<div class="value">{{ client.clientEmail }}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="section">
			<div class="section-column">
				<h6 class="text-primary">Internal Client Notes</h6>
				<textarea class="form-control" placeholder="Client Internal Notes" [disabled]="readonlyView" readonly>{{
					client.internalNotes
				}}</textarea>
			</div>
		</div>
	</div>

	<div class="section">
		<div class="section-group">
			<div class="section-column">
				<h6 class="text-primary">Requested Date & Time</h6>
				<div class="section-information">
					<div class="label">
						<div class="name">Submitted Date</div>
						<div class="value">{{ leadRequest.createdDate.toMillis() | date: 'dd/MM/yyyy' }}</div>
					</div>
					<ng-container *ngIf="leadRequest.requestType === LeadRequestType.ON_SITE_VISIT">
						<div class="label">
							<div class="name">Requested Date</div>
							<div class="value">
								<ng-container *ngIf="leadRequest.onSiteVisitAssessment?.dateTime?.type === LeadRequestDateTimeType.REQUEST">
									{{ leadRequest.onSiteVisitAssessment?.dateTime?.requestedDate | ngbDateToDate | date: 'dd/MM/yyyy' }}
								</ng-container>
								<ng-container *ngIf="leadRequest.onSiteVisitAssessment?.dateTime?.type === LeadRequestDateTimeType.ASAP">
									ASAP
								</ng-container>
							</div>
						</div>
						<div class="label">
							<div class="name">Time</div>
							<div class="value">
								<ng-container *ngIf="leadRequest.onSiteVisitAssessment?.dateTime?.type === LeadRequestDateTimeType.REQUEST">
									{{ leadRequest.onSiteVisitAssessment?.dateTime?.requestedTimeWindow }}
								</ng-container>
								<ng-container *ngIf="leadRequest.onSiteVisitAssessment?.dateTime?.type === LeadRequestDateTimeType.ASAP">
									ASAP
								</ng-container>
							</div>
						</div>
					</ng-container>
					<div class="label">
						<div class="name">
							Address
							<ng-container *ngIf="leadRequest.personalDetails.installationAddress.fullAddress">
								<button type="button" class="btn btn-sm btn-outline-primary border-0" title="Edit" (click)="editProperty()">
									<i class="bi bi-pencil-fill"> </i>
								</button>
							</ng-container>
						</div>
						<div class="value">
							<ng-container *ngIf="leadRequest.personalDetails.installationAddress.fullAddress; else propertyAdd">
								<app-link-location [property]="leadRequest.personalDetails.installationAddress"></app-link-location>
							</ng-container>
							<ng-template #propertyAdd>
								<button type="button" class="btn btn-sm btn-primary" (click)="addProperty()">
									<i class="bi bi-plus"> </i>
									Add
								</button>
							</ng-template>
						</div>
					</div>
				</div>
			</div>

			<div class="section-column">
				<h6 class="text-primary">Assessment Type</h6>
				<ng-container *ngIf="leadRequest.requestType === LeadRequestType.ON_SITE_VISIT">
					<div class="section-information">
						<div class="label">
							<div class="name">Requested Assessment</div>
							<div class="value">{{ leadRequest.onSiteVisitAssessment?.serviceType }}</div>
						</div>
					</div>
					<ng-container *ngIf="leadRequest.onSiteVisitAssessment?.customQuestions?.length">
						<ng-container *ngFor="let customQuestion of leadRequest.onSiteVisitAssessment?.customQuestions">
							<div class="section-information">
								<div class="label">
									<div class="name">{{ customQuestion.question }}</div>
									<div class="value">{{ customQuestion.answer }}</div>
								</div>
							</div>
						</ng-container>
					</ng-container>
				</ng-container>
				<ng-container *ngIf="leadRequest.requestType === LeadRequestType.CALL">
					<div class="section-information">
						<div class="label">
							<div class="name">Requested Assessment</div>
							<div class="value">Call</div>
						</div>
					</div>
					<ng-container *ngIf="leadRequest.callAssessment?.customQuestions?.length">
						<ng-container *ngFor="let customQuestion of leadRequest.callAssessment?.customQuestions">
							<div class="section-information">
								<div class="label">
									<div class="name">{{ customQuestion.question }}</div>
									<div class="value">{{ customQuestion.answer }}</div>
								</div>
							</div>
						</ng-container>
					</ng-container>
				</ng-container>
			</div>
		</div>
		<div class="section-group">
			<ng-container *ngIf="leadRequest.personalDetails.customQuestions?.length">
				<div class="section-column">
					<h6 class="text-primary">Additional Information</h6>
					<ng-container *ngFor="let customQuestion of leadRequest.personalDetails.customQuestions">
						<div class="section-information">
							<div class="label">
								<div class="name">{{ customQuestion.question }}</div>
								<div class="value">{{ customQuestion.answer }}</div>
							</div>
						</div>
					</ng-container>
				</div>
			</ng-container>
			<div class="section-column">
				<h6 class="text-primary">Assessment Notes</h6>
				<div class="section-information">
					<div class="label">
						<ng-container *ngIf="leadRequest.requestType === LeadRequestType.ON_SITE_VISIT">
							<textarea
								placeholder="Assessment Notes"
								class="form-control"
								[style.height.px]="100"
								readonly
								[disabled]="readonlyView"
								>{{ leadRequest.onSiteVisitAssessment?.notes }}</textarea
							>
						</ng-container>
						<ng-container *ngIf="leadRequest.requestType === LeadRequestType.CALL">
							<textarea
								placeholder="Assessment Notes"
								class="form-control"
								[style.height.px]="100"
								readonly
								[disabled]="readonlyView"
								>{{ leadRequest.callAssessment?.callNotes }}</textarea
							>
						</ng-container>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="section">
		<div class="section-column">
			<div class="section-header">
				<h6 class="text-primary">Schedule Items</h6>
				<div *ngIf="!readonlyView" class="section-header">
					<button class="btn btn-sm btn-primary" type="button" [routerLink]="'/' + AppRoutes.SCHEDULE">Open Scheduler</button>
					<button
						class="btn btn-sm btn-primary"
						type="button"
						(click)="addCallAssessment()"
						[disabled]="newScheduledItemActivity.open"
					>
						<i class="bi bi-plus"> </i>
						Schedule Call
					</button>
					<button
						class="btn btn-sm btn-primary"
						type="button"
						(click)="addOnSiteAssessment()"
						[disabled]="newScheduledItemActivity.open"
					>
						<i class="bi bi-plus"> </i>
						Schedule On Site
					</button>
				</div>
			</div>
			<ng-container *ngIf="scheduledItems.length || newScheduledItemActivity.open; else noScheduledItems">
				<app-accordion parentId="scheduledItems">
					<ng-container *ngIf="newScheduledItemActivity.open">
						<app-accordion-item parentId="scheduledItems" [itemId]="'0'" [isOpen]="true" [lineHeight]="26">
							<div header class="w-100">New Assessment</div>
							<div actions class="w-100">
								<app-scheduled-item-actions
									[mobile]="false"
									(cancelAssessment)="cancelAssessment($event)"
									(removeAssessment)="removeAssessment($event)"
								></app-scheduled-item-actions>
							</div>
							<app-desktop-scheduled-item-create
								body
								[scheduledItemRequestType]="newScheduledItemActivity.requestType!"
								[selectedOrganisationRef]="selectedOrganisationRef"
								[employees]="employees"
								[activeOrganisationUsers]="activeOrganisationUsers"
								[client]="client"
								[leadRequest]="leadRequest"
								[disabledDaysOfTheWeek]="disabledDaysOfTheWeek"
								[serviceTypes]="serviceTypes"
								(createdAssessment)="addAssessment($event)"
							></app-desktop-scheduled-item-create>
						</app-accordion-item>
					</ng-container>

					<ng-container *ngFor="let item of scheduledItems; let i = index">
						<app-accordion-item
							parentId="scheduledItems"
							[itemId]="(i + 1).toString()"
							[lineHeight]="26"
							[isOpen]="isAccordionOpen(i)"
							(toggle)="toggleAccordion(i, $event)"
						>
							<div header class="w-100">
								<app-scheduled-item-title
									[index]="i"
									[scheduledItem]="item"
									[readonlyView]="readonlyView"
									[mobile]="false"
								></app-scheduled-item-title>
							</div>
							<div actions>
								<app-scheduled-item-actions
									[scheduledItem]="item"
									[readonlyView]="readonlyView"
									[mobile]="false"
									(cancelAssessment)="cancelAssessment($event)"
									(removeAssessment)="removeAssessment($event)"
								></app-scheduled-item-actions>
							</div>
							<div body>
								<app-desktop-scheduled-item-detail
									[employees]="employees"
									[scheduledItem]="item"
									[client]="client"
									[readonlyView]="readonlyView"
									[isSavingNotes]="isSavingNotes"
									(statusChanged)="statusChanged($event)"
									(employeeIdChanged)="employeeIdChanged($event)"
									(assessmentNotesChanged)="assessmentNotesChanged($event)"
								></app-desktop-scheduled-item-detail>
							</div>
						</app-accordion-item>
					</ng-container>
				</app-accordion>
			</ng-container>
			<ng-template #noScheduledItems>
				<em>No assessments have been scheduled.</em>
			</ng-template>
		</div>
	</div>

	<div class="section">
		<div class="section-column">
			<h6 class="text-primary">Internal Notes</h6>
			<app-textarea-control
				[resizable]="true"
				[heightPx]="200"
				name="internalNotes"
				[formGroup]="internalNotesForm"
				label="Internal Notes"
				placeholder="Internal Notes"
			></app-textarea-control>
		</div>
	</div>
</div>
