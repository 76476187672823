import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LeadAssessmentDetails } from '@leads/customer-facing/components/classes/lead-assessment-details';
import { LeadTimeWindow } from '@leads/shared/models/domain/lead-request.domain';
import { AlertComponent } from '@shared/components/alerts/alert/alert.component';
import { DatePickerDropdownComponent } from '@shared/components/calendar/date-picker-dropdown/date-picker-dropdown.component';
import { dateToNgbDateStruct } from '@shared/components/calendar/utilities';
import {
	DropdownControlComponent,
	dropdownOptionsFromEnum,
	IDropdownOption,
} from '@shared/components/forms/dropdown-control/dropdown-control.component';
import { RadioGroupControlComponent } from '@shared/components/forms/radio-group-control/radio-group-control.component';
import { HeaderPopoverComponent } from '@shared/components/sections/header-popover/header-popover.component';

import { CustomFormDetailsComponent } from '../custom-form-details/custom-form-details.component';

@Component({
	selector: 'app-mobile-lead-assessment-details',
	standalone: true,
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		HeaderPopoverComponent,
		AlertComponent,
		RadioGroupControlComponent,
		DatePickerDropdownComponent,
		DropdownControlComponent,
		CustomFormDetailsComponent,
	],
	templateUrl: './mobile-lead-assessment-details.component.html',
	styleUrl: './mobile-lead-assessment-details.component.scss',
})
export class MobileLeadAssessmentDetailsComponent extends LeadAssessmentDetails {
	preferredTimeOptions: Array<IDropdownOption> = dropdownOptionsFromEnum(LeadTimeWindow, 'bi-wrench');

	override datePickerDateChanged(date: Date): void {
		this.form.controls.requestedDate.patchValue(dateToNgbDateStruct(date));
	}
}
