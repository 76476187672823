<div class="content-container bg-serveox">
	<img class="img-fluid" src="assets/logos/serveox-logo-white.svg" />

	<ng-container *ngFor="let item of content">
		<div class="content">
			<img [src]="item.icon" alt="Icon" width="80px" height="80px" />

			<div class="content-body">
				<h5>{{ item.title }}</h5>
				<p>{{ item.description }}</p>
			</div>
		</div>
	</ng-container>
</div>
