<app-loading-state [isLoading]="isLoading" [loadedContent]="loadedContent"> </app-loading-state>

<ng-template #loadedContent>
	<div class="profile-settings-container">
		<div class="heading">
			<app-page-title title="Profile Settings"></app-page-title>
		</div>
		<div class="content">
			<app-alert
				content="This is where you can update your profile information, including your name, phone number, email address, and password. Keeping these details up to date ensures seamless communication and account security."
			></app-alert>
			<form [formGroup]="profileForm">
				<div class="form-container">
					<h4 class="text-primary">Personal Details</h4>

					<div class="form-row">
						<div class="label text-primary">Full Name</div>

						<app-standard-control
							type="text"
							name="displayName"
							[formGroup]="profileForm"
							label="Full Name"
							placeholder="Full Name"
							autocomplete="name"
						>
						</app-standard-control>
					</div>

					<div class="form-row">
						<div class="label text-primary">Phone Number</div>

						<app-standard-control
							type="text"
							name="phoneNumber"
							[formGroup]="profileForm"
							label="Phone Number"
							placeholder="Phone Number"
							patternMessage="Mobile Number must be a valid phone number."
						>
						</app-standard-control>
					</div>

					<div class="form-row">
						<div class="label text-primary">Email Address</div>

						<div>
							<app-standard-control
								type="email"
								name="email"
								[formGroup]="profileForm"
								label="Email Address"
								placeholder="Email Address"
								autocomplete="email"
							>
							</app-standard-control>

							<small class="text-muted fst-italic">
								Your email address cannot be changed. If you need to update your email address, please contact support.
							</small>
						</div>
					</div>

					<div class="form-row">
						<div class="label text-primary">Sign In Providers</div>

						<div class="text-primary">
							<ng-container *ngFor="let signInProvider of signInProviders">
								{{ signInProvider }}
								<br />
							</ng-container>
						</div>
					</div>

					<div class="form-row">
						<div class="label text-primary">Change Password</div>

						<button type="button" class="btn btn-sm btn-outline-primary" (click)="handleUpdatePassword()">
							<i class="bi bi-lock"></i>
							Change Password
						</button>
					</div>
				</div>
			</form>
		</div>
		<div class="footer">
			<button
				type="button"
				class="btn btn-sm btn-outline-danger btn-width"
				(click)="handleCancel()"
				[disabled]="profileForm.pristine"
			>
				Cancel
			</button>
			<button
				type="button"
				class="btn btn-sm btn-primary btn-width"
				(click)="handleSave()"
				[disabled]="profileForm.invalid || profileForm.pristine"
			>
				<ng-container *ngIf="isSaving; else saveText">
					<div class="spinner-border spinner-border-sm" role="status">
						<span class="visually-hidden">Loading...</span>
					</div>
				</ng-container>
				<ng-template #saveText> Save </ng-template>
			</button>
		</div>
	</div>
</ng-template>
