import { inject } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { CanActivateFn, Router } from '@angular/router';
import { AppRoutes } from 'src/app/app.routes';

export const unauthenticatedGuard: CanActivateFn = (route, state) => {
	const auth = inject(Auth);
	const router = inject(Router);

	return new Promise((resolve) => {
		auth.onAuthStateChanged((user) => {
			if (user) {
				router.navigate([AppRoutes.DASHBOARD]);
				resolve(false);
			}
			resolve(true);
		});
	});
};
