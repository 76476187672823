import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { ContentMarketingComponent } from '../content-marketing/content-marketing.component';
import { PolicyFooterComponent } from '../policy-footer/policy-footer.component';

@Component({
	selector: 'app-auth-layout',
	standalone: true,
	imports: [RouterOutlet, CommonModule, PolicyFooterComponent, ContentMarketingComponent],
	templateUrl: './auth-layout.component.html',
	styleUrl: './auth-layout.component.scss',
})
export class AuthLayoutComponent {}
