{
	"name": "serveox-mvp",
	"version": "0.49.2",
	"scripts": {
		"ng": "ng",
		"start": "ng serve --configuration local",
		"start:local": "ng serve --port 3000 --host 0.0.0.0",
		"build": "ng build",
		"build:local": "ng build --configuration local",
		"build:dev": "ng build --configuration development",
		"build:prod": "ng build --configuration production",
		"watch": "ng build --watch --configuration development",
		"cap:sync": "npx cap sync",
		"cap:copy": "npx cap copy",
		"cap:open:ios": "npx cap open ios",
		"cap:run:ios": "npx cap run ios -l --external",
		"cap:update:ios": "npx cap update ios",
		"test": "ng test",
		"lint": "ng lint",
		"update-version": "tsx scripts/update-version.ts"
	},
	"private": true,
	"dependencies": {
		"@angular/animations": "^17.2.0",
		"@angular/cdk": "^17.3.10",
		"@angular/common": "^17.2.0",
		"@angular/compiler": "^17.2.0",
		"@angular/core": "^17.2.0",
		"@angular/fire": "^17.1.0",
		"@angular/forms": "^17.2.0",
		"@angular/google-maps": "^17.3.10",
		"@angular/platform-browser": "^17.2.0",
		"@angular/platform-browser-dynamic": "^17.2.0",
		"@angular/router": "^17.2.0",
		"@capacitor-firebase/analytics": "^6.3.1",
		"@capacitor-firebase/app-check": "^6.3.1",
		"@capacitor-firebase/authentication": "^6.1.0",
		"@capacitor/android": "^6.1.1",
		"@capacitor/angular": "^2.0.3",
		"@capacitor/app": "^6.0.2",
		"@capacitor/core": "latest",
		"@capacitor/ios": "6.2.0",
		"@capacitor/push-notifications": "^6.0.4",
		"@capacitor/splash-screen": "^6.0.1",
		"@capacitor/toast": "^6.0.3",
		"@googlemaps/js-api-loader": "^1.16.8",
		"@ng-bootstrap/ng-bootstrap": "^16.0.0",
		"@ng-select/ng-select": "^12.0.7",
		"@popperjs/core": "^2.11.8",
		"@types/google.maps": "^3.58.1",
		"angular-calendar": "^0.31.1",
		"bootstrap": "^5.3.3",
		"bootstrap-icons": "^1.11.3",
		"date-fns": "^4.1.0",
		"firebase": "^10.12.5",
		"rxjs": "~7.8.0",
		"signature_pad": "^5.0.4",
		"tslib": "^2.3.0",
		"zone.js": "~0.14.3"
	},
	"devDependencies": {
		"@angular-devkit/build-angular": "^17.2.2",
		"@angular-eslint/builder": "17.5.2",
		"@angular-eslint/eslint-plugin": "17.5.2",
		"@angular-eslint/eslint-plugin-template": "17.5.2",
		"@angular-eslint/schematics": "17.5.2",
		"@angular-eslint/template-parser": "17.5.2",
		"@angular/cli": "^17.2.2",
		"@angular/compiler-cli": "^17.2.0",
		"@angular/localize": "^17.2.0",
		"@capacitor/assets": "^3.0.5",
		"@capacitor/cli": "6.2.0",
		"@ionic/cli": "^7.2.0",
		"@types/bootstrap": "^5.2.10",
		"@types/jasmine": "~5.1.0",
		"@typescript-eslint/eslint-plugin": "7.11.0",
		"@typescript-eslint/parser": "7.11.0",
		"eslint": "^8.57.0",
		"eslint-config-prettier": "^9.1.0",
		"eslint-plugin-prettier": "^5.2.1",
		"jasmine-core": "~5.1.0",
		"karma": "~6.4.0",
		"karma-chrome-launcher": "~3.2.0",
		"karma-coverage": "~2.2.0",
		"karma-jasmine": "~5.1.0",
		"karma-jasmine-html-reporter": "~2.1.0",
		"native-run": "^2.0.1",
		"prettier": "^3.3.3",
		"prettier-eslint": "^16.3.0",
		"tsx": "^4.19.2",
		"typescript": "~5.3.2"
	}
}
