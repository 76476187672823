import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, OnDestroy, OnInit } from '@angular/core';
import { DocumentReference, getDoc } from '@angular/fire/firestore';
import { RouterLink } from '@angular/router';
import { AuthService } from '@auth/services/auth.service';
import { LayoutService } from '@layout/services/layout/layout.service';
import { DesktopOrgUsersTableComponent } from '@organisations/components/desktop-org-users-table/desktop-org-users-table.component';
import { MobileOrgUsersTableComponent } from '@organisations/components/mobile-org-users-table/mobile-org-users-table.component';
import {
	IOrganisationUser,
	IOrganisationUserFull,
	OrganisationMemberStatus,
	OrganisationUserAccessLevel,
} from '@organisations/models/organisation-user';
import { IOrganisation } from '@organisations/models/organisations';
import { OrganisationRoutes } from '@organisations/organisation.routes';
import { OrganisationSelectedService } from '@organisations/services/organisation-selected/organisation-selected.service';
import { OrganisationUserService } from '@organisations/services/organisation-user/organisation-user.service';
import { LoadingStateComponent } from '@shared/components/loading-state/loading-state.component';
import { UnauthorisedBannerComponent } from '@shared/components/ui/unauthorised-banner/unauthorised-banner.component';
import { combineLatest, filter, from, map, Observable, of, switchMap, takeUntil } from 'rxjs';

@Component({
	selector: 'app-organisation-users',
	standalone: true,
	imports: [
		CommonModule,
		RouterLink,
		UnauthorisedBannerComponent,
		DesktopOrgUsersTableComponent,
		MobileOrgUsersTableComponent,
		LoadingStateComponent,
	],
	templateUrl: './organisation-users.component.html',
	styleUrl: './organisation-users.component.scss',
})
export class OrganisationUsersComponent implements OnInit, OnDestroy {
	destroyed$ = new EventEmitter<void>();
	organisation: IOrganisation;
	isMobileSize = true;
	userHasAccess = false;
	isLoading = true;

	OrganisationRoutes = OrganisationRoutes;
	private layoutService = inject(LayoutService);
	private organisationUserService = inject(OrganisationUserService);
	private organisationSelectedService = inject(OrganisationSelectedService);
	private authService = inject(AuthService);

	organisationMembers: Array<IOrganisationUserFull> = [];

	ngOnInit(): void {
		this.layoutService.isMobileSize$.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
			this.isMobileSize = value;
		});

		this.subscribeToSelectedOrganisation();
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}

	subscribeToSelectedOrganisation(): void {
		this.organisationSelectedService.selectedOrganisation
			.pipe(
				takeUntil(this.destroyed$),
				filter((o) => o !== null),
			)
			.subscribe((selectedOrg) => {
				if (selectedOrg?.accessLevel === OrganisationUserAccessLevel.OWNER) {
					this.userHasAccess = true;
					this.fetchOrganisationDetails(selectedOrg.organisation as DocumentReference<IOrganisation, IOrganisation>);
				} else {
					this.userHasAccess = false;
					this.isLoading = false;
				}
			});
	}

	fetchOrganisationDetails(orgRef: DocumentReference<IOrganisation, IOrganisation>): void {
		getDoc(orgRef)
			.then((doc) => {
				if (doc.exists()) {
					this.organisation = doc.data();
					this.organisation.id = doc.id;
					this.subscribeToOrganisationUsers(doc.id);
				}
			})
			.catch((error) => {
				this.isLoading = false;
				console.error('Failed to load organisation');
			});
	}

	subscribeToOrganisationUsers(orgId: string): void {
		this.organisationUserService
			.visibleUsersByOrganisation(orgId)
			.pipe(
				takeUntil(this.destroyed$),
				switchMap((organisationUsers) => {
					return combineLatest(organisationUsers.map((orgUser) => this.processOrganisationUser(orgUser)));
				}),
			)
			.subscribe((organisationUsers) => {
				this.organisationMembers = organisationUsers.sort((a, b) => {
					const nameA = a.displayName?.toLowerCase() || a.email.toLowerCase();
					const nameB = b.displayName?.toLowerCase() || b.email.toLowerCase();
					return nameA.localeCompare(nameB);
				});
				this.isLoading = false;
			});
	}

	private processOrganisationUser(orgUser: IOrganisationUser): Observable<IOrganisationUserFull> {
		const user = this.authService.currentUser;
		if (orgUser.user) {
			return from(getDoc(orgUser.user)).pipe(
				map((userDoc) => {
					const userData = userDoc.data();
					const isCurrentUser = user?.uid === userDoc.id;
					return {
						...orgUser,
						displayName: userData?.displayName,
						photoUrl: userData?.photoUrl,
						status: isCurrentUser ? OrganisationMemberStatus.CURRENTLY_LOGGED_IN : orgUser.status,
					};
				}),
			);
		} else {
			return of(orgUser);
		}
	}
}
