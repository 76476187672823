<form [formGroup]="form">
	<ngb-datepicker
		#dp
		[formControlName]="name"
		(dateSelect)="dateSelected($event)"
		[minDate]="today"
		[firstDayOfWeek]="7"
		[markDisabled]="isDisabled"
	>
	</ngb-datepicker>
</form>
