<form [formGroup]="formGroup">
	<ng-container *ngIf="showLabel && label">
		<label [for]="name" class="form-label">{{ label }}</label>
	</ng-container>
	<ng-select
		[items]="options"
		[formControlName]="name"
		[class.is-invalid]="control.invalid && control.touched"
		bindLabel="label"
		bindValue="value"
		[placeholder]="placeholder"
		[multiple]="multiple"
		[searchable]="searchable"
		[clearable]="clearable"
	>
		<ng-template ng-label-tmp let-item="item">
			<ng-container *ngIf="item.icon">
				<i class="bi {{ item.icon }}"></i>
			</ng-container>
			{{ item.label }}
		</ng-template>
	</ng-select>
	<div *ngIf="control.invalid && control.errors" class="invalid-feedback">
		<p *ngIf="control.errors?.required">{{ label }} required.</p>
	</div>
</form>
