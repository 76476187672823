import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, OnDestroy, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { MobileHeaderCustomerComponent } from '@layout/components/mobile-header-customer/mobile-header-customer.component';
import { SidebarCustomerComponent } from '@layout/components/sidebar-customer/sidebar-customer.component';
import { LayoutService } from '@layout/services/layout/layout.service';
import { takeUntil } from 'rxjs';

@Component({
	selector: 'app-lead-request-base',
	standalone: true,
	imports: [CommonModule, RouterOutlet, SidebarCustomerComponent, MobileHeaderCustomerComponent],
	templateUrl: './lead-request-base.component.html',
	styleUrl: './lead-request-base.component.scss',
})
export class LeadRequestBaseComponent implements OnInit, OnDestroy {
	private layoutService = inject(LayoutService);

	destroyed$ = new EventEmitter<void>();
	isMobileSize = true;

	ngOnInit(): void {
		this.layoutService.isMobileSize$.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
			this.isMobileSize = value;
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}
}
