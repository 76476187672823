import { Component, EventEmitter, inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrganisationSelectedService } from '@organisations/services/organisation-selected/organisation-selected.service';
import { LayoutService } from '@layout/services/layout/layout.service';
import { IAvailableOrganisation } from '@organisations/models/organisation-user';
import { BehaviorSubject, combineLatest, filter, switchMap, takeUntil } from 'rxjs';
import { PageTitleComponent } from '@shared/components/sections/page-title/page-title.component';
import { LeadsDashboardService } from '@leads/client-facing/services/leads-dashboard.service';
import { ILeadRequest, LeadRequestType, LeadStatus } from '@leads/shared/models/lead.models';
import { MobileLeadRequestsTableComponent } from '@leads/client-facing/components/mobile-lead-requests-table/mobile-lead-requests-table.component';
import { DesktopLeadRequestsTableComponent } from '@leads/client-facing/components/desktop-lead-requests-table/desktop-lead-requests-table.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientLeadsRoutes } from '@leads/client-facing/client-facing.routes';
import { LoadingStateComponent } from '@shared/components/loading-state/loading-state.component';

@Component({
	selector: 'app-leads-dashboard',
	standalone: true,
	imports: [CommonModule, PageTitleComponent, MobileLeadRequestsTableComponent, DesktopLeadRequestsTableComponent, LoadingStateComponent],
	templateUrl: './leads-dashboard.component.html',
	styleUrl: './leads-dashboard.component.scss',
})
export class LeadsDashboardComponent implements OnInit, OnDestroy {
	selectedOrganisation: IAvailableOrganisation | null;
	isLoading = true;
	isSearching = true;
	isMobileSize = true;
	leads: Array<ILeadRequest> = [];
	searchTerm$ = new BehaviorSubject<string>('');
	leadStatus$ = new BehaviorSubject<Array<LeadStatus>>([LeadStatus.NEW]);
	leadRequestType$ = new BehaviorSubject<LeadRequestType | null>(null);

	destroyed$ = new EventEmitter<void>();

	private router = inject(Router);
	private route = inject(ActivatedRoute);
	private leadsService = inject(LeadsDashboardService);
	private organisationSelectedService = inject(OrganisationSelectedService);
	private layoutService = inject(LayoutService);

	ngOnInit(): void {
		this.layoutService.isMobileSize$.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
			this.isMobileSize = value;
		});

		combineLatest([this.organisationSelectedService.selectedOrganisation, this.searchTerm$, this.leadStatus$, this.leadRequestType$])
			.pipe(
				takeUntil(this.destroyed$),
				filter(([selectedOrganisation]) => !!selectedOrganisation),
				switchMap(([selectedOrganisation, searchTerm, leadStatus, leadRequestType]) => {
					this.selectedOrganisation = selectedOrganisation;
					this.isSearching = true;
					return this.leadsService.getLeadRequestsByOrganisation(
						selectedOrganisation!.organisation,
						searchTerm,
						leadStatus,
						leadRequestType,
					);
				}),
			)
			.subscribe((leads) => {
				this.leads = leads;
				this.isLoading = false;
				this.isSearching = false;
			});
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}

	onClick(leadRequest: ILeadRequest) {
		this.router.navigate([ClientLeadsRoutes.ASSESSMENT_REVIEW.replace(':leadRequestId', leadRequest.id as string)], {
			relativeTo: this.route,
		});
	}
}
