import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { ToastEvent } from '@shared/models/toast';
import { ToastService } from '@shared/services/toast/toast.service';
import { ToastComponent } from '../toast/toast.component';

@Component({
	selector: 'app-toaster',
	templateUrl: './toaster.component.html',
	styleUrls: ['./toaster.component.scss'],
	standalone: true,
	imports: [CommonModule, ToastComponent],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToasterComponent implements OnInit {
	private toastService = inject(ToastService);

	currentToasts: ToastEvent[] = [];

	constructor(private cdr: ChangeDetectorRef) {}

	ngOnInit(): void {
		this.subscribeToToasts();
	}

	subscribeToToasts(): void {
		this.toastService.toastEvents$.subscribe((toasts) => {
			this.currentToasts.push(toasts);
			this.cdr.detectChanges();
		});
	}

	dispose(index: number): void {
		this.currentToasts.splice(index, 1);
		this.cdr.detectChanges();
	}
}
