import { AppRoutes } from 'src/app/app.routes';

import { inject } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { CanActivateFn, Router } from '@angular/router';

export const authGuard: CanActivateFn = async (route, state) => {
	const auth = inject(Auth);
	const router = inject(Router);

	return new Promise((resolve) => {
		auth.onAuthStateChanged((user) => {
			if (user) {
				resolve(true);
				return;
			}
			router.navigate([AppRoutes.HOME]);
			resolve(false);
		});
	});
};
