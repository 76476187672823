import { combineLatest, from, map, Observable, of, switchMap } from 'rxjs';

import { Injectable } from '@angular/core';
import { collectionData, doc, docData, DocumentReference, orderBy, query, Timestamp, where } from '@angular/fire/firestore';
import { FormGroup } from '@angular/forms';
import { ISchedulerForm } from '@leads/client-facing/models/domain/scheduled-assessment.domain';
import { LeadRequestType } from '@leads/shared/models/domain/lead-request.domain';
import {
	ScheduledAssessment,
	ScheduledAssessmentStatus,
	ScheduledAssessmentType,
} from '@leads/shared/models/domain/scheduled-assessment.domain';
import { ILeadRequest } from '@leads/shared/models/firebase/lead-request.model';
import { ILeadAssessment } from '@leads/shared/models/firebase/scheduled-assessment.model';
import { IOrganisation } from '@organisations/models/organisations';
import { FireStoreCollection } from '@shared/models/firestore';
import { FirestoreService } from '@shared/services/firestore/firestore.service';
import { IUser } from '@user/models/user';

@Injectable({
	providedIn: 'root',
})
export class LeadAssessmentService extends FirestoreService<ILeadAssessment> {
	constructor() {
		super(FireStoreCollection.LEAD_ASSESSMENTS);
	}

	createLeadAssessment(
		scheduledAssessmentForm: FormGroup<ISchedulerForm>,
		leadRequestId: string,
		organisationId: string,
	): Observable<ScheduledAssessment> {
		const scheduledAssessment: ILeadAssessment = {
			organisation: doc(this.firestore, `${FireStoreCollection.ORGANISATIONS}/${organisationId}`),
			assignment: {
				employee: doc(this.firestore, `${FireStoreCollection.USERS}/${scheduledAssessmentForm.controls.employeeId.value}`),
				dateTime: {
					start: Timestamp.fromDate(scheduledAssessmentForm.controls.selectedStartTime.value as Date),
					end: Timestamp.fromDate(scheduledAssessmentForm.controls.selectedEndTime.value as Date),
				},
			},
			leadRequest: doc(this.firestore, `${FireStoreCollection.LEAD_REQUESTS}/${leadRequestId}`),
			createdDate: Timestamp.now(),
			updatedDate: Timestamp.now(),
			whatsappNotes: scheduledAssessmentForm.controls.whatsappNotes.value as string,
			status: ScheduledAssessmentStatus.REQUESTED,
			requestType: scheduledAssessmentForm.controls.requestType.value as LeadRequestType,
			assessmentDetails: {
				serviceTypes: scheduledAssessmentForm.controls.assessments?.value as Array<string>,
				assessmentType: scheduledAssessmentForm.controls.assessmentType?.value as ScheduledAssessmentType,
				callOutFee: {
					amount: Number(scheduledAssessmentForm.controls.callOutFeeRands?.value),
					currency: 'ZAR',
					includeInCommunication: scheduledAssessmentForm.controls.includeCallOutFeeComms?.value as boolean,
				},
				internalAssessmentNotes: scheduledAssessmentForm.controls.assessmentNotes.value as string,
			},
		};

		return from(super.create(scheduledAssessment)).pipe(
			switchMap((documentRef) => from(this.getDocSnapshot(documentRef.id))),
			switchMap((documentSnapshot) => {
				const assessment = {
					id: documentSnapshot.id,
					...(documentSnapshot.data() as ILeadAssessment),
				};
				return this.mapToScheduledAssessment(assessment);
			}),
		);
	}

	getLeadAssessmentsByLeadRequest(leadRequestId: string): Observable<Array<ScheduledAssessment>> {
		const q = query(
			this.collectionRef,
			where('leadRequest', '==', doc(this.firestore, `${FireStoreCollection.LEAD_REQUESTS}/${leadRequestId}`)),
			orderBy('assignment.dateTime.start', 'desc'),
		);
		return collectionData(q, { idField: 'id' }).pipe(
			switchMap((assessments: Array<ILeadAssessment>) => {
				if (assessments.length === 0) {
					return of([]);
				}
				const assessmentWithAdditionalData$ = assessments.map((assessment) => this.mapToScheduledAssessment(assessment));
				return combineLatest(assessmentWithAdditionalData$);
			}),
		);
	}

	private mapToScheduledAssessment(assessment: ILeadAssessment): Observable<ScheduledAssessment> {
		const organisationData$ = docData(assessment.organisation as DocumentReference<IOrganisation>, { idField: 'id' });
		const employeeData$ = docData(assessment.assignment.employee as DocumentReference<IUser>, { idField: 'id' });
		const leadRequestData = docData(assessment.leadRequest as DocumentReference<ILeadRequest>, { idField: 'id' });

		return combineLatest([organisationData$, employeeData$, leadRequestData]).pipe(
			map(
				([organisation, employee, leadRequest]) =>
					({
						id: assessment.id,
						organisationId: organisation?.id,
						organisationName: organisation?.companyName,

						leadRequestId: leadRequest?.id,
						leadRequestType: assessment.requestType ?? null,

						assignedEmployee: {
							userId: employee?.uid ? employee.uid : '',
							name: employee?.displayName ? employee.displayName : '',
						},

						selectedStartTime: assessment.assignment.dateTime.start.toDate(),
						selectedEndTime: assessment.assignment.dateTime.end.toDate(),

						assessmentServiceTypes: assessment.assessmentDetails.serviceTypes,
						assessmentType: assessment.assessmentDetails.assessmentType,
						callOutFeeRands: assessment.assessmentDetails.callOutFee?.amount,
						includeCallOutFeeComms: assessment.assessmentDetails.callOutFee?.includeInCommunication,
						assessmentNotes: assessment.assessmentDetails.internalAssessmentNotes,
						whatsappNotes: assessment.whatsappNotes,
						status: assessment.status,

						customerName: leadRequest?.personalDetails.name,
					}) as ScheduledAssessment,
			),
		);
	}

	getLeadAssessment(leadAssessmentId: string): Observable<ILeadAssessment | undefined> {
		return super.getDocumentData(leadAssessmentId);
	}

	acceptAppointment(assessmentId: string) {
		return super.update(assessmentId, { status: ScheduledAssessmentStatus.SCHEDULED });
	}

	declineAppointment(assessmentId: string) {
		return super.update(assessmentId, { status: ScheduledAssessmentStatus.DECLINED });
	}

	updateScheduledAssessmentStatus(assessmentId: string, status: ScheduledAssessmentStatus) {
		return super.update(assessmentId, { status });
	}

	updateScheduledAssessmentEmployee(assessmentId: string, employeeId: string) {
		return super.update(assessmentId, { 'assignment.employee': doc(this.firestore, `${FireStoreCollection.USERS}/${employeeId}`) });
	}

	updateScheduledAssessmentNotes(assessmentId: string, assessmentNotes: string) {
		return super.update(assessmentId, { 'assessmentDetails.internalAssessmentNotes': assessmentNotes });
	}
}
