import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
	CalendarCommonModule,
	CalendarDayModule,
	CalendarEvent,
	CalendarEventTimesChangedEvent,
	CalendarWeekModule
} from 'angular-calendar';
import { CommonModule } from '@angular/common';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { CalendarPopoverComponent } from '@schedule/components/calendar-popover/calendar-popover.component';

@Component({
	selector: 'app-calendar-day-view',
	standalone: true,
	imports: [
		CommonModule,
		CalendarDayModule,
		NgbPopover,
		CalendarWeekModule,
		CalendarPopoverComponent,
		CalendarCommonModule
	],
	templateUrl: './calendar-day-view.component.html',
	styleUrl: './calendar-day-view.component.scss'
})
export class CalendarDayViewComponent {
	@Input() events: CalendarEvent[];
	@Input() refresh: any;
	@Input() viewDate: Date;
	@Output() eventClicked = new EventEmitter<CalendarEvent>();
	@Output() eventTimesChanged = new EventEmitter<CalendarEventTimesChangedEvent>();

	handleEventClicked(event: CalendarEvent): void {
		this.eventClicked.emit(event);
	}

	handleEventTimesChanged(event: CalendarEventTimesChangedEvent): void {
		this.eventTimesChanged.emit(event);
	}

	toggleEventPopover(popover: NgbPopover, event: CalendarEvent): void {
		console.log('toggleEventPopover', 'event', event);
		if (popover.isOpen()) {
			popover.close();
		} else {
			popover.open({ event });
		}
	}
}
