import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import {
	CalendarCommonModule,
	CalendarEvent,
	CalendarEventTimesChangedEvent,
	CalendarWeekModule
} from 'angular-calendar';
import { DatePipe, LowerCasePipe, NgIf, NgStyle } from '@angular/common';
import { CalendarDayCellComponent } from '@schedule/components/calendar-day-cell/calendar-day-cell.component';
import { CalendarPopoverComponent } from '@schedule/components/calendar-popover/calendar-popover.component';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import {
	CalendarWeekHeadersComponent
} from '@schedule/components/calendar-week-headers/calendar-week-headers.component';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
	selector: 'app-calendar-week-view',
	standalone: true,
	imports: [
		CalendarWeekModule,
		CalendarCommonModule,
		CalendarDayCellComponent,
		CalendarPopoverComponent,
		NgStyle,
		DatePipe,
		LowerCasePipe,
		NgbPopover,
		NgIf,
		CalendarWeekHeadersComponent
	], changeDetection: ChangeDetectionStrategy.OnPush,

	templateUrl: './calendar-week-view.component.html',
	styleUrl: './calendar-week-view.component.scss'
})
export class CalendarWeekViewComponent {
	@Input() events: CalendarEvent[];
	@Input() refresh: any;
	@Input() viewDate: Date;
	@Output() eventClicked = new EventEmitter<CalendarEvent>();
	@Output() eventTimesChanged = new EventEmitter<CalendarEventTimesChangedEvent>();

	handleEventClicked(event: CalendarEvent): void {
		this.eventClicked.emit(event);
	}

	handleEventTimesChanged(event: CalendarEventTimesChangedEvent): void {
		this.eventTimesChanged.emit(event);
	}

	toggleEventPopover(popover: NgbPopover, event: CalendarEvent): void {
		console.log('toggleEventPopover', 'event', event);
		if (popover.isOpen()) {
			popover.close();
		} else {
			popover.open({ event });
		}
	}


	daysInWeek = 7;

	private destroy$ = new Subject<void>();

	constructor(
		private breakpointObserver: BreakpointObserver,
		private cd: ChangeDetectorRef
	) {
	}

	ngOnInit() {
		const CALENDAR_RESPONSIVE = {
			small: {
				breakpoint: '(max-width: 375px)',
				daysInWeek: 2
			},
			medium: {
				breakpoint: '(max-width: 450px)',
				daysInWeek: 3
			},
			large: {
				breakpoint: '(max-width: 600px)',
				daysInWeek: 5
			},
			x_large: {
				breakpoint: '(max-width: 768px)',
				daysInWeek: 7
			}
		};

		this.breakpointObserver
			.observe(
				Object.values(CALENDAR_RESPONSIVE).map(({ breakpoint }) => breakpoint)
			)
			.pipe(takeUntil(this.destroy$))
			.subscribe((state: BreakpointState) => {
				const foundBreakpoint = Object.values(CALENDAR_RESPONSIVE).find(
					({ breakpoint }) => !!state.breakpoints[breakpoint]
				);
				if (foundBreakpoint) {
					this.daysInWeek = foundBreakpoint.daysInWeek;
				} else {
					this.daysInWeek = 7;
				}
				this.cd.markForCheck();
			});
	}

	ngOnDestroy() {
		this.destroy$.next();
	}
}
