import { takeUntil } from 'rxjs';

import { CommonModule, Location } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { LayoutService } from '@layout/services/layout/layout.service';
import { LeadRequestFormLinkService } from '@leads/shared/services/lead-request-form-link/lead-request-form-link.service';
import { IWhatsAppContact, WhatsappService } from '@shared/services/whatsapp/whatsapp.service';

@Component({
	selector: 'app-page-title',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './page-title.component.html',
	styleUrl: './page-title.component.scss',
})
export class PageTitleComponent implements OnInit, OnDestroy {
	@Input() title: string;
	@Input() showBackButton: boolean;
	@Input() showWhatsAppButton: boolean;
	@Input() whatsAppContact?: IWhatsAppContact;
	@Input() showLeadFormLink = true;

	destroyed$ = new EventEmitter<void>();

	isMobileSize = true;

	private whatsAppService = inject(WhatsappService);
	private leadsLinkService = inject(LeadRequestFormLinkService);
	private layoutService = inject(LayoutService);
	private location = inject(Location);

	ngOnInit(): void {
		this.layoutService.isMobileSize$.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
			this.isMobileSize = value;
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}

	openClientWhatsApp() {
		if (this.whatsAppContact) {
			this.whatsAppService.startChatWithContact({ ...this.whatsAppContact, openingText: true });
		}
	}

	copyToClipboard(): void {
		this.leadsLinkService.copyNewLeadLink();
	}

	goBack(): void {
		this.location.back();
	}
}
