<div class="subpage-container">
	<app-alert
		*ngIf="leadRequest.status === LeadStatus.REJECTED"
		[alertType]="AlertTypes.DANGER"
		[content]="alertContent"
		[marginBottom]="8"
	></app-alert>
	<ng-template #alertContent>
		This lead request has been marked as rejected and changes are no longer possible. If you need to make changes, please recover the
		request.
	</ng-template>

	<app-accordion gap="10px" parentId="assessmentRequest">
		<app-accordion-item itemId="contactDetails" parentId="assessmentRequest">
			<div header>Contact Details</div>
			<div body class="section-column">
				<div class="section-column">
					<div class="section-header">
						<h6 class="text-primary">Contact Information</h6>
						<button (click)="viewClient()" class="btn btn-sm btn-primary" type="button">
							<i class="bi bi-arrow-up-right-circle"> </i>
							View Client
						</button>
					</div>
					<div class="section-information">
						<div class="label">
							<div class="name">Customer Name</div>
							<div class="value">{{ client.clientName }}</div>
						</div>
						<ng-container *ngIf="client.properties.length">
							<div class="label">
								<div class="name">Properties</div>
								<div class="value">
									<ng-container *ngFor="let property of client.properties">
										<app-link-location [property]="property"></app-link-location>
									</ng-container>
								</div>
							</div>
						</ng-container>
						<div class="label">
							<div class="name">Phone</div>
							<div class="value">({{ client.clientNumber.countryCode }} ) {{ client.clientNumber.number }}</div>
						</div>
						<div class="label">
							<div class="name">Email</div>
							<div class="value">{{ client.clientEmail }}</div>
						</div>
					</div>
				</div>
				<div class="section-column">
					<h6 class="text-primary">Internal Client Notes</h6>
					<textarea [disabled]="readonlyView" class="form-control" placeholder="Client Internal Notes" readonly>{{
						client.internalNotes
					}}</textarea>
				</div>
			</div>
		</app-accordion-item>

		<app-accordion-item [isOpen]="true" itemId="clientRequirements" parentId="assessmentRequest">
			<div header>Client Requirements - {{ leadRequest.requestType }}</div>
			<div body class="section-column">
				<div class="section-column">
					<h6 class="text-primary">Request Information</h6>
					<div class="section-information">
						<div class="label">
							<div class="name">Submitted Date</div>
							<div class="value">{{ leadRequest.createdDate.toMillis() | date: 'dd/MM/yyyy' }}</div>
						</div>
						<ng-container *ngIf="leadRequest.requestType === LeadRequestType.ON_SITE_VISIT">
							<div class="label">
								<div class="name">Requested Date</div>
								<div class="value">
									<ng-container
										*ngIf="leadRequest.onSiteVisitAssessment?.dateTime?.type === LeadRequestDateTimeType.REQUEST"
									>
										{{
											leadRequest.onSiteVisitAssessment?.dateTime?.requestedDate | ngbDateToDate | date: 'dd/MM/yyyy'
										}}
									</ng-container>
									<ng-container
										*ngIf="leadRequest.onSiteVisitAssessment?.dateTime?.type === LeadRequestDateTimeType.ASAP"
									>
										ASAP
									</ng-container>
								</div>
							</div>
							<div class="label">
								<div class="name">Time</div>
								<div class="value">
									<ng-container
										*ngIf="leadRequest.onSiteVisitAssessment?.dateTime?.type === LeadRequestDateTimeType.REQUEST"
									>
										{{ leadRequest.onSiteVisitAssessment?.dateTime?.requestedTimeWindow }}
									</ng-container>
									<ng-container
										*ngIf="leadRequest.onSiteVisitAssessment?.dateTime?.type === LeadRequestDateTimeType.ASAP"
									>
										ASAP
									</ng-container>
								</div>
							</div>
						</ng-container>
						<div class="label">
							<div class="name">
								Address
								<ng-container *ngIf="leadRequest.personalDetails.installationAddress.fullAddress">
									<button
										type="button"
										class="btn btn-sm btn-outline-primary border-0"
										title="Edit"
										(click)="editProperty()"
									>
										<i class="bi bi-pencil-fill"> </i>
									</button>
								</ng-container>
							</div>
							<div class="value">
								<ng-container *ngIf="leadRequest.personalDetails.installationAddress.fullAddress; else propertyAdd">
									<app-link-location [property]="leadRequest.personalDetails.installationAddress"></app-link-location>
								</ng-container>
								<ng-template #propertyAdd>
									<button type="button" class="btn btn-sm btn-primary" (click)="addProperty()">
										<i class="bi bi-plus"> </i>
										Add
									</button>
								</ng-template>
							</div>
						</div>
					</div>
				</div>

				<ng-container *ngIf="leadRequest.personalDetails.customQuestions?.length">
					<div class="section-column">
						<h6 class="text-primary">Additional Information</h6>
						<ng-container *ngFor="let customQuestion of leadRequest.personalDetails.customQuestions">
							<div class="section-information">
								<div class="label">
									<div class="name">{{ customQuestion.question }}</div>
									<div class="value">{{ customQuestion.answer }}</div>
								</div>
							</div>
						</ng-container>
					</div>
				</ng-container>

				<div class="section-column">
					<h6 class="text-primary">Assessment Type</h6>
					<ng-container *ngIf="leadRequest.requestType === LeadRequestType.ON_SITE_VISIT">
						<div class="section-information">
							<div class="label">
								<div class="name">Requested Assessment</div>
								<div class="value">{{ leadRequest.onSiteVisitAssessment?.serviceType }}</div>
							</div>
						</div>
						<ng-container *ngIf="leadRequest.onSiteVisitAssessment?.customQuestions?.length">
							<ng-container *ngFor="let customQuestion of leadRequest.onSiteVisitAssessment?.customQuestions">
								<div class="section-information">
									<div class="label">
										<div class="name">{{ customQuestion.question }}</div>
										<div class="value">{{ customQuestion.answer }}</div>
									</div>
								</div>
							</ng-container>
						</ng-container>
					</ng-container>
					<ng-container *ngIf="leadRequest.requestType === LeadRequestType.CALL">
						<div class="section-information">
							<div class="label">
								<div class="name">Requested Assessment</div>
								<div class="value">Call</div>
							</div>
						</div>
						<ng-container *ngIf="leadRequest.callAssessment?.customQuestions?.length">
							<ng-container *ngFor="let customQuestion of leadRequest.callAssessment?.customQuestions">
								<div class="section-information">
									<div class="label">
										<div class="name">{{ customQuestion.question }}</div>
										<div class="value">{{ customQuestion.answer }}</div>
									</div>
								</div>
							</ng-container>
						</ng-container>
					</ng-container>
				</div>
				<div class="section-column">
					<h6 class="text-primary">Assessment Notes</h6>
					<div class="section-information">
						<div class="label">
							<ng-container *ngIf="leadRequest.requestType === LeadRequestType.ON_SITE_VISIT">
								<textarea
									class="form-control"
									[style.height.px]="100"
									placeholder="Assessment Notes"
									readonly
									[disabled]="readonlyView"
									>{{ leadRequest.onSiteVisitAssessment?.notes }}</textarea
								>
							</ng-container>
							<ng-container *ngIf="leadRequest.requestType === LeadRequestType.CALL">
								<textarea
									class="form-control"
									[style.height.px]="100"
									placeholder="Assessment Notes"
									readonly
									[disabled]="readonlyView"
									>{{ leadRequest.callAssessment?.callNotes }}</textarea
								>
							</ng-container>
						</div>
					</div>
				</div>
			</div>
		</app-accordion-item>

		<app-accordion-item
			(toggle)="openScheduleItemsListModel()"
			[disableExpand]="true"
			itemId="scheduleItemsModal"
			parentId="assessmentRequest"
		>
			<div header>Schedule Items</div>
		</app-accordion-item>

		<app-accordion-item itemId="notes" parentId="assessmentRequest">
			<div header>Internal Notes</div>
			<div body class="section-column">
				<h6 class="text-primary">Internal Notes</h6>
				<app-textarea-control
					[formGroup]="internalNotesForm"
					[resizable]="true"
					[heightPx]="150"
					label="Internal Notes"
					name="internalNotes"
					placeholder="Internal Notes"
				></app-textarea-control>
			</div>
		</app-accordion-item>
	</app-accordion>
</div>
