<ng-container *ngIf="isMobileSize; else desktopSize">
	<div class="create-organisation-container">
		<div class="heading">
			<app-page-title title="Create Organisation"></app-page-title>
		</div>

		<div class="content">
			<app-mobile-organisation-details [organisationForm]="organisationForm"></app-mobile-organisation-details>
		</div>

		<div class="footer">
			<button
				type="button"
				class="btn btn-sm btn-light border-primary text-primary w-100"
				(click)="handleLocalSave()"
				[disabled]="organisationForm.pristine"
			>
				Save & Exit
			</button>
			<button type="button" class="btn btn-sm btn-primary w-100" (click)="handleNext()" [disabled]="organisationForm.invalid">
				Next
				<i class="bi bi-arrow-right"></i>
			</button>
		</div>
	</div>
</ng-container>
<ng-template #desktopSize>
	<div class="create-organisation-container">
		<div class="heading">
			<app-page-title title="Create Organisation"></app-page-title>
		</div>

		<div class="content">
			<app-desktop-organisation-details [organisationForm]="organisationForm"></app-desktop-organisation-details>
		</div>

		<div class="footer">
			<button
				type="button"
				class="btn btn-sm btn-light border-primary btn-width text-primary"
				(click)="handleLocalSave()"
				[disabled]="organisationForm.pristine"
			>
				Save & Continue later
			</button>
			<button type="button" class="btn btn-sm btn-primary btn-width" (click)="handleNext()" [disabled]="organisationForm.invalid">
				<ng-container *ngIf="isSaving; else notSaving">
					<div class="spinner-border spinner-border-sm" role="status">
						<span class="visually-hidden">Loading...</span>
					</div>
				</ng-container>
				<ng-template #notSaving>
					Next
					<i class="bi bi-arrow-right"></i>
				</ng-template>
			</button>
		</div>
	</div>
</ng-template>
