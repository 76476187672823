export enum AnalyticEvent {
	APP_LOAD = 'app_load',
	GOOGLE_SIGN_IN = 'sign_in_google',
	LOGIN = 'login',
	SIGN_UP = 'sign_up',
	SIGN_OUT = 'sign_out',
	PASSWORD_RESET = 'password_reset',
	PASSWORD_CHANGE = 'password_change',
	PROFILE_UPDATE = 'profile_update',
}
