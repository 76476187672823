<div class="subpage-container">
	<div class="section-group">
		<div class="section">
			<div class="section-column">
				<div class="section-header">
					<h6 class="text-primary">Contact Information</h6>
					<button class="btn btn-sm btn-primary" type="button" (click)="viewClient()">
						<i class="bi bi-arrow-up-right-circle"> </i>
						View Client
					</button>
				</div>
				<div class="section-information">
					<div class="label">
						<div class="name">Customer Name</div>
						<div class="value">{{ client.clientName }}</div>
					</div>
					<ng-container *ngIf="client.properties.length">
						<div class="label">
							<div class="name">Address</div>
							<div class="value">
								{{ client.properties[0].fullAddress }}, {{ client.properties[0].postalCode }},
								{{ client.properties[0].city }},
								{{ client.properties[0].country }}
							</div>
						</div>
					</ng-container>
					<div class="label">
						<div class="name">Phone</div>
						<div class="value">({{ client.clientNumber.countryCode }}) {{ client.clientNumber.number }}</div>
					</div>
					<div class="label">
						<div class="name">Email</div>
						<div class="value">{{ client.clientEmail }}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="section">
			<div class="section-column">
				<h6 class="text-primary">Internal Client Notes</h6>
				<textarea class="form-control" readonly placeholder="Client Internal Notes">{{ client.internalNotes }}</textarea>
			</div>
		</div>
	</div>

	<div class="section">
		<div class="section-group">
			<div class="section-column">
				<h6 class="text-primary">Requested Date & Time</h6>
				<div class="section-information">
					<div class="label">
						<div class="name">Submitted Date</div>
						<div class="value">{{ leadRequest.createdDate.toMillis() | date: 'dd/MM/yyyy' }}</div>
					</div>
					<ng-container *ngIf="leadRequest.requestType === LeadrequestType.ON_SITE_VISIT">
						<div class="label">
							<div class="name">Requested Date</div>
							<div class="value">
								<ng-container *ngIf="leadRequest.onSiteVisitAssessment?.dateTime?.type === LeadrequestDateTimeType.REQUEST">
									{{ leadRequest.onSiteVisitAssessment?.dateTime?.requestedDate | ngbDateToDate | date: 'dd/MM/yyyy' }}
								</ng-container>
								<ng-container *ngIf="leadRequest.onSiteVisitAssessment?.dateTime?.type === LeadrequestDateTimeType.ASAP">
									ASAP
								</ng-container>
							</div>
						</div>
						<div class="label">
							<div class="name">Time</div>
							<div class="value">
								<ng-container *ngIf="leadRequest.onSiteVisitAssessment?.dateTime?.type === LeadrequestDateTimeType.REQUEST">
									{{ leadRequest.onSiteVisitAssessment?.dateTime?.requestedTimeWindow }}
								</ng-container>
								<ng-container *ngIf="leadRequest.onSiteVisitAssessment?.dateTime?.type === LeadrequestDateTimeType.ASAP">
									ASAP
								</ng-container>
							</div>
						</div>
						<div class="label">
							<div class="name">Address</div>
							<div class="value">
								{{ leadRequest.personalDetails.installationAddress.fullAddress }},
								{{ leadRequest.personalDetails.installationAddress.city }},
								{{ leadRequest.personalDetails.installationAddress.country }}
							</div>
						</div>
					</ng-container>
				</div>
			</div>
			<div class="section-column">
				<h6 class="text-primary">Assessment Type</h6>
				<div class="section-information">
					<ng-container *ngIf="leadRequest.requestType === LeadrequestType.ON_SITE_VISIT">
						<div class="label">
							<div class="name">Requested Assessment</div>
							<div class="value">{{ leadRequest.onSiteVisitAssessment?.serviceType }}</div>
						</div>
						<!-- TODO: @CRezelman Pull in Custom Question -->
						<div class="label">
							<div class="name">Do you have an escape Exit?</div>
							<div class="value">YES</div>
						</div>
					</ng-container>
					<ng-container *ngIf="leadRequest.requestType === LeadrequestType.CALL">
						<div class="label">
							<div class="name">Requested Assessment</div>
							<div class="value">Call</div>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
		<div class="section-group">
			<div class="section-column">
				<!-- TODO: @CRezelman Pull in Custom Question -->
				<h6 class="text-primary">Additional Information</h6>
				<div class="section-information">
					<div class="label">
						<div class="name">ID</div>
						<div class="value">9703145213088</div>
					</div>
				</div>
			</div>
			<div class="section-column">
				<h6 class="text-primary">Assessment Notes</h6>
				<div class="section-information">
					<div class="label">
						<ng-container *ngIf="leadRequest.requestType === LeadrequestType.ON_SITE_VISIT">
							<textarea class="form-control" readonly placeholder="Assessment Notes">{{
								leadRequest.onSiteVisitAssessment?.notes
							}}</textarea>
						</ng-container>
						<ng-container *ngIf="leadRequest.requestType === LeadrequestType.CALL">
							<textarea class="form-control" readonly placeholder="Assessment Notes">{{
								leadRequest.callAssessment?.callNotes
							}}</textarea>
						</ng-container>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="section">
		<div class="section-column">
			<div class="section-header">
				<h6 class="text-primary">Schedule Items</h6>
				<div class="section-header">
					<button class="btn btn-sm btn-primary" type="button" [routerLink]="'/' + AppRoutes.SCHEDULE">Open Scheduler</button>
					<button class="btn btn-sm btn-primary" type="button" (click)="addCallAssessment()">
						<i class="bi bi-plus"> </i>
						Schedule Call
					</button>
					<button class="btn btn-sm btn-primary" type="button" (click)="addOnSiteAssessment()">
						<i class="bi bi-plus"> </i>
						Schedule On Site
					</button>
				</div>
			</div>
			<ng-container *ngIf="scheduledItems.length; else noScheduledItems">
				<ng-container *ngFor="let item of scheduledItems.controls; let i = index">
					<app-desktop-assessment-request-schedule-item
						[schedulerFormItem]="item"
						[index]="i"
						(assessmentRemove)="removeAssessment($event)"
					></app-desktop-assessment-request-schedule-item>
				</ng-container>
			</ng-container>
			<ng-template #noScheduledItems>
				<em>No assessments have been scheduled yet.</em>
			</ng-template>
		</div>
	</div>

	<div class="section">
		<div class="section-column">
			<h6 class="text-primary">Internal Notes</h6>
			<app-textarea-control
				[resizable]="false"
				name="internalNotes"
				[formGroup]="internalNotesForm"
				label="Internal Notes"
				placeholder="Internal Notes"
			></app-textarea-control>
		</div>
	</div>
</div>
