<ng-container *ngIf="stepConfig.steps">
	<ng-container *ngIf="isMobileSize; else desktopSize">
		<div class="mobile-tabs-container">
			<ng-container *ngTemplateOutlet="prevButton"></ng-container>
			<ng-container *ngIf="stepConfig.ui === StepUI.WIZARD">
				<ul class="nav nav-underline nav-fill">
					<ng-container *ngFor="let step of unifiedSteps; let i = index">
						<li class="nav-item">
							<a
								#tabLink
								class="nav-link"
								[routerLink]="step.route"
								[class.active]="isActive(step)"
								[routerLinkActive]="step.route ? 'active' : ''"
								[class.disabled]="step.disabled"
								(click)="scrollToTab(i)"
							>
								{{ step.title.mobile }}
							</a>
						</li>
					</ng-container>
				</ul>
			</ng-container>
			<ng-container *ngTemplateOutlet="nextButton"></ng-container>
		</div>
	</ng-container>

	<ng-template #desktopSize>
		<div class="step-wrapper">
			<ng-container *ngTemplateOutlet="prevButton"></ng-container>
			<ng-container *ngIf="stepConfig.ui === StepUI.WIZARD">
				<div class="step-container">
					<ng-container *ngFor="let step of unifiedSteps; let i = index">
						<a
							#tabLink
							class="step"
							[routerLink]="step.route"
							[class.disabled]="step.disabled"
							[class.active]="isActive(step)"
							[routerLinkActive]="step.route ? 'active' : ''"
							(click)="scrollToTab(i)"
						>
							<div class="step-icon" [class.completed]="step.complete">
								<i class="bi" [ngClass]="step.complete ? 'bi-check-circle-fill' : step.icon"></i>
							</div>
							<div class="step-details">
								<ng-container *ngIf="stepConfig.showStepNumbers">
									<div class="step-number"></div>
									<div class="step-title-small">{{ step.title.desktop }}</div>
								</ng-container>
								<ng-container *ngIf="!stepConfig.showStepNumbers">
									<div class="step-title-large">{{ step.title.desktop }}</div>
								</ng-container>
							</div>
							<ng-container *ngIf="i + 1 !== stepConfig.steps.length && stepConfig.showStepChevrons">
								<div>
									<i class="bi bi-chevron-right"></i>
								</div>
							</ng-container>
						</a>
					</ng-container>
				</div>
			</ng-container>
			<ng-container *ngTemplateOutlet="nextButton"></ng-container>
		</div>
	</ng-template>
</ng-container>

<!-- Injectable templates -->
<ng-template #prevButton>
	<ng-container *ngIf="stepConfig.showNavButtons">
		<button
			class="btn btn-sm"
			[class.border-0]="stepConfig.navButtonStyle === NavButtonStyle.CHEVRON"
			[class.ps-0]="stepConfig.navButtonStyle === NavButtonStyle.CHEVRON"
			[class.btn-outline-primary]="stepConfig.navButtonStyle === NavButtonStyle.PRIMARY"
			(click)="scrollToPreviousTab()"
			[disabled]="currentTabIndex === 0 || stepConfig.steps[currentTabIndex - 1].disabled"
		>
			<ng-container *ngIf="stepConfig.navButtonStyle === NavButtonStyle.PRIMARY">
				<i class="bi bi-arrow-left"></i>
				Back
			</ng-container>
			<ng-container *ngIf="stepConfig.navButtonStyle === NavButtonStyle.CHEVRON">
				<i class="bi bi-chevron-left"></i>
			</ng-container>
		</button>
	</ng-container>
</ng-template>

<ng-template #nextButton>
	<ng-container *ngIf="stepConfig.showNavButtons">
		<button
			class="btn btn-sm"
			[class.border-0]="stepConfig.navButtonStyle === NavButtonStyle.CHEVRON"
			[class.pe-0]="stepConfig.navButtonStyle === NavButtonStyle.CHEVRON"
			[class.btn-outline-primary]="stepConfig.navButtonStyle === NavButtonStyle.PRIMARY"
			(click)="scrollToNextTab()"
			[disabled]="currentTabIndex === stepConfig.steps.length - 1 || stepConfig.steps[currentTabIndex + 1].disabled"
		>
			<ng-container *ngIf="stepConfig.navButtonStyle === NavButtonStyle.PRIMARY">
				Next
				<i class="bi bi-arrow-right"></i>
			</ng-container>
			<ng-container *ngIf="stepConfig.navButtonStyle === NavButtonStyle.CHEVRON">
				<i class="bi bi-chevron-right"></i>
			</ng-container>
		</button>
	</ng-container>
</ng-template>
