import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AlertTypes } from '@shared/models/alert';

@Component({
	selector: 'app-alert',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './alert.component.html',
	styleUrl: './alert.component.scss',
})
export class AlertComponent {
	@Input() content: string;
	@Input() alertType: AlertTypes = AlertTypes.INFO;
	@Input() alertIcon: string = 'bi-info-circle-fill';
}
