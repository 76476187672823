<form [formGroup]="formGroup">
	<ng-container *ngIf="showLabel">
		<label [for]="name">{{ label }}</label>
	</ng-container>
	<div class="input-group">
		<ng-container *ngIf="leftText">
			<span class="input-group-text">{{ leftText }}</span>
		</ng-container>
		<input
			[type]="type"
			[formControlName]="name"
			class="form-control"
			[class.is-invalid]="control.invalid && control.touched"
			[id]="name"
			[placeholder]="placeholder"
			[autocomplete]="autocomplete"
		/>
		<ng-container *ngIf="rightText">
			<span class="input-group-text">{{ rightText }}</span>
		</ng-container>
		<div *ngIf="control.invalid && control.errors" class="invalid-feedback">
			<p *ngIf="control.errors?.required">{{ label }} required.</p>
			<p *ngIf="control.errors?.email">Invalid email.</p>
			<p *ngIf="control.errors?.pattern">{{ patternMessage }}</p>
			<p *ngIf="control.errors?.minlength">{{ label }} must be at least {{ control.errors.minlength.requiredLength }} characters.</p>
			<p *ngIf="control.errors?.maxlength">{{ label }} must be less than {{ control.errors.maxlength.requiredLength }} characters.</p>
			<p *ngIf="control.errors?.min">{{ label }} must be at least {{ control.errors.min.min }}.</p>
			<p *ngIf="control.errors?.max">{{ label }} must be less than {{ control.errors.max.max }}</p>
		</div>
	</div>
</form>
