import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { CalendarEvents } from '@schedule/services/calendar/calendar.service';
import { CalendarEvent, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import 'zone.js';
import { LeadRequestType } from '@leads/shared/models/lead.models';
import { CalendarMonthViewComponent } from '@schedule/components/calendar-month-view/calendar-month-view.component';
import { CalendarWeekViewComponent } from '@schedule/components/calendar-week-view/calendar-week-view.component';
import { CalendarControlsComponent } from '@schedule/components/calendar-controls/calendar-controls.component';
import { NgSwitch, NgSwitchCase } from '@angular/common';
import { CalendarDayViewComponent } from '@schedule/components/calendar-day-view/calendar-day-view.component';


@Component({
	selector: 'app-calendar-view',
	standalone: true,
	imports: [
		CalendarMonthViewComponent,
		CalendarWeekViewComponent,
		CalendarDayViewComponent,
		CalendarControlsComponent,
		NgSwitchCase,
		NgSwitch,
	],
	templateUrl: './calendar-view.component.html',
	styleUrl: './calendar-view.component.scss'
})
export class CalendarViewComponent {
	@Input() view: CalendarView
	@Input() viewDate: Date = new Date();
	@Input() events: CalendarEvents;

	@Output() refresh = new EventEmitter<void>();
	@Output() dayClicked = new EventEmitter<{ date: Date; events: CalendarEvent[] }>();
	@Output() eventTimesChanged = new EventEmitter<CalendarEventTimesChangedEvent>();
	@Output() eventClicked = new EventEmitter<CalendarEvent>();

	@ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
	activeDayIsOpen: boolean = true;

	protected readonly CalendarView = CalendarView;
	protected readonly LeadRequestType = LeadRequestType;
}
