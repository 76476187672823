import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, OnDestroy, OnInit } from '@angular/core';
import { OrganisationUserService } from '@organisations/services/organisation-user/organisation-user.service';
import { PageTitleComponent } from '@shared/components/sections/page-title/page-title.component';
import { takeUntil } from 'rxjs';
import { IncompleteOrganisationBannerComponent } from '@organisations/components/banners/incomplete-organisation-banner/incomplete-organisation-banner.component';
import { IAvailableOrganisation } from '@organisations/models/organisation-user';
import { OrganisationInvitationBannerComponent } from '@organisations/components/banners/organisation-invitation-banner/organisation-invitation-banner.component';

@Component({
	selector: 'app-dashboard',
	standalone: true,
	imports: [PageTitleComponent, CommonModule, IncompleteOrganisationBannerComponent, OrganisationInvitationBannerComponent],
	templateUrl: './dashboard.component.html',
	styleUrl: './dashboard.component.scss',
})
export class DashboardComponent implements OnInit, OnDestroy {
	private organisationUserService = inject(OrganisationUserService);

	destroyed$ = new EventEmitter<void>();
	noOrganisationAvailable: boolean;
	organisationInvitations: Array<IAvailableOrganisation>;

	ngOnInit(): void {
		this.organisationUserService
			.organisationsByCurrentUser()
			.pipe(takeUntil(this.destroyed$))
			.subscribe((organisations) => {
				this.noOrganisationAvailable = !organisations.length;
			});

		this.organisationUserService
			.organisationInvitations()
			.pipe(takeUntil(this.destroyed$))
			.subscribe((organisationInvitations) => {
				this.organisationInvitations = organisationInvitations;
			});
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}
}
