<div class="subpage-container">
	<form [formGroup]="form" class="h-100">
		<div class="form-container">
			<app-header-popover heading="Contact Information" [infoPopOver]="infoPopOver"></app-header-popover>
			<div class="form-row">
				<app-standard-control
					type="text"
					name="name"
					[formGroup]="form"
					label="Name"
					placeholder="Name"
					autocomplete="name"
				></app-standard-control>
				<div>
					<app-standard-control
						type="email"
						name="emailAddress"
						[formGroup]="form"
						label="Email Address"
						placeholder="Email Address"
						autocomplete="email"
					></app-standard-control>
					<em style="font-size: small">Enter your email to receive notifications about updates to this request.</em>
				</div>

				<app-custom-form-details [form]="form.controls.customQuestions" [borderWrap]="false"></app-custom-form-details>
			</div>
		</div>
	</form>
</div>
