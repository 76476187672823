<div class="subpage-container">
	<app-alert
		content="Copy your custom link to share with clients via WhatsApp or share it privately. Clients can use
	this link to submit their service requests, and you'll be able to track how often the link is used with helpful
	metrics."
	></app-alert>

	<div class="section">
		<div class="section-column">
			<div class="section-header">
				<h6 class="text-primary">Lead Request Form Link</h6>
			</div>
			<div class="section-information">
				<div class="label">
					<div class="value text-start">
						<a [href]="service.getNewLeadLink()" target="_blank">
							Open Lead Request Form
							<i class="bi bi-box-arrow-up-right"></i>
						</a>
					</div>
				</div>
				<button class="btn btn-sm btn-primary btn-width" type="button" (click)="copyToClipboard()">
					<i class="bi bi-clipboard"></i>
					Copy to Clipboard
				</button>
			</div>
		</div>
	</div>
</div>
