<ng-container *ngIf="customQuestionsLength > 0">
	<div class="additional-info" [class.border-wrap]="borderWrap">
		<h5 class="text-primary">Additional Information</h5>
		<ng-container *ngFor="let control of form.controls | keyvalue">
			<app-standard-control
				type="text"
				[formGroup]="form"
				[name]="control.key"
				[label]="control.key"
				[placeholder]="control.key"
			></app-standard-control>
		</ng-container>
	</div>
</ng-container>
