import { Pipe, PipeTransform } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Pipe({
	name: 'ngbDateToDate',
	standalone: true
})
export class NgbDateToDatePipe implements PipeTransform {

	transform(value: NgbDateStruct | undefined): Date | null {
		if (!value) {
			return null;
		}
		const { year, month, day } = value;
		return new Date(year, month - 1, day);
	}

}
