<div class="clients-container">
	<div class="heading">
		<app-page-title title="Clients"></app-page-title>
	</div>

	<div class="content">
		<app-loading-state [isLoading]="isLoading" [loadedContent]="loadedContent"></app-loading-state>
		<ng-template #loadedContent>
			<ng-container *ngIf="userHasAccess; else noAccess">
				<div class="clients-container">
					<div class="content">
						<ng-container *ngIf="isMobileSize; else desktopSize">
							<app-mobile-clients-table
								[isLoading]="clientsLoading"
								[clients]="clients"
								(searchChange)="onSearchChange($event)"
							></app-mobile-clients-table>
						</ng-container>
						<ng-template #desktopSize>
							<app-desktop-clients-table
								[isLoading]="clientsLoading"
								[clients]="clients"
								(searchChange)="onSearchChange($event)"
							></app-desktop-clients-table>
						</ng-template>
					</div>
				</div>
			</ng-container>

			<ng-template #noAccess>
				<app-unauthorised-banner
					headingText="It looks like you do not have access to this organisation or do not have the required access level."
				></app-unauthorised-banner>
			</ng-template>
		</ng-template>
	</div>
</div>
