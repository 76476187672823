<div class="wrapper bg-serveox">
	<div class="container bg-serveox">
		<app-content-marketing></app-content-marketing>

		<button type="button" class="btn btn-primary" [routerLink]="['/', AppRoutes.AUTH, AuthRoutes.SIGNUP]">Login</button>
		<button type="button" class="btn btn-light" [routerLink]="['/', AppRoutes.AUTH, AuthRoutes.SIGNUP]">Signup</button>

		<app-policy-footer></app-policy-footer>
	</div>
</div>
