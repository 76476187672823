import { takeUntil } from 'rxjs';

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { ILeadRequestForm } from '@leads/customer-facing/models/domain/lead-request.domain';
import { LeadRequestFormService } from '@leads/customer-facing/services/lead-request-form/lead-request-form.service';
import { CustomerLeadRequestRoutes } from '@leads/leads.routes';
import { LeadRequestType } from '@leads/shared/models/domain/lead-request.domain';
import { IOrganisation } from '@organisations/models/organisations';
import { OrganisationService } from '@organisations/services/organisation/organisation.service';
import { LoadingStateComponent } from '@shared/components/loading-state/loading-state.component';
import { PageTitleComponent } from '@shared/components/sections/page-title/page-title.component';
import { StepNavigatorComponent } from '@shared/components/ui/step-navigator/step-navigator.component';
import { UnauthorisedBannerComponent } from '@shared/components/ui/unauthorised-banner/unauthorised-banner.component';
import { IStepNavigatorConfig, NavButtonStyle, StepModes, StepUI } from '@shared/models/step-navigator';

@Component({
	selector: 'app-lead-request',
	standalone: true,
	imports: [PageTitleComponent, RouterOutlet, StepNavigatorComponent, CommonModule, UnauthorisedBannerComponent, LoadingStateComponent],
	templateUrl: './lead-request.component.html',
	styleUrl: './lead-request.component.scss',
})
export class LeadRequestComponent implements OnInit, OnDestroy {
	private organisationService = inject(OrganisationService);
	private leadRequestFormService = inject(LeadRequestFormService);
	private route = inject(ActivatedRoute);
	destroyed$ = new EventEmitter<void>();
	isLoading = true;
	organisationExists: boolean;

	organisation: IOrganisation;
	leadRequestForm: FormGroup<ILeadRequestForm>;
	stepConfig: IStepNavigatorConfig;

	ngOnInit(): void {
		const organisationId = this.route.parent?.snapshot.params.organisationId;

		this.leadRequestForm = this.leadRequestFormService.getForm();

		this.setNavigation();

		this.leadRequestForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
			this.setNavigation();
		});

		this.organisationService.getDocSnapshot(organisationId).then((doc) => {
			this.organisationExists = doc.exists();

			if (doc.exists()) {
				this.organisation = doc.data();
				this.leadRequestFormService.updateCustomQuestions(this.organisation.settings.leadsForm || []);
			}
			this.isLoading = false;
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}

	setNavigation(): void {
		const step1Disabled = false;
		const step2Disabled = this.leadRequestForm.controls.mobileNumber.invalid;
		const step3Disabled = step2Disabled || this.leadRequestForm.controls.requestType.invalid;
		let step4Disabled: boolean = step3Disabled || this.leadRequestForm.controls.contactDetails.invalid;

		if (this.leadRequestForm.controls.requestType.value === LeadRequestType.ON_SITE_VISIT) {
			step4Disabled = step4Disabled || this.leadRequestForm.controls.installationAddress.invalid;
		}

		const step1Complete = this.leadRequestForm.controls.mobileNumber.valid;
		const step2Complete = step1Complete && this.leadRequestForm.controls.requestType.valid;
		let step3Complete = step2Complete && this.leadRequestForm.controls.contactDetails.valid;

		if (this.leadRequestForm.controls.requestType.value === LeadRequestType.ON_SITE_VISIT) {
			step3Complete = step3Complete && this.leadRequestForm.controls.installationAddress.valid;
		}

		let step4Complete: boolean = false;

		if (this.leadRequestForm.controls.requestType.value === LeadRequestType.ON_SITE_VISIT) {
			step4Complete = step3Complete && this.leadRequestForm.controls.onsiteAssessment.valid;
		}
		if (this.leadRequestForm.controls.requestType.value === LeadRequestType.CALL) {
			step4Complete = step3Complete && this.leadRequestForm.controls.callAssessment.valid;
		}

		this.stepConfig = {
			ui: StepUI.WIZARD,
			mode: StepModes.ROUTER_OUTLET,
			showStepChevrons: true,
			showStepNumbers: true,
			showNavButtons: true,
			navButtonStyle: NavButtonStyle.CHEVRON,
			steps: [
				{
					title: {
						desktop: 'Mobile Number',
						mobile: 'Mobile Number',
					},
					icon: 'bi-briefcase',
					route: [CustomerLeadRequestRoutes.NEW],
					disabled: step1Disabled,
					complete: step1Complete,
				},
				{
					title: {
						desktop: 'Request Type',
						mobile: 'Request Type',
					},
					icon: 'bi-clipboard',
					route: [CustomerLeadRequestRoutes.REQUEST_TYPE],
					disabled: step2Disabled,
					complete: step2Complete,
				},
				{
					title: {
						desktop: 'Personal Details',
						mobile: 'Personal Details',
					},
					icon: 'bi-receipt',
					route: [CustomerLeadRequestRoutes.PERSONAL_DETAILS],
					disabled: step3Disabled,
					complete: step3Complete,
				},
				{
					title: {
						desktop: 'Assessment Details',
						mobile: 'Assessment Details',
					},
					icon: 'bi-receipt',
					route: [CustomerLeadRequestRoutes.APPOINTMENT_TIME],
					disabled: step4Disabled,
					complete: step4Complete,
				},
			],
		};
	}
}
