import { Timestamp } from '@angular/fire/firestore';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { FireStoreDocument } from '@shared/models/firestore';

export interface IOrganisation extends FireStoreDocument {
	companyName: string;
	companyContactNumber: string;
	companyRegistrationNumber: string | null;
	companyContactName: string;
	companySize: number;
	industry: OrganisationIndustry;
	companyContactEmail: string;
	createDate: Timestamp;
	status: OrganisationStatus;
	settings: IOrganisationSettings;
}

export interface ICustomFormItem {
	question: string;
	required: boolean;
	category: OrganisationCustomQuestionCategories;
}

export interface IOrganisationSettings {
	businessHours: IBusinessHours;
	serviceTypes: Array<string>;
	leadsForm: Array<ICustomFormItem>;
}

export interface IBusinessHours {
	monday: boolean;
	tuesday: boolean;
	wednesday: boolean;
	thursday: boolean;
	friday: boolean;
	saturday: boolean;
	sunday: boolean;
}

export enum OrganisationCustomQuestionCategories {
	CONTACT_INFORMATION = 'CONTACT_INFORMATION',
	ONSITE_ASSESSMENT_INFORMATION = 'ONSITE_ASSESSMENT_INFORMATION',
	CALL_ASSESSMENT_INFORMATION = 'CALL_ASSESSMENT_INFORMATION',
	ONSITE_AND_CALL_ASSESSMENT_INFORMATION = 'ONSITE_AND_CALL_ASSESSMENT_INFORMATION',
}

export enum OrganisationStatus {
	ACTIVE = 'ACTIVE',
	OVERDUE = 'OVERDUE',
	SUSPENDED = 'SUSPENDED',
	TRIAL = 'TRIAL',
}

export enum OrganisationIndustry {
	PLUMBING = 'Plumbing',
	ELECTRICIAN = 'Electrician',
	OTHER = 'Other',
}

export interface IOrganisationForm {
	companyName: FormControl<string | null>;
	companyContactNumber: FormControl<string | null>;
	companyRegistrationNumber: FormControl<string | null>;
	companyContactName: FormControl<string | null>;
	companySize: FormControl<number | null>;
	industry: FormControl<OrganisationIndustry | null>;
	companyContactEmail: FormControl<string | null>;
}

export interface IOrganisationBusinessHoursForm {
	monday: FormControl<boolean | null>;
	tuesday: FormControl<boolean | null>;
	wednesday: FormControl<boolean | null>;
	thursday: FormControl<boolean | null>;
	friday: FormControl<boolean | null>;
	saturday: FormControl<boolean | null>;
	sunday: FormControl<boolean | null>;
}

export interface IOrganisationServiceTypesForm {
	serviceTypes: FormArray<FormControl<string | null>>;
}

export interface ICustomFormItemForm {
	question: FormControl<string | null>;
	required: FormControl<boolean | null>;
	category: FormControl<OrganisationCustomQuestionCategories | null>;
}

export interface IOrganisationAssessmentForm {
	customFormItems: FormArray<FormGroup<ICustomFormItemForm>>;
}
