import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { OrganisationSettingsRoutes } from '@settings/settings.routes';
import { PageTitleComponent } from '@shared/components/sections/page-title/page-title.component';
import { StepNavigatorComponent } from '@shared/components/ui/step-navigator/step-navigator.component';
import { IStepNavigatorConfig, StepModes, StepUI } from '@shared/models/step-navigator';

@Component({
	selector: 'app-settings-organisation',
	standalone: true,
	imports: [CommonModule, PageTitleComponent, RouterOutlet, StepNavigatorComponent],
	templateUrl: './settings-organisation.component.html',
	styleUrl: './settings-organisation.component.scss',
})
export class SettingsOrganisationComponent {
	stepConfig: IStepNavigatorConfig = {
		ui: StepUI.WIZARD,
		mode: StepModes.ROUTER_OUTLET,
		showStepChevrons: false,
		showStepNumbers: false,
		showNavButtons: true,
		steps: [
			{
				icon: 'bi-clock',
				title: {
					desktop: 'Business Hours',
					mobile: 'Business Hours',
				},
				route: [OrganisationSettingsRoutes.BUSINESS_HOURS],
			},
			{
				icon: 'bi-backpack',
				title: {
					desktop: 'Service Types',
					mobile: 'Service Types',
				},
				route: [OrganisationSettingsRoutes.SERVICE_TYPES],
			},
			{
				icon: 'bi-ui-checks',
				title: {
					desktop: 'Assessment Form',
					mobile: 'Assessment Form',
				},
				route: [OrganisationSettingsRoutes.ASSESSMENT_FORM],
			},
			{
				icon: 'bi-link',
				title: {
					desktop: 'Leads Link',
					mobile: 'Leads Link',
				},
				route: [OrganisationSettingsRoutes.LEADS_LINK],
			},
		],
	};
}
