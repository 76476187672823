import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IOrganisationInvitationCreateForm, OrganisationUserAccessLevel } from '@organisations/models/organisation-user';
import { OrganisationSelectedService } from '@organisations/services/organisation-selected/organisation-selected.service';
import { OrganisationUserService } from '@organisations/services/organisation-user/organisation-user.service';
import { AlertComponent } from '@shared/components/alerts/alert/alert.component';
import { RadioGroupControlComponent } from '@shared/components/forms/radio-group-control/radio-group-control.component';
import { StandardControlComponent } from '@shared/components/forms/standard-control/standard-control.component';
import { AlertTypes } from '@shared/models/alert';
import { IRadioOption } from '@shared/models/form-controls';
import { ToastTypes } from '@shared/models/toast';
import { ToastService } from '@shared/services/toast/toast.service';
import { takeUntil } from 'rxjs';

@Component({
	selector: 'app-add-user-modal',
	standalone: true,
	imports: [CommonModule, FormsModule, ReactiveFormsModule, AlertComponent, StandardControlComponent, RadioGroupControlComponent],
	templateUrl: './add-user-modal.component.html',
	styleUrl: './add-user-modal.component.scss',
})
export class AddUserModalComponent implements OnInit, OnDestroy {
	private activeModal = inject(NgbActiveModal);
	private toastService = inject(ToastService);
	private organisationUserService = inject(OrganisationUserService);
	private organisationSelectedService = inject(OrganisationSelectedService);

	destroyed$ = new EventEmitter<void>();
	isLoading = false;

	inviteUserForm: FormGroup<IOrganisationInvitationCreateForm>;
	OrganisationUserAccessLevel = OrganisationUserAccessLevel;
	AlertTypes = AlertTypes;
	organisationId: string;
	organisationName: string;

	radioOptions: Array<IRadioOption> = [
		{ label: 'Owner', value: OrganisationUserAccessLevel.OWNER, id: 'owner' },
		{ label: 'Employee', value: OrganisationUserAccessLevel.EMPLOYEE, id: 'employee' },
	];

	constructor() {
		this.inviteUserForm = new FormGroup<IOrganisationInvitationCreateForm>({
			email: new FormControl(null, [Validators.required, Validators.email]),
			accessLevel: new FormControl(OrganisationUserAccessLevel.EMPLOYEE, [Validators.required]),
		});
	}

	ngOnInit(): void {
		this.organisationSelectedService.selectedOrganisation.pipe(takeUntil(this.destroyed$)).subscribe((selectedOrg) => {
			if (selectedOrg) {
				this.organisationId = selectedOrg?.organisation.path.split('/')[1];

				if (selectedOrg.companyName) {
					this.organisationName = selectedOrg.companyName;
				}
			} else {
				this.toastService.showToast({
					title: 'Error',
					message: 'No organisation selected.',
					type: ToastTypes.ERROR,
				});
			}
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}

	get email(): FormControl {
		return this.inviteUserForm.controls.email;
	}

	get accessLevel(): FormControl {
		return this.inviteUserForm.controls.accessLevel;
	}

	closeModal(): void {
		this.activeModal.close();
	}

	submit(): void {
		if (this.inviteUserForm.invalid) {
			this.inviteUserForm.markAllAsTouched();
			return;
		}
		this.isLoading = true;

		this.organisationUserService
			.inviteUserToOrganisation(this.email.value, this.accessLevel.value, this.organisationId, this.organisationName)
			.then((result) => {
				if (result) {
					this.toastService.showToast({
						title: 'Organisation Invitation Success',
						message: `${this.email.value} invited to join your organisation`,
						type: ToastTypes.SUCCESS,
					});
					this.closeModal();
				} else {
					this.toastService.showToast({
						title: 'Organisation Invitation Error',
						message:
							'Cannot invite this user to join your organisation. This user may already be a part of your organisation or a pending invation exists.',
						type: ToastTypes.ERROR,
					});
				}
				this.isLoading = false;
			});
	}

	get alertContent(): string {
		switch (this.accessLevel.value) {
			case OrganisationUserAccessLevel.OWNER:
				return 'Owners have full access to all functionalities of ServeOx. They are able to add new users to the platform.';
			case OrganisationUserAccessLevel.EMPLOYEE:
				return "The Employee user role is designed for company staff using ServeOx's platform. Employees cannot add users, view or edit settings, or access leads.";
			default:
				return '';
		}
	}
}
