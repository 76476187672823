import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MobileAssessmentRequestScheduleItemComponent } from '@leads/client-facing/components/mobile-assessment-request-schedule-item/mobile-assessment-request-schedule-item.component';
import { NgbDateToDatePipe } from '@shared/pipes/ngb-date-to-date.pipe';
import { AccordionComponent } from '@shared/components/ui/accordion/accordion.component';
import { AccordionItemComponent } from '@shared/components/ui/accordion-item/accordion-item.component';
import { TextareaControlComponent } from '@shared/components/forms/textarea-control/textarea-control.component';
import { AssessmentRequestScheduler } from '@leads/client-facing/classes/assessment-request-scheduler';

@Component({
	selector: 'app-mobile-assessment-request-details',
	standalone: true,
	imports: [
		CommonModule,
		MobileAssessmentRequestScheduleItemComponent,
		NgbDateToDatePipe,
		AccordionComponent,
		AccordionItemComponent,
		TextareaControlComponent,
	],
	templateUrl: './mobile-assessment-request-details.component.html',
	styleUrl: './mobile-assessment-request-details.component.scss',
})
export class MobileAssessmentRequestDetailsComponent extends AssessmentRequestScheduler {}
