<mwl-calendar-week-view
	[events]="events"
	[refresh]="refresh"
	[viewDate]="viewDate"
	[daysInWeek]="daysInWeek"
	[eventTemplate]="eventTemplate"
	[headerTemplate]="headerTemplate"
	[currentTimeMarkerTemplate]="currentTimeMarkerTemplate"
	(eventClicked)="handleEventClicked($event.event)"
	(eventTimesChanged)="handleEventTimesChanged($event)"
>
</mwl-calendar-week-view>

<ng-template
	#eventTemplate
	let-weekEvent="weekEvent"
	let-tooltipPlacement="tooltipPlacement"
	let-eventClicked="eventClicked"
	let-tooltipTemplate="tooltipTemplate"
	let-tooltipAppendToBody="tooltipAppendToBody"
	let-tooltipDisabled="tooltipDisabled"
	let-tooltipDelay="tooltipDelay"
	let-column="column"
>
	<div
		class="calendar-week-cell-event"
		tabindex="0"
		role="application"
		[ngbPopover]="eventPopoverTemplate"
		popoverClass="popover-body"
		placement="auto"
		triggers="manual"
		#p1="ngbPopover"
		(click)="toggleEventPopover(p1, (weekEvent.tempEvent || weekEvent.event))"
		(mwlKeydownEnter)="toggleEventPopover(p1, (weekEvent.tempEvent || weekEvent.event))"
	>
		<span class="event-time">{{ (weekEvent.tempEvent || weekEvent.event).start | date: 'h:mma' | lowercase }}</span>
		<span class="event-title">{{ (weekEvent.tempEvent || weekEvent.event).title }}</span>
	</div>
</ng-template>

<ng-template
	#headerTemplate
	let-days="days"
	let-locale="locale"
	let-dayHeaderClicked="dayHeaderClicked"
	let-eventDropped="eventDropped"
	let-trackByWeekDayHeaderDate="trackByWeekDayHeaderDate"
	let-dragEnter="dragEnter"
>
      <app-calendar-week-headers
        [days]="days"
        [locale]="locale"
		[trackByWeekDayHeaderDate]="trackByWeekDayHeaderDate"
        (dayHeaderClicked)="dayHeaderClicked.emit($event)"
        (eventDropped)="eventDropped({ dropData: $event }, $event.newStart, true)"
        (dragEnter)="dragEnter($event.date)"
      >
      </app-calendar-week-headers>
</ng-template>


<ng-template
	#currentTimeMarkerTemplate
	let-columnDate="columnDate"
	let-dayStartHour="dayStartHour"
	let-dayStartMinute="dayStartMinute"
	let-dayEndHour="dayEndHour"
	let-dayEndMinute="dayEndMinute"
	let-isVisible="isVisible"
	let-topPx="topPx"
>
	<div
		class="cal-current-time-marker"
		[style.top.px]="topPx"
	></div>
</ng-template>

<ng-template #eventPopoverTemplate let-event="event">
	<app-calendar-popover [event]="event"></app-calendar-popover>
</ng-template>



