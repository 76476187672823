import { Toast } from 'bootstrap';
import { fromEvent, take } from 'rxjs';

import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ToastTypes, ToastEvent } from '@shared/models/toast';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'app-toast',
	templateUrl: './toast.component.html',
	styleUrls: ['./toast.component.scss'],
	standalone: true,
	imports: [CommonModule],
})
export class ToastComponent implements OnInit {
	@Output() disposeEvent$ = new EventEmitter<void>();

	@ViewChild('toastElement', { static: true }) toastEl!: ElementRef;
	@Input() toastEvent!: ToastEvent;

	toast!: Toast;

	ngOnInit(): void {
		this.show();
	}

	show(): void {
		const delay = this.toastEvent.type === ToastTypes.ERROR || this.toastEvent.yes || this.toastEvent.no ? 20000 : 5000;
		this.toast = new Toast(this.toastEl.nativeElement, this.toastEvent.autoHide === false ? { autohide: false } : { delay: delay });

		fromEvent(this.toastEl.nativeElement, 'hidden.bs.toast')
			.pipe(take(1))
			.subscribe(() => this.hide());

		this.toast.show();
	}

	hide(): void {
		this.toast.dispose();
		this.disposeEvent$.emit();
	}
}
