import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, OnDestroy, OnInit } from '@angular/core';
import { IClientWithProperties } from '@clients/models/clients';
import { ClientsService } from '@clients/services/clients/clients.service';
import { DesktopClientsTableComponent } from '@clients/components/desktop-clients-table/desktop-clients-table.component';
import { LayoutService } from '@layout/services/layout/layout.service';
import { IAvailableOrganisation, OrganisationUserAccessLevel } from '@organisations/models/organisation-user';
import { OrganisationSelectedService } from '@organisations/services/organisation-selected/organisation-selected.service';
import { UnauthorisedBannerComponent } from '@shared/components/ui/unauthorised-banner/unauthorised-banner.component';
import { BehaviorSubject, combineLatest, filter, switchMap, takeUntil } from 'rxjs';
import { PageTitleComponent } from '@shared/components/sections/page-title/page-title.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MobileClientsTableComponent } from '@clients/components/mobile-clients-table/mobile-clients-table.component';
import { LoadingStateComponent } from '@shared/components/loading-state/loading-state.component';

@Component({
	selector: 'app-clients-dashboard',
	standalone: true,
	imports: [
		CommonModule,
		UnauthorisedBannerComponent,
		PageTitleComponent,
		DesktopClientsTableComponent,
		MobileClientsTableComponent,
		FormsModule,
		ReactiveFormsModule,
		LoadingStateComponent,
	],
	templateUrl: './clients-dashboard.component.html',
	styleUrl: './clients-dashboard.component.scss',
})
export class ClientsDashboardComponent implements OnInit, OnDestroy {
	destroyed$ = new EventEmitter<void>();
	private clientsService = inject(ClientsService);
	private organisationSelectedService = inject(OrganisationSelectedService);
	private layoutService = inject(LayoutService);

	selectedOrganisation: IAvailableOrganisation | null;

	isLoading = true;
	clientsLoading = true;
	isMobileSize = true;
	userHasAccess = false;

	clients: Array<IClientWithProperties> = [];
	searchTerm$ = new BehaviorSubject<string>('');

	ngOnInit(): void {
		this.layoutService.isMobileSize$.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
			this.isMobileSize = value;
		});

		combineLatest([this.organisationSelectedService.selectedOrganisation, this.searchTerm$])
			.pipe(
				takeUntil(this.destroyed$),
				filter(([selectedOrganisation, searchTerm]) => !!selectedOrganisation),
				switchMap(([selectedOrganisation, searchTerm]) => {
					this.selectedOrganisation = selectedOrganisation;
					if (selectedOrganisation && selectedOrganisation.accessLevel === OrganisationUserAccessLevel.OWNER) {
						this.userHasAccess = true;
						return this.clientsService.getClientsWithPropertiesByOrganisation(selectedOrganisation.organisation, searchTerm);
					} else {
						this.userHasAccess = false;
						this.isLoading = false;
						return [];
					}
				}),
			)
			.subscribe((clients) => {
				this.clients = clients;
				this.isLoading = false;
				this.clientsLoading = false;
			});
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}

	onSearchChange(value: string): void {
		this.clientsLoading = true;

		this.searchTerm$.next(value.trim().toLowerCase());
	}
}
