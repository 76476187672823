<div class="step-container">
	<div class="content">
		<app-loading-state [isLoading]="isLoading" [loadedContent]="loadedContent"></app-loading-state>
		<ng-template #loadedContent>
			<app-lead-mobile-number [form]="leadRequestForm" [organisation]="organisation"></app-lead-mobile-number>
		</ng-template>
	</div>

	<div class="footer">
		<button
			type="button"
			class="btn btn-sm btn-primary btn-width"
			[routerLink]="'../' + CustomerLeadRequestRoutes.REQUEST_TYPE"
			[disabled]="leadRequestForm.controls.mobileNumber.invalid"
		>
			Next
			<i class="bi bi-arrow-right"></i>
		</button>
	</div>
</div>
