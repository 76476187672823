import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { IAuthContent } from '@auth/models/auth-layout';

@Component({
	selector: 'app-content-marketing',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './content-marketing.component.html',
	styleUrl: './content-marketing.component.scss',
})
export class ContentMarketingComponent {
	content: Array<IAuthContent> = [
		{
			title: 'Automate Lead Capture',
			description: 'Convert WhatsApp Enquiries into Potential Clients.',
			icon: 'assets/app-icons/people-group.svg',
		},
		{
			title: 'Simplified Scheduling',
			description: 'Manage Every Interaction with Streamlined Scheduling.',
			icon: 'assets/app-icons/chart-timeline.svg',
		},
		{
			title: 'Connect Your Calendars',
			description: 'Sync Your Business and Personal Life Effortlessly Across All Platforms.',
			icon: 'assets/app-icons/briefcase-outline.svg',
		},
	];
}
