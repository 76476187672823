<div class="user-container">
	<div class="heading">
		<div class="text-primary fw-bold">Current Users</div>
		<button type="button" class="btn btn-sm btn-primary" (click)="openInviteUser()">
			Invite User
			<i class="bi bi-person-plus"></i>
		</button>
	</div>

	<div class="user-table text-primary">
		<ng-container *ngFor="let member of organisationMembers">
			<div class="user-row">
				<div class="column">
					<div class="user-profile">
						<div class="avatar">
							<ng-container *ngIf="member.photoUrl; else noPhoto">
								<img class="img-fluid rounded-circle" [src]="member.photoUrl" />
							</ng-container>
							<ng-template #noPhoto>
								<i class="bi bi-person"></i>
							</ng-template>
						</div>
						<div>
							<div class="title">Name</div>
							<div class="text">{{ member.displayName ?? '-' }}</div>
						</div>
					</div>
				</div>
				<div class="column">
					<div class="title">Role</div>
					<div class="text">{{ member.accessLevel | titlecase }}</div>
				</div>
				<div class="column">
					<div class="title">Email</div>
					<div class="text">{{ member.email }}</div>
				</div>
				<div class="column">
					<div class="user-status">
						<span class="badge rounded-pill" [ngClass]="organisationStatusBackground(member)">{{
							member.status | titlecase
						}}</span>
						<div class="dropdown">
							<button class="btn btn-outline-white border-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
								<i class="bi bi-three-dots-vertical"></i>
							</button>
							<ul class="dropdown-menu">
								<li>
									<button class="dropdown-item" type="button" (click)="openEditUser(member)">
										<i class="bi bi-pencil-square"></i>
										Edit
									</button>
								</li>
								<li
									*ngIf="member.status === OrganisationMemberStatus.INVITATION_PENDING"
									(click)="resendInvitation(member)"
								>
									<button class="dropdown-item" type="button">
										<i class="bi bi-envelope"></i>
										Resend Invitation
									</button>
								</li>
								<li *ngIf="member.status !== OrganisationMemberStatus.CURRENTLY_LOGGED_IN">
									<button class="dropdown-item" type="button" (click)="removeOrganisationUserAction(member)">
										<i class="bi bi-x-square"></i>
										Remove
									</button>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</ng-container>
	</div>
</div>
