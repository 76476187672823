<div class="subpage-container">
	<form [formGroup]="form">
		<div class="form-container">
			<div class="form-column">
				<app-header-popover heading="Request Type" [infoPopOver]="infoPopOver"></app-header-popover>
				<app-radio-group-control [formGroup]="form" name="requestType" [options]="radioOptions" />
			</div>
		</div>
	</form>

	<ng-container *ngIf="requestType.value">
		<app-alert [content]="alertContent"></app-alert>
	</ng-container>
</div>
