import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AbstractControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ISelectOption } from '@shared/models/form-controls';

@Component({
	selector: 'app-select-control',
	standalone: true,
	imports: [CommonModule, FormsModule, ReactiveFormsModule],
	templateUrl: './select-control.component.html',
	styleUrl: './select-control.component.scss',
})
export class SelectControlComponent<T extends { [K in keyof T]: AbstractControl }> {
	@Input() formGroup!: FormGroup<T>;
	@Input() name!: Extract<keyof T, string | number>;
	@Input() label: string;
	@Input() options: Array<ISelectOption> = [];

	get control() {
		return this.formGroup.controls[this.name];
	}

	Validators = Validators;
}
