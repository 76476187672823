import { DatePipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AssessmentStatusBadgeComponent } from '@leads/shared/components/assessment-status-badge/assessment-status-badge.component';
import { LeadRequestType } from '@leads/shared/models/domain/lead-request.domain';
import { ScheduledAssessment, ScheduledAssessmentStatus, ScheduledAssessmentType } from '@leads/shared/models/domain/scheduled-assessment.domain';

@Component({
	selector: 'app-lead-assessment-scheduled-item',
	standalone: true,
	imports: [DatePipe, NgIf, NgForOf, NgClass, AssessmentStatusBadgeComponent],
	templateUrl: './lead-assessment-scheduled-item.component.html',
	styleUrl: './lead-assessment-scheduled-item.component.scss',
})
export class LeadAssessmentScheduledItemComponent {
	@Input({ alias: 'item' }) scheduledItem: ScheduledAssessment;

	@Output() accept = new EventEmitter<ScheduledAssessment>();
	@Output() decline = new EventEmitter<ScheduledAssessment>();

	protected readonly LeadRequestType = LeadRequestType;
	protected readonly ScheduledAssessmentStatus = ScheduledAssessmentStatus;

	assessmentType(assessmentType: ScheduledAssessmentType | undefined): string {
		switch (assessmentType) {
			case ScheduledAssessmentType.ASSESSMENT:
				return 'In Person Assessment';
			case ScheduledAssessmentType.JOB:
				return 'In Person Job';
			case undefined:
				return 'N/A';
		}
	}

	acceptAssessment() {
		this.accept.emit(this.scheduledItem);
	}

	declineAssessment() {
		this.decline.emit(this.scheduledItem);
	}

	assessmentInStatus(status: ScheduledAssessmentStatus): boolean {
		return this.scheduledItem.status === status;
	}

	get acceptActionEnabled(): boolean {
		const acceptableStatuses = [ScheduledAssessmentStatus.REQUESTED, ScheduledAssessmentStatus.DECLINED];
		return acceptableStatuses.includes(this.scheduledItem.status);
	}

	get declineActionEnabled(): boolean {
		const acceptableStatuses = [ScheduledAssessmentStatus.REQUESTED, ScheduledAssessmentStatus.SCHEDULED];
		return acceptableStatuses.includes(this.scheduledItem.status);
	}
}
