import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DocumentReference } from '@angular/fire/firestore';
import { FormGroup } from '@angular/forms';
import { LeadRequestType } from '@leads/shared/models/lead.models';
import { ISchedulerFormItem } from '@leads/shared/models/schedule.model';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { IOrganisationUserWithUserName } from '@organisations/models/organisation-user';
import { IOrganisation } from '@organisations/models/organisations';
import { OrganisationSelectedService } from '@organisations/services/organisation-selected/organisation-selected.service';
import { OrganisationUserService } from '@organisations/services/organisation-user/organisation-user.service';
import { OrganisationService } from '@organisations/services/organisation/organisation.service';
import { ScheduledAssessmentType } from '@schedule/models/firestore/schedule.model';
import { DatePickerParent } from '@shared/components/calendar/date-picker';
import { convertBusinessHoursToDisabledDaysOfTheWeek, IDisabledDaysOfTheWeek } from '@shared/components/calendar/models';
import { dateToNgbDateStruct, ngbDateStructToDate } from '@shared/components/calendar/utilities';
import { IDropdownOption } from '@shared/components/forms/dropdown-control/dropdown-control.component';
import { CalendarEvent, CalendarEventTimesChangedEvent } from 'angular-calendar';
import { filter, Subject, takeUntil } from 'rxjs';
import { AssessmentScheduleSteps } from '../models/assessment-schedule.model';

@Component({
	template: '',
})
export abstract class AssessmentRequestScheduleItem extends DatePickerParent implements OnInit, OnDestroy {
	@Input() schedulerFormItem: FormGroup<ISchedulerFormItem>;
	@Input() index: number;
	@Output() assessmentRemove = new EventEmitter<number>();

	assessmentSubmitted: boolean = false;
	assessmentSharedOnWhatsapp: boolean = false;

	destroyed$ = new EventEmitter<void>();
	isLoading = true;
	LeadRequestType = LeadRequestType;
	ScheduledAssessmentType = ScheduledAssessmentType;
	NavigationStepCases = AssessmentScheduleSteps;

	disabledDaysOfTheWeek: IDisabledDaysOfTheWeek;
	activeOrganisationUsers: Array<IOrganisationUserWithUserName> = [];
	employees: Array<IDropdownOption> = [];
	serviceTypes: Array<IDropdownOption> = [];
	assessmentTypes: Array<IDropdownOption> = [
		{
			id: 'assessment',
			value: ScheduledAssessmentType.ASSESSMENT,
			label: 'Assessment',
			icon: 'bi-clipboard',
		},
		{
			id: 'job',
			value: ScheduledAssessmentType.JOB,
			label: 'Job',
			icon: 'bi-wrench-adjustable',
		},
	];

	timePickerSelectedDate: Date = new Date();
	refreshTimePicker = new Subject<void>();
	timePickerEvents: CalendarEvent[] = [];
	newAssessmentEvent: CalendarEvent;

	private organisationService = inject(OrganisationService);
	private organisationUserService = inject(OrganisationUserService);
	private organisationSelectedService = inject(OrganisationSelectedService);

	abstract initialise(): void;

	ngOnInit(): void {
		this.subscribeToSelectedOrganisation();
		this.listenToFormChanges();
		this.initialise();
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}

	subscribeToSelectedOrganisation(): void {
		this.organisationSelectedService.selectedOrganisation
			.pipe(
				takeUntil(this.destroyed$),
				filter((o) => o !== null),
			)
			.subscribe((selectedOrg) => {
				if (selectedOrg) {
					this.fetchOrganisationUsers(selectedOrg.organisation as DocumentReference<IOrganisation>);
					this.fetchOrganisation(selectedOrg.organisation as DocumentReference<IOrganisation>);
				}
			});
	}

	fetchOrganisationUsers(organisationRef: DocumentReference<IOrganisation>): void {
		this.organisationUserService
			.activeUsersByOrganisationRef(organisationRef)
			.pipe(takeUntil(this.destroyed$))
			.subscribe((organisationUsers) => {
				this.activeOrganisationUsers = organisationUsers;
				this.employees = organisationUsers.map((u, index) => ({
					id: index + 1,
					value: u.id,
					label: u.userDisplayName as string,
					icon: 'bi-person',
				}));
			});
	}

	fetchOrganisation(organisationRef: DocumentReference<IOrganisation>): void {
		this.organisationService
			.getOrganisation(organisationRef)
			.pipe(takeUntil(this.destroyed$))
			.subscribe((organisation) => {
				if (organisation) {
					this.disabledDaysOfTheWeek = convertBusinessHoursToDisabledDaysOfTheWeek(organisation.settings.businessHours);
					this.serviceTypes = organisation.settings.serviceTypes.map((s, index) => ({
						id: index + 1,
						value: s,
						label: s,
						icon: 'bi-wrench',
					}));
				}
				this.isLoading = false;
			});
	}

	/**
	 * On Calendar Date Change.
	 * Updates Requested Date Form Control
	 * Updates Time Picker Start and End Time Slot.
	 * @param ngbDate
	 */
	dateSelected = (ngbDate: NgbDate): void => {
		const ngbDateStruct: NgbDateStruct = {
			day: ngbDate.day,
			month: ngbDate.month,
			year: ngbDate.year,
		};
		this.updateRequestedDate(ngbDateStruct);
		this.updateRequestedTimeSlot(ngbDateStruct);
	};

	private updateRequestedDate(ngbDateStruct: NgbDateStruct): void {
		this.schedulerFormItem.controls.selectedDate.patchValue(ngbDateStruct);
	}

	/**
	 * Set Initial Calendar Date
	 * @param initialDate
	 */
	setRequestedDateToNextAvailable(initialDate: Date): void {
		const ngbDateStruct: NgbDateStruct = dateToNgbDateStruct(initialDate);
		this.updateRequestedDate(ngbDateStruct);
		this.updateRequestedTimeSlot(ngbDateStruct);
	}

	/**
	 * Update Time Picker Start and End Time
	 * @param ngbDate
	 */
	private updateRequestedTimeSlot(ngbDate: NgbDateStruct): void {
		const date = ngbDateStructToDate(ngbDate);
		const startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 9, 0, 0, 0);
		const endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 9, 15, 0, 0);

		this.timePickerSelectedDate = date;
		this.newAssessmentEvent = {
			title: 'New Assessment',
			color: {
				primary: '#05458a',
				secondary: '#a6d0fc',
			},
			start: startDate,
			end: endDate,
			draggable: true,
			resizable: {
				beforeStart: true,
				afterEnd: true,
			},
		};
		this.updateTimePickerEvents(this.newAssessmentEvent);
		this.refreshTimePicker.next();
		this.schedulerFormItem.controls.selectedStartTime.patchValue(startDate);
		this.schedulerFormItem.controls.selectedEndTime.patchValue(endDate);
	}

	/**
	 * On Time Picker Event Change
	 * @param param0
	 */
	timePickerEventTimesChanged({ event, newStart, newEnd }: CalendarEventTimesChangedEvent): void {
		event.start = newStart;
		event.end = newEnd;
		this.refreshTimePicker.next();

		this.selectedStartTime.patchValue(newStart);
		if (newEnd) {
			this.selectedEndTime.patchValue(newEnd);
		}
	}

	/**
	 * Update Time Picker Events.
	 * Displays Employee Records for a given day and highlights the new assessment calendar event to be created.
	 * @param newAssessmentEvent
	 */
	updateTimePickerEvents(newAssessmentEvent: CalendarEvent): void {
		//ToDo: Pull in employee events
		this.timePickerEvents = [newAssessmentEvent];
	}

	/**
	 * Output event when assessment is removed
	 */
	removeAssessment(): void {
		this.assessmentRemove.emit(this.index);
	}

	get employeeId() {
		return this.schedulerFormItem.controls.employeeId;
	}

	get selectedDate() {
		return this.schedulerFormItem.controls.selectedDate;
	}

	get selectedStartTime() {
		return this.schedulerFormItem.controls.selectedStartTime;
	}

	get selectedEndTime() {
		return this.schedulerFormItem.controls.selectedEndTime;
	}

	get requestType() {
		return this.schedulerFormItem.controls.requestType;
	}

	get assessmentType() {
		return this.schedulerFormItem.controls.assessmentType;
	}

	get assessments() {
		return this.schedulerFormItem.controls.assessments;
	}

	get callOutFeeRands() {
		return this.schedulerFormItem.controls.callOutFeeRands;
	}

	get includeCallOutFeeComms() {
		return this.schedulerFormItem.controls.includeCallOutFeeComms;
	}

	/**
	 * Listen to form changes to update validation
	 */
	listenToFormChanges(): void {
		this.assessmentType?.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((newAssessmentType) => {
			switch (newAssessmentType) {
				case ScheduledAssessmentType.ASSESSMENT:
					this.schedulerFormItem.controls.callOutFeeRands?.enable();
					this.schedulerFormItem.controls.includeCallOutFeeComms?.enable();
					break;
				case ScheduledAssessmentType.JOB:
					this.schedulerFormItem.controls.callOutFeeRands?.disable();
					this.schedulerFormItem.controls.includeCallOutFeeComms?.disable();
					break;
			}
		});
	}
}
