import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
	CalendarCommonModule,
	CalendarEvent,
	CalendarEventTimesChangedEvent,
	CalendarMonthModule,
	CalendarMonthViewDay
} from 'angular-calendar';
import { CommonModule } from '@angular/common';
import { CalendarDayCellComponent } from '@schedule/components/calendar-day-cell/calendar-day-cell.component';
import {
	CalendarWeekHeadersComponent
} from '@schedule/components/calendar-week-headers/calendar-week-headers.component';
import {
	CalendarMonthHeadersComponent
} from '@schedule/components/calendar-month-headers/calendar-month-headers.component';
import { CalendarColumnHeaderClickedEvent } from '@schedule/extensions/types/calendar.types';

@Component({
	selector: 'app-calendar-month-view',
	standalone: true,
	imports: [
		CommonModule,
		CalendarCommonModule,
		CalendarMonthModule,
		CalendarDayCellComponent,
		CalendarWeekHeadersComponent,
		CalendarMonthHeadersComponent
	],
	templateUrl: './calendar-month-view.component.html',
	styleUrl: './calendar-month-view.component.scss'
})
export class CalendarMonthViewComponent {
	@Input() events: CalendarEvent[];
	@Input() refresh: any;
	@Input() viewDate: Date;
	@Input() activeDayIsOpen: boolean;
	@Output() dayClicked = new EventEmitter<CalendarMonthViewDay>();
	@Output() eventClicked = new EventEmitter<CalendarEvent>();
	@Output() eventTimesChanged = new EventEmitter<CalendarEventTimesChangedEvent>();
	@Output() columnHeaderClicked = new EventEmitter<CalendarColumnHeaderClickedEvent>();

	handleDayClicked(day: CalendarMonthViewDay): void {
		this.dayClicked.emit(day);
	}

	handleEventClicked(event: CalendarEvent): void {
		this.eventClicked.emit(event);
	}

	handleEventTimesChanged(event: CalendarEventTimesChangedEvent): void {
		this.eventTimesChanged.emit(event);
	}

	handleColumnHeaderClicked(event: CalendarColumnHeaderClickedEvent): void {
		this.columnHeaderClicked.emit(event);
	}
}
