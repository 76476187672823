<form [formGroup]="leadRequestForm">
	<div class="subpage-container">
		<div class="form-container">
			<div class="form-column">
				<app-header-popover heading="Contact Information" [infoPopOver]="contactInfoPopOver"></app-header-popover>
				<button type="button" class="btn btn-sm btn-primary" (click)="openImportClient()">
					<i class="bi bi-person-lines-fill"></i>
					Select Existing Client
				</button>

				<h6 class="text-primary m-0">Or create new client below</h6>

				<app-standard-control
					type="text"
					name="name"
					[formGroup]="leadRequestForm.controls.contactDetails"
					label="Full Name"
					placeholder="Full Name"
				></app-standard-control>

				<app-standard-control
					type="text"
					name="mobileNumber"
					[formGroup]="leadRequestForm"
					label="Mobile Number"
					placeholder="Mobile Number"
					patternMessage="Mobile Number must be a valid phone number."
				></app-standard-control>

				<app-standard-control
					type="email"
					name="emailAddress"
					[formGroup]="leadRequestForm.controls.contactDetails"
					label="Email Address"
					placeholder="Email Address"
				></app-standard-control>

				<app-custom-form-details
					[form]="leadRequestForm.controls.contactDetails.controls.customQuestions"
					[borderWrap]="false"
				></app-custom-form-details>
			</div>

			<div class="form-column">
				<h5 class="text-primary">Installation Address</h5>
				<app-address-with-search [formGroup]="leadRequestForm.controls.installationAddress"></app-address-with-search>
			</div>
		</div>

		<div class="form-container">
			<div class="form-column">
				<h5 class="text-primary">Service Types</h5>
				<app-dropdown-control
					class="w-100"
					[formGroup]="leadRequestForm.controls.onsiteAssessment"
					name="serviceType"
					label="Service Type"
					placeholder="Select a service"
					[options]="serviceTypes"
					[searchable]="false"
				></app-dropdown-control>

				<app-custom-form-details
					[form]="leadRequestForm.controls.onsiteAssessment.controls.customQuestions"
					[borderWrap]="false"
				></app-custom-form-details>
			</div>
		</div>

		<div class="form-container">
			<app-lead-notes
				class="w-100"
				title="Assessment Notes"
				[form]="leadRequestForm.controls.onsiteAssessment"
				[showAssistance]="false"
			></app-lead-notes>
		</div>
	</div>
</form>
