<div class="wrapper">
	<div class="container shadow">
		<div class="left-container">
			<div class="content-wrapper">
				<router-outlet></router-outlet>
				<app-policy-footer></app-policy-footer>
			</div>
		</div>
		<div class="right-container bg-serveox">
			<img class="img-fluid" src="assets/logos/serveox-logo-white.svg" />

			<ng-container *ngFor="let item of content">
				<div class="content">
					<img [src]="item.icon" alt="Icon" width="80px" height="80px" />

					<div class="content-body">
						<h5>{{ item.title }}</h5>
						<p>{{ item.description }}</p>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
</div>
