import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AbstractControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
	selector: 'app-password-control',
	standalone: true,
	imports: [CommonModule, FormsModule, ReactiveFormsModule],
	templateUrl: './password-control.component.html',
	styleUrl: './password-control.component.scss',
})
export class PasswordControlComponent<T extends { [K in keyof T]: AbstractControl }> {
	@Input() formGroup!: FormGroup<T>;
	@Input() name!: Extract<keyof T, string | number>;
	@Input() label: string;
	@Input() placeholder: string;
	@Input() autocomplete?: 'new-password' | 'current-password';
	@Input() patternMessage?: string = 'Invalid format';

	get control() {
		return this.formGroup.controls[this.name];
	}

	showPassword: boolean = false;
	passwordType: 'password' | 'text' = 'password';

	togglePassword(): void {
		this.showPassword = !this.showPassword;
		if (this.showPassword) {
			this.passwordType = 'text';
		} else {
			this.passwordType = 'password';
		}
	}
}
