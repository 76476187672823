import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { AbstractControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { DatePickerBase } from '../date-picker';

@Component({
	selector: 'app-date-picker-fullscreen',
	standalone: true,
	imports: [CommonModule, NgbDatepickerModule, FormsModule, ReactiveFormsModule],
	templateUrl: './date-picker-fullscreen.component.html',
	styleUrl: './date-picker-fullscreen.component.scss',
})
export class DatePickerFullscreenComponent<T extends { [K in keyof T]: AbstractControl }> extends DatePickerBase<T> {}
