import { Injectable } from '@angular/core';
import {
	IScheduledAssessment,
	ScheduledAssessmentStatus,
	ScheduledAssessmentType
} from '@schedule/models/firestore/schedule.model';
import { LeadRequestType } from '@leads/shared/models/lead.models';
import { DocumentReference, Timestamp } from '@angular/fire/firestore';


@Injectable({
	providedIn: 'root'
})
export class ScheduleService {

	constructor() {
	}

	getScheduledAssessment(): IScheduledAssessment[] {
		return [
			{
				createdDate: Timestamp.fromDate(new Date('2023-01-01')),
				updatedDate: Timestamp.fromDate(new Date('2023-01-02')),
				leadRequest: {} as DocumentReference,
				requestType: LeadRequestType.ON_SITE_VISIT,
				employee: {} as DocumentReference,
				selectedDate: { year: 2023, month: 1, day: 10 },
				selectedStartTime: Timestamp.fromDate(new Date('2023-01-10T09:00:00')),
				selectedEndTime: Timestamp.fromDate(new Date('2023-01-10T10:00:00')),
				assessments: ['Assessment 1'],
				assessmentType: ScheduledAssessmentType.ASSESSMENT,
				callOutFeeRands: 100,
				assessmentNotes: 'Initial assessment',
				whatsappNotes: 'Contact via WhatsApp',
				status: ScheduledAssessmentStatus.REQUESTED
			},
			{
				createdDate: Timestamp.fromDate(new Date('2023-02-01')),
				updatedDate: Timestamp.fromDate(new Date('2023-02-02')),
				leadRequest: {} as DocumentReference,
				requestType: LeadRequestType.CALL,
				employee: {} as DocumentReference,
				selectedDate: { year: 2023, month: 2, day: 15 },
				selectedStartTime: Timestamp.fromDate(new Date('2023-02-15T11:00:00')),
				selectedEndTime: Timestamp.fromDate(new Date('2023-02-15T12:00:00')),
				assessments: ['Assessment 2'],
				assessmentType: ScheduledAssessmentType.JOB,
				callOutFeeRands: 200,
				assessmentNotes: 'Job assessment',
				whatsappNotes: 'Contact via WhatsApp',
				status: ScheduledAssessmentStatus.APPROVED
			},
			{
				createdDate: Timestamp.fromDate(new Date('2023-03-01')),
				updatedDate: Timestamp.fromDate(new Date('2023-03-02')),
				leadRequest: {} as DocumentReference,
				requestType: LeadRequestType.CALL,
				employee: {} as DocumentReference,
				selectedDate: { year: 2023, month: 3, day: 20 },
				selectedStartTime: Timestamp.fromDate(new Date('2023-03-20T13:00:00')),
				selectedEndTime: Timestamp.fromDate(new Date('2023-03-20T14:00:00')),
				assessments: ['Assessment 3'],
				assessmentType: ScheduledAssessmentType.ASSESSMENT,
				callOutFeeRands: 150,
				assessmentNotes: 'Follow-up assessment',
				whatsappNotes: 'Contact via WhatsApp',
				status: ScheduledAssessmentStatus.COMPLETED
			},
			{
				createdDate: Timestamp.fromDate(new Date('2023-04-01')),
				updatedDate: Timestamp.fromDate(new Date('2023-04-02')),
				leadRequest: {} as DocumentReference,
				requestType: LeadRequestType.CALL,
				employee: {} as DocumentReference,
				selectedDate: { year: 2023, month: 4, day: 25 },
				selectedStartTime: Timestamp.fromDate(new Date('2023-04-25T15:00:00')),
				selectedEndTime: Timestamp.fromDate(new Date('2023-04-25T16:00:00')),
				assessments: ['Assessment 4'],
				assessmentType: ScheduledAssessmentType.JOB,
				callOutFeeRands: 250,
				assessmentNotes: 'Final job assessment',
				whatsappNotes: 'Contact via WhatsApp',
				status: ScheduledAssessmentStatus.CANCELLED
			}
		];
	}
}
