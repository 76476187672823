<app-loading-state [isLoading]="isLoading" [loadedContent]="loadedContent"></app-loading-state>
<ng-template #loadedContent>
	<ng-container *ngIf="isMobileSize; else desktopSize">
		<div class="view-client-container">
			<div class="heading">
				<app-page-title
					[title]="client.clientName"
					[showBackButton]="true"
					[showWhatsAppButton]="true"
					[whatsAppContact]="{ contactName: client.clientName, contactNumber: client.clientNumber }"
				></app-page-title>
			</div>

			<div class="content">
				<app-mobile-client-edit-details
					[clientForm]="clientForm"
					[properties]="clientProperties"
					[clientId]="clientId"
					(propertyChange)="propertyChange()"
				></app-mobile-client-edit-details>
			</div>

			<div class="footer">
				<button type="button" class="btn btn-sm btn-outline-danger btn-width" [routerLink]="AppRoutes.CLIENTS">Cancel</button>
				<button
					type="button"
					class="btn btn-sm btn-primary btn-width"
					(click)="saveClient()"
					[disabled]="clientForm.invalid || clientForm.pristine"
				>
					<ng-container *ngIf="isSaving; else notSaving">
						<div class="spinner-border spinner-border-sm" role="status">
							<span class="visually-hidden">Loading...</span>
						</div>
					</ng-container>
					<ng-template #notSaving> Save </ng-template>
				</button>
			</div>
		</div>
	</ng-container>
	<ng-template #desktopSize>
		<div class="view-client-container">
			<div class="heading">
				<app-page-title
					[title]="client.clientName"
					[showBackButton]="true"
					[showWhatsAppButton]="true"
					[whatsAppContact]="{ contactName: client.clientName, contactNumber: client.clientNumber }"
				></app-page-title>
			</div>

			<div class="content">
				<app-desktop-client-details [clientForm]="clientForm"></app-desktop-client-details>
				<app-desktop-client-edit-property-table
					[properties]="clientProperties"
					[clientId]="clientId"
					(propertyChange)="propertyChange()"
				></app-desktop-client-edit-property-table>
			</div>

			<div class="footer">
				<button type="button" class="btn btn-sm btn-outline-danger btn-width" [routerLink]="AppRoutes.CLIENTS">Cancel</button>
				<button
					type="button"
					class="btn btn-sm btn-primary btn-width"
					(click)="saveClient()"
					[disabled]="clientForm.invalid || clientForm.pristine"
				>
					<ng-container *ngIf="isSaving; else notSaving">
						<div class="spinner-border spinner-border-sm" role="status">
							<span class="visually-hidden">Loading...</span>
						</div>
					</ng-container>
					<ng-template #notSaving> Save</ng-template>
				</button>
			</div>
		</div>
	</ng-template>
</ng-template>
