import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { INavigationItem } from '@layout/models/navigation';

@Component({
	selector: 'app-menu-nav-item',
	standalone: true,
	imports: [CommonModule, RouterLink, RouterLinkActive],
	templateUrl: './menu-nav-item.component.html',
	styleUrl: './menu-nav-item.component.scss',
})
export class MenuNavItemComponent {
	@Input() item: INavigationItem;
	@Input() parentId: string;
	@Input() isChild: boolean;
	@Input() isCollapsed: boolean;
	@Output() dismiss = new EventEmitter<INavigationItem>();

	isExpanded = false;

	itemId = this.randomId();

	dismissOffcanvas(item: INavigationItem): void {
		this.dismiss.emit(item);
	}

	toggleExpand(): void {
		this.isExpanded = !this.isExpanded;
	}

	onCollapseShow(event: Event): void {
		const target = event.target as HTMLElement;
		if (target.id === this.itemId) {
			this.isExpanded = true;
		}
	}

	onCollapseHide(event: Event): void {
		const target = event.target as HTMLElement;
		if (target.id === this.itemId) {
			this.isExpanded = false;
		}
	}

	randomId(): string {
		const letters = 'abcdefghijklmnopqrstuvwxyz';
		return Array.from({ length: 8 }, () => letters[Math.floor(Math.random() * letters.length)]).join('');
	}
}
