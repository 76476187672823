import { Routes } from '@angular/router';
import { authRoutes } from '@auth/auth.routes';
import { authGuard } from '@auth/guards/auth.guard';
import { unauthenticatedGuard } from '@auth/guards/unauthenticated.guard';
import { HomeComponent } from '@auth/pages/home/home.component';
import { clientsRoutes } from '@clients/clients.routes';
import { dashboardRoutes } from '@dashboard/pages/dashboard.routes';
import { AppLayoutComponent } from '@layout/components/app-layout/app-layout.component';
import { clientFacingLeadsRoutes } from '@leads/client-facing/client-facing.routes';
import { LeadRequestBaseComponent } from '@leads/customer-facing/components/lead-request-base/lead-request-base.component';
import { customerLeadRequestRoutes } from '@leads/leads.routes';
import { accessLevelGuard } from '@organisations/guards/access-level/access-level.guard';
import { selectedOrganisationGuard } from '@organisations/guards/selected-organisation/selected-organisation.guard';
import { organisationRoutes } from '@organisations/organisation.routes';
import { scheduleRoutes } from '@schedule/schedule.routes';
import { settingsRoutes } from '@settings/settings.routes';

export enum AppRoutes {
	HOME = 'home',
	AUTH = 'auth',
	DASHBOARD = 'dashboard',
	ORGANISATION = 'organisation',
	CLIENTS = 'clients',
	SETTINGS = 'settings',
	CUSTOMER_LEAD_REQUEST = ':organisationId/lead-request',
	CLIENT_LEADS_DASHBOARD = 'leads',
	SCHEDULE = 'schedule',
	NOT_FOUND = 'not-found',
}

export const routes: Routes = [
	{
		path: AppRoutes.HOME,
		component: HomeComponent,
		title: 'ServeOx | Home',
		canActivate: [unauthenticatedGuard],
	},
	{
		path: AppRoutes.AUTH,
		children: authRoutes,
		canActivate: [unauthenticatedGuard],
	},
	{
		path: AppRoutes.CUSTOMER_LEAD_REQUEST,
		component: LeadRequestBaseComponent,
		children: customerLeadRequestRoutes,
	},
	{
		path: '',
		component: AppLayoutComponent,
		canActivate: [authGuard, selectedOrganisationGuard],
		children: [
			{
				path: '',
				redirectTo: AppRoutes.DASHBOARD,
				pathMatch: 'full',
			},
			{
				path: AppRoutes.DASHBOARD,
				children: dashboardRoutes,
			},
			{
				path: AppRoutes.ORGANISATION,
				children: organisationRoutes,
			},
			{
				path: AppRoutes.SETTINGS,
				children: settingsRoutes,
			},
			{
				path: AppRoutes.CLIENT_LEADS_DASHBOARD,
				children: clientFacingLeadsRoutes,
				canActivate: [accessLevelGuard],
			},
			{
				path: AppRoutes.SCHEDULE,
				children: scheduleRoutes,
			},
			{
				path: AppRoutes.CLIENTS,
				children: clientsRoutes,
				canActivate: [accessLevelGuard],
			},
		],
	},
	{
		path: AppRoutes.NOT_FOUND,
		redirectTo: AppRoutes.DASHBOARD,
	},
	{
		path: '**',
		redirectTo: AppRoutes.DASHBOARD,
	},
];
