import { Injectable } from '@angular/core';
import { MobileNumber } from '@shared/models/mobile-number';

export interface IWhatsAppContact {
	contactName: string;
	contactNumber: MobileNumber;
}

export interface IWhatsAppMessage extends IWhatsAppContact {
	openingText?: boolean;
	messageText?: string;
}

@Injectable({
	providedIn: 'root',
})
export class WhatsappService {
	/**
	 * Starts a WhatsApp chat with the given contact.
	 */
	startChatWithContact(message: IWhatsAppMessage): void {
		const messageContent = message.openingText ? `Hi ${message.contactName},` : message.messageText || '';

		const url = `https://wa.me/${message.contactNumber.countryCode}${message.contactNumber.number}`;
		const fullUrl = messageContent ? `${url}?text=${messageContent.replaceAll('\n', '%0a')}` : url;

		window.open(fullUrl, '_blank');
	}
}
