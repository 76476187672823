<form [formGroup]="formGroup">
	<div class="password-group">
		<div class="form-floating">
			<input
				[type]="passwordType"
				[formControlName]="name"
				class="form-control"
				[class.is-invalid]="control.invalid && control.touched"
				[id]="name"
				[placeholder]="placeholder"
				[autocomplete]="autocomplete"
			/>
			<label [for]="name">{{ label }}<ng-container *ngIf="control.hasValidator(Validators.required)">*</ng-container></label>

			<div *ngIf="control.invalid && control.errors" class="invalid-feedback">
				<p *ngIf="control.errors?.required">{{ label }} required.</p>
				<p *ngIf="control.errors?.pattern">{{ patternMessage }}</p>
				<p *ngIf="control.errors?.minlength">
					{{ label }} must be at least {{ control.errors.minlength.requiredLength }} characters.
				</p>
				<p *ngIf="control.errors?.maxlength">
					{{ label }} must be less than {{ control.errors.maxlength.requiredLength }} characters.
				</p>
				<p *ngIf="control.errors?.noMatch">Passwords do not match.</p>
			</div>
		</div>
		<button type="button" class="btn text-muted icon" (click)="togglePassword()">
			<i class="bi" [class.bi-eye-fill]="!showPassword" [class.bi-eye-slash-fill]="showPassword"></i>
		</button>
	</div>
</form>
