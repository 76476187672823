import { inject, Injectable } from '@angular/core';
import {
	addDoc,
	collection,
	Firestore,
	CollectionReference,
	DocumentReference,
	docData,
	doc,
	DocumentData,
	setDoc,
	updateDoc,
	collectionData,
	UpdateData,
	deleteDoc,
	getDoc,
	DocumentSnapshot,
} from '@angular/fire/firestore';
import { FireStoreCollection } from '@shared/models/firestore';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export abstract class FirestoreService<T extends DocumentData> {
	firestore: Firestore = inject(Firestore);
	collectionRef: CollectionReference<T>;

	constructor(protected fireStoreCollection: FireStoreCollection) {
		this.collectionRef = collection(this.firestore, this.fireStoreCollection) as CollectionReference<T>;
	}

	protected documentRef(id: string): DocumentReference {
		return doc(this.firestore, `${this.fireStoreCollection}/${id}`);
	}

	getDocumentData(id: string): Observable<T | undefined> {
		return docData<T>(this.documentRef(id) as DocumentReference<T>, { idField: 'id' });
	}

	getCollectionData(): Observable<T[]> {
		return collectionData(this.collectionRef, { idField: 'id' });
	}

	async getDocSnapshot(id: string): Promise<DocumentSnapshot<T>> {
		return getDoc(this.documentRef(id)) as Promise<DocumentSnapshot<T>>;
	}

	async create(object: T): Promise<DocumentReference<T>> {
		return await addDoc(this.collectionRef, object);
	}

	async set(id: string, object: T): Promise<void> {
		return setDoc(this.documentRef(id), object);
	}

	async update(id: string, object: UpdateData<T>): Promise<void> {
		return await updateDoc<T, T>(this.documentRef(id) as DocumentReference<T, T>, object);
	}

	async delete(id: string): Promise<void> {
		return await deleteDoc(this.documentRef(id));
	}
}
