import { take, takeUntil } from 'rxjs';

import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { AppRoutes } from '@app/app.routes';
import { BaseLeadRequestDetail } from '@leads/client-facing/classes/base-lead-request-detail.component';
import { ScheduledAssessmentEmployeeIdChange, ScheduledAssessmentNotesChange, ScheduledAssessmentStatusChange } from '@leads/client-facing/classes/base-scheduled-item-detail.component';
import { LeadAddPropertyModalComponent } from '@leads/client-facing/components/lead-add-property-modal/lead-add-property-modal.component';
import { ScheduledItemActionsComponent } from '@leads/client-facing/components/scheduled-item-actions/scheduled-item-actions.component';
import { ScheduledItemTitleComponent } from '@leads/client-facing/components/scheduled-item-title/scheduled-item-title.component';
import { ILeadAddProperty, PropertySelectType } from '@leads/client-facing/models/domain/lead-request.domain';
import { MobileScheduledItemCreateModalComponent } from '@leads/client-facing/screens/mobile/mobile-scheduled-item-create-modal/mobile-scheduled-item-create-modal.component';
import { MobileScheduledItemDetailModalComponent } from '@leads/client-facing/screens/mobile/mobile-scheduled-item-detail-modal/mobile-scheduled-item-detail-modal.component';
import { LeadRequestType } from '@leads/shared/models/domain/lead-request.domain';
import { ScheduledAssessment } from '@leads/shared/models/domain/scheduled-assessment.domain';
import { BaseModalComponent } from '@shared/components/modals/base-modal/base-modal.component';
import { AccordionItemComponent } from '@shared/components/ui/accordion-item/accordion-item.component';
import { AccordionComponent } from '@shared/components/ui/accordion/accordion.component';

@Component({
	selector: 'app-mobile-scheduled-items-list-modal',
	standalone: true,
	imports: [
		BaseModalComponent,
		CommonModule,

		AccordionComponent,
		AccordionItemComponent,

		AccordionComponent,
		AccordionItemComponent,

		ScheduledItemActionsComponent,
		ScheduledItemTitleComponent,
	],
	templateUrl: './mobile-scheduled-items-list-modal.component.html',
	styleUrl: './mobile-scheduled-items-list-modal.component.scss',
	host: {
		class: 'h-100',
	},
})
export class MobileScheduledItemsListModalComponent extends BaseLeadRequestDetail {
	openScheduler() {
		this.modalService.close();
		this.router.navigate([AppRoutes.SCHEDULE]);
	}

	override addCallAssessment(): void {
		this.openCreateScheduledItemModal(LeadRequestType.CALL);
	}

	override addOnSiteAssessment(): void {
		if (!this.leadRequest.personalDetails.installationAddress.fullAddress) {
			const modalRef = this.modalService.open(LeadAddPropertyModalComponent, false, 'md');
			modalRef.componentInstance.clientProperties = this.client.properties;
			modalRef.componentInstance.showContextAlert = true;
			modalRef.componentInstance.propertySelected.pipe(take(1)).subscribe((propertySelected: ILeadAddProperty) => {
				this.leadRequestService.updateInstallationAddress(this.leadRequest.id!, propertySelected.selectedClientProperty);
				if (propertySelected.propertySelectType === PropertySelectType.NEW) {
					this.clientService.addClientProperty(this.client.id!, propertySelected.selectedClientProperty);
				}
				this.openCreateScheduledItemModal(LeadRequestType.ON_SITE_VISIT);
			});
		} else {
			this.openCreateScheduledItemModal(LeadRequestType.ON_SITE_VISIT);
		}
	}

	private openCreateScheduledItemModal(leadRequestType: LeadRequestType) {
		const modalRef = this.modalService.open(MobileScheduledItemCreateModalComponent, true, 'lg');

		modalRef.componentInstance.scheduledItemRequestType = leadRequestType;
		modalRef.componentInstance.selectedOrganisationRef = this.selectedOrganisationRef;
		modalRef.componentInstance.employees = this.employees;
		modalRef.componentInstance.activeOrganisationUsers = this.activeOrganisationUsers;
		modalRef.componentInstance.client = this.client;
		modalRef.componentInstance.leadRequest = this.leadRequest;
		modalRef.componentInstance.disabledDaysOfTheWeek = this.disabledDaysOfTheWeek;
		modalRef.componentInstance.serviceTypes = this.serviceTypes;
		modalRef.componentInstance.createdAssessment.pipe(take(1)).subscribe((assessment: ScheduledAssessment) => {
			this.addAssessment(assessment);
		});
	}

	openScheduledItemsListItemModal(item: ScheduledAssessment, index: number): void {
		const modalRef = this.modalService.open(MobileScheduledItemDetailModalComponent, true, 'md');
		modalRef.componentInstance.index = index;
		modalRef.componentInstance.isSavingNotes = this.isSavingNotes;
		modalRef.componentInstance.scheduledItem = item;
		modalRef.componentInstance.client = this.client;
		modalRef.componentInstance.readonlyView = this.readonlyView;
		modalRef.componentInstance.employees = this.employees;
		modalRef.componentInstance.statusChanged.pipe(takeUntil(this.destroyed$)).subscribe(($event: ScheduledAssessmentStatusChange) => {
			this.statusChanged($event);
		});
		modalRef.componentInstance.employeeIdChanged
			.pipe(takeUntil(this.destroyed$))
			.subscribe(($event: ScheduledAssessmentEmployeeIdChange) => {
				this.employeeIdChanged($event);
			});
		modalRef.componentInstance.assessmentNotesChanged
			.pipe(takeUntil(this.destroyed$))
			.subscribe(($event: ScheduledAssessmentNotesChange) => {
				this.assessmentNotesChanged($event);
			});
	}
}
