import { NgbCalendar, NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { IDisabledDaysOfTheWeek, NgbDayNumber } from './models';
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

export abstract class DatePickerParent {
	abstract disabledDaysOfTheWeek: IDisabledDaysOfTheWeek;
	abstract dateSelected: (ngbDate: NgbDate) => void;
}

@Component({
	template: '',
})
export abstract class DatePickerBase<T extends { [K in keyof T]: AbstractControl }> {
	@Input() dateSelected!: (ngbDate: NgbDate) => void;
	@Input() disabledDaysOfTheWeek: IDisabledDaysOfTheWeek;
	@Input() form!: FormGroup<T>;
	@Input() name!: Extract<keyof T, string | number>;
	@Output() nextAvailableDate: EventEmitter<Date> = new EventEmitter<Date>();
	today = inject(NgbCalendar).getToday();

	isDisabled = (date: NgbDateStruct) => {
		const currentDate = new Date(date.year, date.month - 1, date.day);
		const day = currentDate.getDay();

		return (
			(day === NgbDayNumber.SUNDAY && this.disabledDaysOfTheWeek.sunday) ||
			(day === NgbDayNumber.MONDAY && this.disabledDaysOfTheWeek.monday) ||
			(day === NgbDayNumber.TUESDAY && this.disabledDaysOfTheWeek.tuesday) ||
			(day === NgbDayNumber.WEDNESDAY && this.disabledDaysOfTheWeek.wednesday) ||
			(day === NgbDayNumber.THURSDAY && this.disabledDaysOfTheWeek.thursday) ||
			(day === NgbDayNumber.FRIDAY && this.disabledDaysOfTheWeek.friday) ||
			(day === NgbDayNumber.SATURDAY && this.disabledDaysOfTheWeek.saturday)
		);
	};

	ngOnInit(): void {
		this.nextAvailableDate.emit(this.getNextAvailableDate(new Date()));
	}

	getNextAvailableDate(date: Date): Date {
		const maxIterations = 30;
		let iterations = 0;
		let nextDate = new Date(date);

		while (
			this.isDisabled({ year: nextDate.getFullYear(), month: nextDate.getMonth() + 1, day: nextDate.getDate() }) &&
			iterations < maxIterations
		) {
			nextDate.setDate(nextDate.getDate() + 1);
			iterations++;
		}

		return iterations < maxIterations ? nextDate : new Date();
	}

	get control() {
		return this.form.controls[this.name];
	}
}
