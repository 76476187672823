<div class="dashboard-container">
	<div class="heading">
		<app-page-title title="Leads"></app-page-title>
	</div>
	<div class="content">
		<app-loading-state [isLoading]="isLoading" [loadedContent]="loadedContent"></app-loading-state>
		<ng-template #loadedContent>
			<div class="dashboard-container">
				<div class="content">
					<ng-container *ngIf="isMobileSize; else desktopSize">
						<app-mobile-lead-requests-table
							[leads]="leads"
							[isLoading]="isSearching"
							[searchTerm$]="searchTerm$"
							[leadRequestType$]="leadRequestType$"
							[leadStatus$]="leadStatus$"
							(onClick)="onClick($event)"
						></app-mobile-lead-requests-table>
					</ng-container>
					<ng-template #desktopSize>
						<app-desktop-lead-requests-table
							[leads]="leads"
							[isLoading]="isSearching"
							[searchTerm$]="searchTerm$"
							[leadRequestType$]="leadRequestType$"
							[leadStatus$]="leadStatus$"
							(onClick)="onClick($event)"
						></app-desktop-lead-requests-table>
					</ng-template>
				</div>
			</div>
		</ng-template>
	</div>
</div>
