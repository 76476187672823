import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
	selector: 'app-search-control',
	standalone: true,
	imports: [CommonModule, FormsModule, ReactiveFormsModule],
	templateUrl: './search-control.component.html',
	styleUrl: './search-control.component.scss',
})
export class SearchControlComponent {
	@Input() control!: FormControl;
	@Input() placeholder: string;
}
