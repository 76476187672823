<ul class="nav nav-pills">
	<div class="nav-menu" id="nav-menu">
		<li class="nav-item">
			<app-organisation-selector [isCollapsed]="isCollapsed"></app-organisation-selector>
		</li>
		<li class="nav-item">
			<div class="line">
				<hr />
			</div>
		</li>
		<ng-container *ngFor="let item of navigationItems">
			<app-menu-nav-item
				[item]="item"
				parentId="nav-menu"
				[isCollapsed]="isCollapsed"
				[isChild]="false"
				(dismiss)="dismissOffcanvas($event)"
			></app-menu-nav-item>
		</ng-container>
	</div>
	<div>
		<li class="nav-item">
			<a class="nav-link text-truncate text-secondary">
				<i class="bi bi-git"></i>
				<ng-container *ngIf="!isCollapsed"> v{{ version }} </ng-container>
			</a>
		</li>

		<li class="nav-item">
			<a class="nav-link text-truncate text-danger" role="button" (click)="handleSignOut()">
				<i class="bi bi-box-arrow-right"></i>
				<ng-container *ngIf="!isCollapsed"> Log out </ng-container>
			</a>
		</li>

		<li class="nav-item">
			<div class="line">
				<hr />
			</div>
		</li>

		<li class="nav-item">
			<a class="nav-link active text-white" role="button" [routerLink]="[AppRoutes.SETTINGS, SettingsRoutes.USER]">
				<div class="my-profile-text">
					<div class="text-truncate">
						<ng-container *ngIf="authService.currentUser?.photoURL; else noPhoto">
							<img [src]="authService.currentUser?.photoURL" class="img-fluid rounded-circle" alt="profile pic" />
						</ng-container>
						<ng-template #noPhoto>
							<i class="bi bi-person"></i>
						</ng-template>
						<ng-container *ngIf="!isCollapsed">
							{{ authService.currentUser?.displayName }}
						</ng-container>
					</div>
					<div>
						<ng-container *ngIf="!isCollapsed">
							<i class="bi bi-arrow-right"></i>
						</ng-container>
					</div>
				</div>
			</a>
		</li>
	</div>
</ul>
