import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit, EventEmitter } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LayoutService } from '@layout/services/layout/layout.service';
import { takeUntil } from 'rxjs';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { MobileHeaderComponent } from '../mobile-header/mobile-header.component';
import { MobileFooterComponent } from '../mobile-footer/mobile-footer.component';

@Component({
	selector: 'app-app-layout',
	standalone: true,
	imports: [RouterOutlet, CommonModule, SidebarComponent, MobileHeaderComponent, MobileFooterComponent],
	templateUrl: './app-layout.component.html',
	styleUrl: './app-layout.component.scss',
})
export class AppLayoutComponent implements OnInit, OnDestroy {
	private layoutService = inject(LayoutService);

	destroyed$ = new EventEmitter<void>();
	isMobileSize = true;

	ngOnInit(): void {
		this.layoutService.isMobileSize$.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
			this.isMobileSize = value;
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}
}
