<div class="wrapper">
	<div class="container shadow">
		<div class="left-container">
			<div class="content-wrapper">
				<router-outlet></router-outlet>
				<app-policy-footer></app-policy-footer>
			</div>
		</div>
		<div class="right-container bg-serveox">
			<app-content-marketing></app-content-marketing>
		</div>
	</div>
</div>
