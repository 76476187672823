import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, QueryList, ViewChildren } from '@angular/core';
import { AbstractControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IRadioOption } from '@shared/models/form-controls';

@Component({
	selector: 'app-radio-group-control',
	standalone: true,
	imports: [CommonModule, FormsModule, ReactiveFormsModule],
	templateUrl: './radio-group-control.component.html',
	styleUrl: './radio-group-control.component.scss',
})
export class RadioGroupControlComponent<T extends { [K in keyof T]: AbstractControl }> {
	@Input() formGroup!: FormGroup<T>;
	@Input() name!: Extract<keyof T, string>;
	@Input() options: Array<IRadioOption> = [];
	@Input() flexDirection?: 'row' | 'column' = 'row';

	@ViewChildren('radioItem') tabLinks!: QueryList<ElementRef>;

	scrollToTab(index: number) {
		this.tabLinks.toArray()[index].nativeElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
	}
}
