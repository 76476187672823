<form [formGroup]="formGroup">
	<div class="form-floating">
		<select class="form-select" [class.is-invalid]="control.invalid && control.touched" [formControlName]="name" [id]="name">
			<option [value]="null" disabled selected>Please select</option>
			<ng-container *ngFor="let option of options">
				<option [value]="option.value" [disabled]="option.disabled">{{ option.label | titlecase }}</option>
			</ng-container>
		</select>
		<label [for]="name">{{ label }}<ng-container *ngIf="control.hasValidator(Validators.required)">*</ng-container></label>

		<div *ngIf="control.invalid && control.errors" class="invalid-feedback">
			<p *ngIf="control.errors?.required">{{ label }} Required.</p>
		</div>
	</div>
</form>
