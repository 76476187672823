<div class="policy-footer">
	<img src="assets/logos/serveox-logo-grey.svg" alt="ServeOx logo" class="img-fluid my-4" />

	<div>
		<p class="text-body-secondary">
			<a class="text-body-secondary text-decoration-none" target="_blank" [href]="privacyPolicyUrl"> Privacy Policy </a>
			•
			<a class="text-body-secondary text-decoration-none" target="_blank" [href]="termsAndConditionsUrl"> Terms & Conditions </a>
		</p>
	</div>
</div>
