<div class="subpage-container">
	<form [formGroup]="form">
		<div class="form-container">
			<div class="form-column">
				<app-header-popover [heading]="title" [infoPopOver]="infoPopover"></app-header-popover>

				<app-textarea-control
					[resizable]="true"
					name="notes"
					[formGroup]="form"
					label="Notes"
					placeholder="Notes"
				></app-textarea-control>
			</div>
		</div>
	</form>
</div>
