import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'calendarDayCellTime',
	standalone: true
})
export class CalendarDayCellTimePipe implements PipeTransform {
	transform(value: Date | string | number): string {
		const date = new Date(value);
		const hours = date.getHours();
		const minutes = date.getMinutes();
		const ampm = hours >= 12 ? 'pm' : 'am';
		const hour = hours % 12 || 12;
		const minuteStr = minutes === 0 ? '' : `:${minutes.toString().padStart(2, '0')}`;
		return `${hour}${minuteStr}${ampm}`;
	}
}
