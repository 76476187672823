import { AbstractControl, ValidatorFn } from '@angular/forms';

/**
 * Class providing a custom validator to check if two form controls match.
 */
export class MatchValidator {
	/**
	 * Returns a ValidatorFn that checks if the values of two form controls match.
	 * @param controlName - The name of the control to match.
	 * @param matchingControlName - The name of the control to match against.
	 * @returns A ValidatorFn that performs the matching validation.
	 */
	static match<T>(controlName: Extract<keyof T, string>, matchingControlName: Extract<keyof T, string>): ValidatorFn {
		return (form: AbstractControl) => {
			const control = form.get(controlName);
			const matchingControl = form.get(matchingControlName);

			if (matchingControl?.errors && !matchingControl?.errors?.noMatch) {
				return null;
			}

			if (control?.value !== matchingControl?.value) {
				const error = { noMatch: 'Fields do not match.' };
				matchingControl?.setErrors(error);
				return error;
			} else {
				matchingControl?.setErrors(null);
				return null;
			}
		};
	}
}
