import { Component } from '@angular/core';
import { MenuCustomerComponent } from '../menu-customer/menu-customer.component';
import { RouterLink } from '@angular/router';

@Component({
	selector: 'app-mobile-header-customer',
	standalone: true,
	imports: [MenuCustomerComponent, RouterLink],
	templateUrl: './mobile-header-customer.component.html',
	styleUrl: './mobile-header-customer.component.scss',
})
export class MobileHeaderCustomerComponent {}
