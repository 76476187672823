<div class="sidebar-container">
	<div class="sidebar-content" [class.collapsed]="isCollapsed">
		<div class="logo-container">
			<a class="navbar-brand" routerLink="/">
				<div class="nav-image-container">
					<img [src]="SERVEOX_LOGO" alt="ServeOx Logo" class="logo" [class.active]="navbarImage === SERVEOX_LOGO" />
					<img [src]="SERVEOX_ICON" alt="ServeOx Logo" class="icon" [class.active]="navbarImage === SERVEOX_ICON" />
				</div>
			</a>
			<button role="button" tabIndex="0" class="collapse-btn" (click)="toggleSidebar()">
				<i class="icon bi bi-chevron-double-left" [class.flipped]="isCollapsed"></i>
			</button>
		</div>

		<app-menu-customer [isCollapsed]="isCollapsed" class="menu-container"></app-menu-customer>
	</div>
</div>
