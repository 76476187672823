import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { LayoutService } from '@layout/services/layout/layout.service';
import { DesktopLeadAssessmentDetailsComponent } from '@leads/customer-facing/components/forms/desktop-lead-assessment-details/desktop-lead-assessment-details.component';
import { LeadNotesComponent } from '@leads/customer-facing/components/forms/lead-notes/lead-notes.component';
import { MobileLeadAssessmentDetailsComponent } from '@leads/customer-facing/components/forms/mobile-lead-assessment-details/mobile-lead-assessment-details.component';
import { LeadRequestFormService } from '@leads/customer-facing/services/lead-request-form/lead-request-form.service';
import { ILeadRequestForm } from '@leads/customer-facing/models/lead-request';
import { LeadRequestsService } from '@leads/customer-facing/services/lead-request/lead-requests.service';
import { CustomerLeadRequestRoutes } from '@leads/leads.routes';
import { LeadRequestType } from '@leads/shared/models/lead.models';
import { IOrganisation } from '@organisations/models/organisations';
import { OrganisationService } from '@organisations/services/organisation/organisation.service';
import { ToastTypes } from '@shared/models/toast';
import { ToastService } from '@shared/services/toast/toast.service';
import { takeUntil } from 'rxjs';

@Component({
	selector: 'app-lead-request-assessment-details',
	standalone: true,
	imports: [CommonModule, RouterLink, LeadNotesComponent, DesktopLeadAssessmentDetailsComponent, MobileLeadAssessmentDetailsComponent],
	templateUrl: './lead-request-assessment-details.component.html',
	styleUrl: './lead-request-assessment-details.component.scss',
})
export class LeadRequestAssessmentDetailsComponent implements OnInit, OnDestroy {
	private layoutService = inject(LayoutService);
	private toastService = inject(ToastService);
	private leadRequestService = inject(LeadRequestsService);
	private leadRequestFormService = inject(LeadRequestFormService);
	private organisationService = inject(OrganisationService);
	private route = inject(ActivatedRoute);
	private router = inject(Router);

	organisationId: string;
	organisation: IOrganisation;
	isSubmitting = false;

	destroyed$ = new EventEmitter<void>();
	isMobileSize = true;
	leadRequestForm: FormGroup<ILeadRequestForm>;
	CustomerLeadRequestRoutes = CustomerLeadRequestRoutes;
	LeadRequestType = LeadRequestType;

	ngOnInit(): void {
		this.leadRequestForm = this.leadRequestFormService.getForm();
		this.validatePreviousStep();

		this.organisationId = this.route.parent?.parent?.snapshot.params.organisationId;

		this.organisationService.getDocSnapshot(this.organisationId).then((doc) => {
			if (doc.exists()) {
				this.organisation = doc.data();
			}
		});

		this.layoutService.isMobileSize$.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
			this.isMobileSize = value;
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}

	validatePreviousStep(): void {
		if (this.leadRequestForm.controls.contactDetails.invalid) {
			this.router.navigate(['../' + CustomerLeadRequestRoutes.NEW], { relativeTo: this.route });
		}

		if (
			this.leadRequestForm.controls.requestType.value === LeadRequestType.ON_SITE_VISIT &&
			this.leadRequestForm.controls.installationAddress.invalid
		) {
			this.router.navigate(['../' + CustomerLeadRequestRoutes.NEW], { relativeTo: this.route });
		}
	}

	get requestType(): FormControl<LeadRequestType | null> {
		return this.leadRequestForm.controls.requestType;
	}

	submit(): void {
		if (this.leadRequestForm.invalid) {
			return;
		}

		this.isSubmitting = true;

		this.leadRequestService
			.createLeadRequest(this.leadRequestForm, this.organisationId, this.organisation.companyName)
			.then((result) => {
				this.isSubmitting = false;

				if (result) {
					this.leadRequestForm.reset();
					this.router.navigate(['../' + CustomerLeadRequestRoutes.SUCCESS.replace(':leadRequestId', result.id)], {
						relativeTo: this.route,
					});
				}
			})
			.catch((error) => {
				console.error(error);
				this.isSubmitting = false;
				this.toastService.showToast({
					title: 'Request',
					message: 'Failed to submit request. Please contact support if the issue persists.',
					type: ToastTypes.ERROR,
				});
			});
	}
}
