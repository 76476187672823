<div class="login-container">
	<div class="logo">
		<img src="assets/logos/serveox-logo-blue.svg" class="img-fluid" alt="ServeOx Logo" />
	</div>

	<h2 class="text-center">Login</h2>

	<app-google-signin></app-google-signin>

	<hr />

	<form [formGroup]="form" class="form-container">
		<app-standard-control
			type="email"
			name="email"
			[formGroup]="form"
			label="Email Address"
			placeholder="Email Address"
			autocomplete="email"
		></app-standard-control>

		<app-password-control
			[formGroup]="form"
			name="password"
			label="Password"
			placeholder="Password"
			autocomplete="current-password"
		></app-password-control>

		<p class="text-center"><a class="text-decoration-none" [routerLink]="'../' + AuthRoutes.PASSWORD_RESET">Forgot Password?</a></p>

		<button type="submit" class="btn btn-primary" [disabled]="form.invalid || isLoading" (click)="handleLogin()">
			<ng-container *ngIf="isLoading; else notLoading">
				<div class="spinner-border spinner-border-sm" role="status">
					<span class="visually-hidden">Loading...</span>
				</div>
			</ng-container>
			<ng-template #notLoading> Login </ng-template>
		</button>
	</form>

	<p class="text-center">
		Don't have an account yet? <a class="text-decoration-none" [routerLink]="'../' + AuthRoutes.SIGNUP">Sign up here.</a>
	</p>
</div>
