import { ChangeDetectionStrategy, Component, EventEmitter, inject, OnDestroy, OnInit } from '@angular/core';
import { JsonPipe, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { FormsModule } from '@angular/forms';
import 'zone.js';
import { PageTitleComponent } from '@shared/components/sections/page-title/page-title.component';
import { StepNavigatorComponent } from '@shared/components/ui/step-navigator/step-navigator.component';
import { LayoutService } from '@layout/services/layout/layout.service';
import {
	OrganisationSelectedService
} from '@organisations/services/organisation-selected/organisation-selected.service';
import { filter, takeUntil } from 'rxjs';
import { OrganisationUserAccessLevel } from '@organisations/models/organisation-user';
import { LoadingStateComponent } from '@shared/components/loading-state/loading-state.component';
import { UnauthorisedBannerComponent } from '@shared/components/ui/unauthorised-banner/unauthorised-banner.component';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { CalendarService } from '@schedule/services/calendar/calendar.service';
import { ScheduledEvent } from '@schedule/models/calendar/schedule.model';
import { CalendarViewComponent } from '@schedule/components/calendar-view/calendar-view.component';

@Component({
	selector: 'app-schedule',
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		NgSwitch,
		FormsModule,
		JsonPipe,
		PageTitleComponent,
		StepNavigatorComponent,
		NgSwitchCase,
		LoadingStateComponent,
		NgIf,
		UnauthorisedBannerComponent,
		CalendarViewComponent
	],
	templateUrl: './schedule.component.html',
	styleUrl: './schedule.component.scss'
})
export class ScheduleComponent implements OnInit, OnDestroy {
	destroyed$ = new EventEmitter<void>();
	isMobileSize = true;
	organisationId: string;

	userHasAccess = false;
	isLoading = true;

	private layoutService = inject(LayoutService);
	private organisationSelectedService = inject(OrganisationSelectedService);

	// Data
	private calendarService = inject(CalendarService);

	view: CalendarView = CalendarView.Month;
	events: CalendarEvent<ScheduledEvent>[] = [];
	actions: CalendarEventAction[] = [
		{
			label: 'View',
			a11yLabel: 'View',
			cssClass: 'bi-eye',
			onClick: ({ event }: { event: CalendarEvent }): void => {
				this.handleEvent('View', event);
			}
		},
		{
			label: 'Edit',
			a11yLabel: 'Edit',
			cssClass: 'bi-pen',
			onClick: ({ event }: { event: CalendarEvent }): void => {
				this.handleEvent('Edited', event);
			}
		},
		{
			label: 'Delete',
			a11yLabel: 'Delete',
			cssClass: 'bi-trash',
			onClick: ({ event }: { event: CalendarEvent }): void => {
				this.events = this.events.filter((iEvent) => iEvent !== event);
				this.handleEvent('Deleted', event);
			}
		}
	];
	viewDate: Date = new Date();

	ngOnInit(): void {
		this.layoutService.isMobileSize$.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
			this.isMobileSize = value;
		});

		this.subscribeToSelectedOrganisation();

		this.calendarService.getCalendarEvents()
			.pipe(takeUntil(this.destroyed$))
			.subscribe((events) => {
				this.events = events.map(event => ({
					...event,
					actions: this.actions,
					resizable: {
						beforeStart: true,
						afterEnd: true
					},
					draggable: true
				}));
			});
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}

	subscribeToSelectedOrganisation(): void {
		this.organisationSelectedService.selectedOrganisation
			.pipe(
				takeUntil(this.destroyed$),
				filter((o) => o !== null)
			)
			.subscribe((selectedOrg) => {
				if (selectedOrg?.accessLevel === OrganisationUserAccessLevel.OWNER) {
					this.userHasAccess = true;
				} else {
					this.userHasAccess = false;
					this.isLoading = false;
				}
			});
	}


	dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
		console.log('dayClicked', date, events);
	}

	handleEvent(action: string, event: CalendarEvent): void {
		console.log('handleEvent', action, event);
		// this.modalData = { event, action };
		// this.modal.open(this.modalContent, { size: 'lg' });
	}

	eventTimesChanged({ event, newStart, newEnd }: CalendarEventTimesChangedEvent): void {
		console.log('eventTimesChanged', event, newStart, newEnd);
		this.events = this.events.map((iEvent) => {
			if (iEvent === event) {
				return {
					...event,
					start: newStart,
					end: newEnd
				};
			}
			return iEvent;
		});
		this.handleEvent('Dropped or resized', event);
	}


}
