import { Component, Input } from '@angular/core';
import { CalendarEvent } from 'angular-calendar';
import { ScheduledEvent } from '@schedule/models/calendar/schedule.model';
import { CommonModule, NgForOf } from '@angular/common';

@Component({
	selector: 'app-calendar-popover',
	standalone: true,
	imports: [
		CommonModule,
		NgForOf
	],
	templateUrl: './calendar-popover.component.html',
	styleUrl: './calendar-popover.component.scss'
})
export class CalendarPopoverComponent {
	@Input() event: CalendarEvent<ScheduledEvent>;
}
