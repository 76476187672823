import { DocumentReference, Timestamp } from '@angular/fire/firestore';
import { LeadRequestType } from '@leads/shared/models/lead.models';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export interface IScheduledAssessment {
	createdDate: Timestamp;
	updatedDate: Timestamp;
	leadRequest: DocumentReference;
	requestType: LeadRequestType;
	employee: DocumentReference;
	selectedDate: NgbDateStruct;
	selectedStartTime: Timestamp;
	selectedEndTime: Timestamp;
	assessments?: Array<string>;
	assessmentType?: ScheduledAssessmentType;
	callOutFeeRands?: number;
	assessmentNotes: string;
	whatsappNotes: string;
	status: ScheduledAssessmentStatus;
}

export enum ScheduledAssessmentType {
	ASSESSMENT = 'ASSESSMENT',
	JOB = 'JOB',
}

export enum ScheduledAssessmentStatus {
	REQUESTED = 'REQUESTED',
	APPROVED = 'APPROVED',
	REJECTED = 'REJECTED',
	CANCELLED = 'CANCELLED',
	COMPLETED = 'COMPLETED',
}
