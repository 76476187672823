import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'nullDefault',
	standalone: true,
})
export class NullDefaultPipe implements PipeTransform {
	transform(value: unknown, defaultValue: string): string | unknown {
		return value == null || (typeof value === 'string' && !value.length) || (Array.isArray(value) && value.length === 0)
			? defaultValue
			: value;
	}
}
