<form [formGroup]="formGroup" [class.h-100]="height100">
	<div body class="form-floating" [class.h-100]="height100">
		<textarea
			[style.height.px]="heightPx"
			[class.h-100]="height100"
			[style.resize]="resizable ? 'vertical' : 'none'"
			[formControlName]="name"
			class="form-control"
			[class.is-invalid]="control.invalid && control.touched"
			[id]="name"
			[placeholder]="placeholder"
		></textarea>
		<label [for]="name">{{ label }}</label>

		<div *ngIf="control.invalid && control.errors" class="invalid-feedback">
			<p *ngIf="control.errors?.required">{{ label }} required.</p>
			<p *ngIf="control.errors?.pattern">{{ label }} is invalid.</p>
			<p *ngIf="control.errors?.minlength">{{ label }} must be at least {{ control.errors.minlength.requiredLength }} characters.</p>
			<p *ngIf="control.errors?.maxlength">{{ label }} must be less than {{ control.errors.maxlength.requiredLength }} characters.</p>
		</div>
	</div>
</form>
