import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

/**
 * Class providing a custom validator to check if a number is a valid South African ID number.
 */
export class IDNumberValidator {
	/**
	 * Returns a ValidatorFn that checks if the value of the form control is a valid South African ID Number
	 * and adheres to Luhn's algorithm.
	 * @returns A ValidatorFn that performs the validation.
	 */
	static validate(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const idNumber: string = control.value;
			const required = control.hasValidator(Validators.required);

			// If ID number is not required and is an empty string, allow it to pass.
			if (!required && (/^\s*$/.test(idNumber) || idNumber === null)) {
				return null;
			}

			// Test ID number is 13 Digits only
			if (!/^\d{13}$/.test(idNumber)) {
				return { custom: 'ID number must contain 13 numerical digits' };
			}

			// Ensure ID number complies with Luhn's Algorithm
			if (!IDNumberValidator.isValidLuhn(idNumber)) {
				return { custom: 'Must be a valid South African ID number' };
			}

			return null;
		};
	}

	/**
	 * Validates a given number using Luhn's algorithm.
	 * @param idNumber The ID number to validate.
	 * @returns True if the number passes the Luhn check, false otherwise.
	 */
	private static isValidLuhn(idNumber: string): boolean {
		let sum = 0;
		let doubleUp = false;

		if (idNumber === null) {
			return false;
		}

		for (let i = idNumber.length - 1; i >= 0; i--) {
			let digit = parseInt(idNumber.charAt(i), 10);

			if (doubleUp) {
				digit *= 2;
				if (digit > 9) {
					digit -= 9;
				}
			}

			sum += digit;
			doubleUp = !doubleUp;
		}

		return sum % 10 === 0;
	}
}
