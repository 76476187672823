<div class="subpage-container">
	<form [formGroup]="form" class="h-100">
		<div class="form-container">
			<div class="form-column">
				<app-header-popover heading="Assessment Date & Time" [infoPopOver]="assessmentPopOver"></app-header-popover>

				<app-radio-group-control [formGroup]="form" name="dateType" [options]="dateTypeOptions" />

				<ng-container *ngIf="dateType.value === LeadOnSiteVisitDateTimeType.REQUEST">
					<app-alert content="Please select your preferred date & time."></app-alert>
					<div class="date-selector">
						<div class="date-item">
							<app-date-picker-fullscreen
								[form]="form"
								name="requestedDate"
								[disabledDaysOfTheWeek]="disabledDaysOfTheWeek"
								(selectedDateChanged)="datePickerDateChanged($event)"
							></app-date-picker-fullscreen>
						</div>
						<div class="date-item">
							<h5 class="text-primary text-center">
								{{ formattedDate }}
							</h5>

							<app-radio-group-control
								[formGroup]="form"
								name="requestedTimeWindow"
								[options]="preferredTimeOptions"
								flexDirection="column"
							></app-radio-group-control>
						</div>
					</div>
				</ng-container>
				<ng-container *ngIf="dateType.value === LeadOnSiteVisitDateTimeType.ASAP">
					<app-alert
						content="This will notify the specialist that it's an emergency, and they'll be alerted right away."
					></app-alert>
				</ng-container>
			</div>
			<div class="form-column">
				<app-header-popover heading="Service Type" [infoPopOver]="serviceTypePopover"></app-header-popover>

				<app-dropdown-control
					class="w-100"
					[formGroup]="form"
					name="serviceType"
					label="Service Type"
					placeholder="Select a service"
					[options]="serviceTypes"
					[searchable]="false"
				></app-dropdown-control>

				<app-custom-form-details [form]="form.controls.customQuestions" [borderWrap]="false"></app-custom-form-details>
			</div>
		</div>
	</form>
</div>
