import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { PopoverComponent } from '@shared/components/ui/popover/popover.component';
import { IPopover } from '@shared/models/popover';

@Component({
	selector: 'app-header-popover',
	standalone: true,
	imports: [CommonModule, PopoverComponent],
	templateUrl: './header-popover.component.html',
	styleUrl: './header-popover.component.scss',
})
export class HeaderPopoverComponent {
	@Input() infoPopOver: IPopover | null;
	@Input() heading: string;
}
