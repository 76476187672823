import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, OnDestroy, OnInit } from '@angular/core';
import { DocumentReference } from '@angular/fire/firestore';
import { ActivatedRoute } from '@angular/router';
import { ILeadRequest, LeadRequestType, LeadStatus } from '@leads/shared/models/lead.models';
import { LeadRequestsService } from '@leads/customer-facing/services/lead-request/lead-requests.service';
import { IOrganisation } from '@organisations/models/organisations';
import { OrganisationService } from '@organisations/services/organisation/organisation.service';
import { ConfirmationModalComponent } from '@shared/components/modals/confirmation-modal/confirmation-modal.component';
import { PageTitleComponent } from '@shared/components/sections/page-title/page-title.component';
import { ModalService } from '@shared/services/modal/modal.service';
import { take, takeUntil } from 'rxjs';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertComponent } from '@shared/components/alerts/alert/alert.component';
import { AlertTypes } from '@shared/models/alert';
import { LoadingStateComponent } from '@shared/components/loading-state/loading-state.component';

@Component({
	selector: 'app-lead-request-view',
	standalone: true,
	imports: [CommonModule, PageTitleComponent, FormsModule, ReactiveFormsModule, AlertComponent, LoadingStateComponent],
	templateUrl: './lead-request-view.component.html',
	styleUrl: './lead-request-view.component.scss',
})
export class LeadRequestViewComponent implements OnInit, OnDestroy {
	private route = inject(ActivatedRoute);
	private modalService = inject(ModalService);
	private leadRequestService = inject(LeadRequestsService);
	private organisationService = inject(OrganisationService);

	destroyed$ = new EventEmitter<void>();
	isLoading = true;
	isSaving = false;
	leadRequestId: string;
	leadRequest: ILeadRequest;
	organisation: IOrganisation;

	LeadStatus = LeadStatus;
	LeadRequestType = LeadRequestType;

	notesForm: FormGroup = new FormGroup({
		callNotes: new FormControl<string | null>(null),
		onsiteNotes: new FormControl<string | null>(null),
	});

	ngOnInit(): void {
		this.leadRequestId = this.route.snapshot.params.leadRequestId;

		this.leadRequestService
			.getLeadRequest(this.leadRequestId)
			.pipe(takeUntil(this.destroyed$))
			.subscribe((leadRequest) => {
				if (leadRequest) {
					this.leadRequest = leadRequest as ILeadRequest;
					if (this.leadRequest.callAssessment?.callNotes) {
						this.notesForm.controls.callNotes.setValue(this.leadRequest.callAssessment.callNotes);
					}
					if (this.leadRequest.onSiteVisitAssessment?.notes) {
						this.notesForm.controls.onsiteNotes.setValue(this.leadRequest.onSiteVisitAssessment.notes);
					}

					this.organisationService
						.getOrganisationByRef(this.leadRequest.organisation as DocumentReference<IOrganisation>)
						.then((doc) => {
							this.isLoading = false;
							if (doc.exists()) {
								this.organisation = doc.data();
							}
						});
				}
			});
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}

	updateCallNotes(): void {
		if (this.notesForm.controls.callNotes.invalid) {
			return;
		}
		this.isSaving = true;
		this.leadRequestService
			.update(this.leadRequestId, {
				'callAssessment.callNotes': this.notesForm.controls.callNotes.value as string,
			})
			.then(() => {
				this.isSaving = false;
				this.notesForm.controls.callNotes.markAsPristine();
			});
	}

	updateOnSiteNotes(): void {
		if (this.notesForm.controls.onsiteNotes.invalid) {
			return;
		}
		this.isSaving = true;
		this.leadRequestService
			.update(this.leadRequestId, {
				'onSiteVisitAssessment.notes': this.notesForm.controls.onsiteNotes.value as string,
			})
			.then(() => {
				this.isSaving = false;
				this.notesForm.controls.onsiteNotes.markAsPristine();
			});
	}

	cancelLeadRequest(): void {
		const modalRef = this.modalService.open(ConfirmationModalComponent, false, 'md');
		modalRef.componentInstance.title = 'Are you sure you want to cancel your appointment request?';
		modalRef.componentInstance.contentText = 'You will not be able to recover this appointment request.';
		modalRef.componentInstance.confirmAction.pipe(take(1)).subscribe(() => {
			this.leadRequestService.updateStatus(this.leadRequestId, LeadStatus.CANCELLED);
		});
	}

	get alertContent(): string {
		switch (this.leadRequest.status) {
			case LeadStatus.NEW:
				return `Your appointment has not been scheduled by ${this.organisation.companyName} yet.`;
			case LeadStatus.ACTIVE:
				return `Your appointment has been scheduled for [Placeholder Date].`;
			case LeadStatus.REJECTED:
				return `Your appointment has been rejected by ${this.organisation.companyName}.`;
			case LeadStatus.CANCELLED:
				return `Your appointment has been cancelled.`;
			case LeadStatus.COMPLETED:
				return `Your appointment with ${this.organisation.companyName} has been completed.`;
			default:
				return '';
		}
	}

	get alertType(): AlertTypes {
		switch (this.leadRequest.status) {
			case LeadStatus.NEW:
			case LeadStatus.ACTIVE:
				return AlertTypes.INFO;
			case LeadStatus.REJECTED:
			case LeadStatus.CANCELLED:
				return AlertTypes.DANGER;
			case LeadStatus.COMPLETED:
				return AlertTypes.SUCCESS;
		}
	}
}
