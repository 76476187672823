import { FormControl } from '@angular/forms';
import { LeadRequestType, LeadStatus } from '@leads/shared/models/lead.models';
import { IDropdownOption } from '@shared/components/forms/dropdown-control/dropdown-control.component';
import { IRadioOption } from '@shared/models/form-controls';

export interface ILeadRequestNotesForm {
	internalNotes: FormControl<string | null>;
}

export const leadStatusOptions: Array<IDropdownOption> = [
	{
		id: 'new',
		icon: 'bi-hourglass-split',
		value: LeadStatus.NEW,
		label: 'New',
	},
	{
		id: 'active',
		icon: 'bi-clock',
		value: LeadStatus.ACTIVE,
		label: 'Active',
	},
	{
		id: 'completed',
		icon: 'bi-check-circle-fill',
		value: LeadStatus.COMPLETED,
		label: 'Completed',
	},
	{
		id: 'rejected',
		icon: 'bi-x-circle-fill',
		value: LeadStatus.REJECTED,
		label: 'Rejected',
	},
	{
		id: 'cancelled',
		icon: 'bi-x-circle-fill',
		value: LeadStatus.CANCELLED,
		label: 'Cancelled',
	},
];

export const leadRequestTypeOptionsDesktop: Array<IRadioOption> = [
	{
		id: 'all',
		label: 'All',
		value: null,
		statusIndicator: 'amber',
	},
	{
		id: 'onsite',
		label: 'On-Site Visits',
		value: LeadRequestType.ON_SITE_VISIT,
		statusIndicator: 'green',
	},
	{
		id: 'call',
		label: 'Call Requests',
		value: LeadRequestType.CALL,
		statusIndicator: 'blue',
	},
];

export const leadRequestTypeOptionsMobile: Array<IRadioOption> = [
	{
		id: 'onsite',
		label: 'On-Site Visits',
		value: LeadRequestType.ON_SITE_VISIT,
		statusIndicator: 'green',
	},
	{
		id: 'call',
		label: 'Call Requests',
		value: LeadRequestType.CALL,
		statusIndicator: 'blue',
	},
];
