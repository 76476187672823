import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-information-modal',
	standalone: true,
	imports: [],
	templateUrl: './information-modal.component.html',
	styleUrl: './information-modal.component.scss',
})
export class InformationModalComponent {
	@Input() title: string;
	@Input() contentText: string;
	@Output() dismissAction = new EventEmitter<void>();
	private activeModal = inject(NgbActiveModal);

	closeModal(): void {
		this.activeModal.close();
	}

	dismiss() {
		this.dismissAction.emit();
		this.closeModal();
	}
}
