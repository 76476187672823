import { takeUntil } from 'rxjs';

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, OnDestroy, OnInit } from '@angular/core';
import { DocumentReference } from '@angular/fire/firestore';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { AppRoutes } from '@app/app.routes';
import { IClientWithProperties } from '@clients/models/firebase/client.model';
import { ClientsService } from '@clients/services/clients/clients.service';
import { LayoutService } from '@layout/services/layout/layout.service';
import { ClientLeadsRoutes } from '@leads/client-facing/client-facing.routes';
import { DesktopLeadRequestCreateDetailsComponent } from '@leads/client-facing/screens/desktop/desktop-lead-request-create-details/desktop-lead-request-create-details.component';
import { MobileLeadRequestCreateDetailsComponent } from '@leads/client-facing/screens/mobile/mobile-lead-request-create-details/mobile-lead-request-create-details.component';
import { ILeadRequestForm } from '@leads/customer-facing/models/domain/lead-request.domain';
import { LeadRequestFormService } from '@leads/customer-facing/services/lead-request-form/lead-request-form.service';
import { LeadRequestType } from '@leads/shared/models/domain/lead-request.domain';
import { LeadRequestService } from '@leads/shared/services/lead-request/lead-request.service';
import { IAvailableOrganisation, OrganisationUserAccessLevel } from '@organisations/models/organisation-user';
import { IOrganisation } from '@organisations/models/organisations';
import { OrganisationSelectedService } from '@organisations/services/organisation-selected/organisation-selected.service';
import { OrganisationService } from '@organisations/services/organisation/organisation.service';
import { IDropdownOption } from '@shared/components/forms/dropdown-control/dropdown-control.component';
import { LoadingStateComponent } from '@shared/components/loading-state/loading-state.component';
import { PageTitleComponent } from '@shared/components/sections/page-title/page-title.component';
import { UnauthorisedBannerComponent } from '@shared/components/ui/unauthorised-banner/unauthorised-banner.component';
import { ToastTypes } from '@shared/models/toast';
import { ToastService } from '@shared/services/toast/toast.service';

@Component({
	selector: 'app-lead-create',
	standalone: true,
	imports: [
		CommonModule,
		RouterLink,
		PageTitleComponent,
		DesktopLeadRequestCreateDetailsComponent,
		MobileLeadRequestCreateDetailsComponent,
		LoadingStateComponent,
		UnauthorisedBannerComponent,
	],
	templateUrl: './lead-create.component.html',
	styleUrl: './lead-create.component.scss',
})
export class LeadCreateComponent implements OnInit, OnDestroy {
	private layoutService = inject(LayoutService);
	private toastService = inject(ToastService);
	private router = inject(Router);
	private route = inject(ActivatedRoute);
	private organisationSelectedService = inject(OrganisationSelectedService);
	private clientsService = inject(ClientsService);
	private leadRequestFormService = inject(LeadRequestFormService);
	private leadRequestService = inject(LeadRequestService);
	private organisationService = inject(OrganisationService);

	destroyed$ = new EventEmitter<void>();
	AppRoutes = AppRoutes;

	isLoading = true;
	isSaving = false;
	isMobileSize = true;
	userHasAccess = false;

	selectedOrganisation: IAvailableOrganisation | null = null;
	clients: Array<IClientWithProperties> = [];
	serviceTypes: Array<IDropdownOption> = [];

	leadRequestForm: FormGroup<ILeadRequestForm>;
	organisation: IOrganisation;

	ngOnInit(): void {
		this.layoutService.isMobileSize$.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
			this.isMobileSize = value;
		});
		this.subscribeToSelectedOrganisation();

		this.initLeadRequestForm();
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}

	initLeadRequestForm(): void {
		this.leadRequestForm = this.leadRequestFormService.getForm();
		this.leadRequestForm.reset();
		this.leadRequestForm.controls.requestType.setValue(LeadRequestType.ON_SITE_VISIT);
		this.leadRequestForm.controls.onsiteAssessment.controls.dateType.setValue(null);
	}

	private fetchOrganisation(organisationRef: DocumentReference<IOrganisation>) {
		this.organisationService.getDocSnapshot(organisationRef.id).then((doc) => {
			if (doc.exists()) {
				this.organisation = doc.data();
				this.leadRequestFormService.updateCustomQuestions(this.organisation.settings.leadsForm || []);
				this.leadRequestForm.controls.callAssessment.disable();
				this.leadRequestFormService.relaxRequiredRestriction();

				this.serviceTypes = this.organisation.settings.serviceTypes.map((s, index) => ({
					id: index + 1,
					value: s,
					label: s,
					icon: 'bi-wrench',
				}));
				this.fetchOrganisationClients(organisationRef);
			}
		});
	}

	private subscribeToSelectedOrganisation() {
		this.organisationSelectedService.selectedOrganisation.pipe(takeUntil(this.destroyed$)).subscribe((selectedOrganisation) => {
			if (selectedOrganisation && selectedOrganisation.accessLevel === OrganisationUserAccessLevel.OWNER) {
				this.selectedOrganisation = selectedOrganisation;
				this.userHasAccess = true;
				this.leadRequestFormService.resetForm();
				this.initLeadRequestForm();
				this.fetchOrganisation(selectedOrganisation.organisation as DocumentReference<IOrganisation>);
			} else {
				this.userHasAccess = false;
				this.isLoading = false;
			}
		});
	}

	private fetchOrganisationClients(organisationRef: DocumentReference<IOrganisation>): void {
		this.clientsService
			.getClientsWithPropertiesByOrganisation(organisationRef, '')
			.pipe(takeUntil(this.destroyed$))
			.subscribe((clients) => {
				this.clients = clients;
				this.isLoading = false;
			});
	}

	createLeadRequest(): void {
		if (this.leadRequestForm.invalid) {
			this.leadRequestForm.markAllAsTouched();
			return;
		}

		this.isSaving = true;

		this.leadRequestService
			.createLeadRequest(this.leadRequestForm, this.selectedOrganisation!.organisation.id, this.selectedOrganisation?.companyName!)
			.then((result) => {
				this.isSaving = false;

				if (result) {
					this.leadRequestForm.reset();
					this.navigateToLeadRequest(result.id);
				}
			})
			.catch((error) => {
				console.error(error);
				this.isSaving = false;
				this.toastService.showToast({
					title: 'Request',
					message: 'Failed to submit request. Please contact support if the issue persists.',
					type: ToastTypes.ERROR,
				});
			});
	}

	private navigateToLeadRequest(leadRequestId: string) {
		this.router.navigate(['../' + ClientLeadsRoutes.ASSESSMENT_REVIEW.replace(':leadRequestId', leadRequestId)], {
			relativeTo: this.route,
		});
	}
}
