export enum FireStoreCollection {
	USERS = 'users',
	ORGANISATIONS = 'organisations',
	ORGANISATION_USER = 'organisation-user',
	CLIENTS = 'clients',
	LEAD_REQUESTS = 'lead-requests',
	MAIL = 'mail',
	MAIL_TEMPLATES = 'mail-templates',
}

export interface FireStoreDocument {
	id?: string;
}
