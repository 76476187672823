import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
	selector: 'app-unauthorised-banner',
	standalone: true,
	imports: [CommonModule, RouterLink],
	templateUrl: './unauthorised-banner.component.html',
	styleUrl: './unauthorised-banner.component.scss',
})
export class UnauthorisedBannerComponent {
	@Input() headingText: string;
}
