/**
 * Format a date range in the format example:
 * Sun, 09 Feb, 09:00–09:30
 * @param start
 * @param end
 */
export function dateTimeRange(start: Date, end: Date): string {
	return Intl.DateTimeFormat('en-ZA', {
		weekday: 'short',
		month: 'short',
		day: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
	}).formatRange(start, end);
}
