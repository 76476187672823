<app-loading-state [isLoading]="isLoading" [loadedContent]="loadedContent"></app-loading-state>
<ng-template #loadedContent>
	<div class="assessment-header">
		<div class="section-header">
			<p class="section-title">{{ schedulerFormItem.controls.requestType.value }}: Assessment {{ index + 1 }}</p>
			<button class="remove-btn btn btn-sm btn-outline-danger" type="button" (click)="removeAssessment()">Remove</button>
		</div>
		<div class="border-container">
			<app-step-navigator [stepConfig]="navigationConfig" [(selectedStep)]="selectedStep"></app-step-navigator>

			<div class="assessment-content">
				<ng-container [ngSwitch]="selectedStep.switchCase">
					<ng-container *ngSwitchCase="NavigationStepCases.ASSIGN_EMPLOYEE">
						<div class="employee-date-time">
							<div class="form-column">
								<div class="text-primary">Employee Name</div>
								<app-dropdown-control
									[formGroup]="schedulerFormItem"
									name="employeeId"
									placeholder="Select an employee"
									label="Employee"
									[options]="employees"
									[searchable]="true"
								></app-dropdown-control>
							</div>
							<div class="form-column">
								<div class="form-row">
									<div class="text-primary">Select a Date & Time</div>
								</div>
								<div class="form-column">
									<div class="date-time-container">
										<div class="date-column" style="min-width: 250px">
											<app-date-picker-fullscreen
												[form]="schedulerFormItem"
												name="selectedDate"
												[disabledDaysOfTheWeek]="disabledDaysOfTheWeek"
												[dateSelected]="dateSelected"
												(nextAvailableDate)="setRequestedDateToNextAvailable($event)"
											></app-date-picker-fullscreen>
										</div>
										<div class="date-column w-100">
											<mwl-calendar-day-view
												[viewDate]="timePickerSelectedDate"
												[dayStartHour]="9"
												[dayEndHour]="18"
												[events]="timePickerEvents"
												[refresh]="refreshTimePicker"
												(eventTimesChanged)="timePickerEventTimesChanged($event)"
											>
											</mwl-calendar-day-view>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="pagination-controls justify-content-end">
							<button
								class="btn btn-primary"
								(click)="nextTab()"
								[disabled]="
									employeeId.invalid || selectedDate.invalid || selectedStartTime.invalid || selectedEndTime.invalid
								"
							>
								Next
								<i class="bi bi-arrow-right"></i>
							</button>
						</div>
					</ng-container>

					<ng-container *ngSwitchCase="NavigationStepCases.ASSIGNMENT_DETAILS">
						<div class="form-row">
							<ng-container *ngIf="requestType.value === LeadRequestType.ON_SITE_VISIT">
								<div class="form-column">
									<div class="text-primary">Assessment Type</div>
									<div class="form-column assessment-details">
										<div class="form-row">
											<app-dropdown-control
												class="w-100"
												[formGroup]="schedulerFormItem"
												name="assessments"
												placeholder="Select a service"
												label="Service Type"
												[options]="serviceTypes"
												[multiple]="true"
												[searchable]="true"
												[clearable]="true"
											></app-dropdown-control>
										</div>
										<div class="form-row">
											<app-dropdown-control
												class="w-100"
												[formGroup]="schedulerFormItem"
												name="assessmentType"
												label="Assessment Type"
												placeholder="Select an assessment type"
												[options]="assessmentTypes"
											></app-dropdown-control>
										</div>
										<ng-container *ngIf="assessmentType?.value === ScheduledAssessmentType.ASSESSMENT">
											<div class="form-row">
												<app-standard-group-control
													class="w-100"
													type="number"
													name="callOutFeeRands"
													[formGroup]="schedulerFormItem"
													label="Call-Out Fee"
													[showLabel]="false"
													placeholder="Call-Out Fee"
													leftText="R"
												></app-standard-group-control>
											</div>
											<div class="form-row">
												<app-switch-control
													name="includeCallOutFeeComms"
													[formGroup]="schedulerFormItem"
													label="Include call-out fee in communication to client"
												></app-switch-control>
											</div>
										</ng-container>
									</div>
								</div>
							</ng-container>
							<div class="form-column">
								<div class="text-primary">Internal Assessment Notes</div>
								<app-textarea-control
									class="h-100"
									[resizable]="false"
									[height100]="true"
									name="assessmentNotes"
									[formGroup]="schedulerFormItem"
									label="Assessment Notes"
									placeholder="Assessment Notes"
								></app-textarea-control>
							</div>
						</div>

						<div class="pagination-controls">
							<button class="btn btn-outline-primary" (click)="previousTab()">
								<i class="bi bi-arrow-left btn-sm"></i>
								Back
							</button>
							<button
								class="btn btn-primary"
								(click)="submitAssessment()"
								[disabled]="schedulerFormItem.invalid || this.assessmentSubmitted"
							>
								Create Assessment
								<i class="bi bi-arrow-right"></i>
							</button>
						</div>
					</ng-container>

					<ng-container *ngSwitchCase="NavigationStepCases.COMMUNICATIONS">
						<div class="form-column">
							<div class="text-primary">WhatsApp Notes</div>
							<app-textarea-control
								class="h-100"
								[resizable]="false"
								[height100]="true"
								name="whatsappNotes"
								[formGroup]="schedulerFormItem"
								label="WhatsApp Notes"
								placeholder="WhatsApp Notes"
							></app-textarea-control>
						</div>

						<div class="pagination-controls">
							<button class="btn btn-outline-primary" (click)="previousTab()">
								<i class="bi bi-arrow-left btn-sm"></i>
								Back
							</button>
							<button class="btn btn-success btn-whatsapp" (click)="shareToWhatsApp()">
								<i class="bi bi-whatsapp"></i>
								Share to WhatsApp
							</button>
						</div>
					</ng-container>
				</ng-container>

				<!-- <pre>{{ schedulerFormItem.valid }}</pre>
				<pre>{{ schedulerFormItem.value | json }}</pre> -->
			</div>
		</div>
	</div>
</ng-template>
