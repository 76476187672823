<div class="modal-header">
	<h5 class="modal-title">Invite User</h5>
	<button type="button" class="btn-close" (click)="closeModal()" aria-label="Close"></button>
</div>
<div class="modal-body">
	<form [formGroup]="inviteUserForm" class="body">
		<app-alert
			alertIcon="bi-exclamation-triangle-fill"
			[alertType]="AlertTypes.WARNING"
			content="When a new user is added and accepts, your billing will be automatically adjusted. Please check the billing section in
		your Organisation settings to monitor any changes."
		></app-alert>

		<app-standard-control
			class="control"
			type="email"
			name="email"
			[formGroup]="inviteUserForm"
			label="Email"
			placeholder="Email"
		></app-standard-control>

		<app-radio-group-control [formGroup]="inviteUserForm" name="accessLevel" [options]="radioOptions" />

		<app-alert [content]="alertContent"></app-alert>
	</form>
</div>
<div class="modal-footer">
	<button type="button" [disabled]="isLoading" class="btn btn-primary btn-width" (click)="submit()" data-id="submit">
		<ng-container *ngIf="isLoading; else notLoading">
			<div class="spinner-border spinner-border-sm" role="status">
				<span class="visually-hidden">Loading...</span>
			</div>
		</ng-container>
		<ng-template #notLoading> Invite </ng-template>
	</button>
</div>
