<form [formGroup]="form">
	<input
		class="form-control"
		[readOnly]="readonly"
		[class.is-invalid]="control.invalid && control.touched"
		placeholder="yyyy-mm-dd"
		name="dp"
		[formControlName]="name"
		ngbDatepicker
		#d="ngbDatepicker"
		(dateSelect)="dateSelected($event)"
		[minDate]="today"
		[firstDayOfWeek]="7"
		[markDisabled]="isDisabled"
		(click)="d.toggle()"
	/>
	<div *ngIf="control.invalid && control.errors" class="invalid-feedback">
		<p *ngIf="control.errors?.required">Date Required.</p>
		<p *ngIf="control.errors?.ngbDate.invalid">Date must be in the format yyyy-mm-dd</p>
		<p *ngIf="control.errors?.ngbDate.minDate">
			Date must be at least {{ control.errors.ngbDate.minDate.minDate.year }}-{{ control.errors.ngbDate.minDate.minDate.month }}-{{
				control.errors.ngbDate.minDate.minDate.day
			}}
		</p>
	</div>
</form>
