import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { OrganisationRoutes } from '@organisations/organisation.routes';
import { AppRoutes } from 'src/app/app.routes';

@Component({
	selector: 'app-incomplete-organisation-banner',
	standalone: true,
	imports: [RouterLink],
	templateUrl: './incomplete-organisation-banner.component.html',
	styleUrl: './incomplete-organisation-banner.component.scss',
})
export class IncompleteOrganisationBannerComponent {
	createOrganisationRoute = ['/', AppRoutes.ORGANISATION, OrganisationRoutes.CREATE];
}
