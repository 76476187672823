<ng-container *ngIf="isMobileSize; else desktopSize">
	<div class="create-client-container">
		<div class="heading">
			<app-page-title title="Create Client" [showBackButton]="true"></app-page-title>
		</div>

		<div class="content">
			<app-mobile-client-create-details [clientForm]="clientForm" [properties]="clientProperties"></app-mobile-client-create-details>
		</div>

		<div class="footer">
			<button type="button" class="btn btn-sm btn-outline-danger btn-width" [routerLink]="AppRoutes.CLIENTS">Cancel</button>
			<button type="button" class="btn btn-sm btn-primary btn-width" (click)="addClient()" [disabled]="clientForm.invalid">
				<ng-container *ngIf="isSaving; else notSaving">
					<div class="spinner-border spinner-border-sm" role="status">
						<span class="visually-hidden">Loading...</span>
					</div>
				</ng-container>
				<ng-template #notSaving> Add Client </ng-template>
			</button>
		</div>
	</div>
</ng-container>
<ng-template #desktopSize>
	<div class="create-client-container">
		<div class="heading">
			<app-page-title title="Add Client" [showBackButton]="true"></app-page-title>
		</div>

		<div class="content">
			<app-desktop-client-details [clientForm]="clientForm"></app-desktop-client-details>
			<app-desktop-client-create-property-table [properties]="clientProperties"></app-desktop-client-create-property-table>
		</div>

		<div class="footer">
			<button type="button" class="btn btn-sm btn-outline-danger btn-width" [routerLink]="AppRoutes.CLIENTS">Cancel</button>
			<button type="button" class="btn btn-sm btn-primary btn-width" (click)="addClient()" [disabled]="clientForm.invalid">
				<ng-container *ngIf="isSaving; else notSaving">
					<div class="spinner-border spinner-border-sm" role="status">
						<span class="visually-hidden">Loading...</span>
					</div>
				</ng-container>
				<ng-template #notSaving> Add Client </ng-template>
			</button>
		</div>
	</div>
</ng-template>
