import { inject, NgZone } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { CanActivateFn, Router } from '@angular/router';
import { AppRoutes } from '@app/app.routes';
import { AuthRoutes } from '@auth/auth.routes';

export const authGuard: CanActivateFn = async (route, state) => {
	const auth = inject(Auth);
	const router = inject(Router);
	const ngZone = inject(NgZone);

	return new Promise(async (resolve) => {
		return auth.onAuthStateChanged((user) => {
			if (user) {
				resolve(true);
				return;
			}
			ngZone.run(() => {
				router.navigate(['/', AppRoutes.AUTH, AuthRoutes.HOME]);
			});
			resolve(false);
			return;
		});
	});
};
