import { Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';

export enum DashboardRoutes {
	DASHBOARD = '',
}

export const dashboardRoutes: Routes = [
	{
		path: '',
		component: DashboardComponent,
		title: 'ServeOx | Dashboard',
	},
	{
		path: '**',
		redirectTo: '',
	},
];
