import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { decrementMonth, formatMonthYear, incrementMonth } from '@organisations/classes/utilities';
import { IOrganisationBill } from '@organisations/models/organisation-user';

@Component({
	selector: 'app-billing-table',
	standalone: true,
	imports: [CommonModule, FormsModule, ReactiveFormsModule],
	templateUrl: './billing-table.component.html',
	styleUrl: './billing-table.component.scss',
})
export class BillingTableComponent {
	@Input() isMobile: boolean;
	@Input() organisationBill: IOrganisationBill;
	@Input() billingMonth: string;
	@Output() billingMonthChange = new EventEmitter<string>();

	formatMonthYear(input: string): string {
		return formatMonthYear(input);
	}

	nextMonth(): void {
		this.billingMonth = incrementMonth(this.billingMonth);
		this.billingMonthChange.emit(this.billingMonth);
	}

	previousMonth(): void {
		this.billingMonth = decrementMonth(this.billingMonth);
		this.billingMonthChange.emit(this.billingMonth);
	}
}
