import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-policy-footer',
	templateUrl: './policy-footer.component.html',
	styleUrls: ['./policy-footer.component.scss'],
	standalone: true,
	imports: [],
})
export class PolicyFooterComponent {
	termsAndConditionsUrl = environment.termsAndConditionsUrl;
	privacyPolicyUrl = environment.privacyPolicyUrl;
}
