import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import {
	CalendarCommonModule,
	CalendarDayModule,
	CalendarEvent,
	CalendarMonthModule,
	CalendarMonthViewDay,
	CalendarWeekModule
} from 'angular-calendar';
import { CommonModule } from '@angular/common';
import { NgbPopover, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { CalendarPopoverComponent } from '@schedule/components/calendar-popover/calendar-popover.component';
import { CalendarDayCellTimePipe } from '@schedule/pipes/calendar-day-cell-time.pipe';

@Component({
	selector: 'app-calendar-day-cell',
	standalone: true,
	imports: [
		CommonModule,
		NgbPopoverModule,
		CalendarCommonModule,
		CalendarDayModule,
		CalendarMonthModule,
		CalendarWeekModule,
		CalendarPopoverComponent,
		CalendarDayCellTimePipe
	],
	templateUrl: './calendar-day-cell.component.html',
	styleUrl: './calendar-day-cell.component.scss'
})
export class CalendarDayCellComponent {
	@Input() eventDisplayThreshold = 4;
	@Input() day: CalendarMonthViewDay;
	@Input() locale: string;

	@ViewChild('popup', { static: false }) popup: ElementRef;

	isPopupVisible = false;
	popupStyle = {};

	togglePopup($event: MouseEvent) {
		// FIXME: @Carl - please with a fresh set of eyes and fix the positioning for the bottom right corners
		if ($event) {
			setTimeout(() => {
				const screen = document.querySelector('.page-container') as HTMLElement;
				const screenRect = screen.getBoundingClientRect();
				const screenWidth = screenRect.width;
				const screenHeight = screenRect.height;

				const popup = this.popup.nativeElement as HTMLElement;
				const popupRect = popup.getBoundingClientRect();
				const popupWidth = popupRect.width;
				const popupHeight = popupRect.height;

				const eventX = $event.clientX;
				const eventY = $event.clientY;

				let left = eventX - popupWidth / 2;
				let top = eventY - popupHeight / 2;

				// Ensure the popup is within the screen's width
				if (left + popupWidth > screenWidth) {
					left = screenWidth - popupWidth / 2;
				}
				if (left < 0) {
					left = 0;
				}

				// Ensure the popup is within the screen's height
				if (top + popupHeight > screenHeight) {
					top = screenHeight - popupHeight / 2;
				}
				if (top < 0) {
					top = 0;
				}

				this.popupStyle = {
					top: `${top}px`,
					left: `${left}px`,
				};
				this.isPopupVisible = !this.isPopupVisible;
			}, 0);

		}
	}

	eventClasses(event: CalendarEvent, day: CalendarMonthViewDay): string {
		const cssClasses = [];
		if (event.cssClass) {
			cssClasses.push(event.cssClass);
		}

		// Prepare event start and end dates
		const eventStart = new Date(event.start).setHours(0, 0, 0, 0);
		let eventEnd = null;
		if (event.end) {
			eventEnd = new Date(event.end).setHours(0, 0, 0, 0);
		}

		// If single day event
		if (eventStart && !eventEnd || eventStart == eventEnd) {
			cssClasses.push('single-day');
			return cssClasses.join(' ');
		}

		// If multi-day event
		const currentDay = new Date(day.date).setHours(0, 0, 0, 0);
		cssClasses.push('multi-day');

		if (currentDay === eventStart) {
			cssClasses.push('start');
		} else if (currentDay === eventEnd) {
			cssClasses.push('end');
		} else if (currentDay > eventStart && eventEnd && currentDay < eventEnd) {
			cssClasses.push('join');
		}

		return cssClasses.join(' ');
	}

	shouldShowEventText(event: CalendarEvent, day: CalendarMonthViewDay): boolean {
		const eventStart = new Date(event.start).setHours(0, 0, 0, 0);
		const currentDay = new Date(day.date).setHours(0, 0, 0, 0);

		return currentDay === eventStart;
	}

	toggleEventPopover(popover: NgbPopover, event: CalendarEvent, day: CalendarMonthViewDay): void {
		console.log('toggleEventPopover', 'event', JSON.stringify(event), 'day', JSON.stringify(day));
		if (popover.isOpen()) {
			popover.close();
		} else {
			popover.open({ event });
		}
	}
}
