<div class="subpage-container">
	<form [formGroup]="form" class="h-100">
		<div class="form-container">
			<div class="form-column">
				<app-header-popover heading="Assessment Date & Time" [infoPopOver]="assessmentPopOver"></app-header-popover>

				<app-radio-group-control [formGroup]="form" name="dateType" [options]="dateTypeOptions" />

				<ng-container *ngIf="dateType.value === LeadOnSiteVisitDateTimeType.REQUEST">
					<app-alert content="Please select your preferred date & time."></app-alert>
					<h6 class="text-primary">Request Date</h6>
					<app-date-picker-dropdown
						[form]="form"
						name="requestedDate"
						[readonly]="true"
						[disabledDaysOfTheWeek]="disabledDaysOfTheWeek"
						[dateSelected]="dateSelected"
						(nextAvailableDate)="setRequestedDateToNextAvailable($event)"
					></app-date-picker-dropdown>

					<h6 class="text-primary">Preferred Time</h6>
					<app-select-control
						[formGroup]="form"
						name="requestedTimeWindow"
						label="Preferred Time"
						[options]="prefferedTimeOptions"
					></app-select-control>
				</ng-container>

				<ng-container *ngIf="dateType.value === LeadOnSiteVisitDateTimeType.ASAP">
					<app-alert
						content="This will notify the specialist that it's an emergency, and they'll be alerted right away."
					></app-alert>
				</ng-container>
			</div>
			<div class="form-column">
				<app-header-popover heading="Service Type" [infoPopOver]="serviceTypePopover"></app-header-popover>
				<app-select-control
					[formGroup]="form"
					name="serviceType"
					label="Service Type"
					[options]="assessmentTypes"
				></app-select-control>
			</div>
		</div>
	</form>
</div>
