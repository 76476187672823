import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CalendarCommonModule, CalendarDateFormatter, CalendarView } from 'angular-calendar';
import { TitleCasePipe } from '@angular/common';
import {
	calendarViewTitle,
	calendarViewTitleMobile,
	CustomDateFormatterService
} from '@schedule/extensions/custom-date-formatter.service';
import { LayoutService } from '@layout/services/layout/layout.service';
import { takeUntil } from 'rxjs';

@Component({
	selector: 'app-calendar-controls',
	standalone: true,
	imports: [
		CalendarCommonModule,
		TitleCasePipe
	],
	// @Carl Duplicate here??? see: CalendarWeekHeadersComponent
	providers: [
		{
			provide: CalendarDateFormatter,
			useClass: CustomDateFormatterService
		}
	],
	templateUrl: './calendar-controls.component.html',
	styleUrl: './calendar-controls.component.scss'
})
export class CalendarControlsComponent  implements OnInit, OnDestroy {
	destroyed$ = new EventEmitter<void>();
	isMobileSize = true;
	private layoutService = inject(LayoutService);

	@Input() viewDate: Date = new Date();
	@Output() viewDateChange = new EventEmitter<Date>();

	@Input() calendarView: CalendarView = CalendarView.Month;
	@Output() calendarViewChange = new EventEmitter<CalendarView>();

	headerCalendarDateMethod = calendarViewTitle(this.calendarView);

	protected readonly CalendarView = CalendarView;

	changeViewDate() {
		this.viewDateChange.emit(this.viewDate);
	}

	changeView(view: CalendarView) {
		this.calendarViewChange.emit(view);
	}

	ngOnInit(): void {
		this.layoutService.isMobileSize$.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
			this.isMobileSize = value;
			this.headerCalendarDateMethod = this.isMobileSize
				? calendarViewTitleMobile(this.calendarView)
				: calendarViewTitle(this.calendarView);

			console.log('headerCalendarDateMethod', this.headerCalendarDateMethod)
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}
}
