<ul class="nav nav-pills">
	<div>
		<ng-container *ngFor="let item of navigationItems">
			<ng-container *ngIf="item.visible">
				<li class="nav-item">
					<a
						class="nav-link text-truncate nav-text-color"
						[class.disabled]="item.disabled"
						routerLinkActive="active"
						[routerLink]="item.route"
						(click)="dismissOffcanvas()"
					>
						<i class="bi" [ngClass]="item.icon"></i>
						<ng-container *ngIf="!isCollapsed">
							{{ item.label }}
						</ng-container>
					</a>
				</li>
			</ng-container>
		</ng-container>
		<li class="nav-item">
			<div class="line">
				<hr />
			</div>
		</li>
	</div>
	<div>
		<li class="nav-item">
			<a class="nav-link text-truncate nav-text-color" href="https://serveox.com" target="_blank">
				<i class="bi bi-info-circle"></i>
				<ng-container *ngIf="!isCollapsed"> What is ServeOx? </ng-container>
			</a>
		</li>
	</div>
</ul>
