<ng-container *ngIf="isLoading; else content">
	<div class="spinner-container">
		<div class="spinner-border text-primary" role="status">
			<span class="visually-hidden">Loading...</span>
		</div>
	</div>
</ng-container>

<ng-template #content>
	<ng-container *ngTemplateOutlet="loadedContent"></ng-container>
</ng-template>
