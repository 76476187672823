import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { PageTitleComponent } from '@shared/components/sections/page-title/page-title.component';

@Component({
	selector: 'app-settings',
	standalone: true,
	imports: [CommonModule, PageTitleComponent, RouterOutlet],
	templateUrl: './settings.component.html',
	styleUrl: './settings.component.scss',
})
export class SettingsComponent {}
