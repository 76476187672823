<div class="subpage-container">
	<app-calendar-controls [(calendarView)]="view" [(viewDate)]="viewDate"></app-calendar-controls>

	<ng-container [ngSwitch]="view">
		<ng-container *ngSwitchCase="CalendarView.Month">
			<app-calendar-month-view
				[events]="events"
				[refresh]="refresh"
				[viewDate]="viewDate"
				[activeDayIsOpen]="activeDayIsOpen"
				(dayClicked)="dayClicked.emit($event)"
				(eventClicked)="eventClicked.emit($event)"
				(eventTimesChanged)="eventTimesChanged.emit($event)"
			></app-calendar-month-view>
		</ng-container>
		<ng-container *ngSwitchCase="CalendarView.Week">
			<app-calendar-week-view
				[events]="events"
				[refresh]="refresh"
				[viewDate]="viewDate"
				(eventClicked)="eventClicked.emit($event)"
				(eventTimesChanged)="eventTimesChanged.emit($event)"
			></app-calendar-week-view>
		</ng-container>
		<ng-container *ngSwitchCase="CalendarView.Day">
			<app-calendar-day-view
				[events]="events"
				[refresh]="refresh"
				[viewDate]="viewDate"
				(eventClicked)="eventClicked.emit($event)"
				(eventTimesChanged)="eventTimesChanged.emit($event)"
			></app-calendar-day-view>
		</ng-container>
	</ng-container>
</div>
