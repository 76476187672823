import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, OnDestroy, OnInit } from '@angular/core';
import { DocumentReference } from '@angular/fire/firestore';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { OrganisationUserAccessLevel } from '@organisations/models/organisation-user';
import { IBusinessHours, IOrganisation, IOrganisationBusinessHoursForm } from '@organisations/models/organisations';
import { OrganisationSelectedService } from '@organisations/services/organisation-selected/organisation-selected.service';
import { OrganisationService } from '@organisations/services/organisation/organisation.service';
import { AlertComponent } from '@shared/components/alerts/alert/alert.component';
import { SwitchControlComponent } from '@shared/components/forms/switch-control/switch-control.component';
import { LoadingStateComponent } from '@shared/components/loading-state/loading-state.component';
import { UnauthorisedBannerComponent } from '@shared/components/ui/unauthorised-banner/unauthorised-banner.component';
import { ToastTypes } from '@shared/models/toast';
import { ToastService } from '@shared/services/toast/toast.service';
import { filter, takeUntil } from 'rxjs';

@Component({
	selector: 'app-business-hours',
	standalone: true,
	imports: [
		CommonModule,
		UnauthorisedBannerComponent,
		FormsModule,
		ReactiveFormsModule,
		AlertComponent,
		SwitchControlComponent,
		LoadingStateComponent,
	],
	templateUrl: './business-hours.component.html',
	styleUrl: './business-hours.component.scss',
	host: {
		class: 'h-100',
	},
})
export class BusinessHoursComponent implements OnInit, OnDestroy {
	businessHoursForm: FormGroup<IOrganisationBusinessHoursForm>;
	destroyed$ = new EventEmitter<void>();

	organisationId: string;
	organisation: IOrganisation;
	userHasAccess = false;
	isLoading = true;
	isSaving = false;

	private organisationService = inject(OrganisationService);
	private organisationSelectedService = inject(OrganisationSelectedService);
	private toastService = inject(ToastService);

	dayOrder: Array<keyof IOrganisationBusinessHoursForm> = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

	ngOnInit(): void {
		this.subscribeToSelectedOrganisation();

		this.businessHoursForm = new FormGroup<IOrganisationBusinessHoursForm>({
			monday: new FormControl<boolean | null>(false, [Validators.required]),
			tuesday: new FormControl<boolean | null>(false, [Validators.required]),
			wednesday: new FormControl<boolean | null>(false, [Validators.required]),
			thursday: new FormControl<boolean | null>(false, [Validators.required]),
			friday: new FormControl<boolean | null>(false, [Validators.required]),
			saturday: new FormControl<boolean | null>(false, [Validators.required]),
			sunday: new FormControl<boolean | null>(false, [Validators.required]),
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}

	subscribeToSelectedOrganisation(): void {
		this.organisationSelectedService.selectedOrganisation
			.pipe(
				takeUntil(this.destroyed$),
				filter((o) => o !== null),
			)
			.subscribe((selectedOrg) => {
				if (selectedOrg?.accessLevel === OrganisationUserAccessLevel.OWNER) {
					this.userHasAccess = true;
					this.fetchOrganisationDetails(selectedOrg.organisation as DocumentReference<IOrganisation>);
				} else {
					this.userHasAccess = false;
					this.isLoading = false;
				}
			});
	}

	fetchOrganisationDetails(orgRef: DocumentReference<IOrganisation>): void {
		this.organisationService
			.getOrganisationByRef(orgRef)
			.then((doc) => {
				if (doc.exists()) {
					this.organisationId = doc.id;
					this.organisation = doc.data();

					if (this.organisation.settings?.businessHours) {
						this.businessHoursForm.patchValue(this.organisation.settings?.businessHours);
					} else {
						this.businessHoursForm.reset();
					}
				}
				this.isLoading = false;
			})
			.catch((error) => {
				console.error(error);
				console.error('Failed to load organisation');
				this.isLoading = false;
			});
	}

	handleCancel(): void {
		if (this.organisation.settings.businessHours) {
			this.businessHoursForm.patchValue(this.organisation.settings?.businessHours);
			this.businessHoursForm.markAsPristine();
		}
	}

	handleSave(): void {
		if (this.businessHoursForm.invalid) {
			return;
		}

		this.isSaving = true;

		this.organisationService
			.updateBusinessHours(this.organisationId, this.businessHoursForm.value as IBusinessHours)
			.then(() => {
				this.isSaving = false;
				this.businessHoursForm.markAsPristine();

				this.toastService.showToast({
					title: 'Business Hours',
					message: 'Successfully updated business hours',
					type: ToastTypes.SUCCESS,
				});
			})
			.catch((error) => {
				console.error(error);
				this.isSaving = false;

				this.toastService.showToast({
					title: 'Business Hours',
					message: `Failed to update business hours: ${error.message}`,
					type: ToastTypes.ERROR,
				});
			});
	}
}
