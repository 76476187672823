<ng-template
	#defaultTemplate
	let-days="days"
	let-locale="locale"
	let-trackByWeekDayHeaderDate="trackByWeekDayHeaderDate"
>
	<div class="cal-cell-row cal-header" role="row">
		<div
			class="cal-cell"
			*ngFor="let day of days; trackBy: trackByWeekDayHeaderDate"
			[class.cal-past]="day.isPast"
			[class.cal-today]="day.isToday"
			[class.cal-future]="day.isFuture"
			[class.cal-weekend]="day.isWeekend"
			(click)="columnHeaderClicked.emit({ isoDayNumber: day.day, sourceEvent: $event })"
			[ngClass]="day.cssClass"
			tabindex="0"
			role="columnheader"
		>
			<span class="column-header">{{ day.date | calendarDate : headerCalendarDateMethod : locale }}</span>
		</div>
	</div>
</ng-template>
<ng-template
	[ngTemplateOutlet]="defaultTemplate"
	[ngTemplateOutletContext]="{
        days: days,
        locale: locale,
        trackByWeekDayHeaderDate: trackByWeekDayHeaderDate
      }"
>
</ng-template>
