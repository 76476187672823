import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ILeadRequest, LeadRequestType, LeadStatus } from '@leads/shared/models/lead.models';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { BehaviorSubject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { RouterLinkActive, RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SearchControlComponent } from '@shared/components/forms/search-control/search-control.component';
import { IRadioOption } from '@shared/models/form-controls';
import { RadioGroupControlComponent } from '@shared/components/forms/radio-group-control/radio-group-control.component';
import { LoadingStateComponent } from '@shared/components/loading-state/loading-state.component';
import { DropdownControlComponent, IDropdownOption } from '@shared/components/forms/dropdown-control/dropdown-control.component';
import { leadRequestTypeOptionsMobile, leadStatusOptions } from '@leads/client-facing/models/lead-request.model';

@Component({
	selector: 'app-mobile-lead-requests-table',
	standalone: true,
	imports: [
		CommonModule,
		RouterOutlet,
		RouterLinkActive,
		ReactiveFormsModule,
		SearchControlComponent,
		RadioGroupControlComponent,
		LoadingStateComponent,
		DropdownControlComponent,
	],
	templateUrl: './mobile-lead-requests-table.component.html',
	styleUrl: './mobile-lead-requests-table.component.scss',
	host: {
		class: 'h-100',
	},
})
export class MobileLeadRequestsTableComponent implements OnInit, OnDestroy {
	@Input() isLoading: boolean;
	@Input() leads: Array<ILeadRequest>;
	@Input() searchTerm$: BehaviorSubject<string>;
	@Input() leadStatus$: BehaviorSubject<Array<LeadStatus>>;
	@Input() leadRequestType$: BehaviorSubject<LeadRequestType | null>;
	@Output() onClick = new EventEmitter<ILeadRequest>();

	form: FormGroup<{ leadStatus: FormControl; leadRequestType: FormControl }>;
	searchControl = new FormControl('', { nonNullable: true });
	destroyed$ = new EventEmitter<void>();

	LeadRequestType = LeadRequestType;
	leadStatusOptions: Array<IDropdownOption> = leadStatusOptions;
	leadRequestTypeOptions: Array<IRadioOption> = leadRequestTypeOptionsMobile;

	get leadRequestType(): FormControl<LeadRequestType> {
		return this.form.controls.leadRequestType;
	}

	get leadStatus(): FormControl<Array<LeadStatus>> {
		return this.form.controls.leadStatus;
	}

	ngOnInit(): void {
		this.isLoading = false;

		this.form = new FormGroup({
			leadStatus: new FormControl<Array<LeadStatus>>(this.leadStatus$.value),
			leadRequestType: new FormControl<LeadRequestType | null>(this.leadRequestType$.value),
		});

		this.searchControl.valueChanges.pipe(takeUntil(this.destroyed$), debounceTime(500), distinctUntilChanged()).subscribe((value) => {
			this.searchTerm$.next(value);
		});
		this.leadStatus.valueChanges.pipe(takeUntil(this.destroyed$), distinctUntilChanged()).subscribe((value) => {
			this.leadStatus$.next(value);
		});
		this.leadRequestType.valueChanges.pipe(takeUntil(this.destroyed$), distinctUntilChanged()).subscribe((value) => {
			this.leadRequestType$.next(value);
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}

	onLeadItemClick(lead: ILeadRequest) {
		this.onClick.emit(lead);
	}
}
