import { FireStoreDocument } from '@shared/models/firestore';
import { DocumentReference, Timestamp } from '@angular/fire/firestore';
import { IClientProperty } from '@clients/models/clients';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export interface ILeadRequest extends FireStoreDocument {
	organisation: DocumentReference;
	client: DocumentReference;
	createdDate: Timestamp;
	updatedDate: Timestamp;
	status: LeadStatus;
	mobileNumber: MobileNumber;
	requestType: LeadRequestType;
	personalDetails: {
		name: string;
		nameLower: string;
		emailAddress: string;
		installationAddress: IClientProperty;
	};
	callAssessment?: {
		callNotes: string;
	};
	onSiteVisitAssessment?: {
		dateTime: {
			type: LeadOnSiteVisitDateTimeType;
			requestedDate: NgbDateStruct;
			requestedTimeWindow: LeadTimeWindow;
		};
		serviceType: string;
		notes: string;
	};
	internalNotes?: string | null;
}

export interface MobileNumber {
	countryCode: string;
	number: string;
}

export enum LeadStatus {
	NEW = 'NEW',
	ACTIVE = 'ACTIVE',
	COMPLETED = 'COMPLETED',
	REJECTED = 'REJECTED',
	CANCELLED = 'CANCELLED',
}

export enum LeadRequestType {
	CALL = 'Call',
	ON_SITE_VISIT = 'On Site Visit',
}

export enum LeadOnSiteVisitDateTimeType {
	REQUEST = 'REQUEST',
	ASAP = 'ASAP',
}

export enum LeadTimeWindow {
	MORNING = 'Morning',
	MIDDAY = 'Midday',
	AFTERNOON = 'Afternoon',
}
