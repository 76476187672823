import { Pipe, PipeTransform } from '@angular/core';
import { IClientProperty } from '@clients/models/firebase/client.model';

@Pipe({
	name: 'property',
	standalone: true,
})
export class PropertyPipe implements PipeTransform {
	transform(value: IClientProperty): string {
		return `${value.fullAddress}, ${value.suburb}`;
	}
}
