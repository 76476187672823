<div class="subpage-container">
	<form [formGroup]="form" class="h-100">
		<div class="form-container">
			<app-header-popover heading="Contact Information" [infoPopOver]="infoPopOver"></app-header-popover>
			<div class="form-row">
				<app-standard-control
					type="text"
					name="name"
					[formGroup]="form"
					label="Name"
					placeholder="Name"
					autocomplete="name"
				></app-standard-control>
				<app-standard-control
					type="email"
					name="emailAddress"
					[formGroup]="form"
					label="Email Address"
					placeholder="Email Address"
					autocomplete="email"
				></app-standard-control>
			</div>
		</div>
	</form>
</div>
