<div class="page-container">
	<div class="heading">
		<app-page-title title="Schedule"></app-page-title>
	</div>
	<div class="content">
		<app-loading-state [isLoading]="false" [loadedContent]="loadedContent"></app-loading-state>
		<ng-template #loadedContent>
			<ng-container *ngIf="userHasAccess; else noAccess">
				<ng-container *ngIf="isMobileSize; else desktopSize">
					<app-calendar-view
						[view]="view"
						[viewDate]="viewDate"
						[events]="events"
						(dayClicked)="dayClicked($event)"
						(eventClicked)="handleEvent('Clicked', $event)"
						(eventTimesChanged)="eventTimesChanged($event)"
					></app-calendar-view>
				</ng-container>
				<ng-template #desktopSize>
					<div class="desktop-container">
						<app-calendar-view
							[view]="view"
							[viewDate]="viewDate"
							[events]="events"
							(dayClicked)="dayClicked($event)"
							(eventClicked)="handleEvent('Clicked', $event)"
							(eventTimesChanged)="eventTimesChanged($event)"
						></app-calendar-view>
					</div>
				</ng-template>
			</ng-container>

			<ng-template #noAccess>
				<app-unauthorised-banner
					headingText="It looks like you do not have access to this organisation or do not have the required access level."
				></app-unauthorised-banner>
			</ng-template>
		</ng-template>
	</div>
</div>
