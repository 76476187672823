import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import {
	CalendarCommonModule,
	CalendarDateFormatter,
	CalendarMonthModule,
	ɵCalendarMonthViewHeaderComponent as CalendarMonthViewHeaderComponent
} from 'angular-calendar';
import { WeekDay } from 'calendar-utils';
import { takeUntil } from 'rxjs';
import {
	CustomDateFormatterService,
	monthViewColumnHeader,
	monthViewColumnHeaderMobile, weekViewColumnHeader
} from '@schedule/extensions/custom-date-formatter.service';
import { LayoutService } from '@layout/services/layout/layout.service';
import { NgClass, NgForOf, NgTemplateOutlet } from '@angular/common';
import { CalendarColumnHeaderClickedEvent } from '@schedule/extensions/types/calendar.types';


@Component({
	selector: 'app-calendar-month-headers',
	standalone: true,
	imports: [
		CalendarMonthModule,
		NgClass,
		CalendarCommonModule,
		NgTemplateOutlet,
		NgForOf
	],
	providers: [
		{
			provide: CalendarDateFormatter,
			useClass: CustomDateFormatterService
		}
	],
	templateUrl: './calendar-month-headers.component.html',
	styleUrl: './calendar-month-headers.component.scss'
})
export class CalendarMonthHeadersComponent extends CalendarMonthViewHeaderComponent implements OnInit, OnDestroy {
	destroyed$ = new EventEmitter<void>();
	isMobileSize = true;
	private layoutService = inject(LayoutService);

	headerCalendarDateMethod = monthViewColumnHeader;

	@Input() override days: WeekDay[];

	@Input() override locale: string;

	// Don't allow the custom template
	override customTemplate: TemplateRef<any>;

	@Input() override trackByWeekDayHeaderDate: (index: number, day: WeekDay) => string;

	@Output() override columnHeaderClicked = new EventEmitter<CalendarColumnHeaderClickedEvent>();

	ngOnInit(): void {
		this.layoutService.isMobileSize$.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
			this.isMobileSize = value;
			this.headerCalendarDateMethod = this.isMobileSize ? monthViewColumnHeaderMobile : monthViewColumnHeader;
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}
}
