<div class="google-signin">
	<button class="btn btn-width p-0" (click)="signUpWithGoogle()" [disabled]="isLoading">
		<ng-container *ngIf="isLoading; else notLoading">
			<div class="spinner-border spinner-border-sm text-primary" role="status">
				<span class="visually-hidden">Loading...</span>
			</div>
		</ng-container>
		<ng-template #notLoading>
			<img src="assets/logos/google-continue.svg" />
		</ng-template>
	</button>
</div>
