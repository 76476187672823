import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { DesktopClientCreatePropertyTableComponent } from '@clients/components/desktop-client-create-property-table/desktop-client-create-property-table.component';
import { DesktopClientDetailsComponent } from '@clients/components/forms/desktop-client-details/desktop-client-details.component';
import { MobileClientCreateDetailsComponent } from '@clients/components/forms/mobile-client-create-details/mobile-client-create-details.component';
import { IClient, IClientForm, IClientProperty } from '@clients/models/clients';
import { ClientsService } from '@clients/services/clients/clients.service';
import { LayoutService } from '@layout/services/layout/layout.service';
import { IAvailableOrganisation } from '@organisations/models/organisation-user';
import { OrganisationSelectedService } from '@organisations/services/organisation-selected/organisation-selected.service';
import { PageTitleComponent } from '@shared/components/sections/page-title/page-title.component';
import { takeUntil } from 'rxjs';
import { AppRoutes } from 'src/app/app.routes';

@Component({
	selector: 'app-client-create',
	standalone: true,
	imports: [
		CommonModule,
		PageTitleComponent,
		MobileClientCreateDetailsComponent,
		DesktopClientDetailsComponent,
		DesktopClientCreatePropertyTableComponent,
		RouterLink,
	],
	templateUrl: './client-create.component.html',
	styleUrl: './client-create.component.scss',
})
export class ClientCreateComponent implements OnInit, OnDestroy {
	private layoutService = inject(LayoutService);
	private clientsService = inject(ClientsService);
	private organisationSelectedService = inject(OrganisationSelectedService);
	private router = inject(Router);

	destroyed$ = new EventEmitter<void>();
	isMobileSize = true;
	isSaving = false;

	selectedOrganisation: IAvailableOrganisation | null = null;
	clientForm: FormGroup<IClientForm>;
	clientProperties: Array<IClientProperty> = [];

	AppRoutes = AppRoutes;

	ngOnInit(): void {
		this.layoutService.isMobileSize$.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
			this.isMobileSize = value;
		});

		this.organisationSelectedService.selectedOrganisation.pipe(takeUntil(this.destroyed$)).subscribe((selectedOrganisation) => {
			this.selectedOrganisation = selectedOrganisation;
		});

		this.clientForm = new FormGroup<IClientForm>({
			clientName: new FormControl(null, [Validators.required, Validators.minLength(2), Validators.maxLength(50)]),
			clientNumber: new FormControl(null, [
				Validators.required,
				Validators.minLength(10),
				Validators.maxLength(20),
				Validators.pattern(/^[0-9]{10,20}$/),
			]),
			clientEmail: new FormControl(null, [Validators.required, Validators.email, Validators.maxLength(50)]),
			internalNotes: new FormControl(null),
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}

	addClient(): void {
		if (this.clientForm.invalid) {
			return;
		}
		if (this.selectedOrganisation) {
			this.isSaving = true;
			const client: IClient = {
				organisation: this.selectedOrganisation?.organisation,
				clientEmail: this.clientForm.controls.clientEmail.value as string,
				clientName: this.clientForm.controls.clientName.value as string,
				clientNameLower: this.clientForm.controls.clientName.value?.toLocaleLowerCase() as string,
				clientNumber: {
					countryCode: '+27',
					number: this.clientForm.controls.clientNumber.value as string,
				},
				internalNotes: this.clientForm.controls.internalNotes.value as string,
			};
			this.clientsService.createClient(client, this.clientProperties).then(() => {
				this.isSaving = false;
				this.router.navigate([AppRoutes.CLIENTS]);
			});
		}
	}
}
