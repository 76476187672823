import { Routes } from '@angular/router';

import { AuthLayoutComponent } from './components/auth-layout/auth-layout.component';
import { LoginComponent } from './pages/login/login.component';
import { PasswordResetComponent } from './pages/password-reset/password-reset.component';
import { SignupComponent } from './pages/signup/signup.component';

export enum AuthRoutes {
	LOGIN = 'login',
	SIGNUP = 'signup',
	PASSWORD_RESET = 'password-reset',
}

export const authRoutes: Routes = [
	{
		path: '',
		component: AuthLayoutComponent,
		children: [
			{
				path: '',
				redirectTo: AuthRoutes.LOGIN,
				pathMatch: 'full',
			},
			{
				path: AuthRoutes.LOGIN,
				component: LoginComponent,
				title: 'ServeOx | Login',
			},
			{
				path: AuthRoutes.SIGNUP,
				component: SignupComponent,
				title: 'ServeOx | Signup',
			},
			{
				path: AuthRoutes.PASSWORD_RESET,
				component: PasswordResetComponent,
				title: 'ServeOx | Password Reset',
			},
		],
	},
];
