<div class="modal-header">
	<h5 class="modal-title text-primary">Select Property</h5>
	<button type="button" class="btn-close" (click)="closeModal()" aria-label="Close"></button>
</div>
<div class="modal-body">
	<app-alert *ngIf="showContextAlert" [content]="alertContent"></app-alert>
	<ng-template #alertContent>
		No property was assigned to this lead when when it was created. A property must be linked before scheduling. Please select from
		existing client properties or create a new one below.
	</ng-template>
	<app-radio-group-control [formGroup]="formGroup" name="propertySelectType" [options]="propertyTypeOptions"></app-radio-group-control>

	<ng-container *ngIf="propertySelectType.value === PropertySelectType.EXISTING">
		<ul class="list-group">
			<ng-container *ngFor="let property of clientProperties">
				<button
					class="list-group-item list-group-item-action"
					[class.active]="selectedClientProperty.value === property"
					(click)="setSelectedClientProperty(property)"
				>
					{{ property | property }}
				</button>
			</ng-container>
		</ul>
	</ng-container>

	<ng-container *ngIf="propertySelectType.value === PropertySelectType.NEW">
		<app-address-with-search [formGroup]="newProperty"></app-address-with-search>
	</ng-container>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-primary btn-width" (click)="submit()" data-id="submit" [disabled]="formGroup.invalid">
		Confirm
	</button>
</div>
