import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ILeadRequestForm } from '@leads/customer-facing/models/lead-request';
import { CommonModule } from '@angular/common';
import { IOrganisation } from '@organisations/models/organisations';
import { PopoverComponent } from '@shared/components/ui/popover/popover.component';
import { IPopover } from '@shared/models/popover';
import { HeaderPopoverComponent } from '@shared/components/sections/header-popover/header-popover.component';
import { AlertComponent } from '@shared/components/alerts/alert/alert.component';
import { StandardControlComponent } from '@shared/components/forms/standard-control/standard-control.component';

@Component({
	selector: 'app-lead-mobile-number',
	standalone: true,
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		PopoverComponent,
		HeaderPopoverComponent,
		AlertComponent,
		StandardControlComponent,
	],
	templateUrl: './lead-mobile-number.component.html',
	styleUrl: './lead-mobile-number.component.scss',
})
export class LeadMobileNumberComponent {
	@Input() form: FormGroup<ILeadRequestForm>;
	@Input() organisation: IOrganisation;

	get mobileNumber(): FormControl {
		return this.form.controls.mobileNumber;
	}

	infoPopOver: IPopover = {
		triggerText: {
			desktop: "What's this?",
			mobile: "What's this?",
		},
		title: 'Mobile Number',
		description: 'Please enter in your phone number for us to contact you regarding your request.',
		icon: 'bi-info-circle-fill',
	};
}
