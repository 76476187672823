import { IOrganisationUserFull, OrganisationMemberStatus } from '@organisations/models/organisation-user';

export function organisationStatusBackground(member: IOrganisationUserFull): string {
	switch (member.status) {
		case OrganisationMemberStatus.ACTIVE:
			return 'text-bg-success';
		case OrganisationMemberStatus.INVITATION_PENDING:
			return 'text-bg-warning';
		case OrganisationMemberStatus.DECLINED:
			return 'text-bg-danger';
		default:
			return 'text-bg-primary';
	}
}

export function formatMonthYear(input: string): string {
	const [year, month] = input.split('-').map(Number);

	const date = new Date(year, month - 1);

	const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long' };
	return date.toLocaleDateString(undefined, options);
}

export function incrementMonth(yyyymm: string): string {
	let [year, month] = yyyymm.split('-').map(Number);

	month += 1;

	if (month > 12) {
		month = 1;
		year += 1;
	}

	return `${year}-${String(month).padStart(2, '0')}`;
}

export function decrementMonth(yyyymm: string): string {
	let [year, month] = yyyymm.split('-').map(Number);

	month -= 1;

	if (month < 1) {
		month = 12;
		year -= 1;
	}

	return `${year}-${String(month).padStart(2, '0')}`;
}
