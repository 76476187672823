import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AbstractControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgOption, NgSelectModule } from '@ng-select/ng-select';

export interface IDropdownOption extends NgOption {
	id: string | number;
	icon?: string;
}

export function dropdownOptionsFromEnum<T>(o: { [s: string]: T } | ArrayLike<T>, icon: string): Array<IDropdownOption> {
	return Object.values<T>(o).map((key) => ({
		id: key as string,
		label: key as string,
		value: key as string,
		icon,
	}));
}

@Component({
	selector: 'app-dropdown-control',
	standalone: true,
	imports: [CommonModule, FormsModule, ReactiveFormsModule, NgSelectModule],
	templateUrl: './dropdown-control.component.html',
	styleUrl: './dropdown-control.component.scss',
})
export class DropdownControlComponent<T extends { [K in keyof T]: AbstractControl }> {
	@Input() formGroup!: FormGroup<T>;
	@Input() name!: Extract<keyof T, string | number>;
	@Input() label: string;
	@Input() showLabel: boolean = false;
	@Input() placeholder: string;
	@Input() options: Array<IDropdownOption>;
	@Input() multiple: boolean = false;
	@Input() searchable: boolean = false;
	@Input() clearable: boolean = false;

	get control() {
		return this.formGroup.controls[this.name];
	}
}
