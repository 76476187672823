<app-loading-state [isLoading]="isLoading" [loadedContent]="loadedContent"> </app-loading-state>
<ng-template #loadedContent>
	<div class="lead-request-container">
		<div class="heading">
			<app-page-title [title]="organisation.companyName"></app-page-title>
		</div>
		<div class="content">
			<app-alert [content]="alertContent" [alertType]="alertType"></app-alert>

			<div class="request-type-container">
				<div class="request-type">
					{{ leadRequest.requestType | titlecase }}
				</div>
			</div>

			<div class="appointment-details">
				<div class="customer-details">
					<h5 class="text-primary text-center">Customer Details</h5>

					<div class="detail-item">
						<div class="text-body-secondary">Customer Name</div>
						<div>
							{{ leadRequest.personalDetails.name }}
						</div>
					</div>

					<div class="detail-item">
						<div class="text-body-secondary">Phone Number</div>
						<div>({{ leadRequest.mobileNumber.countryCode }}) {{ leadRequest.mobileNumber.number }}</div>
					</div>

					<div class="detail-item">
						<div class="text-body-secondary">Email</div>
						<div>{{ leadRequest.personalDetails.emailAddress }}</div>
					</div>

					<ng-container *ngIf="leadRequest.requestType === LeadRequestType.ON_SITE_VISIT">
						<div class="detail-item">
							<div class="text-body-secondary">Address</div>
							<div>{{ leadRequest.personalDetails.installationAddress.fullAddress }}</div>
						</div>
					</ng-container>
				</div>
				<form [formGroup]="notesForm" class="assessment-notes">
					<h5 class="text-primary text-center">Assessment Notes</h5>
					<ng-container *ngIf="leadRequest.requestType === LeadRequestType.CALL">
						<p class="m-0 text-primary text-center" style="font-size: 14px">
							If you have any updates or additional details that weren't included in your initial request, please type them
							into the notes box and click save to notify your specialist.
						</p>
						<textarea
							class="form-control"
							style="max-height: 300px; min-height: 200px"
							formControlName="callNotes"
							placeholder="Any additional information we may need to know about?"
						></textarea>
						<button
							type="button"
							class="btn btn-sm btn-primary"
							(click)="updateCallNotes()"
							[disabled]="notesForm.controls.callNotes.invalid || notesForm.controls.callNotes.pristine"
						>
							<ng-container *ngIf="isSaving; else notSaving">
								<div class="spinner-border spinner-border-sm" role="status">
									<span class="visually-hidden">Loading...</span>
								</div>
							</ng-container>
							<ng-template #notSaving>Save</ng-template>
						</button>
					</ng-container>
					<ng-container *ngIf="leadRequest.requestType === LeadRequestType.ON_SITE_VISIT">
						<p class="m-0 text-primary text-center" style="font-size: 14px">
							If you have any updates or additional details that weren't included in your initial request, please type them
							into the notes box and click save to notify your specialist.
						</p>
						<textarea
							class="form-control"
							style="max-height: 300px; min-height: 200px"
							formControlName="onsiteNotes"
							placeholder="Any additional information we may need to know about?"
						></textarea>
						<button
							type="button"
							class="btn btn-sm btn-primary"
							(click)="updateOnSiteNotes()"
							[disabled]="notesForm.controls.callNotes.invalid || notesForm.controls.onsiteNotes.pristine"
						>
							<ng-container *ngIf="isSaving; else notSaving">
								<div class="spinner-border spinner-border-sm" role="status">
									<span class="visually-hidden">Loading...</span>
								</div>
							</ng-container>
							<ng-template #notSaving>Save</ng-template>
						</button>
					</ng-container>
				</form>
			</div>
		</div>

		<div class="footer">
			<div class="btn-container">
				<ng-container *ngIf="leadRequest.status === LeadStatus.NEW || leadRequest.status === LeadStatus.ACTIVE">
					<button type="button" class="btn btn-sm btn-outline-danger btn-width" (click)="cancelLeadRequest()">
						Cancel Appointment
					</button>
				</ng-container>
			</div>
			<div class="btn-container">
				<ng-container *ngIf="leadRequest.status === LeadStatus.ACTIVE">
					<button type="button" class="btn btn-sm btn-outline-primary btn-width">Request Change</button>
				</ng-container>
				<ng-container *ngIf="leadRequest.status === LeadStatus.ACTIVE">
					<button type="button" class="btn btn-sm btn-primary btn-width">Accept Appointment</button>
				</ng-container>
			</div>
		</div>
	</div>
</ng-template>
