import { inject, Injectable } from '@angular/core';
import { User } from '@angular/fire/auth/firebase';
import { IBusinessHours, IOrganisation } from '@organisations/models/organisations';
import { FireStoreCollection } from '@shared/models/firestore';
import { FirestoreService } from '@shared/services/firestore/firestore.service';
import { OrganisationUserService } from '../organisation-user/organisation-user.service';
import { OrganisationMemberStatus, OrganisationUserAccessLevel } from '@organisations/models/organisation-user';
import {
	collectionData,
	doc,
	docData,
	documentId,
	DocumentReference,
	getDoc,
	query,
	Timestamp,
	updateDoc,
	where,
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class OrganisationService extends FirestoreService<IOrganisation> {
	private organisationUserService = inject(OrganisationUserService);

	constructor() {
		super(FireStoreCollection.ORGANISATIONS);
	}

	async createOrganisation(organisation: IOrganisation, creatingUser: User): Promise<void> {
		return super.create(organisation).then((document) => {
			this.organisationUserService.create({
				accessLevel: OrganisationUserAccessLevel.OWNER,
				organisation: doc(this.firestore, `${FireStoreCollection.ORGANISATIONS}/${document.id}`),
				user: doc(this.firestore, `${FireStoreCollection.USERS}/${creatingUser.uid}`),
				email: creatingUser.email as string,
				createdDate: Timestamp.now(),
				updatedDate: Timestamp.now(),
				joinDate: Timestamp.now(),
				removeDate: null,
				status: OrganisationMemberStatus.ACTIVE,
			});
		});
	}

	organisationsByIds(organisationIds: Array<string>): Observable<Array<IOrganisation>> {
		const q = query(this.collectionRef, where(documentId(), 'in', organisationIds));
		return collectionData(q, { idField: 'id' });
	}

	async getOrganisationByRef(organisationRef: DocumentReference<IOrganisation>) {
		return getDoc(organisationRef);
	}

	getOrganisation(organisationRef: DocumentReference<IOrganisation>): Observable<IOrganisation | undefined> {
		return docData(organisationRef, { idField: 'id' });
	}

	async updateBusinessHours(organisationId: string, businessHours: IBusinessHours): Promise<void> {
		return await updateDoc(this.documentRef(organisationId), { 'settings.businessHours': businessHours });
	}

	async updateServiceTypes(organisationId: string, serviceTypes: Array<string>): Promise<void> {
		return await updateDoc(this.documentRef(organisationId), { 'settings.serviceTypes': serviceTypes });
	}
}
