import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { StepNavigatorComponent } from '@shared/components/ui/step-navigator/step-navigator.component';
import { IStepNavigation, IStepNavigatorConfig, NavButtonStyle, StepModes, StepUI } from '@shared/models/step-navigator';
import { DatePickerDropdownComponent } from '@shared/components/calendar/date-picker-dropdown/date-picker-dropdown.component';
import { DropdownControlComponent } from '@shared/components/forms/dropdown-control/dropdown-control.component';
import { AssessmentRequestScheduleItem } from '@leads/client-facing/classes/assesment-request-schedule-item';
import { AssessmentScheduleSteps } from '@leads/client-facing/models/assessment-schedule.model';
import { LoadingStateComponent } from '@shared/components/loading-state/loading-state.component';
import { CalendarModule } from 'angular-calendar';
import { takeUntil } from 'rxjs';
import { StandardGroupControlComponent } from '@shared/components/forms/standard-group-control/standard-group-control.component';
import { TextareaControlComponent } from '@shared/components/forms/textarea-control/textarea-control.component';
import { SwitchControlComponent } from '@shared/components/forms/switch-control/switch-control.component';
import { LeadRequestType } from '@leads/shared/models/lead.models';
import { ScheduledAssessmentType } from '@schedule/models/firestore/schedule.model';

@Component({
	selector: 'app-mobile-assessment-request-schedule-item',
	standalone: true,
	imports: [
		CommonModule,
		ReactiveFormsModule,
		StepNavigatorComponent,
		DatePickerDropdownComponent,
		DropdownControlComponent,
		LoadingStateComponent,
		CalendarModule,
		StandardGroupControlComponent,
		TextareaControlComponent,
		SwitchControlComponent,
	],
	templateUrl: './mobile-assessment-request-schedule-item.component.html',
	styleUrl: './mobile-assessment-request-schedule-item.component.scss',
})
export class MobileAssessmentRequestScheduleItemComponent extends AssessmentRequestScheduleItem {
	navigationConfig: IStepNavigatorConfig;
	selectedStep: IStepNavigation;

	initialise(): void {
		this.setNavigation();
		this.selectedStep = this.navigationConfig.steps[0];

		this.schedulerFormItem.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
			this.setNavigation();
		});
	}

	setNavigation(): void {
		const step1Disabled = false;
		const step2Disabled =
			this.employeeId.invalid || this.selectedDate.invalid || this.selectedStartTime.invalid || this.selectedEndTime.invalid;
		let step3Disabled: boolean = step2Disabled;

		if (this.requestType.value === LeadRequestType.ON_SITE_VISIT) {
			step3Disabled = step3Disabled || (this.assessments?.invalid as boolean);
			if (this.assessmentType?.value === ScheduledAssessmentType.ASSESSMENT) {
				step3Disabled =
					step3Disabled || (this.callOutFeeRands?.invalid as boolean) || (this.includeCallOutFeeComms?.invalid as boolean);
			}
		}

		step3Disabled = step3Disabled || !this.assessmentSubmitted;

		const step1Complete =
			this.employeeId.valid && this.selectedDate.valid && this.selectedStartTime.valid && this.selectedEndTime.valid;
		let step2Complete: boolean = step1Complete;

		if (this.requestType.value === LeadRequestType.ON_SITE_VISIT) {
			step2Complete = step2Complete && (this.assessments?.valid as boolean);
			if (this.assessmentType?.value === ScheduledAssessmentType.ASSESSMENT) {
				step2Complete =
					step2Complete && (this.callOutFeeRands?.valid as boolean) && (this.includeCallOutFeeComms?.valid as boolean);
			}
		}

		step2Complete = step2Complete && this.assessmentSubmitted;
		let step3Complete = step2Complete && this.assessmentSharedOnWhatsapp;

		this.navigationConfig = {
			ui: StepUI.PAGINATION,
			mode: StepModes.NG_SWITCH,
			showStepChevrons: true,
			showStepNumbers: false,
			showNavButtons: true,
			navButtonStyle: NavButtonStyle.PRIMARY,
			steps: [
				{
					title: {
						desktop: 'Assign Employee',
						mobile: 'Assign Employee',
					},
					icon: 'bi-person',
					switchCase: AssessmentScheduleSteps.ASSIGN_EMPLOYEE,
					disabled: step1Disabled,
					complete: step1Complete,
				},
				{
					title: {
						desktop: 'Assessment Details',
						mobile: 'Assessment Details',
					},
					icon: 'bi-person',
					switchCase: AssessmentScheduleSteps.ASSIGNMENT_DETAILS,
					disabled: step2Disabled,
					complete: step2Complete,
				},
				{
					title: {
						desktop: 'Communication',
						mobile: 'Communication',
					},
					icon: 'bi-person',
					switchCase: AssessmentScheduleSteps.COMMUNICATIONS,
					disabled: step3Disabled,
					complete: step3Complete,
				},
			],
		};
	}

	submitAssessment(): void {
		// this.schedulerFormItem.disable();
		this.assessmentSubmitted = true;
		this.setNavigation();
		// this.nextTab();
	}

	shareToWhatsApp(): void {
		this.assessmentSharedOnWhatsapp = true;
		this.setNavigation();
	}
}
