<div class="organisation-create-container">
	<app-page-subtitle title="Basic Company Details"></app-page-subtitle>

	<form [formGroup]="organisationForm">
		<div class="form-container">
			<div class="form-row">
				<app-standard-control
					class="control"
					type="text"
					name="companyName"
					[formGroup]="organisationForm"
					label="Company Name"
					placeholder="Company Name"
				></app-standard-control>

				<app-standard-control
					class="control"
					type="text"
					name="companyContactNumber"
					[formGroup]="organisationForm"
					label="Company Contact Number"
					placeholder="Company Contact Number"
				></app-standard-control>
			</div>

			<div class="form-row">
				<app-standard-control
					class="control"
					type="text"
					name="companyRegistrationNumber"
					[formGroup]="organisationForm"
					label="Company Registration Number"
					placeholder="Company Registration Number"
					patternMessage="Company Registration Number must be YYYY/NNNNNN/XX"
				></app-standard-control>

				<app-standard-control
					class="control"
					type="text"
					name="companyContactName"
					[formGroup]="organisationForm"
					label="Company Contact Name"
					placeholder="Company Contact Name"
				></app-standard-control>
			</div>

			<div class="form-row">
				<app-select-control
					class="control"
					[formGroup]="organisationForm"
					name="companySize"
					label="Company Size"
					[options]="companySizeOptions"
				></app-select-control>

				<app-standard-control
					class="control"
					type="email"
					name="companyContactEmail"
					[formGroup]="organisationForm"
					label="Company Contact Email"
					placeholder="Company Contact Email"
				></app-standard-control>
			</div>

			<app-select-control
				class="control"
				[formGroup]="organisationForm"
				name="industry"
				label="Industry"
				[options]="industryOptions"
			></app-select-control>
		</div>
	</form>
</div>
