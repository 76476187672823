import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { PageSubtitleComponent } from '@shared/components/sections/page-subtitle/page-subtitle.component';
import { ModalService } from '@shared/services/modal/modal.service';
import { AddPropertyModalComponent } from '../modals/add-property-modal/add-property-modal.component';
import { take } from 'rxjs';
import { IClientProperty } from '@clients/models/clients';
import { EditPropertyModalComponent } from '../modals/edit-property-modal/edit-property-modal.component';

@Component({
	selector: 'app-desktop-client-create-property-table',
	standalone: true,
	imports: [CommonModule, PageSubtitleComponent],
	templateUrl: './desktop-client-create-property-table.component.html',
	styleUrl: './desktop-client-create-property-table.component.scss',
})
export class DesktopClientCreatePropertyTableComponent {
	@Input() properties: Array<IClientProperty>;
	private modalService = inject(ModalService);

	openAddProperty(): void {
		const modalRef = this.modalService.open(AddPropertyModalComponent);
		modalRef.componentInstance.propertySelected.pipe(take(1)).subscribe((property: IClientProperty) => {
			this.properties.push(property);
		});
	}

	openEditProperty(clientProperty: IClientProperty, index: number): void {
		const modalRef = this.modalService.open(EditPropertyModalComponent);
		modalRef.componentInstance.clientProperty = clientProperty;
		modalRef.componentInstance.propertySelected.pipe(take(1)).subscribe((property: IClientProperty) => {
			this.properties[index] = property;
		});
		modalRef.componentInstance.propertyRemoved.pipe(take(1)).subscribe((property: IClientProperty) => {
			this.properties.splice(index, 1);
		});
	}
}
