import { filter, takeUntil } from 'rxjs';

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, OnDestroy, OnInit } from '@angular/core';
import { DocumentReference } from '@angular/fire/firestore';
import { FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { OrganisationUserAccessLevel } from '@organisations/models/organisation-user';
import { IOrganisation, IOrganisationServiceTypesForm } from '@organisations/models/organisations';
import { OrganisationSelectedService } from '@organisations/services/organisation-selected/organisation-selected.service';
import { OrganisationService } from '@organisations/services/organisation/organisation.service';
import { AlertComponent } from '@shared/components/alerts/alert/alert.component';
import { LoadingStateComponent } from '@shared/components/loading-state/loading-state.component';
import { UnauthorisedBannerComponent } from '@shared/components/ui/unauthorised-banner/unauthorised-banner.component';
import { ToastTypes } from '@shared/models/toast';
import { ToastService } from '@shared/services/toast/toast.service';

@Component({
	selector: 'app-service-types',
	standalone: true,
	imports: [CommonModule, UnauthorisedBannerComponent, FormsModule, ReactiveFormsModule, AlertComponent, LoadingStateComponent],
	templateUrl: './service-types.component.html',
	styleUrl: './service-types.component.scss',
})
export class ServiceTypesComponent implements OnInit, OnDestroy {
	serviceTypesForm: FormGroup<IOrganisationServiceTypesForm>;
	destroyed$ = new EventEmitter<void>();

	organisationId: string;
	organisation: IOrganisation;
	userHasAccess = false;
	isLoading = true;
	isSaving = false;

	private organisationService = inject(OrganisationService);
	private organisationSelectedService = inject(OrganisationSelectedService);
	private toastService = inject(ToastService);

	ngOnInit(): void {
		this.serviceTypesForm = new FormGroup<IOrganisationServiceTypesForm>({
			serviceTypes: new FormArray<FormControl<string | null>>([]),
		});

		this.subscribeToSelectedOrganisation();
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}

	subscribeToSelectedOrganisation(): void {
		this.organisationSelectedService.selectedOrganisation
			.pipe(
				takeUntil(this.destroyed$),
				filter((o) => o !== null),
			)
			.subscribe((selectedOrg) => {
				if (selectedOrg?.accessLevel === OrganisationUserAccessLevel.OWNER) {
					this.userHasAccess = true;
					this.fetchOrganisationDetails(selectedOrg.organisation as DocumentReference<IOrganisation>);
				} else {
					this.userHasAccess = false;
					this.isLoading = false;
				}
			});
	}

	fetchOrganisationDetails(orgRef: DocumentReference<IOrganisation>): void {
		this.organisationService
			.getOrganisation(orgRef)
			.pipe(takeUntil(this.destroyed$))
			.subscribe((organisation) => {
				if (organisation) {
					this.organisationId = organisation.id as string;
					this.organisation = organisation;
					this.buildForm();
				}
				this.isLoading = false;
			});
	}

	get serviceTypes(): FormArray<FormControl<string | null>> {
		return this.serviceTypesForm.controls.serviceTypes;
	}

	buildForm(): void {
		if (this.organisation.settings?.serviceTypes) {
			const serviceTypes = this.organisation.settings?.serviceTypes.map(
				(serviceType) => new FormControl<string | null>(serviceType, [Validators.required]),
			);
			this.serviceTypesForm.controls.serviceTypes = new FormArray<FormControl<string | null>>(serviceTypes);
		} else {
			this.serviceTypesForm.controls.serviceTypes = new FormArray<FormControl<string | null>>([]);
			this.addServiceType();
		}
	}

	addServiceType(): void {
		this.serviceTypes.push(new FormControl<string | null>(null, [Validators.required]));
		this.serviceTypes.markAsDirty();
	}

	removeServiceType(index: number): void {
		this.serviceTypes.removeAt(index);
		this.serviceTypes.markAsDirty();
	}

	handleCancel(): void {
		this.buildForm();
	}

	handleSave(): void {
		if (this.serviceTypes.invalid) {
			return;
		}

		this.isSaving = true;

		this.organisationService
			.updateServiceTypes(this.organisationId, this.serviceTypes.value as Array<string>)
			.then(() => {
				this.isSaving = false;
				this.serviceTypesForm.markAsPristine();

				this.toastService.showToast({
					title: 'Service Types',
					message: 'Successfully updated service types',
					type: ToastTypes.SUCCESS,
				});
			})
			.catch((error) => {
				console.error(error);
				this.isSaving = false;

				this.toastService.showToast({
					title: 'Service Types',
					message: `Failed to update service types: ${error.message}`,
					type: ToastTypes.ERROR,
				});
			});
	}
}
