import { inject, Injectable, OnDestroy } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
	providedIn: 'root',
})
export class LayoutService implements OnDestroy {
	private breakpointObserver = inject(BreakpointObserver);
	public isMobileSize$: BehaviorSubject<boolean> = new BehaviorSubject(true);
	public isTabletSize$: BehaviorSubject<boolean> = new BehaviorSubject(true);

	constructor() {
		this.breakpointObserver.observe('(max-width: 768px)').subscribe((result: BreakpointState) => {
			this.isMobileSize$.next(result.matches);
		});
		this.breakpointObserver.observe('(max-width: 1024px)').subscribe((result: BreakpointState) => {
			this.isTabletSize$.next(result.matches);
		});
	}

	ngOnDestroy(): void {
		this.breakpointObserver.ngOnDestroy();
	}
}
