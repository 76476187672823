import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { OrganisationUserAccessLevel } from '@organisations/models/organisation-user';
import { OrganisationSelectedService } from '@organisations/services/organisation-selected/organisation-selected.service';
import { map, tap } from 'rxjs';
import { AppRoutes } from 'src/app/app.routes';

export const accessLevelGuard: CanActivateFn = (route, state) => {
	const organisationSelected = inject(OrganisationSelectedService);
	const router = inject(Router);

	return organisationSelected.selectedOrganisation.pipe(
		map((organisation) => organisation?.accessLevel === OrganisationUserAccessLevel.OWNER),
		tap((hasAccess) => {
			if (!hasAccess) {
				router.navigate([AppRoutes.DASHBOARD]);
			}
		}),
	);
};
