import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, OnDestroy, OnInit } from '@angular/core';
import { LayoutService } from '@layout/services/layout/layout.service';
import { takeUntil } from 'rxjs';
import { MenuCustomerComponent } from '../menu-customer/menu-customer.component';

@Component({
	selector: 'app-sidebar-customer',
	standalone: true,
	imports: [CommonModule, MenuCustomerComponent],
	templateUrl: './sidebar-customer.component.html',
	styleUrl: './sidebar-customer.component.scss',
})
export class SidebarCustomerComponent implements OnInit, OnDestroy {
	private layoutService = inject(LayoutService);
	destroyed$ = new EventEmitter<void>();
	isCollapsed = false;
	SERVEOX_LOGO = 'assets/logos/serveox-logo-white.svg';
	SERVEOX_ICON = 'assets/logos/serveox-icon-white.svg';
	navbarImage = this.SERVEOX_LOGO;

	ngOnInit(): void {
		this.layoutService.isTabletSize$.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
			if (value) {
				this.collapseSidebar();
			} else {
				this.expandSidebar();
			}
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}

	collapseSidebar() {
		this.isCollapsed = true;
		this.navbarImage = this.SERVEOX_ICON;
	}

	expandSidebar() {
		this.isCollapsed = false;
		this.navbarImage = this.SERVEOX_LOGO;
	}

	toggleSidebar() {
		this.isCollapsed = !this.isCollapsed;
		this.navbarImage = this.isCollapsed ? this.SERVEOX_ICON : this.SERVEOX_LOGO;
	}
}
