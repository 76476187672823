import { takeUntil } from 'rxjs';

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, OnDestroy, OnInit } from '@angular/core';
import { ToastEvent } from '@shared/models/toast';
import { ToastService } from '@shared/services/toast/toast.service';

import { ToastComponent } from '../toast/toast.component';

@Component({
	selector: 'app-toaster',
	templateUrl: './toaster.component.html',
	styleUrls: ['./toaster.component.scss'],
	standalone: true,
	imports: [CommonModule, ToastComponent],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToasterComponent implements OnInit, OnDestroy {
	private toastService = inject(ToastService);
	private destroyed$ = new EventEmitter<void>();

	currentToasts: ToastEvent[] = [];

	constructor(private cdr: ChangeDetectorRef) {}

	ngOnInit(): void {
		this.subscribeToToasts();
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}

	subscribeToToasts(): void {
		this.toastService.toastEvents$.pipe(takeUntil(this.destroyed$)).subscribe((toasts) => {
			this.currentToasts.push(toasts);
			this.cdr.detectChanges();
		});
	}

	dispose(index: number): void {
		this.currentToasts.splice(index, 1);
		this.cdr.detectChanges();
	}
}
