<form [formGroup]="form">
	<div class="password-reset-container">
		<div class="logo">
			<img src="assets/logos/serveox-logo-blue.svg" class="img-fluid" alt="ServeOx Logo" />
		</div>

		<h2 class="text-center">Password Reset</h2>

		<app-standard-control
			type="email"
			name="email"
			[formGroup]="form"
			label="Email Address"
			placeholder="Email Address"
			autocomplete="email"
		></app-standard-control>

		<button type="button" class="btn btn-primary" [disabled]="form.invalid || isLoading" (click)="handlePasswordReset()">
			<ng-container *ngIf="isLoading; else notLoading">
				<div class="spinner-border spinner-border-sm" role="status">
					<span class="visually-hidden">Loading...</span>
				</div>
			</ng-container>
			<ng-template #notLoading>Reset Password </ng-template>
		</button>

		<p class="text-center">Return to <a class="text-decoration-none" [routerLink]="'../' + AuthRoutes.LOGIN">Login page</a></p>
	</div>
</form>
