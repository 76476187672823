<app-loading-state [isLoading]="isLoading" [loadedContent]="loadedContent"> </app-loading-state>

<ng-template #loadedContent>
	<ng-container *ngIf="userHasAccess; else noAccess">
		<div class="assessment-form-container">
			<div class="content">
				<form [formGroup]="assessmentForm">
					<div class="form-container" formArrayName="customFormItems">
						<app-alert
							content="Here, you can create the questions you want your clients to fill in, tailoring them to suit your services. Choose whether each question belongs to client details or is specific to the assessment."
						></app-alert>

						<div class="assessment-form-wrapper">
							<ng-container *ngFor="let controlGroup of customFormItems.controls; let i = index">
								<div class="custom-form-item-wrapper">
									<h5 class="text-primary">Custom Form Item {{ i + 1 }}</h5>
									<ng-container *ngIf="i > 0">
										<button
											type="button"
											class="btn btn-close close-button"
											(click)="removeAssesmentFormItem(i)"
										></button>
									</ng-container>
									<div class="custom-form-item-container">
										<div class="form-column">
											<app-dropdown-control
												[formGroup]="controlGroup"
												name="type"
												label="Form Type"
												[showLabel]="true"
												[options]="formTypeOptions"
											></app-dropdown-control>

											<app-dropdown-control
												[formGroup]="controlGroup"
												name="category"
												label="Lead Request Category"
												[showLabel]="true"
												[options]="formCategoryOptions"
											></app-dropdown-control>
										</div>

										<div class="form-column">
											<app-standard-control
												[formGroup]="controlGroup"
												name="question"
												type="text"
												label="Question"
												placeholder="Eg. How many bathrooms do you have?"
												labelPlacement="above"
											></app-standard-control>

											<app-switch-control
												[formGroup]="controlGroup"
												name="required"
												label="This field is required"
											></app-switch-control>
										</div>
									</div>
								</div>
							</ng-container>
						</div>

						<button
							type="button"
							class="btn btn-sm btn-primary btn-width"
							(click)="addAssesmentFormItem()"
							[disabled]="customFormItems.length >= MAX_FORM_ITEMS"
						>
							Add new <i class="bi bi-plus"></i>
						</button>
					</div>
				</form>
			</div>
			<div class="footer">
				<button
					type="button"
					class="btn btn-sm btn-outline-danger btn-width"
					(click)="handleCancel()"
					[disabled]="customFormItems.pristine"
				>
					Cancel
				</button>
				<button
					type="button"
					class="btn btn-sm btn-primary btn-width"
					(click)="handleSave()"
					[disabled]="customFormItems.invalid || customFormItems.pristine"
				>
					<ng-container *ngIf="isSaving; else saveText">
						<div class="spinner-border spinner-border-sm" role="status">
							<span class="visually-hidden">Loading...</span>
						</div>
					</ng-container>
					<ng-template #saveText> Save </ng-template>
				</button>
			</div>
		</div>
	</ng-container>

	<ng-template #noAccess>
		<app-unauthorised-banner
			headingText="It looks like you do not have access to this organisation or do not have the required access level."
		></app-unauthorised-banner>
	</ng-template>
</ng-template>
