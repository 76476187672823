<div class="page-container">
	<div class="heading">
		<app-page-title title="Add Lead" [showBackButton]="true"></app-page-title>
	</div>

	<div class="content">
		<app-loading-state [isLoading]="isLoading" [loadedContent]="loadedContent"></app-loading-state>

		<ng-template #loadedContent>
			<ng-container *ngIf="userHasAccess; else noAccess">
				<ng-container *ngIf="isMobileSize; else desktopSize">
					<app-mobile-lead-request-create-details
						[leadRequestForm]="leadRequestForm"
						[clients]="clients"
						[serviceTypes]="serviceTypes"
					></app-mobile-lead-request-create-details>
				</ng-container>
				<ng-template #desktopSize>
					<app-desktop-lead-request-create-details
						[leadRequestForm]="leadRequestForm"
						[clients]="clients"
						[serviceTypes]="serviceTypes"
					></app-desktop-lead-request-create-details>
				</ng-template>
			</ng-container>
		</ng-template>
	</div>

	<div class="footer">
		<button type="button" class="btn btn-sm btn-outline-danger btn-width" [routerLink]="AppRoutes.CLIENT_LEADS_DASHBOARD">
			Cancel
		</button>
		<button type="button" class="btn btn-sm btn-primary btn-width" (click)="createLeadRequest()" [disabled]="leadRequestForm.pristine">
			<ng-container *ngIf="isSaving; else notSaving">
				<div class="spinner-border spinner-border-sm" role="status">
					<span class="visually-hidden">Loading...</span>
				</div>
			</ng-container>
			<ng-template #notSaving> Add Lead </ng-template>
		</button>
	</div>
</div>

<ng-template #noAccess>
	<app-unauthorised-banner
		headingText="It looks like you do not have access to this organisation or do not have the required access level to view this page."
	></app-unauthorised-banner>
</ng-template>
