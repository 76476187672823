export enum StepUI {
	WIZARD = 'wizard',
	PAGINATION = 'pagination',
}

export enum NavButtonStyle {
	CHEVRON = 'chevron',
	PRIMARY = 'primary',
}

interface IBaseStepNavigatorConfig {
	ui: StepUI;
	showStepNumbers: boolean;
	showStepChevrons: boolean;
	showNavButtons: boolean;
	navButtonStyle?: NavButtonStyle;
}

export enum StepModes {
	ROUTER_OUTLET = 'router-outlet',
	NG_SWITCH = 'ng-switch',
}

interface IStepNavigatorConfigRouterOutlet extends IBaseStepNavigatorConfig {
	mode: StepModes.ROUTER_OUTLET;
	steps: Array<IStepNavigationRouterOutlet>;
}

interface IStepNavigatorConfigNgSwitch extends IBaseStepNavigatorConfig {
	mode: StepModes.NG_SWITCH;
	steps: Array<IStepNavigationNgSwitch>;
}

export type IStepNavigatorConfig = IStepNavigatorConfigRouterOutlet | IStepNavigatorConfigNgSwitch;

interface IBaseStepNavigation {
	icon: string;
	title: {
		desktop: string;
		mobile: string;
	};
	disabled?: boolean;
	complete?: boolean;
}

interface IStepNavigationRouterOutlet extends IBaseStepNavigation {
	route: Array<string>;
	switchCase?: never;
}

interface IStepNavigationNgSwitch extends IBaseStepNavigation {
	route?: never;
	switchCase: string;
}

export type IStepNavigation = IStepNavigationRouterOutlet | IStepNavigationNgSwitch;
