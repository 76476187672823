<div class="modal-header">
	<h5 class="modal-title">Change Password</h5>
	<button type="button" class="btn-close" (click)="closeModal()" aria-label="Close"></button>
</div>
<div class="modal-body">
	<form [formGroup]="form" class="body">
		<app-password-control
			[formGroup]="form"
			name="password"
			label="New Password"
			placeholder="New Password"
			autocomplete="new-password"
		></app-password-control>

		<app-password-control
			[formGroup]="form"
			name="confirmPassword"
			label="Confirm New Password"
			placeholder="Confirm New Password"
			autocomplete="new-password"
		></app-password-control>
	</form>
</div>
<div class="modal-footer">
	<button
		type="button"
		[disabled]="isLoading || form.invalid || form.pristine"
		class="btn btn-sm btn-primary btn-width"
		(click)="submit()"
		data-id="submit"
	>
		<ng-container *ngIf="isLoading; else notLoading">
			<div class="spinner-border spinner-border-sm" role="status">
				<span class="visually-hidden">Loading...</span>
			</div>
		</ng-container>
		<ng-template #notLoading> Change Password </ng-template>
	</button>
</div>
