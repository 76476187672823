import { IUser } from 'src/app/core/user/models/user';

import { Injectable } from '@angular/core';
import { DocumentReference, getDocs, query, where } from '@angular/fire/firestore';
import { FireStoreCollection } from '@shared/models/firestore';
import { FirestoreService } from '@shared/services/firestore/firestore.service';

@Injectable({
	providedIn: 'root',
})
export class UsersService extends FirestoreService<IUser> {
	constructor() {
		super(FireStoreCollection.USERS);
	}

	async userByEmail(email: string): Promise<IUser | null> {
		const q = query(this.collectionRef, where('email', '==', email));
		return getDocs(q).then((snapshot) => {
			if (!snapshot.empty) {
				return snapshot.docs[0].data();
			}
			return null;
		});
	}

	getUserByRef(userRef: DocumentReference) {
		return this.getDocSnapshot(userRef.id);
	}

	getUserById(userId: string) {
		return this.getDocSnapshot(userId);
	}
}
