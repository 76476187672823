<ng-container *ngIf="item.visible">
	<li
		class="nav-item"
		[class.collapsed]="isCollapsed"
		[class.has-children]="item.children"
		[class.child]="isChild"
		[class.expanded]="isExpanded"
	>
		<a
			class="nav-link nav-text-color"
			[class.has-children]="item.children"
			[class.menu-collapsed]="isCollapsed && item.children"
			data-bs-toggle="collapse"
			[attr.data-bs-target]="'#' + itemId"
			aria-expanded="false"
			[attr.aria-controls]="itemId"
			[class.disabled]="item.disabled"
			routerLinkActive="active"
			[routerLink]="item.route"
			(click)="dismissOffcanvas(item)"
		>
			<i class="bi" [ngClass]="item.icon"></i>
			<div class="nav-text">
				<div class="text-truncate">
					<ng-container *ngIf="!isCollapsed">
						{{ item.label }}
					</ng-container>
				</div>
				<div>
					<ng-container *ngIf="!isCollapsed">
						<ng-container *ngIf="item.children">
							<i class="bi bi-chevron-down" [class.rotate]="isExpanded"></i>
						</ng-container>
					</ng-container>
					<ng-container *ngIf="isCollapsed && item.children">
						<i class="bi bi-chevron-compact-down" [class.rotate]="isExpanded"></i>
					</ng-container>
				</div>
			</div>
		</a>
		<ng-container *ngIf="item.children">
			<div
				class="collapse"
				[id]="itemId"
				[attr.data-bs-parent]="'#' + parentId"
				(show.bs.collapse)="onCollapseShow($event)"
				(hide.bs.collapse)="onCollapseHide($event)"
			>
				<ng-container *ngFor="let child of item.children">
					<app-menu-nav-item
						[item]="child"
						[parentId]="itemId"
						[isCollapsed]="isCollapsed"
						[isChild]="true"
						(dismiss)="dismissOffcanvas($event)"
					></app-menu-nav-item>
				</ng-container>
			</div>
		</ng-container>
	</li>
</ng-container>
