import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { ClientsRoutes } from '@clients/clients.routes';
import { IClientWithProperties } from '@clients/models/firebase/client.model';
import { SearchControlComponent } from '@shared/components/forms/search-control/search-control.component';
import { LoadingStateComponent } from '@shared/components/loading-state/loading-state.component';
import { PropertyPipe } from '@shared/pipes/property.pipe';

@Component({
	selector: 'app-mobile-clients-table',
	standalone: true,
	imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterLink, SearchControlComponent, LoadingStateComponent, PropertyPipe],
	templateUrl: './mobile-clients-table.component.html',
	styleUrl: './mobile-clients-table.component.scss',
	host: {
		class: 'h-100',
	},
})
export class MobileClientsTableComponent implements OnInit, OnDestroy {
	@Input() isLoading: boolean;
	@Input() clients: Array<IClientWithProperties>;
	@Output() searchChange = new EventEmitter<string>();

	searchControl = new FormControl('', { nonNullable: true });
	destroyed$ = new EventEmitter<void>();
	ClientsRoutes = ClientsRoutes;

	ngOnInit(): void {
		this.searchControl.valueChanges.pipe(takeUntil(this.destroyed$), debounceTime(500), distinctUntilChanged()).subscribe((value) => {
			this.searchChange.emit(value);
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}

	viewClientRoute(client: IClientWithProperties): string {
		return ClientsRoutes.VIEW.replace(':clientId', client.id as string);
	}
}
