import { Injectable } from '@angular/core';
import { FirestoreService } from '@shared/services/firestore/firestore.service';
import { and, DocumentReference, getDocs, or, orderBy, query, where } from '@angular/fire/firestore';
import { from, map, Observable } from 'rxjs';
import { ILeadRequest, LeadRequestType, LeadStatus } from '@leads/shared/models/lead.models';
import { FireStoreCollection } from '@shared/models/firestore';

@Injectable({
	providedIn: 'root',
})
export class LeadsDashboardService extends FirestoreService<ILeadRequest> {
	constructor() {
		super(FireStoreCollection.LEAD_REQUESTS);
	}

	getLeadRequestsByOrganisation(
		organisationRef: DocumentReference,
		searchTerm: string,
		leadStatus: Array<LeadStatus>,
		leadRequestType: LeadRequestType | null,
	): Observable<Array<ILeadRequest>> {
		let leadRequestsQuery = query(this.collectionRef, where('organisation', '==', organisationRef));
		if (searchTerm) {
			const lowerCaseSearchTerm = searchTerm.toLocaleLowerCase();
			leadRequestsQuery = query(
				leadRequestsQuery,
				or(
					and(
						where('personalDetails.nameLower', '>=', lowerCaseSearchTerm),
						where('personalDetails.nameLower', '<=', lowerCaseSearchTerm + '\uf8ff'),
					),
					and(
						where('mobileNumber.number', '>=', lowerCaseSearchTerm),
						where('mobileNumber.number', '<=', lowerCaseSearchTerm + '\uf8ff'),
					),
				),
			);
		}
		if (leadStatus.length) {
			leadRequestsQuery = query(leadRequestsQuery, where('status', 'in', leadStatus));
		}
		if (leadRequestType) {
			leadRequestsQuery = query(leadRequestsQuery, where('requestType', '==', leadRequestType));
		}

		leadRequestsQuery = query(leadRequestsQuery, orderBy('createdDate', 'asc'));

		return from(getDocs(leadRequestsQuery)).pipe(
			map((querySnapshot) => querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }) as ILeadRequest)),
		);
	}

	getLeadRequest(leadRequestId: string): Observable<ILeadRequest | undefined> {
		return super.getDocumentData(leadRequestId);
	}

	updateStatus(leadRequestId: string, leadStatus: LeadStatus) {
		return super.update(leadRequestId, { status: leadStatus });
	}
}
