import { FormControl, FormGroup } from '@angular/forms';
import { IClientProperty, IClientWithProperties } from '@clients/models/firebase/client.model';
import { IInstallationAddressForm } from '@leads/customer-facing/models/domain/lead-request.domain';
import { LeadRequestType, LeadStatus } from '@leads/shared/models/domain/lead-request.domain';
import { IDropdownOption } from '@shared/components/forms/dropdown-control/dropdown-control.component';
import { IRadioOption } from '@shared/models/form-controls';

export interface ILeadRequestNotesForm {
	internalNotes: FormControl<string | null>;
}

export const leadStatusOptions: Array<IDropdownOption> = [
	{
		id: 'new',
		icon: 'bi-hourglass-split',
		value: LeadStatus.NEW,
		label: 'New',
	},
	{
		id: 'active',
		icon: 'bi-clock',
		value: LeadStatus.ACTIVE,
		label: 'Active',
	},
	{
		id: 'completed',
		icon: 'bi-check-circle-fill',
		value: LeadStatus.COMPLETED,
		label: 'Completed',
	},
	{
		id: 'rejected',
		icon: 'bi-x-circle-fill',
		value: LeadStatus.REJECTED,
		label: 'Rejected',
	},
	{
		id: 'cancelled',
		icon: 'bi-x-circle-fill',
		value: LeadStatus.CANCELLED,
		label: 'Cancelled',
	},
];

export const leadRequestTypeOptions: Array<IRadioOption> = [
	{
		id: 'all',
		label: 'All',
		value: null,
		statusIndicator: 'amber',
	},
	{
		id: 'onsite',
		label: 'On-Site',
		value: LeadRequestType.ON_SITE_VISIT,
		statusIndicator: 'green',
	},
	{
		id: 'call',
		label: 'Call',
		value: LeadRequestType.CALL,
		statusIndicator: 'blue',
	},
];

export interface IImportClient {
	selectedClient: IClientWithProperties;
	selectedClientProperty: IClientProperty | null;
	propertySelectType: PropertySelectType;
}

export interface IImportClientForm {
	selectedClient: FormControl<IClientWithProperties | null>;
	selectedClientProperty: FormControl<IClientProperty | null>;
	propertySelectType: FormControl<PropertySelectType | null>;
}

export enum PropertySelectType {
	EXISTING = 'EXISTING',
	NEW = 'NEW',
}

export interface ILeadAddProperty {
	selectedClientProperty: IClientProperty;
	propertySelectType: PropertySelectType;
}

export interface ILeadAddPropertyForm {
	selectedClientProperty: FormControl<IClientProperty | null>;
	propertySelectType: FormControl<PropertySelectType | null>;
	newProperty: FormGroup<IInstallationAddressForm>;
}
