<app-loading-state [isLoading]="isLoading" [loadedContent]="loadedContent"> </app-loading-state>

<ng-template #loadedContent>
	<ng-container *ngIf="userHasAccess; else noAccess">
		<div class="service-types-container">
			<div class="content">
				<form [formGroup]="serviceTypesForm">
					<div class="form-container" formArrayName="serviceTypes">
						<app-alert
							content="In this section, you can select and manage the types of services you offer. These options will appear
						for your clients when they request a service, making it easy for them to understand the work you
						specialise in."
						></app-alert>

						<div class="service-type-wrapper">
							<ng-container *ngFor="let control of serviceTypes.controls; let i = index">
								<div class="service-type-container">
									<div class="input-group">
										<input
											class="form-control"
											type="text"
											[formControlName]="i"
											[placeholder]="'Service Type ' + (i + 1)"
										/>
										<ng-container *ngIf="i > 0">
											<button type="button" class="btn btn-sm btn-outline-danger" (click)="removeServiceType(i)">
												<i class="bi bi-trash"></i>
											</button>
										</ng-container>
									</div>
								</div>
							</ng-container>
						</div>

						<button type="button" class="btn btn-sm btn-primary btn-width" (click)="addServiceType()">
							Add Service <i class="bi bi-plus"></i>
						</button>
					</div>
				</form>
			</div>
			<div class="footer">
				<button
					type="button"
					class="btn btn-sm btn-outline-danger btn-width"
					(click)="handleCancel()"
					[disabled]="serviceTypes.pristine"
				>
					Cancel
				</button>
				<button
					type="button"
					class="btn btn-sm btn-primary btn-width"
					(click)="handleSave()"
					[disabled]="serviceTypes.invalid || serviceTypes.pristine"
				>
					<ng-container *ngIf="isSaving; else saveText">
						<div class="spinner-border spinner-border-sm" role="status">
							<span class="visually-hidden">Loading...</span>
						</div>
					</ng-container>
					<ng-template #saveText> Save </ng-template>
				</button>
			</div>
		</div>
	</ng-container>

	<ng-template #noAccess>
		<app-unauthorised-banner
			headingText="It looks like you do not have access to this organisation or do not have the required access level."
		></app-unauthorised-banner>
	</ng-template>
</ng-template>
