<form [formGroup]="formGroup">
	<div [class.form-floating]="labelPlacement === 'floating'">
		<ng-container *ngIf="labelPlacement === 'above'">
			<label [for]="name" class="form-label"
				>{{ label }}<ng-container *ngIf="control.hasValidator(Validators.required)">*</ng-container></label
			>
		</ng-container>
		<input
			[type]="type"
			[formControlName]="name"
			class="form-control"
			[class.is-invalid]="control.invalid && control.touched"
			[id]="name"
			[placeholder]="placeholder"
			[autocomplete]="autocomplete"
		/>
		<ng-container *ngIf="labelPlacement === 'floating'">
			<label [for]="name"> {{ label }}<ng-container *ngIf="control.hasValidator(Validators.required)">*</ng-container> </label>
		</ng-container>

		<div *ngIf="control.invalid && control.errors" class="invalid-feedback">
			<p *ngIf="control.errors?.required">{{ label }} required.</p>
			<p *ngIf="control.errors?.email">Invalid email.</p>
			<p *ngIf="control.errors?.pattern">{{ patternMessage }}</p>
			<p *ngIf="control.errors?.minlength">{{ label }} must be at least {{ control.errors.minlength.requiredLength }} characters.</p>
			<p *ngIf="control.errors?.maxlength">{{ label }} must be less than {{ control.errors.maxlength.requiredLength }} characters.</p>
			<p *ngIf="control.errors?.min">{{ label }} must be at least {{ control.errors.min.min }}.</p>
			<p *ngIf="control.errors?.max">{{ label }} must be less than {{ control.errors.max.max }}</p>
			<p *ngIf="control.errors?.custom">{{ control.errors.custom }}</p>
		</div>
	</div>
</form>
