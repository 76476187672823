import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BaseLeadRequestCreate } from '@leads/client-facing/classes/base-lead-request-create.component';
import { CustomFormDetailsComponent } from '@leads/customer-facing/components/forms/custom-form-details/custom-form-details.component';
import { LeadNotesComponent } from '@leads/customer-facing/components/forms/lead-notes/lead-notes.component';
import { AddressWithSearchComponent } from '@shared/components/forms/address-with-search/address-with-search.component';
import { DropdownControlComponent } from '@shared/components/forms/dropdown-control/dropdown-control.component';
import { StandardControlComponent } from '@shared/components/forms/standard-control/standard-control.component';
import { HeaderPopoverComponent } from '@shared/components/sections/header-popover/header-popover.component';

@Component({
	selector: 'app-desktop-lead-request-create-details',
	standalone: true,
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		StandardControlComponent,
		AddressWithSearchComponent,
		CustomFormDetailsComponent,
		DropdownControlComponent,
		LeadNotesComponent,
		HeaderPopoverComponent,
	],
	templateUrl: './desktop-lead-request-create-details.component.html',
	styleUrl: './desktop-lead-request-create-details.component.scss',
})
export class DesktopLeadRequestCreateDetailsComponent extends BaseLeadRequestCreate {}
