import { from, map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { inject, Injectable } from '@angular/core';
import { and, doc, DocumentReference, getDocs, or, orderBy, query, Timestamp, where } from '@angular/fire/firestore';
import { FormGroup } from '@angular/forms';
import { AppRoutes } from '@app/app.routes';
import { IClient, IClientProperty } from '@clients/models/firebase/client.model';
import { ClientsService } from '@clients/services/clients/clients.service';
import { ICustomFormControl, ILeadRequestForm } from '@leads/customer-facing/models/domain/lead-request.domain';
import { CustomerLeadRequestRoutes } from '@leads/leads.routes';
import { LeadOnSiteVisitDateTimeType, LeadRequestType, LeadStatus, LeadTimeWindow } from '@leads/shared/models/domain/lead-request.domain';
import { ICustomQuestion, ILeadRequest } from '@leads/shared/models/firebase/lead-request.model';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FireStoreCollection } from '@shared/models/firestore';
import { ILeadRequestConfirmationMail } from '@shared/models/mail';
import { EmailService } from '@shared/services/email/email.service';
import { FirestoreService } from '@shared/services/firestore/firestore.service';

@Injectable({
	providedIn: 'root',
})
export class LeadRequestService extends FirestoreService<ILeadRequest> {
	private clientsService = inject(ClientsService);
	private emailService = inject(EmailService);

	constructor() {
		super(FireStoreCollection.LEAD_REQUESTS);
	}

	getLeadRequestsByOrganisation(
		organisationRef: DocumentReference,
		searchTerm: string,
		leadStatus: Array<LeadStatus>,
		leadRequestType: LeadRequestType | null,
	): Observable<Array<ILeadRequest>> {
		let leadRequestsQuery = query(this.collectionRef, where('organisation', '==', organisationRef));
		if (searchTerm) {
			const lowerCaseSearchTerm = searchTerm.toLocaleLowerCase();
			leadRequestsQuery = query(
				leadRequestsQuery,
				or(
					and(
						where('personalDetails.nameLower', '>=', lowerCaseSearchTerm),
						where('personalDetails.nameLower', '<=', lowerCaseSearchTerm + '\uf8ff'),
					),
					and(
						where('mobileNumber.number', '>=', lowerCaseSearchTerm),
						where('mobileNumber.number', '<=', lowerCaseSearchTerm + '\uf8ff'),
					),
				),
			);
		}
		if (leadStatus.length) {
			leadRequestsQuery = query(leadRequestsQuery, where('status', 'in', leadStatus));
		}
		if (leadRequestType) {
			leadRequestsQuery = query(leadRequestsQuery, where('requestType', '==', leadRequestType));
		}

		leadRequestsQuery = query(leadRequestsQuery, orderBy('createdDate', 'desc'));

		return from(getDocs(leadRequestsQuery)).pipe(
			map((querySnapshot) => querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }) as ILeadRequest)),
		);
	}

	getLeadRequest(leadRequestId: string): Observable<ILeadRequest | undefined> {
		return super.getDocumentData(leadRequestId);
	}

	mapCustomFormItemToCustomQuestion(customQuestions: FormGroup<ICustomFormControl>): Array<ICustomQuestion> {
		const customQuestionsArray: Array<ICustomQuestion> = [];
		Object.keys(customQuestions.controls).forEach((key) => {
			const customQuestion: ICustomQuestion = {
				question: key,
				answer: customQuestions.controls[key].value as string,
			};
			customQuestionsArray.push(customQuestion);
		});
		return customQuestionsArray;
	}

	async createLeadRequest(leadRequestForm: FormGroup<ILeadRequestForm>, organisationId: string, organisationName: string) {
		try {
			const organisationRef = doc(this.firestore, `${FireStoreCollection.ORGANISATIONS}/${organisationId}`);
			const existingClients = await this.clientsService.getClientByMobileNumber(
				organisationRef,
				leadRequestForm.controls.mobileNumber.value as string,
			);

			let clientId: string;
			const clientProperty: IClientProperty = leadRequestForm.controls.installationAddress.value as IClientProperty;
			const requestType = leadRequestForm.controls.requestType.value as LeadRequestType;

			if (existingClients.size === 0) {
				const client: IClient = {
					clientEmail: leadRequestForm.controls.contactDetails.controls.emailAddress.value as string,
					clientName: leadRequestForm.controls.contactDetails.controls.name.value as string,
					clientNameLower: leadRequestForm.controls.contactDetails.controls.name.value?.toLocaleLowerCase() as string,
					clientNumber: {
						countryCode: '+27',
						number: leadRequestForm.controls.mobileNumber.value as string,
					},
					organisation: organisationRef,
					internalNotes: null,
				};
				let properties: Array<IClientProperty> = [];
				if (!Object.values(clientProperty).every((value) => value === null)) {
					properties.push(clientProperty);
				}
				const clientResult = await this.clientsService.createClient(client, properties);
				clientId = clientResult.id;
			} else {
				clientId = existingClients.docs[0].id;

				const existingClientProperties = await this.clientsService.searchClientProperty(clientId, clientProperty);

				if (existingClientProperties.empty && requestType === LeadRequestType.ON_SITE_VISIT) {
					await this.clientsService.addClientProperty(clientId, clientProperty);
				}
			}

			const email = leadRequestForm.controls.contactDetails.controls.emailAddress.value as string;
			const clientName = leadRequestForm.controls.contactDetails.controls.name.value as string;

			const serviceType = leadRequestForm.controls.onsiteAssessment.controls.serviceType.value as string;
			const timeWindow = leadRequestForm.controls.onsiteAssessment.controls.requestedTimeWindow.value as LeadTimeWindow;

			const leadRequest: ILeadRequest = {
				createdDate: Timestamp.now(),
				updatedDate: Timestamp.now(),
				status: LeadStatus.NEW,
				organisation: organisationRef,
				client: doc(this.firestore, `${FireStoreCollection.CLIENTS}/${clientId}`),
				mobileNumber: {
					countryCode: '+27',
					number: leadRequestForm.controls.mobileNumber.value as string,
				},
				personalDetails: {
					emailAddress: email,
					name: clientName,
					nameLower: clientName.toLocaleLowerCase(),
					installationAddress: leadRequestForm.controls.installationAddress.value as IClientProperty,
					customQuestions: this.mapCustomFormItemToCustomQuestion(
						leadRequestForm.controls.contactDetails.controls.customQuestions,
					),
				},
				requestType: requestType,
				callAssessment: {
					callNotes: leadRequestForm.controls.callAssessment.controls.notes.value as string,
					customQuestions: this.mapCustomFormItemToCustomQuestion(
						leadRequestForm.controls.callAssessment.controls.customQuestions,
					),
				},
				onSiteVisitAssessment: {
					notes: leadRequestForm.controls.onsiteAssessment.controls.notes.value as string,
					serviceType: leadRequestForm.controls.onsiteAssessment.controls.serviceType.value as string,
					dateTime: {
						type: leadRequestForm.controls.onsiteAssessment.controls.dateType.value as LeadOnSiteVisitDateTimeType,
						requestedTimeWindow: timeWindow,
						requestedDate: leadRequestForm.controls.onsiteAssessment.controls.requestedDate.value as NgbDateStruct,
					},
					customQuestions: this.mapCustomFormItemToCustomQuestion(
						leadRequestForm.controls.onsiteAssessment.controls.customQuestions,
					),
				},
				internalNotes: null,
			};
			const leadRequestResult = await super.create(leadRequest);

			const day = leadRequestForm.controls.onsiteAssessment.controls.requestedDate.value?.day;
			const month = leadRequestForm.controls.onsiteAssessment.controls.requestedDate.value?.month;
			const year = leadRequestForm.controls.onsiteAssessment.controls.requestedDate.value?.year;
			let formattedDate: string;
			if (day && month && year) {
				formattedDate = `${day}/${month}/${year}: ${timeWindow}`;
			} else {
				formattedDate = 'N/A';
			}

			const leadRequestConfirmation: ILeadRequestConfirmationMail = {
				clientName: clientName,
				organisationName: organisationName,
				requestType: requestType,
				serviceType: serviceType ?? 'N/A',
				formattedDate: formattedDate,
				leadRequestLink: `${environment.baseUrl}/${AppRoutes.CUSTOMER_LEAD_REQUEST.replace(':organisationId', organisationId)}/${CustomerLeadRequestRoutes.VIEW_REQUEST.replace(':leadRequestId', leadRequestResult.id)}`,
			};

			await this.emailService.leadRequestConfirmation(email, leadRequestConfirmation);
			return leadRequestResult;
		} catch (error) {
			console.error(error);
			return null;
		}
	}

	updateStatus(leadRequestId: string, leadStatus: LeadStatus) {
		return super.update(leadRequestId, { status: leadStatus });
	}

	updateInstallationAddress(leadRequestId: string, property: IClientProperty) {
		return super.update(leadRequestId, { 'personalDetails.installationAddress': property });
	}
}
