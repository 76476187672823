<div class="table-container">
	<div class="date-container text-primary">
		<button type="button" class="btn border-0" (click)="previousMonth()">
			<i class="bi bi-chevron-left"></i>
		</button>
		<h4>{{ formatMonthYear(billingMonth) }}</h4>
		<button type="button" class="btn border-0" (click)="nextMonth()">
			<i class="bi bi-chevron-right"></i>
		</button>
	</div>
	<div class="table-responsive">
		<table class="table table-hover table-bordered table-striped">
			<thead>
				<tr>
					<th scope="col">#</th>
					<th scope="col">Email</th>
					<ng-container *ngIf="!isMobile">
						<th scope="col">Join Date</th>
						<th scope="col">Removed Date</th>
					</ng-container>
					<th scope="col">Status</th>
					<th scope="col">Amount</th>
				</tr>
			</thead>
			<tbody class="table-group-divider">
				<ng-container *ngFor="let item of organisationBill.invoices; let i = index">
					<tr>
						<th scope="row">{{ i + 1 }}</th>
						<td>{{ item.email }}</td>
						<ng-container *ngIf="!isMobile">
							<td>{{ item.joinDate | date }}</td>
							<td>
								<ng-container *ngIf="item.removeDate; else emptyText">
									{{ item.removeDate | date }}
								</ng-container>
							</td>
						</ng-container>
						<td>{{ item.status | titlecase }}</td>
						<th>{{ item.billingAmount | currency: 'R' }}</th>
					</tr>
				</ng-container>
			</tbody>
			<tfoot>
				<tr>
					<th scope="row">Total</th>
					<td [attr.colspan]="isMobile ? 2 : 4"></td>
					<th>{{ organisationBill.totalAmount | currency: 'R' }}</th>
				</tr>
			</tfoot>
		</table>
	</div>
</div>

<ng-template #emptyText> - </ng-template>
