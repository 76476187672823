<div class="calendar-controls">
	<div class="calendar-date-selector">
		<div
			[(viewDate)]="viewDate"
			class="btn btn-sm btn-outline-secondary"
			mwlCalendarToday
		>
			Today
		</div>
		<div class="btn-group">
			<div
				(viewDateChange)="changeViewDate()"
				[(viewDate)]="viewDate"
				[view]="calendarView"
				class="btn"
				mwlCalendarPreviousView
			>
				<span class="bi bi-chevron-left"></span>
			</div>

			<div
				(viewDateChange)="changeViewDate()"
				[(viewDate)]="viewDate"
				[view]="calendarView"
				class="btn"
				mwlCalendarNextView
			>
				<span class="bi bi-chevron-right"></span>
			</div>
		</div>
		<h6 class="header">{{ viewDate | calendarDate: headerCalendarDateMethod:'en' }}</h6>
	</div>
	<div class="calendar-view">
		<div class="dropdown">
			<button
				aria-expanded="false"
				class="btn btn-sm btn-primary dropdown-toggle"
				data-bs-toggle="dropdown"
				id="dropdownMenuButton"
				type="button"
			>
				{{ calendarView | titlecase }}
			</button>
			<ul aria-labelledby="dropdownMenuButton" class="dropdown-menu">
				<li>
					<button
						(click)="changeView(CalendarView.Month)"
						class="dropdown-item"
						type="button"
					>
						Month
					</button>
				</li>
				<li>
					<button
						(click)="changeView(CalendarView.Week)"
						class="dropdown-item"
						type="button"
					>
						Week
					</button>
				</li>
				<li>
					<button
						(click)="changeView(CalendarView.Day)"
						class="dropdown-item"
						type="button"
					>
						Day
					</button>
				</li>
			</ul>
		</div>
	</div>
</div>
