<div class="dropdown">
	<div class="organisation-selector dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
		<i class="bi bi-people-fill"></i>
		<ng-container *ngIf="!isCollapsed">
			<ng-container *ngIf="selectedOrganisation; else noOrganisations">
				{{ selectedOrganisation.companyName }}
			</ng-container>
			<ng-template #noOrganisations> ----------------- </ng-template>
		</ng-container>
	</div>
	<ul class="dropdown-menu">
		<li><h6 class="dropdown-header">Your Organisations</h6></li>
		<ng-container *ngFor="let organisation of availableOrganisations">
			<li>
				<button
					class="dropdown-item"
					[class.active]="organisation.id === selectedOrganisation?.id"
					type="button"
					(click)="selectOrganisation(organisation)"
				>
					<div class="fw-bold">
						{{ organisation.companyName }}
					</div>
					<div class="ms-1" style="font-size: small">
						<em>Access level: {{ organisation.accessLevel | titlecase }}</em>
					</div>
				</button>
			</li>
		</ng-container>
		<li><hr class="dropdown-divider" /></li>
		<li>
			<button class="dropdown-item" type="button" [routerLink]="createOrganisationRoute" (click)="dismissOffcanvas()">
				<i class="bi bi-house-add"></i> Create New Organisation
			</button>
		</li>
	</ul>
</div>
