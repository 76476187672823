import { Routes } from '@angular/router';
import { ClientsDashboardComponent } from './pages/clients-dashboard/clients-dashboard.component';
import { ClientCreateComponent } from './pages/client-create/client-create.component';
import { ClientViewComponent } from './pages/client-view/client-view.component';

export enum ClientsRoutes {
	DASHBOARD = '',
	CREATE = 'create',
	VIEW = 'view/:clientId',
}

export const clientsRoutes: Routes = [
	{
		path: ClientsRoutes.DASHBOARD,
		component: ClientsDashboardComponent,
		title: 'ServeOx | Clients',
	},
	{
		path: ClientsRoutes.CREATE,
		component: ClientCreateComponent,
		title: 'ServeOx | Create Clients',
	},
	{
		path: ClientsRoutes.VIEW,
		component: ClientViewComponent,
		title: 'ServeOx | View Client',
	},
	{
		path: '**',
		redirectTo: ClientsRoutes.DASHBOARD,
	},
];
