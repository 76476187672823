import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'nullDefault',
	standalone: true
})
export class NullDefaultPipe implements PipeTransform {

	transform(value: unknown, defaultValue: unknown): unknown {
		return value == null ? defaultValue : value;
	}
}
