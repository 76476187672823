import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ToastEvent } from '../../models/toast';

@Injectable({
	providedIn: 'root',
})
export class ToastService {
	toastEvents$: Observable<ToastEvent>;
	private toastEvents = new Subject<ToastEvent>();

	constructor() {
		this.toastEvents$ = this.toastEvents.asObservable();
	}
	/**
	 * Show toast notification.
	 * @param toastEvent Toast Event Object
	 */
	showToast(toastEvent: ToastEvent): void {
		this.toastEvents.next(toastEvent);
	}
}
