import { Component } from '@angular/core';
import { MenuComponent } from '../menu/menu.component';
import { RouterLink } from '@angular/router';

@Component({
	selector: 'app-mobile-header',
	standalone: true,
	imports: [MenuComponent, RouterLink],
	templateUrl: './mobile-header.component.html',
	styleUrl: './mobile-header.component.scss',
})
export class MobileHeaderComponent {}
