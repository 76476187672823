import { Component, inject, ViewChild } from '@angular/core';
import { LeadRequestFormLinkDirective } from '@leads/shared/directives/lead-request-form-link.directive';
import { LeadRequestFormLinkService } from '@leads/shared/services/lead-request-form-link/lead-request-form-link.service';
import { AlertComponent } from '@shared/components/alerts/alert/alert.component';

@Component({
	selector: 'app-leads-link',
	standalone: true,
	imports: [AlertComponent, LeadRequestFormLinkDirective],
	templateUrl: './leads-link.component.html',
	styleUrl: './leads-link.component.scss',
})
export class LeadsLinkComponent {
	@ViewChild(LeadRequestFormLinkDirective) leadRequestFormLinkDirective!: LeadRequestFormLinkDirective;

	private service = inject(LeadRequestFormLinkService);

	copyToClipboard() {
		this.service.copyNewLeadLink();
	}
}
