<div class="subpage-container">
	<app-accordion parentId="assessmentRequest">
		<app-accordion-item itemId="contactDetails" parentId="assessmentRequest" [openOnInit]="true">
			<div header>Contact Details</div>
			<div body class="section-column">
				<div class="section-column">
					<h6 class="text-primary">Contact Details</h6>
					<div class="section-information">
						<div class="label">
							<div class="name">Customer Name</div>
							<div class="value">{{ client.clientName }}</div>
						</div>
						<ng-container *ngIf="client.properties.length">
							<div class="label">
								<div class="name">Address</div>
								<div class="value">
									{{ client.properties[0].fullAddress }}, {{ client.properties[0].postalCode }},
									{{ client.properties[0].city }},
									{{ client.properties[0].country }}
								</div>
							</div>
						</ng-container>
						<div class="label">
							<div class="name">Phone</div>
							<div class="value">({{ client.clientNumber.countryCode }}) {{ client.clientNumber.number }}</div>
						</div>
						<div class="label">
							<div class="name">Email</div>
							<div class="value">{{ client.clientEmail }}</div>
						</div>
					</div>
				</div>
				<div class="section-column">
					<h6 class="text-primary">Internal Client Notes</h6>
					<textarea class="form-control" readonly placeholder="Client Internal Notes">{{ client.internalNotes }}</textarea>
				</div>
			</div>
		</app-accordion-item>

		<app-accordion-item itemId="clientRequirements" parentId="assessmentRequest">
			<div header>Client Requirements - {{ leadRequest.requestType }}</div>
			<div body class="section-column">
				<div class="section-column">
					<h6 class="text-primary">Request Information</h6>
					<div class="section-information">
						<div class="label">
							<div class="name">Submitted Date</div>
							<div class="value">{{ leadRequest.createdDate.toMillis() | date: 'dd/MM/yyyy' }}</div>
						</div>
						<ng-container *ngIf="leadRequest.requestType === LeadrequestType.ON_SITE_VISIT">
							<div class="label">
								<div class="name">Date</div>
								<div class="value">
									<ng-container
										*ngIf="leadRequest.onSiteVisitAssessment?.dateTime?.type === LeadrequestDateTimeType.REQUEST"
									>
										{{
											leadRequest.onSiteVisitAssessment?.dateTime?.requestedDate | ngbDateToDate | date: 'dd/MM/yyyy'
										}}
									</ng-container>
									<ng-container
										*ngIf="leadRequest.onSiteVisitAssessment?.dateTime?.type === LeadrequestDateTimeType.ASAP"
									>
										ASAP
									</ng-container>
								</div>
							</div>
							<div class="label">
								<div class="name">Time</div>
								<div class="value">
									<ng-container
										*ngIf="leadRequest.onSiteVisitAssessment?.dateTime?.type === LeadrequestDateTimeType.REQUEST"
									>
										{{ leadRequest.onSiteVisitAssessment?.dateTime?.requestedTimeWindow }}
									</ng-container>
									<ng-container
										*ngIf="leadRequest.onSiteVisitAssessment?.dateTime?.type === LeadrequestDateTimeType.ASAP"
									>
										ASAP
									</ng-container>
								</div>
							</div>
						</ng-container>
					</div>
				</div>
				<div class="section-column">
					<h6 class="text-primary">Assessment Notes</h6>
					<div class="section-information">
						<div class="label">
							<ng-container *ngIf="leadRequest.requestType === LeadrequestType.ON_SITE_VISIT">
								<textarea class="form-control" readonly placeholder="Assessment Notes">{{
									leadRequest.onSiteVisitAssessment?.notes
								}}</textarea>
							</ng-container>
							<ng-container *ngIf="leadRequest.requestType === LeadrequestType.CALL">
								<textarea class="form-control" readonly placeholder="Assessment Notes">{{
									leadRequest.callAssessment?.callNotes
								}}</textarea>
							</ng-container>
						</div>
					</div>
				</div>
			</div>
		</app-accordion-item>

		<app-accordion-item itemId="scheduleItems" parentId="assessmentRequest">
			<div header>Schedule Items</div>
			<div body class="section-column">
				<div class="action-buttons">
					<button class="btn btn-sm btn-primary" type="button">
						<i class="bi bi-calendar-check"></i>
						<span class="btn-text">Open Scheduler</span>
					</button>
					<button class="btn btn-sm btn-primary" type="button" (click)="addCallAssessment()">
						<i class="bi bi-telephone-plus"></i>
						<span class="btn-text">Schedule Call</span>
					</button>
					<button class="btn btn-sm btn-primary" type="button" (click)="addOnSiteAssessment()">
						<i class="bi bi-building-add"></i>
						<span class="btn-text">Schedule On Site</span>
					</button>
				</div>
				<ng-container *ngIf="scheduledItems.length; else noScheduledItems">
					<ng-container *ngFor="let item of scheduledItems.controls; let i = index">
						<app-mobile-assessment-request-schedule-item
							[schedulerFormItem]="item"
							[index]="i"
							(assessmentRemove)="removeAssessment($event)"
						></app-mobile-assessment-request-schedule-item>
					</ng-container>
				</ng-container>
				<ng-template #noScheduledItems>
					<em>No assessments have been scheduled yet.</em>
				</ng-template>
			</div>
		</app-accordion-item>

		<app-accordion-item itemId="notes" parentId="assessmentRequest">
			<div header>Internal Notes</div>
			<div body class="section-column">
				<h6 class="text-primary">Internal Notes</h6>
				<app-textarea-control
					[resizable]="false"
					name="internalNotes"
					[formGroup]="internalNotesForm"
					label="Internal Notes"
					placeholder="Internal Notes"
				></app-textarea-control>
			</div>
		</app-accordion-item>
	</app-accordion>
</div>
