import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AbstractControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormControlTypes } from '@shared/models/form-controls';

@Component({
	selector: 'app-standard-group-control',
	standalone: true,
	imports: [CommonModule, FormsModule, ReactiveFormsModule],
	templateUrl: './standard-group-control.component.html',
	styleUrl: './standard-group-control.component.scss',
})
export class StandardGroupControlComponent<T extends { [K in keyof T]: AbstractControl }> {
	@Input() formGroup!: FormGroup<T>;
	@Input() type: FormControlTypes = 'text';
	@Input() name!: Extract<keyof T, string | number>;
	@Input() leftText?: string;
	@Input() rightText?: string;
	@Input() label: string;
	@Input() showLabel: boolean = true;
	@Input() placeholder: string;
	@Input() autocomplete?: string;
	@Input() patternMessage?: string = 'Invalid format';

	get control() {
		return this.formGroup.controls[this.name];
	}
}
