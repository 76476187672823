import { takeUntil } from 'rxjs';

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { IClientProperty, IClientWithProperties } from '@clients/models/firebase/client.model';
import { IImportClient, IImportClientForm, PropertySelectType } from '@leads/client-facing/models/domain/lead-request.domain';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DropdownControlComponent, IDropdownOption } from '@shared/components/forms/dropdown-control/dropdown-control.component';
import { RadioGroupControlComponent } from '@shared/components/forms/radio-group-control/radio-group-control.component';
import { IRadioOption } from '@shared/models/form-controls';
import { MobileNumberPipe } from '@shared/pipes/mobile-number.pipe';
import { PropertyPipe } from '@shared/pipes/property.pipe';

@Component({
	selector: 'app-import-client-modal',
	standalone: true,
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		DropdownControlComponent,
		MobileNumberPipe,
		PropertyPipe,
		RadioGroupControlComponent,
	],
	templateUrl: './import-client-modal.component.html',
	styleUrl: './import-client-modal.component.scss',
})
export class ImportClientModalComponent implements OnInit, OnDestroy {
	@Input() clients: Array<IClientWithProperties>;
	@Output() clientSelected = new EventEmitter<IImportClient>();

	formGroup = new FormGroup<IImportClientForm>({
		selectedClient: new FormControl<IClientWithProperties | null>(null, [Validators.required]),
		propertySelectType: new FormControl<PropertySelectType>(PropertySelectType.EXISTING, [Validators.required]),
		selectedClientProperty: new FormControl<IClientProperty | null>(null),
	});

	clientOptions: Array<IDropdownOption<IClientWithProperties>> = [];
	propertyTypeOptions: Array<IRadioOption> = [
		{
			label: 'Existing',
			value: PropertySelectType.EXISTING,
			id: 'existing',
		},
		{
			label: 'New',
			value: PropertySelectType.NEW,
			id: 'new',
		},
	];

	destroyed = new EventEmitter<void>();
	PropertySelectType = PropertySelectType;

	private activeModal = inject(NgbActiveModal);

	ngOnInit(): void {
		this.clientOptions = this.clients.map((c, index) => ({
			id: index + 1,
			value: c,
			label: c.clientName as string,
			icon: 'bi-person',
			object: c,
		}));

		this.formGroup.controls.selectedClient.valueChanges.pipe(takeUntil(this.destroyed)).subscribe((value) => {
			if (value?.properties.length) {
				this.setSelectedClientProperty(value?.properties[0]);
				this.propertySelectType.setValue(PropertySelectType.EXISTING);
				this.propertyTypeOptions[0].disabled = false;
			} else {
				this.setSelectedClientProperty(null);
				this.propertySelectType.setValue(PropertySelectType.NEW);
				this.propertyTypeOptions[0].disabled = true;
			}
		});

		this.formGroup.controls.propertySelectType.valueChanges.pipe(takeUntil(this.destroyed)).subscribe((value) => {
			if (value === PropertySelectType.NEW) {
				this.setSelectedClientProperty(null);
			}
			if (value === PropertySelectType.EXISTING) {
				this.setSelectedClientProperty(this.selectedClient.value?.properties[0]!);
			}
		});
	}

	ngOnDestroy(): void {
		this.destroyed.emit();
	}

	closeModal(): void {
		this.activeModal.close();
	}

	get selectedClient(): FormControl<IClientWithProperties | null> {
		return this.formGroup.controls.selectedClient;
	}

	get propertySelectType(): FormControl<PropertySelectType | null> {
		return this.formGroup.controls.propertySelectType;
	}

	get selectedClientProperty(): FormControl<IClientProperty | null> {
		return this.formGroup.controls.selectedClientProperty;
	}

	clientDropdownSearchFn(term: string, item: IDropdownOption) {
		term = term.toLowerCase();
		const clientWithProperties = item.object as IClientWithProperties;
		return (
			clientWithProperties.clientName.toLowerCase().indexOf(term) > -1 ||
			clientWithProperties.clientEmail.toLowerCase().indexOf(term) > -1 ||
			clientWithProperties.clientNumber.number.toLowerCase().indexOf(term) > -1
		);
	}

	setSelectedClientProperty(property: IClientProperty | null): void {
		this.formGroup.controls.selectedClientProperty.setValue(property);
	}

	submit(): void {
		if (this.formGroup.invalid) {
			return;
		}

		this.clientSelected.emit(this.formGroup.value as IImportClient);
		this.closeModal();
	}
}
