<div class="popover-container">
	<div class="popover-head">
		<div class="event-title">{{ event.title }}</div>
		<button type="button" class="btn-close" aria-label="Close"></button>
	</div>
	<div class="popover-body">
		<div class="event-detail-row">
			<div class="detail-value">Tuesday, November 5</div>
			<div class="detail-value">⋅</div>
			<div class="detail-value">8:00</div>
		</div>
		<div class="event-detail-col">
			<div class="detail-label">Customer</div>
			<div class="detail-value">Johnn Knox</div>
		</div>
		<div class="event-detail-col">
			<div class="detail-label">Person in charge</div>
			<div class="detail-value">Carl Rezelman</div>
		</div>
		<div class="event-detail-col">
			<div class="detail-label">Job Type</div>
			<div class="detail-value">Scheduled</div>
		</div>
		<div class="event-detail-col">
			<div class="detail-label">Job Status</div>
			<div class="detail-value">Scheduled</div>
		</div>
		<div class="event-detail-col">
			<div class="detail-label">Client Confirmation</div>
			<div class="detail-value">Confirmed</div>
		</div>
		<div class="event-detail-col">
			<div class="detail-label">Description</div>
			<div class="detail-value">Need to pick the kids up from school and do some random stuff.</div>
		</div>
	</div>
	<div class="popover-actions">
		<ng-container *ngFor="let action of event.actions">
			<button class="action-button btn btn-sm btn-primary"
					(click)="action.onClick({ event: event, sourceEvent: $event })"
			>
				<i class="action-icon bi {{ action.cssClass }}"></i>
				{{ action.label }}
			</button>
		</ng-container>
	</div>
</div>
