<ng-template
	#defaultTemplate
	let-days="days"
	let-locale="locale"
	let-dayHeaderClicked="dayHeaderClicked"
	let-eventDropped="eventDropped"
	let-trackByWeekDayHeaderDate="trackByWeekDayHeaderDate"
	let-dragEnter="dragEnter"
>
	<div class="cal-day-headers" role="row">
		<div
			class="cal-header"
			*ngFor="let day of days; trackBy: trackByWeekDayHeaderDate"
			[class.cal-past]="day.isPast"
			[class.cal-today]="day.isToday"
			[class.cal-future]="day.isFuture"
			[class.cal-weekend]="day.isWeekend"
			[ngClass]="day.cssClass"
			(mwlClick)="dayHeaderClicked.emit({ day: day, sourceEvent: $event })"
			mwlDroppable
			dragOverClass="cal-drag-over"
			(drop)="
            eventDropped.emit({
              event: $event.dropData.event,
              newStart: day.date
            })
          "
			(dragEnter)="dragEnter.emit({ date: day.date })"
			tabindex="0"
			role="columnheader"
		>
			<span class="column-header">{{ day.date | calendarDate : headerCalendarDateMethod : locale }}</span>
			<span class="column-sub-header">
				<span class="calendar-day-number">{{ day.date | calendarDate : 'weekViewColumnSubHeader' : locale }}</span>
			</span>
		</div>
	</div>
</ng-template>
<ng-template
	[ngTemplateOutlet]="defaultTemplate"
	[ngTemplateOutletContext]="{
        days: days,
        locale: locale,
        dayHeaderClicked: dayHeaderClicked,
        eventDropped: eventDropped,
        dragEnter: dragEnter,
        trackByWeekDayHeaderDate: trackByWeekDayHeaderDate
      }"
>
</ng-template>
