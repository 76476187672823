<div
	#toastElement
	class="toast fade toast-width mt-2"
	[ngClass]="'bg-' + toastEvent.type + '-subtle'"
	role="alert"
	aria-live="assertive"
	aria-atomic="true"
>
	<div class="toast-header">
		<strong class="me-auto" [ngClass]="'text-' + toastEvent.type">{{ toastEvent.title }}</strong>
		<button type="button" class="btn-close" aria-label="Close" (click)="hide()"></button>
	</div>
	<div class="toast-body text-black">
		{{ toastEvent.message }}
		<div [innerHTML]="toastEvent.innerHtml"></div>
		<button *ngIf="toastEvent.yes" type="button" class="btn btn-sm mx-1 btn-danger" (click)="toastEvent.yes(); hide()">Yes</button>
		<button *ngIf="toastEvent.no" type="button" class="btn btn-sm mx-1 btn-success" (click)="toastEvent.no(); hide()">No</button>
	</div>
</div>
