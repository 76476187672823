import { Injectable } from '@angular/core';
import { CalendarDateFormatter, CalendarView, DateFormatterParams } from 'angular-calendar';
import { formatDate } from '@angular/common';

// Week calendar headers
export const weekViewColumnHeader: string = 'weekViewColumnHeader';
export const weekViewColumnHeaderMobile: string = 'weekViewColumnHeaderMobile';

// Month calendar headers
export const monthViewColumnHeader: string = 'monthViewColumnHeader';
export const monthViewColumnHeaderMobile: string = 'monthViewColumnHeaderMobile';

// Calendar date titles for day, week, and month views
export const calendarViewTitle = (calendarView: CalendarView) => `${calendarView}ViewTitle`;
export const calendarViewTitleMobile = (calendarView: CalendarView) => `${calendarView}ViewTitleMobile`;

@Injectable()
export class CustomDateFormatterService extends CalendarDateFormatter {

	// Week calendar headers
	public override weekViewColumnHeader({ date, locale }: DateFormatterParams): string {
		return formatDate(date, 'EEEE', locale!);
	}

	public weekViewColumnHeaderMobile({ date, locale }: DateFormatterParams): string {
		return formatDate(date, 'EEEEE', locale!);
	}

	public override weekViewColumnSubHeader({ date, locale }: DateFormatterParams): string {
		return formatDate(date, 'd', locale!);
	}

	// Month calendar headers

	public override monthViewColumnHeader({ date, locale }: DateFormatterParams): string {
		return formatDate(date, 'EEEE', locale!);
	}

	public monthViewColumnHeaderMobile({ date, locale }: DateFormatterParams): string {
		return formatDate(date, 'EEEEE', locale!);
	}

	// Apples to calendarViewTitle and calendarViewTitleMobile using reflection

	public dayViewTitleMobile({ date, locale }: DateFormatterParams): string {
		return formatDate(date, 'LLLL', locale!);
	}

	public weekViewTitleMobile({ date, locale, weekStartsOn, excludeDays, daysInWeek, }: DateFormatterParams): string {
		return formatDate(date, 'LLLL', locale!);
	}

	public monthViewTitleMobile({ date, locale }: DateFormatterParams): string {
		return formatDate(date, 'LLLL', locale!);
	}
}
