<div class="leads-table-container">
	<div class="top-bar">
		<button class="btn btn-sm btn-primary" type="button">
			Add New Request
			<i class="bi bi-plus"> </i>
		</button>

		<app-search-control [control]="searchControl" placeholder="Search"></app-search-control>

		<app-dropdown-control
			[formGroup]="form"
			name="leadStatus"
			placeholder="Filter by status"
			[options]="leadStatusOptions"
			[multiple]="true"
			[clearable]="true"
		/>

		<app-radio-group-control [formGroup]="form" name="leadRequestType" [options]="leadRequestTypeOptions" />
	</div>

	<app-loading-state [isLoading]="isLoading" [loadedContent]="loadedContent"></app-loading-state>
	<ng-template #loadedContent>
		<div class="content">
			<div class="table-container">
				<ng-container *ngIf="leads.length === 0; else leadsTable">
					<div class="table-row">
						<div class="inner-row">
							<em class="fw-bold">
								No
								{{ leadStatus.value.join(', ') | titlecase }}
								leads.
							</em>
						</div>
					</div>
				</ng-container>
				<ng-template #leadsTable>
					<ng-container *ngFor="let lead of leads">
						<div class="section table-row cursor-p" (click)="onLeadItemClick(lead)">
							<div class="fs-5 fw-bolder inner-row">{{ lead.personalDetails.name }}</div>
							<div [ngSwitch]="lead.requestType" class="fw-lighter inner-row">
								<ng-container *ngSwitchCase="LeadRequestType.ON_SITE_VISIT">
									{{ lead.onSiteVisitAssessment?.serviceType }}
								</ng-container>
								<ng-container *ngSwitchCase="LeadRequestType.CALL">
									{{ lead.mobileNumber.number }}
								</ng-container>
							</div>
						</div>
					</ng-container>
				</ng-template>
			</div>
		</div>
	</ng-template>
</div>
