import { Timestamp } from '@angular/fire/firestore';

export type FormControlTypes = 'text' | 'email' | 'number';

export interface IRadioOption {
	label: string;
	value: string | null | Timestamp;
	id: string;
	statusIndicator?: 'red' | 'amber' | 'green' | 'blue';
	disabled?: boolean;
}

export interface ISelectOption {
	label: string;
	value: string;
	disabled?: boolean;
}

export function radioOptionsFromEnum<T>(o: { [s: string]: T } | ArrayLike<T>): Array<IRadioOption> {
	return Object.values<T>(o).map((key) => ({
		label: key as string,
		value: key as string,
		id: key as string,
	}));
}

export function selectOptionsFromEnum<T>(o: { [s: string]: T } | ArrayLike<T>): Array<ISelectOption> {
	return Object.values<T>(o).map((key) => ({
		label: key as string,
		value: key as string,
	}));
}
