import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { IOrganisation } from '@organisations/models/organisations';
import { IAvailableOrganisation } from '@organisations/models/organisation-user';
import { OrganisationRoutes } from '@organisations/organisation.routes';
import { OrganisationUserService } from '@organisations/services/organisation-user/organisation-user.service';
import { takeUntil } from 'rxjs';
import { AppRoutes } from 'src/app/app.routes';
import { OrganisationSelectedService } from '@organisations/services/organisation-selected/organisation-selected.service';
import { Offcanvas } from 'bootstrap';

@Component({
	selector: 'app-organisation-selector',
	standalone: true,
	imports: [CommonModule, RouterLink],
	templateUrl: './organisation-selector.component.html',
	styleUrl: './organisation-selector.component.scss',
})
export class OrganisationSelectorComponent implements OnInit, OnDestroy {
	@Input() isCollapsed: boolean;

	private organisationUserService = inject(OrganisationUserService);
	private organisationSelectedService = inject(OrganisationSelectedService);
	destroyed$ = new EventEmitter<void>();

	organisationMap: Map<string | undefined, IOrganisation> = new Map();
	availableOrganisations: Array<IAvailableOrganisation> = [];
	selectedOrganisation: IAvailableOrganisation | null = null;

	createOrganisationRoute = `${AppRoutes.ORGANISATION}/${OrganisationRoutes.CREATE}`;

	ngOnInit(): void {
		this.subscribeToSelectedOrganisationUser();
		this.subscribeToOrganisationUsers();
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}

	subscribeToOrganisationUsers(): void {
		this.organisationUserService
			.availableOrganisations()
			.pipe(takeUntil(this.destroyed$))
			.subscribe((availableOrganisations) => {
				if (!this.selectedOrganisation) {
					this.organisationSelectedService.setSelectedOrganisation(availableOrganisations[0]);
				}
				this.availableOrganisations = availableOrganisations;
			});
	}

	subscribeToSelectedOrganisationUser(): void {
		this.organisationSelectedService.selectedOrganisation.pipe(takeUntil(this.destroyed$)).subscribe((selectedOrganisation) => {
			this.selectedOrganisation = selectedOrganisation;
		});
	}

	selectOrganisation(availableOrganisation: IAvailableOrganisation): void {
		this.organisationSelectedService.setSelectedOrganisation(availableOrganisation);
		this.dismissOffcanvas();
	}

	dismissOffcanvas(): void {
		const mobileOffcanvas = Offcanvas.getInstance('#mobileNavbar');
		mobileOffcanvas?.hide();
	}
}
