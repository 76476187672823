<form [formGroup]="formGroup">
	<div class="form-floating">
		<input
			[type]="type"
			[formControlName]="name"
			class="form-control"
			[class.is-invalid]="control.invalid && control.touched"
			[id]="name"
			[placeholder]="placeholder"
			[autocomplete]="autocomplete"
		/>
		<label [for]="name">{{ label }}</label>

		<div *ngIf="control.invalid && control.errors" class="invalid-feedback">
			<p *ngIf="control.errors?.required">{{ label }} required.</p>
			<p *ngIf="control.errors?.email">Invalid email.</p>
			<p *ngIf="control.errors?.pattern">{{ patternMessage }}</p>
			<p *ngIf="control.errors?.minlength">{{ label }} must be at least {{ control.errors.minlength.requiredLength }} characters.</p>
			<p *ngIf="control.errors?.maxlength">{{ label }} must be less than {{ control.errors.maxlength.requiredLength }} characters.</p>
		</div>
	</div>
</form>
