import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { DesktopClientEditPropertyTableComponent } from '@clients/components/desktop-client-edit-property-table/desktop-client-edit-property-table.component';
import { DesktopClientDetailsComponent } from '@clients/components/forms/desktop-client-details/desktop-client-details.component';
import { MobileClientEditDetailsComponent } from '@clients/components/forms/mobile-client-edit-details/mobile-client-edit-details.component';
import { IClient, IClientForm, IClientProperty, IClientWithProperties } from '@clients/models/clients';
import { ClientsService } from '@clients/services/clients/clients.service';
import { LayoutService } from '@layout/services/layout/layout.service';
import { IAvailableOrganisation } from '@organisations/models/organisation-user';
import { OrganisationSelectedService } from '@organisations/services/organisation-selected/organisation-selected.service';
import { LoadingStateComponent } from '@shared/components/loading-state/loading-state.component';
import { PageTitleComponent } from '@shared/components/sections/page-title/page-title.component';
import { ToastTypes } from '@shared/models/toast';
import { ToastService } from '@shared/services/toast/toast.service';
import { takeUntil } from 'rxjs';
import { AppRoutes } from 'src/app/app.routes';

@Component({
	selector: 'app-client-view',
	standalone: true,
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		PageTitleComponent,
		RouterLink,
		DesktopClientDetailsComponent,
		DesktopClientEditPropertyTableComponent,
		MobileClientEditDetailsComponent,
		LoadingStateComponent,
	],
	templateUrl: './client-view.component.html',
	styleUrl: './client-view.component.scss',
})
export class ClientViewComponent implements OnInit, OnDestroy {
	private layoutService = inject(LayoutService);
	private clientsService = inject(ClientsService);
	private organisationSelectedService = inject(OrganisationSelectedService);
	private route = inject(ActivatedRoute);
	private router = inject(Router);
	private toastService = inject(ToastService);

	destroyed$ = new EventEmitter<void>();
	isMobileSize = true;
	isSaving = false;
	isLoading = true;

	selectedOrganisation: IAvailableOrganisation | null = null;
	clientId: string;
	client: IClient;
	clientForm: FormGroup<IClientForm>;
	clientProperties: Array<IClientProperty> = [];
	addedClientProperties: Array<IClientProperty> = [];
	edittedClientProperties: Array<IClientProperty> = [];

	AppRoutes = AppRoutes;

	ngOnInit(): void {
		this.layoutService.isMobileSize$.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
			this.isMobileSize = value;
		});

		this.organisationSelectedService.selectedOrganisation.pipe(takeUntil(this.destroyed$)).subscribe((selectedOrganisation) => {
			this.selectedOrganisation = selectedOrganisation;
		});

		this.clientForm = new FormGroup<IClientForm>({
			clientName: new FormControl(null, [Validators.required, Validators.minLength(2), Validators.maxLength(50)]),
			clientNumber: new FormControl(null, [
				Validators.required,
				Validators.minLength(10),
				Validators.maxLength(20),
				Validators.pattern(/^[0-9]{10,20}$/),
			]),
			clientEmail: new FormControl(null, [Validators.required, Validators.email, Validators.maxLength(50)]),
			internalNotes: new FormControl(null),
		});

		this.populateClient();
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}

	populateClient(): void {
		this.clientId = this.route.snapshot.params.clientId;

		if (!this.clientId) {
			this.router.navigate([AppRoutes.CLIENTS]);
		}

		this.clientsService
			.getClientWithProperties(this.clientId)
			.pipe(takeUntil(this.destroyed$))
			.subscribe((clientWithProperties: IClientWithProperties | null) => {
				if (clientWithProperties === null) {
					this.router.navigate([AppRoutes.CLIENTS]);
					return;
				}

				this.isLoading = false;

				const { properties, ...client } = clientWithProperties;

				const clientFormData = {
					clientName: client.clientName,
					clientNumber: client.clientNumber.number,
					clientEmail: client.clientEmail,
					internalNotes: client.internalNotes,
				};

				this.clientForm.patchValue(clientFormData);
				this.clientProperties = properties;
				this.client = client;
			});
	}

	saveClient(): void {
		if (this.clientForm.invalid) {
			return;
		}
		this.isSaving = true;

		const client: Partial<IClient> = {
			clientEmail: this.clientForm.controls.clientEmail.value as string,
			clientName: this.clientForm.controls.clientName.value as string,
			clientNameLower: this.clientForm.controls.clientName.value?.toLocaleLowerCase() as string,
			clientNumber: {
				countryCode: '+27',
				number: this.clientForm.controls.clientNumber.value as string,
			},
			internalNotes: this.clientForm.controls.internalNotes.value as string,
		};
		this.clientsService
			.update(this.clientId, client)
			.then(() => {
				this.isSaving = false;
				this.clientForm.markAsPristine();
				this.populateClient();
				this.toastService.showToast({
					title: `${this.clientForm.controls.clientName.value}`,
					message: 'Updated Client',
					type: ToastTypes.SUCCESS,
				});
			})
			.catch((error) => {
				this.isSaving = false;
				this.toastService.showToast({
					title: `${this.clientForm.controls.clientName.value}`,
					message: `Failed to update client. ${error.message}`,
					type: ToastTypes.ERROR,
				});
			});
	}

	propertyChange(): void {
		this.populateClient();
	}
}
