import { takeUntil } from 'rxjs';

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AuthService } from '@auth/services/auth.service';
import { ChangePasswordModalComponent } from '@settings/components/user/change-password-modal/change-password-modal.component';
import { AlertComponent } from '@shared/components/alerts/alert/alert.component';
import { StandardControlComponent } from '@shared/components/forms/standard-control/standard-control.component';
import { LoadingStateComponent } from '@shared/components/loading-state/loading-state.component';
import { PageTitleComponent } from '@shared/components/sections/page-title/page-title.component';
import { ToastTypes } from '@shared/models/toast';
import { ModalService } from '@shared/services/modal/modal.service';
import { ToastService } from '@shared/services/toast/toast.service';
import { IUser, IUserSettingsForm } from '@user/models/user';
import { UsersService } from '@user/services/users/users.service';

@Component({
	selector: 'app-settings-user',
	standalone: true,
	imports: [
		CommonModule,
		PageTitleComponent,
		FormsModule,
		ReactiveFormsModule,
		AlertComponent,
		LoadingStateComponent,
		StandardControlComponent,
	],
	templateUrl: './settings-user.component.html',
	styleUrl: './settings-user.component.scss',
})
export class SettingsUserComponent implements OnInit, OnDestroy {
	profileForm: FormGroup<IUserSettingsForm>;
	destroyed$ = new EventEmitter<void>();

	currentUser: IUser | null | undefined = null;
	signInProviders: Array<string> = [];

	isLoading = true;
	isSaving = false;

	private usersService = inject(UsersService);
	private toastService = inject(ToastService);
	private authService = inject(AuthService);
	private modalServcice = inject(ModalService);

	ngOnInit(): void {
		this.profileForm = new FormGroup<IUserSettingsForm>({
			displayName: new FormControl<string | null>(null, [Validators.required, Validators.minLength(2), Validators.maxLength(50)]),
			email: new FormControl<string | null>(
				{
					disabled: true,
					value: null,
				},
				[Validators.required, Validators.email, Validators.minLength(2), Validators.maxLength(50)],
			),
			phoneNumber: new FormControl<string | null | undefined>(null, [
				Validators.required,
				Validators.minLength(10),
				Validators.maxLength(20),
				Validators.pattern(/^[0-9]{10,20}$/),
			]),
		});

		this.subscribeToCurrentUser();
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}

	subscribeToCurrentUser(): void {
		this.usersService
			.getDocumentData(this.authService.currentUser?.uid!)
			.pipe(takeUntil(this.destroyed$))
			.subscribe((user) => {
				this.currentUser = user;
				this.signInProviders = this.authService.currentUser?.providerData.map((provider) => provider.providerId) || [];
				this.isLoading = false;

				this.resetForm();
			});
	}

	resetForm(): void {
		this.profileForm.patchValue({
			displayName: this.currentUser?.displayName,
			email: this.currentUser?.email,
			phoneNumber: this.currentUser?.phoneNumber?.number,
		});
	}

	handleUpdatePassword(): void {
		this.modalServcice.open(ChangePasswordModalComponent, false, 'lg');
	}

	async handleSave(): Promise<void> {
		if (this.profileForm.invalid) {
			return;
		}

		this.isSaving = true;

		try {
			await this.authService.updateUserProfile(this.profileForm.value.displayName!);

			let updateData: Partial<IUser> = {
				displayName: this.profileForm.value.displayName!,
				phoneNumber: {
					countryCode: '+27',
					number: this.profileForm.value.phoneNumber!,
				},
			};

			await this.usersService.update(this.currentUser!.uid, updateData);

			this.toastService.showToast({
				title: 'Profile Updated',
				message: 'Your profile has been updated successfully.',
				type: ToastTypes.SUCCESS,
			});

			this.isSaving = false;
			this.profileForm.markAsPristine();
			this.profileForm.markAsUntouched();
		} catch (error) {
			this.isSaving = false;
		}
	}

	handleCancel(): void {
		this.resetForm();
		this.profileForm.markAsPristine();
		this.profileForm.markAsUntouched();
	}
}
