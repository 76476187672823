import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { IUserSignUp, IUserSignUpForm } from '@auth/models/signup';
import { AuthService } from '@auth/services/auth.service';
import { GoogleSigninComponent } from '@auth/components/google-signin/google-signin.component';
import { RouterLink } from '@angular/router';
import { AuthRoutes } from '@auth/auth.routes';
import { MatchValidator } from '@shared/classes/match-validator';
import { StandardControlComponent } from '@shared/components/forms/standard-control/standard-control.component';
import { PasswordControlComponent } from '@shared/components/forms/password-control/password-control.component';

@Component({
	selector: 'app-signup',
	standalone: true,
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		GoogleSigninComponent,
		RouterLink,
		StandardControlComponent,
		PasswordControlComponent,
	],
	templateUrl: './signup.component.html',
	styleUrl: './signup.component.scss',
	host: {
		class: 'h-100',
	},
})
export class SignupComponent {
	authService = inject(AuthService);
	AuthRoutes = AuthRoutes;

	isLoading: boolean = false;

	form: FormGroup<IUserSignUpForm> = new FormGroup<IUserSignUpForm>(
		{
			email: new FormControl(null, [Validators.required, Validators.email]),
			password: new FormControl(null, [Validators.required, Validators.minLength(8)]),
			confirmPassword: new FormControl(null, [Validators.required]),
			fullName: new FormControl(null, [Validators.required, Validators.minLength(2)]),
		},
		[MatchValidator.match<IUserSignUpForm>('password', 'confirmPassword')],
	);

	handleSignUp(): void {
		if (this.form.invalid) {
			return;
		}
		this.isLoading = true;
		this.authService
			.signUpWithEmailAndPassword(this.form.value as IUserSignUp)
			.then((result) => {
				this.isLoading = false;
			})
			.catch((error) => {
				this.isLoading = false;
			});
	}
}
