<div class="step-container">
	<div class="content">
		<app-lead-request-type [form]="leadRequestForm"></app-lead-request-type>
	</div>

	<div class="footer">
		<button type="button" class="btn btn-sm btn-light btn-width" [routerLink]="'../' + CustomerLeadRequestRoutes.NEW">
			<i class="bi bi-arrow-left"></i>
			Previous
		</button>
		<button
			type="button"
			class="btn btn-sm btn-primary btn-width"
			[routerLink]="'../' + CustomerLeadRequestRoutes.PERSONAL_DETAILS"
			[disabled]="leadRequestForm.controls.requestType.invalid"
		>
			Next
			<i class="bi bi-arrow-right"></i>
		</button>
	</div>
</div>
