import { Observable, Subject } from 'rxjs';

import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Toast } from '@capacitor/toast';
import { ToastEvent } from '@shared/models/toast';

@Injectable({
	providedIn: 'root',
})
export class ToastService {
	toastEvents$: Observable<ToastEvent>;
	private toastEvents = new Subject<ToastEvent>();

	constructor() {
		this.toastEvents$ = this.toastEvents.asObservable();
	}
	/**
	 * Show toast notification.
	 * @param toastEvent Toast Event Object
	 */
	showToast(toastEvent: ToastEvent): void {
		if (Capacitor.isNativePlatform() && !toastEvent.yes && !toastEvent.no) {
			Toast.show({
				text: toastEvent.message,
				duration: 'short',
			});
		} else {
			this.toastEvents.next(toastEvent);
		}
	}
}
