import { filter, take, takeUntil } from 'rxjs';

import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DocumentReference } from '@angular/fire/firestore';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AppRoutes } from '@app/app.routes';
import { ClientsRoutes } from '@clients/clients.routes';
import { EditPropertyModalComponent } from '@clients/components/modals/edit-property-modal/edit-property-modal.component';
import { IClientProperty, IClientWithProperties } from '@clients/models/firebase/client.model';
import { ClientsService } from '@clients/services/clients/clients.service';
import { ScheduledAssessmentEmployeeIdChange, ScheduledAssessmentNotesChange, ScheduledAssessmentStatusChange } from '@leads/client-facing/classes/base-scheduled-item-detail.component';
import { LeadOnSiteVisitDateTimeType, LeadRequestType, LeadStatus } from '@leads/shared/models/domain/lead-request.domain';
import { ScheduledAssessment, ScheduledAssessmentStatus } from '@leads/shared/models/domain/scheduled-assessment.domain';
import { ILeadRequest } from '@leads/shared/models/firebase/lead-request.model';
import { LeadAssessmentService } from '@leads/shared/services/lead-assessment/lead-assessment.service';
import { LeadRequestService } from '@leads/shared/services/lead-request/lead-request.service';
import { IOrganisationUserWithUserName } from '@organisations/models/organisation-user';
import { IOrganisation } from '@organisations/models/organisations';
import { OrganisationSelectedService } from '@organisations/services/organisation-selected/organisation-selected.service';
import { OrganisationUserService } from '@organisations/services/organisation-user/organisation-user.service';
import { OrganisationService } from '@organisations/services/organisation/organisation.service';
import { convertBusinessHoursToDisabledDaysOfTheWeek, IDisabledDaysOfTheWeek } from '@shared/components/calendar/models';
import { IDropdownOption } from '@shared/components/forms/dropdown-control/dropdown-control.component';
import { ConfirmationModalComponent } from '@shared/components/modals/confirmation-modal/confirmation-modal.component';
import { AlertTypes } from '@shared/models/alert';
import { ModalService } from '@shared/services/modal/modal.service';
import { WhatsappService } from '@shared/services/whatsapp/whatsapp.service';

import { LeadAddPropertyModalComponent } from '../components/lead-add-property-modal/lead-add-property-modal.component';
import { ILeadAddProperty, ILeadRequestNotesForm, PropertySelectType } from '../models/domain/lead-request.domain';

export interface NewScheduledItemActivity {
	open: boolean;
	requestType: LeadRequestType | null;
}

@Component({
	template: '',
})
export abstract class BaseLeadRequestDetail implements OnInit, OnDestroy {
	@Input() leadRequest: ILeadRequest;
	@Input() client: IClientWithProperties;
	@Input() scheduledItems: Array<ScheduledAssessment>;
	@Input() internalNotesForm: FormGroup<ILeadRequestNotesForm>;
	@Input() readonlyView = false;

	@Output() newScheduledItemActivityChanged = new EventEmitter<NewScheduledItemActivity>();
	@Output() newScheduledItemCreated = new EventEmitter<void>();

	destroyed$ = new EventEmitter<void>();

	protected readonly AlertTypes = AlertTypes;
	protected readonly LeadStatus = LeadStatus;

	protected readonly LeadRequestType = LeadRequestType;
	protected readonly LeadRequestDateTimeType = LeadOnSiteVisitDateTimeType;
	protected readonly ScheduledAssessmentStatus = ScheduledAssessmentStatus;
	protected readonly AppRoutes = AppRoutes;

	protected router = inject(Router);
	protected modalService = inject(ModalService);

	protected organisationService = inject(OrganisationService);
	protected organisationUserService = inject(OrganisationUserService);
	protected organisationSelectedService = inject(OrganisationSelectedService);
	protected leadAssessmentService = inject(LeadAssessmentService);
	protected leadRequestService = inject(LeadRequestService);
	protected whatsAppService = inject(WhatsappService);
	protected clientService = inject(ClientsService);

	selectedOrganisationRef: DocumentReference<IOrganisation>;
	activeOrganisationUsers: Array<IOrganisationUserWithUserName> = [];
	employees: Array<IDropdownOption> = [];
	serviceTypes: Array<IDropdownOption> = [];
	disabledDaysOfTheWeek: IDisabledDaysOfTheWeek;

	newScheduledItemActivity: NewScheduledItemActivity = { open: false, requestType: null };

	isSavingNotes: boolean = false;

	ngOnInit(): void {
		this.subscribeToSelectedOrganisation();
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}

	openClientWhatsApp() {
		this.whatsAppService.startChatWithContact({
			contactName: this.client.clientName,
			contactNumber: this.client.clientNumber,
			openingText: true,
		});
	}

	viewClient(): void {
		this.router.navigate([`${AppRoutes.CLIENTS}/${ClientsRoutes.VIEW.replace(':clientId', this.client.id as string)}`]);
	}

	subscribeToSelectedOrganisation(): void {
		this.organisationSelectedService.selectedOrganisation
			.pipe(
				takeUntil(this.destroyed$),
				filter((o) => o !== null),
			)
			.subscribe((selectedOrg) => {
				if (selectedOrg) {
					this.selectedOrganisationRef = selectedOrg.organisation as DocumentReference<IOrganisation>;
					this.fetchOrganisationUsers(selectedOrg.organisation as DocumentReference<IOrganisation>);
					this.fetchOrganisation(selectedOrg.organisation as DocumentReference<IOrganisation>);
				}
			});
	}

	fetchOrganisationUsers(organisationRef: DocumentReference<IOrganisation>): void {
		this.organisationUserService
			.activeUsersByOrganisationRef(organisationRef)
			.pipe(takeUntil(this.destroyed$))
			.subscribe((organisationUsers) => {
				this.activeOrganisationUsers = organisationUsers;
				this.employees = organisationUsers.map((u, index) => ({
					id: index + 1,
					value: u.userId,
					label: u.userDisplayName as string,
					icon: 'bi-person',
				}));
			});
	}

	fetchOrganisation(organisationRef: DocumentReference<IOrganisation>): void {
		this.organisationService
			.getOrganisation(organisationRef)
			.pipe(takeUntil(this.destroyed$))
			.subscribe((organisation) => {
				if (organisation) {
					this.disabledDaysOfTheWeek = convertBusinessHoursToDisabledDaysOfTheWeek(organisation.settings.businessHours);
					this.serviceTypes = organisation.settings.serviceTypes.map((s, index) => ({
						id: index + 1,
						value: s,
						label: s,
						icon: 'bi-wrench',
					}));
				}
			});
	}

	addProperty(): void {
		const modalRef = this.modalService.open(LeadAddPropertyModalComponent, false, 'md');
		modalRef.componentInstance.clientProperties = this.client.properties;
		modalRef.componentInstance.propertySelected.pipe(take(1)).subscribe((propertySelected: ILeadAddProperty) => {
			this.leadRequestService.updateInstallationAddress(this.leadRequest.id!, propertySelected.selectedClientProperty);
			if (propertySelected.propertySelectType === PropertySelectType.NEW) {
				this.clientService.addClientProperty(this.client.id!, propertySelected.selectedClientProperty);
			}
		});
	}

	editProperty(): void {
		const modalRef = this.modalService.open(EditPropertyModalComponent, false, 'md');
		modalRef.componentInstance.clientProperty = this.leadRequest.personalDetails.installationAddress;
		modalRef.componentInstance.showRemove = false;
		modalRef.componentInstance.propertySelected.pipe(take(1)).subscribe((property: IClientProperty) => {
			delete property.id;
			this.leadRequestService.updateInstallationAddress(this.leadRequest.id!, property);
		});
	}

	addCallAssessment(): void {
		this.setActiveScheduledItem({ open: true, requestType: LeadRequestType.CALL });
	}

	addOnSiteAssessment(): void {
		if (!this.leadRequest.personalDetails.installationAddress.fullAddress) {
			const modalRef = this.modalService.open(LeadAddPropertyModalComponent, false, 'md');
			modalRef.componentInstance.clientProperties = this.client.properties;
			modalRef.componentInstance.showContextAlert = true;
			modalRef.componentInstance.propertySelected.pipe(take(1)).subscribe((propertySelected: ILeadAddProperty) => {
				this.leadRequestService.updateInstallationAddress(this.leadRequest.id!, propertySelected.selectedClientProperty);
				if (propertySelected.propertySelectType === PropertySelectType.NEW) {
					this.clientService.addClientProperty(this.client.id!, propertySelected.selectedClientProperty);
				}
				this.setActiveScheduledItem({ open: true, requestType: LeadRequestType.ON_SITE_VISIT });
			});
		} else {
			this.setActiveScheduledItem({ open: true, requestType: LeadRequestType.ON_SITE_VISIT });
		}
	}

	protected addAssessment(assessment: ScheduledAssessment): void {
		this.scheduledItems.unshift(assessment);
		this.newScheduledItemCreated.emit();
	}

	removeAssessment(assessment: ScheduledAssessment | null): void {
		if (!assessment) {
			this.clearActiveScheduledItem();
			return;
		}

		const modalRef = this.modalService.open(ConfirmationModalComponent, false, 'md');
		modalRef.componentInstance.title = 'Are you sure you want to remove this scheduled item?';
		modalRef.componentInstance.contentText = 'This cannot be undone.';
		modalRef.componentInstance.confirmAction.pipe(take(1)).subscribe(() => {
			this.leadAssessmentService.delete(assessment.id as string).then(() => {
				this.scheduledItems = this.scheduledItems.filter((item) => item.id !== assessment.id);
			});
		});
	}

	setActiveScheduledItem(activity: NewScheduledItemActivity): void {
		this.newScheduledItemActivity = activity;
		this.newScheduledItemActivityChanged.emit(activity);
	}

	clearActiveScheduledItem(): void {
		this.setActiveScheduledItem({ open: false, requestType: null });
	}

	cancelAssessment(assessment: ScheduledAssessment): void {
		const modalRef = this.modalService.open(ConfirmationModalComponent, false, 'md');
		modalRef.componentInstance.title = 'Are you sure you want to cancel this scheduled item?';
		modalRef.componentInstance.contentText = 'This can be retrieved later.';
		modalRef.componentInstance.confirmAction.pipe(take(1)).subscribe(() => {
			assessment.status = ScheduledAssessmentStatus.CANCELLED;
			this.leadAssessmentService.updateScheduledAssessmentStatus(assessment.id as string, ScheduledAssessmentStatus.CANCELLED);
		});
	}

	statusChanged($event: ScheduledAssessmentStatusChange) {
		this.leadAssessmentService.updateScheduledAssessmentStatus($event.assessmentId, $event.status);
	}

	employeeIdChanged($event: ScheduledAssessmentEmployeeIdChange) {
		this.leadAssessmentService.updateScheduledAssessmentEmployee($event.assessmentId, $event.employeeId);
	}

	assessmentNotesChanged($event: ScheduledAssessmentNotesChange) {
		this.isSavingNotes = true;
		this.leadAssessmentService.updateScheduledAssessmentNotes($event.assessmentId, $event.assessmentNotes).finally(() => {
			this.isSavingNotes = false;
		});
	}
}
