import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-page-subtitle',
	standalone: true,
	imports: [],
	templateUrl: './page-subtitle.component.html',
	styleUrl: './page-subtitle.component.scss',
})
export class PageSubtitleComponent {
	@Input() title: string;
}
