import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ILeadRequestForm } from '@leads/customer-facing/models/lead-request';
import { LeadRequestType } from '@leads/shared/models/lead.models';
import { AlertComponent } from '@shared/components/alerts/alert/alert.component';
import { RadioGroupControlComponent } from '@shared/components/forms/radio-group-control/radio-group-control.component';
import { HeaderPopoverComponent } from '@shared/components/sections/header-popover/header-popover.component';
import { IRadioOption } from '@shared/models/form-controls';
import { IPopover } from '@shared/models/popover';

@Component({
	selector: 'app-lead-request-type',
	standalone: true,
	imports: [CommonModule, FormsModule, ReactiveFormsModule, HeaderPopoverComponent, AlertComponent, RadioGroupControlComponent],
	templateUrl: './lead-request-type.component.html',
	styleUrl: './lead-request-type.component.scss',
})
export class LeadRequestTypeComponent {
	@Input() form: FormGroup<ILeadRequestForm>;

	LeadRequestType = LeadRequestType;

	infoPopOver: IPopover = {
		triggerText: {
			desktop: "What's this?",
			mobile: "What's this?",
		},
		title: 'Request Type',
		description:
			'Choose On-Site to schedule in a visit from one of our specialists, or Call Back for a phone consultation to discuss your issue.',
		icon: 'bi-info-circle-fill',
	};

	radioOptions: Array<IRadioOption> = [
		{ label: 'On-Site Service Request', value: LeadRequestType.ON_SITE_VISIT, id: 'onsite' },
		{ label: 'Call Back Request', value: LeadRequestType.CALL, id: 'call' },
	];

	get requestType(): FormControl {
		return this.form.controls.requestType;
	}

	get alertContent(): string {
		switch (this.requestType.value) {
			case LeadRequestType.CALL:
				return 'Prefer to chat first? Request a call from our specialist to discuss your issue and find out if you need an on-site visit.';
			case LeadRequestType.ON_SITE_VISIT:
				return "Need hands-on support? Request an on-site visit, and we'll schedule a time to fix your issue directly at your location.";
			default:
				return '';
		}
	}
}
