<div class="modal-header">
	<h5 class="modal-title">Edit User</h5>
	<button type="button" class="btn-close" (click)="closeModal()" aria-label="Close"></button>
</div>
<div class="modal-body">
	<form [formGroup]="editUserForm" class="form-body">
		<app-radio-group-control [formGroup]="editUserForm" name="accessLevel" [options]="radioOptions" />

		<app-alert [content]="alertContent"></app-alert>
	</form>
</div>
<div class="modal-footer">
	<div class="button-group">
		<ng-container *ngIf="organisationUser.status !== OrganisationMemberStatus.CURRENTLY_LOGGED_IN">
			<button type="button" class="btn btn-outline-danger" (click)="removeOrganisationUserAction()">Remove</button>
		</ng-container>
		<ng-container *ngIf="organisationUser.status === OrganisationMemberStatus.INVITATION_PENDING">
			<button type="button" class="btn btn-outline-primary" (click)="resendInvitation()">Resend Invitation</button>
		</ng-container>
	</div>
	<button type="button" [disabled]="editUserForm.invalid" class="btn btn-primary btn-width" (click)="submit()" data-id="submit">
		<ng-container *ngIf="isLoading; else notLoading">
			<div class="spinner-border spinner-border-sm" role="status">
				<span class="visually-hidden">Loading...</span>
			</div>
		</ng-container>
		<ng-template #notLoading> Save </ng-template>
	</button>
</div>
