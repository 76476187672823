import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AppRoutes } from '@app/app.routes';
import { AuthRoutes } from '@auth/auth.routes';
import { ContentMarketingComponent } from '@auth/components/content-marketing/content-marketing.component';
import { PolicyFooterComponent } from '@auth/components/policy-footer/policy-footer.component';

@Component({
	selector: 'app-home',
	standalone: true,
	imports: [CommonModule, ContentMarketingComponent, RouterLink, PolicyFooterComponent],
	templateUrl: './home.component.html',
	styleUrl: './home.component.scss',
})
export class HomeComponent {
	AppRoutes = AppRoutes;
	AuthRoutes = AuthRoutes;
}
