import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
	IOrganisationUserEditForm,
	IOrganisationUserFull,
	OrganisationMemberStatus,
	OrganisationUserAccessLevel,
} from '@organisations/models/organisation-user';
import { IOrganisation } from '@organisations/models/organisations';
import { OrganisationUserService } from '@organisations/services/organisation-user/organisation-user.service';
import { AlertComponent } from '@shared/components/alerts/alert/alert.component';
import { ConfirmationModalComponent } from '@shared/components/modals/confirmation-modal/confirmation-modal.component';
import { RadioGroupControlComponent } from '@shared/components/forms/radio-group-control/radio-group-control.component';
import { IRadioOption } from '@shared/models/form-controls';
import { ToastTypes } from '@shared/models/toast';
import { ModalService } from '@shared/services/modal/modal.service';
import { ToastService } from '@shared/services/toast/toast.service';
import { take } from 'rxjs';

@Component({
	selector: 'app-edit-user-modal',
	standalone: true,
	imports: [CommonModule, FormsModule, ReactiveFormsModule, AlertComponent, RadioGroupControlComponent],
	templateUrl: './edit-user-modal.component.html',
	styleUrl: './edit-user-modal.component.scss',
})
export class EditUserModalComponent implements OnInit, OnDestroy {
	@Input() organisationUser: IOrganisationUserFull;
	@Input() organisation: IOrganisation;

	private activeModal = inject(NgbActiveModal);
	private toastService = inject(ToastService);
	private modalService = inject(ModalService);
	private organisationUserService = inject(OrganisationUserService);

	destroyed$ = new EventEmitter<void>();
	isLoading = false;

	editUserForm: FormGroup<IOrganisationUserEditForm>;
	OrganisationMemberStatus = OrganisationMemberStatus;

	radioOptions: Array<IRadioOption> = [
		{ label: 'Owner', value: OrganisationUserAccessLevel.OWNER, id: 'owner' },
		{ label: 'Employee', value: OrganisationUserAccessLevel.EMPLOYEE, id: 'employee' },
	];

	ngOnInit(): void {
		this.editUserForm = new FormGroup<IOrganisationUserEditForm>({
			accessLevel: new FormControl(this.organisationUser.accessLevel, {
				nonNullable: true,
				validators: [Validators.required],
			}),
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}

	get accessLevel(): FormControl {
		return this.editUserForm.controls.accessLevel;
	}

	closeModal(): void {
		this.activeModal.close();
	}

	submit(): void {
		if (this.editUserForm.invalid) {
			return;
		}

		this.isLoading = true;

		if (this.organisationUser.id) {
			this.organisationUserService
				.updateUserAccessLevel(
					this.organisation.id as string,
					this.organisationUser.id,
					this.editUserForm.controls.accessLevel.value,
				)
				.then((result) => {
					if (result) {
						this.toastService.showToast({
							title: 'Edit User',
							message: `Updated user's organisation permissions.`,
							type: ToastTypes.SUCCESS,
						});
					} else {
						this.toastService.showToast({
							title: 'Edit User Error',
							message: `At least one member has to have the role of Owner.`,
							type: ToastTypes.ERROR,
						});
					}
					this.isLoading = false;
					this.closeModal();
				})
				.catch((error: any) => {
					this.isLoading = false;
					this.toastService.showToast({
						title: 'Edit User Error',
						message: `Failed to edit user: ${error.message}`,
						type: ToastTypes.ERROR,
					});
				});
		}
	}

	removeOrganisationUserAction(): void {
		const modalRef = this.modalService.open(ConfirmationModalComponent, false, 'md');
		modalRef.componentInstance.title = 'Are you sure you want to remove this member from your organisation?';
		modalRef.componentInstance.contentText = this.organisationUser.email;
		modalRef.componentInstance.confirmAction.pipe(take(1)).subscribe(() => {
			if (this.organisationUser.id) {
				this.organisationUserService.removeUserFromOrganisation(this.organisationUser.id);
			}
		});
	}

	resendInvitation(): void {
		if (this.organisationUser.email && this.organisationUser.id) {
			this.organisationUserService
				.resendOrganisationInvitation(this.organisationUser.id, this.organisationUser.email, this.organisation.companyName)
				.then((result) => {
					this.toastService.showToast({
						title: 'Organisation Invitation',
						message: `Resent invitation to ${this.organisationUser.email}.`,
						type: ToastTypes.INFO,
					});
				});
		}
	}

	get alertContent(): string {
		switch (this.accessLevel.value) {
			case OrganisationUserAccessLevel.OWNER:
				return 'Owners have full access to all functionalities of ServeOx. They are able to add new users to the platform.';
			case OrganisationUserAccessLevel.EMPLOYEE:
				return "The Employee user role is designed for company staff using ServeOx's platform. Employees cannot add users, view or edit settings, or access leads.";
			default:
				return '';
		}
	}
}
