import { Component, inject } from '@angular/core';
import { PageSubtitleComponent } from '@shared/components/sections/page-subtitle/page-subtitle.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DesktopAssessmentRequestScheduleItemComponent } from '@leads/client-facing/components/desktop-assessment-request-schedule-item/desktop-assessment-request-schedule-item.component';
import { CommonModule } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { AppRoutes } from '@app/app.routes';
import { ClientsRoutes } from '@clients/clients.routes';
import { NgbDateToDatePipe } from '@shared/pipes/ngb-date-to-date.pipe';
import { TextareaControlComponent } from '@shared/components/forms/textarea-control/textarea-control.component';
import { AssessmentRequestScheduler } from '@leads/client-facing/classes/assessment-request-scheduler';

@Component({
	selector: 'app-desktop-assessment-request-details',
	standalone: true,
	imports: [
		PageSubtitleComponent,
		FormsModule,
		ReactiveFormsModule,
		DesktopAssessmentRequestScheduleItemComponent,
		CommonModule,
		RouterLink,
		NgbDateToDatePipe,
		TextareaControlComponent,
	],
	templateUrl: './desktop-assessment-request-details.component.html',
	styleUrl: './desktop-assessment-request-details.component.scss',
})
export class DesktopAssessmentRequestDetailsComponent extends AssessmentRequestScheduler {
	private router = inject(Router);

	AppRoutes = AppRoutes;
	ClientRoutes = ClientsRoutes;

	viewClient(): void {
		this.router.navigate([`${AppRoutes.CLIENTS}/${ClientsRoutes.VIEW.replace(':clientId', this.client.id as string)}`]);
	}
}
