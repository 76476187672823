import { Routes } from '@angular/router';
import { LeadAssessmentRequestComponent } from '@leads/client-facing/pages/lead-assessment-request/lead-assessment-request.component';
import { LeadsDashboardComponent } from '@leads/client-facing/pages/leads-dashboard/leads-dashboard.component';

import { LeadCreateComponent } from './pages/lead-create/lead-create.component';

export enum ClientLeadsRoutes {
	DASHBOARD = '',
	CREATE = 'create',
	ASSESSMENT_REVIEW = ':leadRequestId/assessment-request',
}

export const clientFacingLeadsRoutes: Routes = [
	{
		path: ClientLeadsRoutes.DASHBOARD,
		component: LeadsDashboardComponent,
		title: 'ServeOx | Leads',
	},
	{
		path: ClientLeadsRoutes.CREATE,
		component: LeadCreateComponent,
		title: 'ServeOx | Create Lead',
	},
	{
		path: ClientLeadsRoutes.ASSESSMENT_REVIEW,
		component: LeadAssessmentRequestComponent,
		title: 'ServeOx | Assessment Request',
	},
];
