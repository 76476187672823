import { DocumentReference, Timestamp } from '@angular/fire/firestore';
import { FireStoreDocument } from '@shared/models/firestore';
import { FormControl } from '@angular/forms';

export interface IOrganisationUser extends FireStoreDocument {
	user?: DocumentReference;
	organisation: DocumentReference;
	accessLevel: OrganisationUserAccessLevel;
	email: string;
	status: OrganisationMemberStatus;
	createdDate: Timestamp;
	updatedDate: Timestamp;
	joinDate: Timestamp | null;
	removeDate: Timestamp | null;
}

export enum OrganisationUserAccessLevel {
	EMPLOYEE = 'employee',
	OWNER = 'owner',
}

export interface IAvailableOrganisation extends IOrganisationUser {
	companyName: string | undefined;
}

export interface IOrganisationUserWithUserName extends IOrganisationUser {
	userDisplayName: string | null | undefined;
}

export enum OrganisationMemberStatus {
	CURRENTLY_LOGGED_IN = 'currently logged in',
	INVITATION_PENDING = 'invitation pending',
	ACTIVE = 'active',
	DECLINED = 'declined',
	REMOVED = 'removed',
}

export interface IOrganisationInvitationCreateForm {
	email: FormControl<string | null>;
	accessLevel: FormControl<OrganisationUserAccessLevel | null>;
}

export interface IOrganisationUserEditForm {
	accessLevel: FormControl<OrganisationUserAccessLevel>;
}

export interface IOrganisationUserFull extends IOrganisationUser {
	displayName?: string;
	photoUrl?: string;
}

export interface IOrganisationBill {
	invoices: Array<IOrganisationBilableUser>;
	totalAmount: number;
}

export interface IOrganisationBilableUser {
	email: string;
	billingAmount: number;
	joinDate: Date | undefined;
	removeDate: Date | undefined;
	daysActive: number;
	status: OrganisationMemberStatus;
}
