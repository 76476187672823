import { Component } from '@angular/core';

@Component({
	selector: 'app-settings-user',
	standalone: true,
	imports: [],
	templateUrl: './settings-user.component.html',
	styleUrl: './settings-user.component.scss',
})
export class SettingsUserComponent {}
