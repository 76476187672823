import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IInstallationAddressForm } from '@leads/customer-facing/models/lead-request';
import { IOrganisation } from '@organisations/models/organisations';
import { StandardControlComponent } from '@shared/components/forms/standard-control/standard-control.component';
import { GooglePlacesAutocompleteComponent } from '@shared/components/forms/google-places-autocomplete/google-places-autocomplete.component';
import { HeaderPopoverComponent } from '@shared/components/sections/header-popover/header-popover.component';
import { IPopover } from '@shared/models/popover';
import { IGoogleProperty } from '@shared/models/property';

@Component({
	selector: 'app-lead-address-information',
	standalone: true,
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		GooglePlacesAutocompleteComponent,
		HeaderPopoverComponent,
		StandardControlComponent,
	],
	templateUrl: './lead-address-information.component.html',
	styleUrl: './lead-address-information.component.scss',
	host: {
		class: 'h-100',
	},
})
export class LeadAddressInformationComponent {
	@Input() form: FormGroup<IInstallationAddressForm>;
	@Input() organisation: IOrganisation;

	get fullAddress(): FormControl {
		return this.form.controls.fullAddress;
	}

	get country(): FormControl {
		return this.form.controls.country;
	}

	get province(): FormControl {
		return this.form.controls.province;
	}

	get city(): FormControl {
		return this.form.controls.city;
	}

	get postalCode(): FormControl {
		return this.form.controls.postalCode;
	}

	onPlaceSelected(property: IGoogleProperty) {
		this.form.patchValue(property);
	}

	infoPopOver: IPopover = {
		triggerText: {
			desktop: "What's this?",
			mobile: "What's this?",
		},
		title: 'Installation Address',
		description: 'Please enter in the address where the specialist should visit for your On-Site service Request.',
		icon: 'bi-info-circle-fill',
	};
}
