import {
	CalendarCommonModule,
	CalendarDayModule,
	CalendarEvent,
	CalendarEventTimesChangedEvent,
	CalendarMonthModule,
	CalendarWeekModule,
} from 'angular-calendar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatePipe, LowerCasePipe, NgIf, NgStyle } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { LayoutService, ScreenSize } from '@layout/services/layout/layout.service';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { CalendarPopoverComponent } from '@schedule/lib/components/calendar-popover/calendar-popover.component';
import { CalendarWeekHeadersComponent } from '@schedule/lib/components/calendar-week-headers/calendar-week-headers.component';
import { CalendarPopoverServiceService } from '@schedule/lib/services/calendar-popover-service/calendar-popover-service.service';
import { ScheduledEvent } from '@schedule/models/calendar/calendar.model';
import { CalendarSettings, CalendarSettingsService } from '@schedule/services/calendar/calendar-settings/calendar-settings.service';

@Component({
	selector: 'app-calendar-week-view',
	standalone: true,
	imports: [
		CalendarWeekModule,
		CalendarCommonModule,
		CalendarPopoverComponent,
		NgIf,
		NgStyle,
		DatePipe,
		LowerCasePipe,
		NgbPopover,
		CalendarWeekHeadersComponent,
		CalendarMonthModule,
		CalendarDayModule,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,

	templateUrl: './calendar-week-view.component.html',
	styleUrl: './calendar-week-view.component.scss',
})
export class CalendarWeekViewComponent implements OnInit, OnDestroy, AfterViewInit {
	protected screenSize: ScreenSize;
	protected calendarSettings: CalendarSettings;

	@Input() isOwner: boolean = false;
	@Input() events: CalendarEvent[];
	@Input() refresh: Subject<void>;
	@Input() viewDate: Date;
	@Output() eventClicked = new EventEmitter<CalendarEvent>();
	@Output() eventTimesChanged = new EventEmitter<CalendarEventTimesChangedEvent>();

	private calendarSettingsService = inject(CalendarSettingsService);
	private layoutService = inject(LayoutService);
	private popupService = inject(CalendarPopoverServiceService);

	private destroyed$ = new EventEmitter<void>();
	ngOnInit() {
		this.calendarSettings = this.calendarSettingsService.getDefaultSettings();

		this.layoutService.isMobileSize$.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
			this.screenSize = value ? ScreenSize.Mobile : ScreenSize.Desktop;
		});
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			const timeElements = document.querySelectorAll('.cal-time');
			const timeTarget = Array.from(timeElements).find((el) => el.textContent?.trim() === '6 AM');

			if (timeTarget) {
				timeTarget.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'center' });
			}
		}, 300);
	}

	ngOnDestroy() {
		this.destroyed$.emit();
	}

	handleEventClicked(event: CalendarEvent): void {
		this.eventClicked.emit(event);
	}

	handleEventTimesChanged(event: CalendarEventTimesChangedEvent): void {
		this.eventTimesChanged.emit(event);
	}

	toggleEventPopover(popover: NgbPopover, event: CalendarEvent<ScheduledEvent>): void {
		this.popupService.toggleEventPopover(this.screenSize, popover, event, this.isOwner);
	}

	onClosePopup(): void {
		this.popupService.closePopup();
	}
}
