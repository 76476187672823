import { inject, Injectable } from '@angular/core';
import { CalendarEvent } from 'angular-calendar';
import { ScheduleService } from '@schedule/services/schedule/schedule.service';
import { ScheduledEvent } from '@schedule/models/calendar/schedule.model';
import { addHours, startOfDay } from 'date-fns';
import { Observable, of } from 'rxjs';

export type CalendarEvents = Array<CalendarEvent<ScheduledEvent>>

@Injectable({
	providedIn: 'root'
})
export class CalendarService {

	private scheduleService = inject(ScheduleService);

	constructor() {
	}

	/**
	 * Transforms the scheduled assessments into calendar events
	 */
	getCalendarEvents(): Observable<CalendarEvents> {
		// const scheduledAssessments = this.scheduleService.getScheduledAssessment();
		// return scheduledAssessments.map(assessment => {
		// 	return {
		// 		start: assessment.selectedStartTime.toDate(),
		// 		end: assessment.selectedEndTime.toDate(),
		// 		title: assessment.assessments?.join(', '),
		// 		meta: {
		// 			assessmentNotes: assessment.assessmentNotes,
		// 			whatsappNotes: assessment.whatsappNotes,
		// 			status: assessment.status,
		// 			assessmentType: assessment.assessmentType,
		// 			callOutFeeRands: assessment.callOutFeeRands
		// 		}
		// 	} as CalendarEvent<ScheduledEvent>;
		// });

		const events: CalendarEvents = [];
		const today = new Date();
		const daysInMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();

		for (let day = 1; day <= daysInMonth; day++) {
			const eventCount = Math.floor(Math.random() * 5) + 3; // 3 to 7 events
			const date = new Date(today.getFullYear(), today.getMonth(), day);
			for (let i = 0; i < eventCount; i++) {
				const start = addHours(startOfDay(date), 8);
      			const end = addHours(start, 3);

				events.push({
					title: `Job ${i + 1}`,
					start,
					end
				});
			}
		}

		return of(events);
	}
}
