import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ICustomFormControl } from '@leads/customer-facing/models/domain/lead-request.domain';
import { StandardControlComponent } from '@shared/components/forms/standard-control/standard-control.component';

@Component({
	selector: 'app-custom-form-details',
	standalone: true,
	imports: [CommonModule, FormsModule, ReactiveFormsModule, StandardControlComponent],
	templateUrl: './custom-form-details.component.html',
	styleUrl: './custom-form-details.component.scss',
})
export class CustomFormDetailsComponent {
	@Input() form: FormGroup<ICustomFormControl>;
	@Input() borderWrap: boolean = false;

	get customQuestionsLength(): number {
		return Object.keys(this.form.controls).length || 0;
	}
}
