<app-loading-state [isLoading]="isLoading" [loadedContent]="loadedContent"> </app-loading-state>

<ng-template #loadedContent>
	<ng-container *ngIf="userHasAccess; else noAccess">
		<div class="details-container">
			<div class="content">
				<ng-container *ngIf="isMobileSize; else desktopSize">
					<app-mobile-organisation-details [organisationForm]="organisationForm"></app-mobile-organisation-details>
				</ng-container>
				<ng-template #desktopSize>
					<app-desktop-organisation-details [organisationForm]="organisationForm"></app-desktop-organisation-details>
				</ng-template>
			</div>
			<div class="footer">
				<button
					type="button"
					class="btn btn-sm btn-primary btn-width"
					(click)="handleSave()"
					[disabled]="organisationForm.invalid || organisationForm.pristine"
				>
					<ng-container *ngIf="isSaving; else saveText">
						<div class="spinner-border spinner-border-sm" role="status">
							<span class="visually-hidden">Loading...</span>
						</div>
					</ng-container>
					<ng-template #saveText> Save </ng-template>
				</button>
			</div>
		</div>
	</ng-container>

	<ng-template #noAccess>
		<app-unauthorised-banner
			headingText="It looks like you do not have access to this organisation or do not have the required access level."
		></app-unauthorised-banner>
	</ng-template>
</ng-template>
