<div class="dashboard-container">
	<div class="heading">
		<app-page-title title="Dashboard"></app-page-title>
	</div>
	<div class="content">
		<ng-container *ngFor="let invitation of organisationInvitations">
			<app-organisation-invitation-banner [organisationInvitation]="invitation"></app-organisation-invitation-banner>
		</ng-container>

		<ng-container *ngIf="noOrganisationAvailable">
			<app-incomplete-organisation-banner></app-incomplete-organisation-banner>
		</ng-container>
	</div>
</div>
