<ul class="nav nav-underline nav-justified">
	<ng-container *ngFor="let item of navItems">
		<ng-container *ngIf="item.visible">
			<li class="nav-item">
				<a class="nav-link" [routerLink]="item.route" routerLinkActive="active" [class.disabled]="item.disabled">
					<div class="tab">
						<div>
							<i class="bi" [ngClass]="item.icon"></i>
						</div>
						<div class="label">
							{{ item.label }}
						</div>
					</div>
				</a>
			</li>
		</ng-container>
	</ng-container>
</ul>
