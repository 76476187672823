<div class="form-container">
	<ng-container *ngIf="infoPopOver">
		<app-header-popover heading="Installation Address" [infoPopOver]="infoPopOver"></app-header-popover>
	</ng-container>
	<div class="form-row">
		<h6 class="text-primary m-0">Search Address</h6>
		<app-google-places-autocomplete (placeSelected)="onPlaceSelected($event)"></app-google-places-autocomplete>
		<h6 class="text-primary m-0">Or enter property manually</h6>
	</div>

	<form [formGroup]="formGroup" class="body">
		<div class="row-group">
			<app-standard-control
				class="control"
				type="text"
				name="fullAddress"
				[formGroup]="formGroup"
				label="Full Address"
				placeholder="Full Address"
				autocomplete="address-level1"
			></app-standard-control>

			<app-standard-control
				class="control"
				type="text"
				name="suburb"
				[formGroup]="formGroup"
				label="Suburb"
				placeholder="Suburb"
			></app-standard-control>
		</div>

		<div class="row-group">
			<app-standard-control
				class="control"
				type="text"
				name="city"
				[formGroup]="formGroup"
				label="City"
				placeholder="City"
			></app-standard-control>

			<app-standard-control
				class="control"
				type="text"
				name="province"
				[formGroup]="formGroup"
				label="Province"
				placeholder="Province"
			></app-standard-control>
		</div>

		<div class="row-group">
			<app-standard-control
				class="control"
				type="text"
				name="country"
				[formGroup]="formGroup"
				label="Country"
				placeholder="Country"
			></app-standard-control>

			<app-standard-control
				class="control"
				type="text"
				name="postalCode"
				[formGroup]="formGroup"
				label="Postal Code"
				placeholder="Postal Code"
			></app-standard-control>
		</div>
	</form>
</div>
