import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
	ICallAssessmentForm,
	IContactDetailsForm,
	ICustomFormControl,
	IInstallationAddressForm,
	ILeadRequestForm,
	IOnSiteAssessmentForm,
} from '@leads/customer-facing/models/domain/lead-request.domain';
import { LeadOnSiteVisitDateTimeType } from '@leads/shared/models/domain/lead-request.domain';
import { ICustomFormItem, OrganisationCustomQuestionCategories } from '@organisations/models/organisations';

@Injectable({
	providedIn: 'root',
})
export class LeadRequestFormService {
	private form: FormGroup<ILeadRequestForm>;

	private redirectToLeads = false;

	constructor() {
		this.setForm();
	}

	setRedirectToLeads(value: boolean) {
		this.redirectToLeads = value;
	}

	getRedirectToLeads(): boolean {
		return this.redirectToLeads;
	}

	getForm(): FormGroup<ILeadRequestForm> {
		return this.form;
	}

	private setForm() {
		this.form = new FormGroup<ILeadRequestForm>({
			mobileNumber: new FormControl(null, [
				Validators.required,
				Validators.minLength(10),
				Validators.maxLength(20),
				Validators.pattern(/^[0-9]{10,20}$/),
			]),
			requestType: new FormControl(null, [Validators.required]),
			contactDetails: new FormGroup<IContactDetailsForm>({
				name: new FormControl(null, [Validators.required, Validators.minLength(2), Validators.maxLength(50)]),
				emailAddress: new FormControl(null, [
					Validators.required,
					Validators.email,
					Validators.minLength(2),
					Validators.maxLength(50),
				]),
				customQuestions: new FormGroup<ICustomFormControl>({}),
			}),
			installationAddress: new FormGroup<IInstallationAddressForm>({
				fullAddress: new FormControl(null, [Validators.required, Validators.minLength(3)]),
				suburb: new FormControl(null, [Validators.required, Validators.minLength(3)]),
				city: new FormControl(null, [Validators.required, Validators.minLength(3)]),
				country: new FormControl(null, [Validators.required, Validators.minLength(3)]),
				postalCode: new FormControl(null, [Validators.required, Validators.minLength(3)]),
				province: new FormControl(null, [Validators.required, Validators.minLength(3)]),
			}),
			callAssessment: new FormGroup<ICallAssessmentForm>({
				notes: new FormControl(null),
				customQuestions: new FormGroup<ICustomFormControl>({}),
			}),
			onsiteAssessment: new FormGroup<IOnSiteAssessmentForm>({
				dateType: new FormControl(LeadOnSiteVisitDateTimeType.REQUEST, [Validators.required]),
				requestedDate: new FormControl(null, [Validators.required]),
				requestedTimeWindow: new FormControl(null, [Validators.required]),
				serviceType: new FormControl(null, [Validators.required]),
				notes: new FormControl(null),
				customQuestions: new FormGroup<ICustomFormControl>({}),
			}),
		});
	}

	updateCustomQuestions(customQuestions: Array<ICustomFormItem>) {
		const customQuestionsContactDetails = this.form.controls.contactDetails.controls.customQuestions;
		const customQuestionsOnsite = this.form.controls.onsiteAssessment.controls.customQuestions;
		const customQuestionsCall = this.form.controls.callAssessment.controls.customQuestions;
		customQuestions.forEach((question) => {
			const validators = [Validators.minLength(1), Validators.maxLength(50)];
			if (question.required) {
				validators.push(Validators.required);
			}
			switch (question.category) {
				case OrganisationCustomQuestionCategories.CONTACT_INFORMATION:
					customQuestionsContactDetails.addControl(question.question, new FormControl(null, validators));
					break;
				case OrganisationCustomQuestionCategories.ONSITE_ASSESSMENT_INFORMATION:
					customQuestionsOnsite.addControl(question.question, new FormControl(null, validators));
					break;
				case OrganisationCustomQuestionCategories.CALL_ASSESSMENT_INFORMATION:
					customQuestionsCall.addControl(question.question, new FormControl(null, validators));
					break;
				case OrganisationCustomQuestionCategories.ONSITE_AND_CALL_ASSESSMENT_INFORMATION:
					customQuestionsOnsite.addControl(question.question, new FormControl(null, validators));
					customQuestionsCall.addControl(question.question, new FormControl(null, validators));
					break;
			}
		});
		this.form.updateValueAndValidity();
	}
}
