<nav class="navbar bg-body-tertiary fixed-top">
	<div class="container-fluid">
		<a class="navbar-brand" routerLink="/">
			<img src="assets/logos/serveox-logo-blue.svg" alt="ServeOx logo" class="img-fluid" width="150" />
		</a>
		<button
			class="navbar-toggler"
			type="button"
			data-bs-toggle="offcanvas"
			data-bs-target="#mobileNavbar"
			aria-controls="mobileNavbar"
			aria-label="Toggle navigation"
		>
			<img src="assets/app-icons/hamburger-menu.svg" alt="Menu" class="img-fluid" width="25" />
		</button>
		<div class="offcanvas offcanvas-start" tabindex="-1" id="mobileNavbar" aria-labelledby="mobileNavbarLabel">
			<div class="offcanvas-header">
				<div class="offcanvas-title" id="mobileNavbarLabel">
					<img src="assets/logos/serveox-logo-white.svg" alt="ServeOx logo" class="img-fluid" width="150" />
				</div>
				<button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
			</div>
			<div class="offcanvas-body">
				<app-menu-customer></app-menu-customer>
			</div>
		</div>
	</div>
</nav>
