import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { AuthRoutes } from '@auth/auth.routes';
import { IPasswordReset, IPasswordResetForm } from '@auth/models/password-reset';
import { AuthService } from '@auth/services/auth.service';
import { StandardControlComponent } from '@shared/components/forms/standard-control/standard-control.component';

@Component({
	selector: 'app-password-reset',
	standalone: true,
	imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterLink, StandardControlComponent],
	templateUrl: './password-reset.component.html',
	styleUrl: './password-reset.component.scss',
	host: {
		class: 'h-100',
	},
})
export class PasswordResetComponent {
	authService = inject(AuthService);
	AuthRoutes = AuthRoutes;

	isLoading: boolean = false;

	form: FormGroup<IPasswordResetForm> = new FormGroup<IPasswordResetForm>({
		email: new FormControl(null, [Validators.required, Validators.email]),
	});

	handlePasswordReset(): void {
		if (this.form.invalid) {
			return;
		}
		this.isLoading = true;
		this.authService
			.sendPasswordResetEmail(this.form.value as IPasswordReset)
			.then((result) => {
				this.isLoading = false;
			})
			.catch((error) => {
				this.isLoading = false;
			});
	}
}
