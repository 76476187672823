<app-loading-state [isLoading]="isLoading" [loadedContent]="loadedContent"> </app-loading-state>

<ng-template #loadedContent>
	<ng-container *ngIf="userHasAccess; else noAccess">
		<div class="users-container">
			<div class="content">
				<ng-container *ngIf="isMobileSize; else desktopSize">
					<app-mobile-org-users-table
						[organisationMembers]="organisationMembers"
						[organisation]="organisation"
					></app-mobile-org-users-table>
				</ng-container>
				<ng-template #desktopSize>
					<app-desktop-org-users-table
						[organisationMembers]="organisationMembers"
						[organisation]="organisation"
					></app-desktop-org-users-table>
				</ng-template>
			</div>
			<div class="footer">
				<button type="button" class="btn btn-sm btn-outline-primary btn-width" [routerLink]="'../' + OrganisationRoutes.DETAILS">
					<i class="bi bi-arrow-left"></i>
					Previous
				</button>
				<button type="button" class="btn btn-sm btn-primary btn-width" [routerLink]="'../' + OrganisationRoutes.BILLING">
					Next
					<i class="bi bi-arrow-right"></i>
				</button>
			</div>
		</div>
	</ng-container>

	<ng-template #noAccess>
		<app-unauthorised-banner
			headingText="It looks like you do not have access to this organisation or do not have the required access level."
		></app-unauthorised-banner>
	</ng-template>
</ng-template>
