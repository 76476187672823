import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { IOrganisationUserFull } from '@organisations/models/organisation-user';
import { ModalService } from '@shared/services/modal/modal.service';
import { AddUserModalComponent } from '../modals/add-user-modal/add-user-modal.component';
import { EditUserModalComponent } from '../modals/edit-user-modal/edit-user-modal.component';
import { IOrganisation } from '@organisations/models/organisations';
import { organisationStatusBackground } from '@organisations/classes/utilities';

@Component({
	selector: 'app-mobile-org-users-table',
	standalone: true,
	imports: [CommonModule, RouterLink],
	templateUrl: './mobile-org-users-table.component.html',
	styleUrl: './mobile-org-users-table.component.scss',
})
export class MobileOrgUsersTableComponent {
	@Input() organisationMembers: Array<IOrganisationUserFull>;
	@Input() organisation: IOrganisation;

	private modalService = inject(ModalService);

	openInviteUser(): void {
		this.modalService.open(AddUserModalComponent);
	}

	openEditUser(member: IOrganisationUserFull): void {
		const modelRef = this.modalService.open(EditUserModalComponent);
		modelRef.componentInstance.organisationUser = member;
		modelRef.componentInstance.organisation = this.organisation;
	}

	organisationStatusBackground(member: IOrganisationUserFull) {
		return organisationStatusBackground(member);
	}
}
