import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AbstractControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
	selector: 'app-textarea-control',
	standalone: true,
	imports: [CommonModule, FormsModule, ReactiveFormsModule],
	templateUrl: './textarea-control.component.html',
	styleUrl: './textarea-control.component.scss',
})
export class TextareaControlComponent<T extends { [K in keyof T]: AbstractControl }> {
	@Input() formGroup!: FormGroup<T>;
	@Input() name!: Extract<keyof T, string | number>;
	@Input() label: string;
	@Input() placeholder: string;
	@Input() heightPx?: number;
	@Input() height100?: boolean = false;
	@Input() resizable?: boolean;

	get control() {
		return this.formGroup.controls[this.name];
	}
}
