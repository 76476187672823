<div class="step-container">
	<div class="content">
		<app-loading-state [isLoading]="isLoading" [loadedContent]="loadedContent"></app-loading-state>
		<ng-template #loadedContent>
			<div class="personal-details-container">
				<div class="form-item">
					<app-lead-contact-information
						[form]="leadRequestForm.controls.contactDetails"
						[organisation]="organisation"
					></app-lead-contact-information>
				</div>
				<ng-container *ngIf="leadRequestForm.controls.requestType.value === LeadRequestType.ON_SITE_VISIT">
					<div class="form-item">
						<app-lead-address-information
							[form]="leadRequestForm.controls.installationAddress"
							[organisation]="organisation"
						></app-lead-address-information>
					</div>
				</ng-container>
			</div>
		</ng-template>
	</div>

	<div class="footer">
		<button type="button" class="btn btn-sm btn-light btn-width" [routerLink]="'../' + CustomerLeadRequestRoutes.REQUEST_TYPE">
			<i class="bi bi-arrow-left"></i>
			Previous
		</button>
		<button
			type="button"
			class="btn btn-sm btn-primary btn-width"
			[routerLink]="'../' + CustomerLeadRequestRoutes.APPOINTMENT_TIME"
			[disabled]="nextDisabled()"
		>
			Next
			<i class="bi bi-arrow-right"></i>
		</button>
	</div>
</div>
