import { Component, EventEmitter, inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageTitleComponent } from '@shared/components/sections/page-title/page-title.component';
import { IAvailableOrganisation } from '@organisations/models/organisation-user';
import { take, takeUntil } from 'rxjs';
import { LayoutService } from '@layout/services/layout/layout.service';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { DesktopAssessmentRequestDetailsComponent } from '@leads/client-facing/components/desktop-assessment-request-details/desktop-assessment-request-details.component';
import { MobileAssessmentRequestDetailsComponent } from '@leads/client-facing/components/mobile-assessment-request-details/mobile-assessment-request-details.component';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AppRoutes } from '@app/app.routes';
import { LoadingStateComponent } from '@shared/components/loading-state/loading-state.component';
import { LeadsDashboardService } from '@leads/client-facing/services/leads-dashboard.service';
import { ILeadRequest, LeadRequestType, LeadStatus } from '@leads/shared/models/lead.models';
import { IClientWithProperties } from '@clients/models/clients';
import { ClientsService } from '@clients/services/clients/clients.service';
import { ConfirmationModalComponent } from '@shared/components/modals/confirmation-modal/confirmation-modal.component';
import { ModalService } from '@shared/services/modal/modal.service';
import { ILeadRequestNotesForm } from '@leads/client-facing/models/lead-request.model';
import { ISchedulerForm, ISchedulerFormItem } from '@leads/shared/models/schedule.model';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ScheduledAssessmentType } from '@schedule/models/firestore/schedule.model';

@Component({
	selector: 'app-lead-assessment-request',
	standalone: true,
	imports: [
		PageTitleComponent,
		RouterLink,
		DesktopAssessmentRequestDetailsComponent,
		MobileAssessmentRequestDetailsComponent,
		ReactiveFormsModule,
		CommonModule,
		LoadingStateComponent,
		ConfirmationModalComponent,
	],
	templateUrl: './lead-assessment-request.component.html',
	styleUrl: './lead-assessment-request.component.scss',
})
export class LeadAssessmentRequestComponent implements OnInit, OnDestroy {
	destroyed$ = new EventEmitter<void>();
	selectedOrganisation: IAvailableOrganisation | null;
	leadRequestId: string;
	leadRequest: ILeadRequest;
	client: IClientWithProperties;
	isLoading = true;
	isSavingNotes = false;
	isUpdatingStatus = false;
	isMobileSize = true;

	AppRoutes = AppRoutes;
	LeadStatus = LeadStatus;

	private layoutService = inject(LayoutService);
	private modalService = inject(ModalService);
	private leadsRequestService = inject(LeadsDashboardService);
	private clientService = inject(ClientsService);
	private route = inject(ActivatedRoute);
	private router = inject(Router);

	internalNotesForm: FormGroup<ILeadRequestNotesForm>;
	schedulerForm: FormGroup<ISchedulerForm>;

	ngOnInit(): void {
		this.layoutService.isMobileSize$.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
			this.isMobileSize = value;
		});

		this.internalNotesForm = new FormGroup<ILeadRequestNotesForm>({
			internalNotes: new FormControl<string | null>(null),
		});

		this.schedulerForm = new FormGroup<ISchedulerForm>({
			scheduledItems: new FormArray<FormGroup<ISchedulerFormItem>>([], [Validators.required]),
		});

		this.populateLeadRequest();
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}

	populateLeadRequest(): void {
		this.leadRequestId = this.route.snapshot.params.leadRequestId;
		this.leadsRequestService
			.getLeadRequest(this.leadRequestId)
			.pipe(takeUntil(this.destroyed$))
			.subscribe((leadrequest: ILeadRequest | undefined) => {
				if (leadrequest === undefined) {
					this.router.navigate([AppRoutes.CLIENT_LEADS_DASHBOARD]);
				}
				this.leadRequest = leadrequest as ILeadRequest;
				const clientId = this.leadRequest.client.path.split('/')[1];
				this.populateClient(clientId);
				if (this.leadRequest.internalNotes) {
					this.internalNotesForm.controls.internalNotes.patchValue(this.leadRequest.internalNotes);
				}
			});
	}

	populateClient(clientId: string): void {
		this.clientService
			.getClientWithProperties(clientId)
			.pipe(takeUntil(this.destroyed$))
			.subscribe((client) => {
				if (client) {
					this.client = client;
				}
				this.isLoading = false;
			});
	}

	rejectAssessment(): void {
		const modalRef = this.modalService.open(ConfirmationModalComponent, false, 'md');
		modalRef.componentInstance.title = 'Are you sure you want to reject this assessment request?';
		modalRef.componentInstance.contentText = 'You will be able to recover this assessment request.';
		modalRef.componentInstance.confirmAction.pipe(take(1)).subscribe(() => {
			this.leadsRequestService.updateStatus(this.leadRequestId, LeadStatus.REJECTED);
		});
	}

	recoverAssessment(): void {
		const modalRef = this.modalService.open(ConfirmationModalComponent, false, 'md');
		modalRef.componentInstance.title = 'Are you sure you want to recover this assessment request?';
		modalRef.componentInstance.contentText = 'The request will be marked as new.';
		modalRef.componentInstance.confirmAction.pipe(take(1)).subscribe(() => {
			this.leadsRequestService.updateStatus(this.leadRequestId, LeadStatus.NEW);
		});
	}

	saveNotes(): void {
		if (this.internalNotesForm.invalid) {
			return;
		}
		this.isSavingNotes = true;

		this.leadsRequestService
			.update(this.leadRequestId, {
				internalNotes: this.internalNotesForm.controls.internalNotes.value,
			})
			.finally(() => {
				this.isSavingNotes = false;
				this.internalNotesForm.markAsPristine();
			});
	}

	get scheduledItems() {
		return this.schedulerForm.controls.scheduledItems;
	}

	addOnSiteAssessment(): void {
		const form = new FormGroup<ISchedulerFormItem>({
			requestType: new FormControl<LeadRequestType | null>(LeadRequestType.ON_SITE_VISIT, [Validators.required]),
			employeeId: new FormControl<string | null>(null, [Validators.required]),
			selectedDate: new FormControl<NgbDateStruct | null>(null, [Validators.required]),
			selectedStartTime: new FormControl<Date | null>(null, [Validators.required]),
			selectedEndTime: new FormControl<Date | null>(null, [Validators.required]),
			assessments: new FormControl<Array<string> | null>([], [Validators.required]),
			assessmentType: new FormControl<ScheduledAssessmentType | null>(null, [Validators.required]),
			callOutFeeRands: new FormControl<number | null>(null, [Validators.required, Validators.min(0), Validators.max(10_000)]),
			includeCallOutFeeComms: new FormControl<boolean | null>(false, [Validators.required]),
			assessmentNotes: new FormControl<string | null>(null),
			whatsappNotes: new FormControl<string | null>(null),
		});
		this.scheduledItems.push(form);
	}

	addCallAssessment(): void {
		const form = new FormGroup<ISchedulerFormItem>({
			requestType: new FormControl<LeadRequestType | null>(LeadRequestType.CALL, [Validators.required]),
			employeeId: new FormControl<string | null>(null, [Validators.required]),
			selectedDate: new FormControl<NgbDateStruct | null>(null, [Validators.required]),
			selectedStartTime: new FormControl<Date | null>(null, [Validators.required]),
			selectedEndTime: new FormControl<Date | null>(null, [Validators.required]),
			assessmentNotes: new FormControl<string | null>(null),
			whatsappNotes: new FormControl<string | null>(null),
		});
		this.scheduledItems.push(form);
	}

	removeAssessment(index: number): void {
		const modalRef = this.modalService.open(ConfirmationModalComponent, false, 'md');
		modalRef.componentInstance.title = 'Are you sure you want to remove this assessment request?';
		modalRef.componentInstance.contentText = 'The assessment will not be able to be recovered.';
		modalRef.componentInstance.confirmAction.pipe(take(1)).subscribe(() => {
			this.scheduledItems.removeAt(index);
		});
	}
}
