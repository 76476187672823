import { Routes } from '@angular/router';
import { ScheduleComponent } from '@schedule/pages/schedule/schedule.component';

export enum ScheduleRoutes {
	SCHEDULE = '',
}

export const scheduleRoutes: Routes = [
	{
		path: '',
		component: ScheduleComponent,
		title: 'ServeOx | Schedule',
	},
	{
		path: '**',
		redirectTo: ScheduleRoutes.SCHEDULE,
	},
];
