import { take } from 'rxjs';

import { Component, inject, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IClientWithProperties } from '@clients/models/firebase/client.model';
import { ILeadRequestForm } from '@leads/customer-facing/models/domain/lead-request.domain';
import { IDropdownOption } from '@shared/components/forms/dropdown-control/dropdown-control.component';
import { IPopover } from '@shared/models/popover';
import { ModalService } from '@shared/services/modal/modal.service';

import { ImportClientModalComponent } from '../components/import-client-modal/import-client-modal.component';
import { IImportClient, PropertySelectType } from '../models/domain/lead-request.domain';

@Component({
	template: '',
})
export abstract class BaseLeadRequestCreate {
	@Input() leadRequestForm: FormGroup<ILeadRequestForm>;
	@Input() clients: Array<IClientWithProperties>;
	@Input() serviceTypes: Array<IDropdownOption>;

	private modalService = inject(ModalService);

	openImportClient(): void {
		const modalRef = this.modalService.open(ImportClientModalComponent, false, 'lg');
		modalRef.componentInstance.clients = this.clients;
		modalRef.componentInstance.clientSelected.pipe(take(1)).subscribe((clientImport: IImportClient) => {
			this.leadRequestForm.controls.mobileNumber.setValue(clientImport.selectedClient.clientNumber.number);
			this.leadRequestForm.controls.contactDetails.controls.name.setValue(clientImport.selectedClient.clientName);
			this.leadRequestForm.controls.contactDetails.controls.emailAddress.setValue(clientImport.selectedClient.clientEmail);
			if (clientImport.propertySelectType === PropertySelectType.EXISTING && clientImport.selectedClientProperty) {
				this.leadRequestForm.controls.installationAddress.patchValue(clientImport.selectedClientProperty);
			} else {
				this.leadRequestForm.controls.installationAddress.reset();
			}
		});
	}

	contactInfoPopOver: IPopover = {
		triggerText: {
			desktop: "What's this?",
			mobile: "What's this?",
		},
		title: 'Contact Information',
		description: 'Create a new lead by selecting an existing contact or manually creating a new one below.',
		icon: 'bi-info-circle-fill',
	};
}
