import { filter, takeUntil } from 'rxjs';

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, OnDestroy, OnInit } from '@angular/core';
import { DocumentReference } from '@angular/fire/firestore';
import { FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { OrganisationUserAccessLevel } from '@organisations/models/organisation-user';
import {
	ICustomFormItem,
	ICustomFormItemForm,
	IOrganisation,
	IOrganisationAssessmentForm,
	OrganisationCustomQuestionCategories,
} from '@organisations/models/organisations';
import { OrganisationSelectedService } from '@organisations/services/organisation-selected/organisation-selected.service';
import { OrganisationService } from '@organisations/services/organisation/organisation.service';
import { AlertComponent } from '@shared/components/alerts/alert/alert.component';
import { DropdownControlComponent, IDropdownOption } from '@shared/components/forms/dropdown-control/dropdown-control.component';
import { StandardControlComponent } from '@shared/components/forms/standard-control/standard-control.component';
import { SwitchControlComponent } from '@shared/components/forms/switch-control/switch-control.component';
import { LoadingStateComponent } from '@shared/components/loading-state/loading-state.component';
import { UnauthorisedBannerComponent } from '@shared/components/ui/unauthorised-banner/unauthorised-banner.component';
import { ToastTypes } from '@shared/models/toast';
import { ToastService } from '@shared/services/toast/toast.service';

@Component({
	selector: 'app-assessment-form',
	standalone: true,
	imports: [
		CommonModule,
		LoadingStateComponent,
		AlertComponent,
		UnauthorisedBannerComponent,
		FormsModule,
		ReactiveFormsModule,
		SwitchControlComponent,
		DropdownControlComponent,
		StandardControlComponent,
	],
	templateUrl: './assessment-form.component.html',
	styleUrl: './assessment-form.component.scss',
})
export class AssessmentFormComponent implements OnInit, OnDestroy {
	assessmentForm: FormGroup<IOrganisationAssessmentForm>;
	destroyed$ = new EventEmitter<void>();

	organisationId: string;
	organisation: IOrganisation;
	userHasAccess = false;
	isLoading = true;
	isSaving = false;

	MAX_FORM_ITEMS = 2;

	formCategoryOptions: Array<IDropdownOption> = [
		{
			id: OrganisationCustomQuestionCategories.CONTACT_INFORMATION,
			label: 'Client Contact Information',
			value: OrganisationCustomQuestionCategories.CONTACT_INFORMATION,
			icon: 'bi-person-lines-fill',
		},
		{
			id: OrganisationCustomQuestionCategories.ONSITE_ASSESSMENT_INFORMATION,
			label: 'On Site - Assessment Info',
			value: OrganisationCustomQuestionCategories.ONSITE_ASSESSMENT_INFORMATION,
			icon: 'bi-building-check',
		},
		{
			id: OrganisationCustomQuestionCategories.CALL_ASSESSMENT_INFORMATION,
			label: 'Call Request - Assessment Info',
			value: OrganisationCustomQuestionCategories.CALL_ASSESSMENT_INFORMATION,
			icon: 'bi-telephone',
		},
		{
			id: OrganisationCustomQuestionCategories.ONSITE_AND_CALL_ASSESSMENT_INFORMATION,
			label: 'On Site & Call - Assessment Info',
			value: OrganisationCustomQuestionCategories.ONSITE_AND_CALL_ASSESSMENT_INFORMATION,
			icon: 'bi-clipboard-check',
		},
	];

	private organisationService = inject(OrganisationService);
	private organisationSelectedService = inject(OrganisationSelectedService);
	private toastService = inject(ToastService);

	ngOnInit(): void {
		this.assessmentForm = new FormGroup<IOrganisationAssessmentForm>({
			customFormItems: new FormArray<FormGroup<ICustomFormItemForm>>([]),
		});

		this.subscribeToSelectedOrganisation();
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}

	subscribeToSelectedOrganisation(): void {
		this.organisationSelectedService.selectedOrganisation
			.pipe(
				takeUntil(this.destroyed$),
				filter((o) => o !== null),
			)
			.subscribe((selectedOrg) => {
				if (selectedOrg?.accessLevel === OrganisationUserAccessLevel.OWNER) {
					this.userHasAccess = true;
					this.fetchOrganisationDetails(selectedOrg.organisation as DocumentReference<IOrganisation>);
				} else {
					this.userHasAccess = false;
					this.isLoading = false;
				}
			});
	}

	fetchOrganisationDetails(orgRef: DocumentReference<IOrganisation>): void {
		this.organisationService
			.getOrganisation(orgRef)
			.pipe(takeUntil(this.destroyed$))
			.subscribe((organisation) => {
				if (organisation) {
					this.organisationId = organisation.id as string;
					this.organisation = organisation;
					this.buildForm();
				}
				this.isLoading = false;
			});
	}

	get customFormItems(): FormArray<FormGroup<ICustomFormItemForm>> {
		return this.assessmentForm.controls.customFormItems;
	}

	buildForm(): void {
		if (this.organisation.settings?.leadsForm) {
			const assessmentFormItems = this.organisation.settings?.leadsForm.map(
				(formItem) =>
					new FormGroup<ICustomFormItemForm>(
						{
							question: new FormControl<string | null>(formItem.question, [Validators.required]),
							required: new FormControl<boolean | null>(formItem.required, [Validators.required]),
							category: new FormControl<OrganisationCustomQuestionCategories | null>(formItem.category, [
								Validators.required,
							]),
						},
						[Validators.required],
					),
			);
			this.assessmentForm.controls.customFormItems = new FormArray<FormGroup<ICustomFormItemForm>>(assessmentFormItems);
		} else {
			this.assessmentForm.controls.customFormItems = new FormArray<FormGroup<ICustomFormItemForm>>([]);
			this.addAssesmentFormItem();
		}
	}

	addAssesmentFormItem(): void {
		if (this.customFormItems.length >= this.MAX_FORM_ITEMS) {
			return;
		}
		this.customFormItems.push(
			new FormGroup<ICustomFormItemForm>({
				question: new FormControl<string | null>(null, [Validators.required]),
				required: new FormControl<boolean | null>(false, [Validators.required]),
				category: new FormControl<OrganisationCustomQuestionCategories | null>(
					OrganisationCustomQuestionCategories.CONTACT_INFORMATION,
					[Validators.required],
				),
			}),
		);
		this.customFormItems.markAsDirty();
	}

	removeAssesmentFormItem(index: number): void {
		this.customFormItems.removeAt(index);
		this.customFormItems.markAsDirty();
	}

	handleCancel(): void {
		this.buildForm();
	}

	handleSave(): void {
		if (this.customFormItems.invalid) {
			return;
		}

		this.isSaving = true;

		this.organisationService
			.updateCustomFormItems(this.organisationId, this.customFormItems.value as Array<ICustomFormItem>)
			.then(() => {
				this.isSaving = false;
				this.assessmentForm.markAsPristine();

				this.toastService.showToast({
					title: 'Assessment Form',
					message: 'Successfully updated assessment form',
					type: ToastTypes.SUCCESS,
				});
			})
			.catch((error) => {
				console.error(error);
				this.isSaving = false;

				this.toastService.showToast({
					title: 'Assessment Form',
					message: `Failed to update assessment form: ${error.message}`,
					type: ToastTypes.ERROR,
				});
			});
	}
}
