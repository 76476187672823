<app-loading-state [isLoading]="isLoading" [loadedContent]="loadedContent"></app-loading-state>
<ng-template #loadedContent>
	<ng-container *ngIf="organisationExists; else noOrganisation">
		<div class="lead-request-container">
			<div class="heading">
				<app-page-title [title]="organisation.companyName"></app-page-title>
				<app-step-navigator [stepConfig]="stepConfig"></app-step-navigator>
			</div>
			<div class="content">
				<router-outlet></router-outlet>
			</div>
		</div>
	</ng-container>

	<ng-template #noOrganisation>
		<app-unauthorised-banner headingText="It looks like the organisation you are looking for does not exist."></app-unauthorised-banner>
	</ng-template>
</ng-template>
