import { take } from 'rxjs';

import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { organisationStatusBackground } from '@organisations/classes/utilities';
import { IOrganisationUserFull, OrganisationMemberStatus } from '@organisations/models/organisation-user';
import { IOrganisation } from '@organisations/models/organisations';
import { OrganisationUserService } from '@organisations/services/organisation-user/organisation-user.service';
import { ConfirmationModalComponent } from '@shared/components/modals/confirmation-modal/confirmation-modal.component';
import { ToastTypes } from '@shared/models/toast';
import { EmailService } from '@shared/services/email/email.service';
import { ModalService } from '@shared/services/modal/modal.service';
import { ToastService } from '@shared/services/toast/toast.service';

import { AddUserModalComponent } from '../modals/add-user-modal/add-user-modal.component';
import { EditUserModalComponent } from '../modals/edit-user-modal/edit-user-modal.component';

@Component({
	selector: 'app-desktop-org-users-table',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './desktop-org-users-table.component.html',
	styleUrl: './desktop-org-users-table.component.scss',
})
export class DesktopOrgUsersTableComponent {
	@Input() organisationMembers: Array<IOrganisationUserFull>;
	@Input() organisation: IOrganisation;
	OrganisationMemberStatus = OrganisationMemberStatus;

	private modalService = inject(ModalService);
	private emailService = inject(EmailService);
	private toastService = inject(ToastService);
	private organisationUserService = inject(OrganisationUserService);

	openInviteUser(): void {
		const modelRef = this.modalService.open(AddUserModalComponent);
	}

	openEditUser(member: IOrganisationUserFull): void {
		const modelRef = this.modalService.open(EditUserModalComponent);
		modelRef.componentInstance.organisationUser = member;
		modelRef.componentInstance.organisation = this.organisation;
	}

	removeOrganisationUserAction(member: IOrganisationUserFull): void {
		const modalRef = this.modalService.open(ConfirmationModalComponent, false, 'md');
		modalRef.componentInstance.title = 'Are you sure you want to remove this member from your organisation?';
		modalRef.componentInstance.contentText = member.email;
		modalRef.componentInstance.confirmAction.pipe(take(1)).subscribe(() => {
			if (member.id) {
				this.organisationUserService.removeUserFromOrganisation(member.id);
			}
		});
	}

	resendInvitation(member: IOrganisationUserFull): void {
		if (member.email && member.id) {
			this.organisationUserService
				.resendOrganisationInvitation(member.id, member.email, this.organisation.companyName)
				.then((result) => {
					this.toastService.showToast({
						title: 'Organisation Invitation',
						message: `Resent invitation to ${member.email}.`,
						type: ToastTypes.INFO,
					});
				});
		}
	}

	organisationStatusBackground(member: IOrganisationUserFull) {
		return organisationStatusBackground(member);
	}
}
