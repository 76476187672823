import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { IOnSiteAssessmentForm } from '@leads/customer-facing/models/lead-request';
import { LeadOnSiteVisitDateTimeType, LeadTimeWindow } from '@leads/shared/models/lead.models';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AlertComponent } from '@shared/components/alerts/alert/alert.component';
import { DatePickerParent } from '@shared/components/calendar/date-picker';
import { DatePickerFullscreenComponent } from '@shared/components/calendar/date-picker-fullscreen/date-picker-fullscreen.component';
import { IDisabledDaysOfTheWeek } from '@shared/components/calendar/models';
import { dateToNgbDateStruct, formatDate, ngbDateStructToDate } from '@shared/components/calendar/utilities';
import { RadioGroupControlComponent } from '@shared/components/forms/radio-group-control/radio-group-control.component';
import { SelectControlComponent } from '@shared/components/forms/select-control/select-control.component';
import { HeaderPopoverComponent } from '@shared/components/sections/header-popover/header-popover.component';
import { IRadioOption, ISelectOption, radioOptionsFromEnum } from '@shared/models/form-controls';
import { IPopover } from '@shared/models/popover';
import { takeUntil } from 'rxjs';

@Component({
	selector: 'app-desktop-lead-assessment-details',
	standalone: true,
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		HeaderPopoverComponent,
		AlertComponent,
		RadioGroupControlComponent,
		SelectControlComponent,
		DatePickerFullscreenComponent,
	],
	templateUrl: './desktop-lead-assessment-details.component.html',
	styleUrl: './desktop-lead-assessment-details.component.scss',
})
export class DesktopLeadAssessmentDetailsComponent extends DatePickerParent implements OnInit, OnDestroy {
	@Input() form: FormGroup<IOnSiteAssessmentForm>;
	destroyed$ = new EventEmitter<void>();
	formattedDate: string;

	LeadOnSiteVisitDateTimeType = LeadOnSiteVisitDateTimeType;
	assessmentTypes: Array<ISelectOption> = [];

	prefferedTimeOptions: Array<IRadioOption> = radioOptionsFromEnum(LeadTimeWindow);

	dateTypeOptions: Array<IRadioOption> = [
		{
			label: 'Request Date',
			value: LeadOnSiteVisitDateTimeType.REQUEST,
			id: 'request',
		},
		{
			label: 'ASAP',
			value: LeadOnSiteVisitDateTimeType.ASAP,
			id: 'asap',
		},
	];

	disabledDaysOfTheWeek: IDisabledDaysOfTheWeek;

	ngOnInit(): void {
		this.validateForm();

		// ToDo: This must be updated to be fetched from the organisation settings
		// ToDo: Use NG-select
		this.assessmentTypes = [
			{
				label: 'Geyser Installation / Repairs',
				value: 'Geyser Installation / Repairs',
			},
			{
				label: 'Toilet Installation / Repairs',
				value: 'Toilet Installation / Repairs',
			},
			{
				label: 'Pipe Installation / Repairs',
				value: 'Pipe Installation / Repairs',
			},
			{
				label: 'Basin Installation / Repairs',
				value: 'Basin Installation / Repairs',
			},
		];

		// ToDo: Pull in business days
		this.disabledDaysOfTheWeek = {
			sunday: true,
			monday: false,
			tuesday: false,
			wednesday: false,
			thursday: false,
			friday: false,
			saturday: false,
		};
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}

	dateSelected = (ngbDate: NgbDate): void => {
		const ngbDateStruct: NgbDateStruct = {
			day: ngbDate.day,
			month: ngbDate.month,
			year: ngbDate.year,
		};
		this.updateRequestedDate(ngbDateStruct);
	};

	private updateRequestedDate(ngbDateStruct: NgbDateStruct): void {
		this.form.controls.requestedDate.patchValue(ngbDateStruct);
		const date = ngbDateStructToDate(ngbDateStruct);
		this.formattedDate = formatDate(date);
	}

	get dateType(): FormControl {
		return this.form.controls.dateType;
	}

	setRequestedDateToNextAvailable(initialDate: Date): void {
		const ngbDateStruct: NgbDateStruct = dateToNgbDateStruct(initialDate);
		this.updateRequestedDate(ngbDateStruct);
	}

	validateForm(): void {
		//ToDo: Just enable/disable form controls
		this.dateType.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((dateType) => {
			if (dateType === LeadOnSiteVisitDateTimeType.REQUEST) {
				this.form.controls.requestedDate.setValidators([Validators.required]);
				this.form.controls.requestedTimeWindow.setValidators([Validators.required]);
			}

			if (dateType === LeadOnSiteVisitDateTimeType.ASAP) {
				this.form.controls.requestedDate.clearValidators();
				this.form.controls.requestedTimeWindow.clearValidators();
				this.form.controls.requestedDate.reset();
				this.form.controls.requestedTimeWindow.reset();
			}

			this.form.controls.requestedDate.updateValueAndValidity();
			this.form.controls.requestedTimeWindow.updateValueAndValidity();
		});
	}

	assessmentPopOver: IPopover = {
		triggerText: {
			desktop: "What's this?",
			mobile: "What's this?",
		},
		title: 'Assessment Date & Time',
		description:
			"Select a preferred date and time for your On-Site Service Request, or choose ASAP if it's an emergency and the specialist will be notified immediately.",
		icon: 'bi-info-circle-fill',
	};

	serviceTypePopover: IPopover = {
		triggerText: {
			desktop: "What's this?",
			mobile: "What's this?",
		},
		title: 'Service Type',
		description:
			'Please select your required service from the dropdown to help the specialist prepare for your On-Site Service Request.',
		icon: 'bi-info-circle-fill',
	};
}
