import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IInstallationAddressForm } from '@leads/customer-facing/models/domain/lead-request.domain';
import { AddressWithSearchComponent } from '@shared/components/forms/address-with-search/address-with-search.component';
import { IPopover } from '@shared/models/popover';

@Component({
	selector: 'app-lead-address-information',
	standalone: true,
	imports: [CommonModule, FormsModule, ReactiveFormsModule, AddressWithSearchComponent],
	templateUrl: './lead-address-information.component.html',
	styleUrl: './lead-address-information.component.scss',
	host: {
		class: 'h-100',
	},
})
export class LeadAddressInformationComponent {
	@Input() form: FormGroup<IInstallationAddressForm>;

	infoPopOver: IPopover = {
		triggerText: {
			desktop: "What's this?",
			mobile: "What's this?",
		},
		title: 'Installation Address',
		description: 'Please enter in the address where the specialist should visit for your On-Site service Request.',
		icon: 'bi-info-circle-fill',
	};
}
