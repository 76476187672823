import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IInstallationAddressForm } from '@leads/customer-facing/models/domain/lead-request.domain';
import { IPopover } from '@shared/models/popover';
import { IGoogleProperty } from '@shared/models/property';

import { HeaderPopoverComponent } from '../../sections/header-popover/header-popover.component';
import { GooglePlacesAutocompleteComponent } from '../google-places-autocomplete/google-places-autocomplete.component';
import { StandardControlComponent } from '../standard-control/standard-control.component';

@Component({
	selector: 'app-address-with-search',
	standalone: true,
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		GooglePlacesAutocompleteComponent,
		HeaderPopoverComponent,
		StandardControlComponent,
	],
	templateUrl: './address-with-search.component.html',
	styleUrl: './address-with-search.component.scss',
})
export class AddressWithSearchComponent {
	@Input() formGroup: FormGroup<IInstallationAddressForm>;
	@Input() infoPopOver: IPopover;

	get fullAddress(): FormControl {
		return this.formGroup.controls.fullAddress;
	}

	get country(): FormControl {
		return this.formGroup.controls.country;
	}

	get province(): FormControl {
		return this.formGroup.controls.province;
	}

	get city(): FormControl {
		return this.formGroup.controls.city;
	}

	get postalCode(): FormControl {
		return this.formGroup.controls.postalCode;
	}

	onPlaceSelected(property: IGoogleProperty) {
		this.formGroup.patchValue(property);
	}
}
