import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

/**
 * Formats a date to a readable string. Eg. `Friday, 01 November`
 * @param date Date
 * @returns string
 */
export function formatDate(date: Date): string {
	const options: Intl.DateTimeFormatOptions = { weekday: 'long', day: 'numeric', month: 'long' };
	const formattedDate = date.toLocaleDateString('en-ZA', options);
	return formattedDate;
}

/**
 * Converts Date to NgbDateStruct
 * @param date Date
 * @returns NgbDateStruct
 */
export function dateToNgbDateStruct(date: Date): NgbDateStruct {
	const ngbDate: NgbDateStruct = {
		year: date.getFullYear(),
		month: date.getMonth() + 1,
		day: date.getDate(),
	};
	return ngbDate;
}

/**
 * Converts NgbDateStruct to Date.
 * @param ngbDate NgbDateStruct
 * @returns Date
 */
export function ngbDateStructToDate(ngbDate: NgbDateStruct): Date {
	const date = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
	return date;
}
