<app-loading-state [isLoading]="isLoading" [loadedContent]="loadedContent"></app-loading-state>
<ng-template #loadedContent>
	<div class="page-container">
		<div class="heading">
			<app-page-title [showBackButton]="true" title="{{ leadRequest.status | titlecase }} Assessment Request "></app-page-title>
		</div>

		<div class="content">
			<ng-container *ngIf="isMobileSize; else desktopSize">
				<app-mobile-lead-request-detail
					[leadRequest]="leadRequest"
					[client]="client"
					[scheduledItems]="scheduledItems"
					[internalNotesForm]="internalNotesForm"
					[readonlyView]="readonlyView"
					(newScheduledItemCreated)="newScheduledItemCreated()"
				></app-mobile-lead-request-detail>
			</ng-container>
			<ng-template #desktopSize>
				<app-desktop-lead-request-detail
					[leadRequest]="leadRequest"
					[client]="client"
					[scheduledItems]="scheduledItems"
					[internalNotesForm]="internalNotesForm"
					[readonlyView]="readonlyView"
					(newScheduledItemActivityChanged)="newScheduledItemActivityChanged($event)"
					(newScheduledItemCreated)="newScheduledItemCreated()"
				></app-desktop-lead-request-detail>
			</ng-template>
		</div>

		<div class="footer">
			<ng-container *ngIf="leadRequest.status === LeadStatus.NEW">
				<button
					class="btn btn-sm btn-outline-danger btn-width"
					type="button"
					(click)="rejectAssessment()"
					[disabled]="!rejectAssessmentEnabled"
				>
					<ng-container *ngIf="isUpdatingStatus; else notUpdating">
						<div class="spinner-border spinner-border-sm" role="status">
							<span class="visually-hidden">Loading...</span>
						</div>
					</ng-container>
					<ng-template #notUpdating>Reject Assessment</ng-template>
				</button>
			</ng-container>
			<ng-container *ngIf="leadRequest.status === LeadStatus.REJECTED">
				<button class="btn btn-sm btn-outline-warning btn-width" type="button" (click)="recoverAssessment()">
					<ng-container *ngIf="isUpdatingStatus; else notUpdating">
						<div class="spinner-border spinner-border-sm" role="status">
							<span class="visually-hidden">Loading...</span>
						</div>
					</ng-container>
					<ng-template #notUpdating>Recover Assessment</ng-template>
				</button>
			</ng-container>
			<ng-container *ngIf="allAssessmentsCompleted && leadRequest.status !== LeadStatus.COMPLETED">
				<button class="btn btn-sm btn-outline-success btn-width" type="button" (click)="completeAssessment()">
					<ng-container *ngIf="isUpdatingStatus; else notUpdating">
						<div class="spinner-border spinner-border-sm" role="status">
							<span class="visually-hidden">Loading...</span>
						</div>
					</ng-container>
					<ng-template #notUpdating>Complete Assessment</ng-template>
				</button>
			</ng-container>
			<button
				class="btn btn-sm btn-outline-primary btn-width"
				type="button"
				[disabled]="internalNotesForm.invalid || internalNotesForm.pristine || isSavingNotes"
				(click)="saveNotes()"
			>
				<ng-container *ngIf="isSavingNotes; else notSaving">
					<div class="spinner-border spinner-border-sm" role="status">
						<span class="visually-hidden">Loading...</span>
					</div>
				</ng-container>
				<ng-template #notSaving>Save Notes</ng-template>
			</button>
		</div>
	</div>
</ng-template>
