import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IClientWithProperties } from '@clients/models/clients';
import { ILeadRequest, LeadOnSiteVisitDateTimeType, LeadRequestType } from '@leads/shared/models/lead.models';
import { ISchedulerForm } from '@leads/shared/models/schedule.model';
import { ILeadRequestNotesForm } from '../models/lead-request.model';

@Component({
	template: '',
})
export abstract class AssessmentRequestScheduler {
	@Input() leadRequest: ILeadRequest;
	@Input() client: IClientWithProperties;
	@Input() internalNotesForm: FormGroup<ILeadRequestNotesForm>;
	@Input() schedulerForm: FormGroup<ISchedulerForm>;
	@Output() onSiteAssessmentAdd = new EventEmitter<void>();
	@Output() callAssessmentAdd = new EventEmitter<void>();
	@Output() assessmentRemoved = new EventEmitter<number>();

	LeadrequestType = LeadRequestType;
	LeadrequestDateTimeType = LeadOnSiteVisitDateTimeType;

	get scheduledItems() {
		return this.schedulerForm.controls.scheduledItems;
	}

	addOnSiteAssessment(): void {
		this.onSiteAssessmentAdd.emit();
	}

	addCallAssessment(): void {
		this.callAssessmentAdd.emit();
	}

	removeAssessment(index: number): void {
		this.assessmentRemoved.emit(index);
	}
}
