import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AbstractControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { DatePickerBase } from '../date-picker';

@Component({
	selector: 'app-date-picker-dropdown',
	standalone: true,
	imports: [CommonModule, FormsModule, ReactiveFormsModule, NgbDatepickerModule],
	templateUrl: './date-picker-dropdown.component.html',
	styleUrl: './date-picker-dropdown.component.scss',
})
export class DatePickerDropdownComponent<T extends { [K in keyof T]: AbstractControl }> extends DatePickerBase<T> {
	@Input() readonly: boolean = true;
}
