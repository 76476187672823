<ng-container *ngIf="popover">
	<button
		class="popover-text"
		role="button"
		data-bs-toggle="popover"
		[attr.data-bs-title]="popover.title"
		[attr.data-bs-content]="popover.description"
	>
		<ng-container *ngIf="isMobileSize; else desktopText">
			{{ popover.triggerText.mobile }}
		</ng-container>
		<ng-template #desktopText>
			{{ popover.triggerText.desktop }}
		</ng-template>
		<i class="bi" [ngClass]="popover.icon"></i>
	</button>
</ng-container>
