<div class="banner-container">
	<div class="heading">Request Submitted Successfully</div>
	<div class="action-text">
		<p>Thank you for submitting your request with us. We will be in contact with you shortly with further details.</p>
		<p>Click the "View Assessment" button to send any additional notes to your specialist.</p>
	</div>
	<div class="action-button">
		<button type="button" class="btn btn-primary" (click)="viewAssessment()">View Assessment</button>
	</div>
</div>
