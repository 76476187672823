import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { IPasswordChangeForm } from '@auth/models/password-change';
import { AuthService } from '@auth/services/auth.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MatchValidator } from '@shared/classes/match-validator';
import { PasswordControlComponent } from '@shared/components/forms/password-control/password-control.component';
import { ToastTypes } from '@shared/models/toast';
import { ToastService } from '@shared/services/toast/toast.service';

@Component({
	selector: 'app-change-password-modal',
	standalone: true,
	imports: [CommonModule, FormsModule, ReactiveFormsModule, PasswordControlComponent],
	templateUrl: './change-password-modal.component.html',
	styleUrl: './change-password-modal.component.scss',
})
export class ChangePasswordModalComponent {
	private activeModal = inject(NgbActiveModal);
	private authService = inject(AuthService);
	private toastService = inject(ToastService);

	form: FormGroup<IPasswordChangeForm> = new FormGroup<IPasswordChangeForm>(
		{
			password: new FormControl(null, [Validators.required, Validators.minLength(8)]),
			confirmPassword: new FormControl(null, [Validators.required]),
		},
		[MatchValidator.match<IPasswordChangeForm>('password', 'confirmPassword')],
	);

	isLoading = false;

	closeModal(): void {
		this.activeModal.close();
	}

	submit(): void {
		if (this.form.invalid) {
			return;
		}

		this.isLoading = true;

		this.authService
			.updatePassword(this.form.controls.password.value!)
			.then(() => {
				this.toastService.showToast({
					title: 'Password Updated',
					message: 'Your password has been updated successfully.',
					type: ToastTypes.SUCCESS,
				});
				this.closeModal();
			})
			.finally(() => {
				this.isLoading = false;
			});
	}
}
