import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddPropertyModalComponent } from '@clients/components/modals/add-property-modal/add-property-modal.component';
import { EditPropertyModalComponent } from '@clients/components/modals/edit-property-modal/edit-property-modal.component';
import { IClientForm, IClientProperty } from '@clients/models/clients';
import { AccordionItemComponent } from '@shared/components/ui/accordion-item/accordion-item.component';
import { AccordionComponent } from '@shared/components/ui/accordion/accordion.component';
import { StandardControlComponent } from '@shared/components/forms/standard-control/standard-control.component';
import { TextareaControlComponent } from '@shared/components/forms/textarea-control/textarea-control.component';
import { PageSubtitleComponent } from '@shared/components/sections/page-subtitle/page-subtitle.component';
import { ModalService } from '@shared/services/modal/modal.service';
import { take } from 'rxjs';

@Component({
	selector: 'app-mobile-client-create-details',
	standalone: true,
	imports: [
		CommonModule,
		PageSubtitleComponent,
		FormsModule,
		ReactiveFormsModule,
		AccordionComponent,
		AccordionItemComponent,
		StandardControlComponent,
		TextareaControlComponent,
	],
	templateUrl: './mobile-client-create-details.component.html',
	styleUrl: './mobile-client-create-details.component.scss',
})
export class MobileClientCreateDetailsComponent {
	@Input() clientForm: FormGroup<IClientForm>;
	@Input() properties: Array<IClientProperty>;
	private modalService = inject(ModalService);

	openAddProperty(): void {
		const modalRef = this.modalService.open(AddPropertyModalComponent);
		modalRef.componentInstance.propertySelected.pipe(take(1)).subscribe((property: IClientProperty) => {
			this.properties.push(property);
		});
	}

	openEditProperty(clientProperty: IClientProperty, index: number): void {
		const modalRef = this.modalService.open(EditPropertyModalComponent);
		modalRef.componentInstance.clientProperty = clientProperty;
		modalRef.componentInstance.propertySelected.pipe(take(1)).subscribe((property: IClientProperty) => {
			this.properties[index] = property;
		});
		modalRef.componentInstance.propertyRemoved.pipe(take(1)).subscribe((propertyId: string) => {
			this.properties.splice(index, 1);
		});
	}
}
