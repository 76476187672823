<div class="page-container">
	<div class="section lead-status-filter">
		<h5 class="text-primary">Lead Status</h5>
		<app-dropdown-control
			[formGroup]="form"
			name="leadStatus"
			placeholder="Filter by status"
			[options]="leadStatusOptions"
			[multiple]="true"
			[clearable]="true"
		/>
	</div>

	<div class="content">
		<div class="section table-container">
			<div class="table-actions">
				<app-radio-group-control class="action-item" [formGroup]="form" name="leadRequestType" [options]="leadRequestTypeOptions" />
				<div class="action-item right">
					<app-search-control [control]="searchControl" placeholder="Search"></app-search-control>
					<button class="btn btn-sm btn-primary" type="button">
						<i class="bi bi-plus"> </i>
						Add new
					</button>
				</div>
			</div>
			<div class="table-headings">
				<div class="column-name">Client Name</div>
				<div class="column-name">Property Address</div>
				<div class="column-name">Assessment Type</div>
				<div class="column-name">Submitted Date</div>
				<div class="column-name">Preferred Date</div>
			</div>

			<app-loading-state [isLoading]="isLoading" [loadedContent]="loadedContent"></app-loading-state>
			<ng-template #loadedContent>
				<ng-container *ngIf="leads.length === 0; else leadsTable">
					<div class="table-row">
						<div class="inner-row">
							<em class="fw-bold">
								No
								{{ leadStatus.value.join(', ') | titlecase }}
								leads.
							</em>
						</div>
					</div>
				</ng-container>
				<ng-template #leadsTable>
					<ng-container *ngFor="let lead of leads">
						<div class="table-row cursor-p" (click)="onLeadItemClick(lead)">
							<div class="inner-row">{{ lead.personalDetails.name | nullDefault: 'N/A' }}</div>
							<div class="inner-row">
								{{ lead?.personalDetails?.installationAddress?.fullAddress | nullDefault: '[On Site Visit Only]' }}
							</div>
							<div class="inner-row">
								{{ lead?.onSiteVisitAssessment?.serviceType | nullDefault: '[On Site Visit Only]' }}
							</div>
							<div class="inner-row">{{ lead?.createdDate?.toDate() | date: 'yyyy-MM-dd' }}</div>
							<div class="inner-row">
								<ng-container *ngIf="lead.requestType === LeadRequestType.ON_SITE_VISIT">
									<ng-container *ngIf="lead.onSiteVisitAssessment?.dateTime?.type === LeadRequestDatetimeType.REQUEST">
										{{
											lead?.onSiteVisitAssessment?.dateTime?.requestedDate
												| ngbDateToDate
												| date: 'yyyy-MM-dd'
												| nullDefault: '[On Site Visit Only]'
										}}
									</ng-container>
									<ng-container *ngIf="lead.onSiteVisitAssessment?.dateTime?.type === LeadRequestDatetimeType.ASAP">
										ASAP
									</ng-container>
								</ng-container>
								<ng-container *ngIf="lead.requestType === LeadRequestType.CALL"> [On Site Visit Only] </ng-container>
							</div>
						</div>
					</ng-container>
				</ng-template>
			</ng-template>
		</div>
	</div>
</div>
