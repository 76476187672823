<app-loading-state [isLoading]="isLoading" [loadedContent]="loadedContent"> </app-loading-state>

<ng-template #loadedContent>
	<ng-container *ngIf="userHasAccess; else noAccess">
		<div class="business-hours-container">
			<div class="content">
				<form [formGroup]="businessHoursForm">
					<div class="form-container">
						<app-alert
							content="Clients will be able to select their preferred service date from these available week days in your leads
						form."
						></app-alert>

						<h4 class="text-primary">Business Days</h4>

						<ng-container *ngFor="let day of dayOrder">
							<div class="day-container">
								<div class="text-primary">{{ day | titlecase }}</div>
								<app-switch-control [name]="day" [formGroup]="businessHoursForm"></app-switch-control>
							</div>
						</ng-container>
					</div>
				</form>
			</div>
			<div class="footer">
				<button
					type="button"
					class="btn btn-sm btn-outline-danger btn-width"
					(click)="handleCancel()"
					[disabled]="businessHoursForm.pristine"
				>
					Cancel
				</button>
				<button
					type="button"
					class="btn btn-sm btn-primary btn-width"
					(click)="handleSave()"
					[disabled]="businessHoursForm.invalid || businessHoursForm.pristine"
				>
					<ng-container *ngIf="isSaving; else saveText">
						<div class="spinner-border spinner-border-sm" role="status">
							<span class="visually-hidden">Loading...</span>
						</div>
					</ng-container>
					<ng-template #saveText> Save </ng-template>
				</button>
			</div>
		</div>
	</ng-container>

	<ng-template #noAccess>
		<app-unauthorised-banner
			headingText="It looks like you do not have access to this organisation or do not have the required access level."
		></app-unauthorised-banner>
	</ng-template>
</ng-template>
