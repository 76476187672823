<app-loading-state [isLoading]="isLoading" [loadedContent]="loadedContent"></app-loading-state>
<ng-template #loadedContent>
	<div class="subpage-container">
		<div class="assessment">
			<div class="assessment-header">
				<p class="section-title">{{ schedulerFormItem.controls.requestType.value }} {{ index + 1 }}</p>
				<button class="remove-btn btn btn-sm btn-outline-danger" type="button" (click)="removeAssessment()">Remove</button>
			</div>
			<div class="assessment-border">
				<div class="assessment-content">
					<ng-container [ngSwitch]="selectedStep.switchCase">
						<ng-container *ngSwitchCase="NavigationStepCases.ASSIGN_EMPLOYEE">
							<h6 class="text-primary">Step 1 of 3: Assign Employee</h6>
							<div class="form-column">
								<div class="text-primary">Employee Name</div>
								<app-dropdown-control
									[formGroup]="schedulerFormItem"
									name="employeeId"
									placeholder="Select an employee"
									label="Employee"
									[options]="employees"
									[searchable]="true"
								></app-dropdown-control>
							</div>
							<div class="form-row">
								<div class="form-column">
									<div class="text-primary">Request Date</div>
									<app-date-picker-dropdown
										[form]="schedulerFormItem"
										name="selectedDate"
										[readonly]="true"
										[disabledDaysOfTheWeek]="disabledDaysOfTheWeek"
										[dateSelected]="dateSelected"
										(nextAvailableDate)="setRequestedDateToNextAvailable($event)"
									></app-date-picker-dropdown>
								</div>
							</div>
							<div class="form-row">
								<div class="form-column">
									<div class="text-primary">Requested Time</div>
									<div class="date-column">
										<mwl-calendar-day-view
											[viewDate]="timePickerSelectedDate"
											[dayStartHour]="9"
											[dayEndHour]="18"
											[events]="timePickerEvents"
											[refresh]="refreshTimePicker"
											(eventTimesChanged)="timePickerEventTimesChanged($event)"
										>
										</mwl-calendar-day-view>
									</div>
								</div>
							</div>
						</ng-container>
						<ng-container *ngSwitchCase="NavigationStepCases.ASSIGNMENT_DETAILS">
							<h6 class="text-primary">Step 2 of 3: Create Assessment</h6>
							<ng-container *ngIf="requestType.value === LeadRequestType.ON_SITE_VISIT">
								<div class="form-column">
									<div class="text-primary">Assessment Type</div>
									<div class="form-column assessment-details">
										<div class="form-row">
											<app-dropdown-control
												class="w-100"
												[formGroup]="schedulerFormItem"
												name="assessments"
												placeholder="Select a service"
												label="Service Type"
												[options]="serviceTypes"
												[multiple]="true"
												[searchable]="true"
												[clearable]="true"
											></app-dropdown-control>
										</div>
										<div class="form-row">
											<app-dropdown-control
												class="w-100"
												[formGroup]="schedulerFormItem"
												name="assessmentType"
												label="Assessment Type"
												placeholder="Select an assessment type"
												[options]="assessmentTypes"
											></app-dropdown-control>
										</div>
										<ng-container *ngIf="assessmentType?.value === ScheduledAssessmentType.ASSESSMENT">
											<div class="form-row">
												<app-standard-group-control
													class="w-100"
													type="number"
													name="callOutFeeRands"
													[formGroup]="schedulerFormItem"
													label="Call-Out Fee"
													[showLabel]="false"
													placeholder="Call-Out Fee"
													leftText="R"
												></app-standard-group-control>
											</div>
											<div class="form-row">
												<app-switch-control
													name="includeCallOutFeeComms"
													[formGroup]="schedulerFormItem"
													label="Include call-out fee in communication to client"
												></app-switch-control>
											</div>
										</ng-container>
									</div>
								</div>
							</ng-container>
							<div class="form-column">
								<div class="text-primary">Internal Assessment Notes</div>
								<app-textarea-control
									class="h-100"
									[resizable]="false"
									[height100]="true"
									name="assessmentNotes"
									[formGroup]="schedulerFormItem"
									label="Assessment Notes"
									placeholder="Assessment Notes"
								></app-textarea-control>
							</div>
							<div class="form-row justify-content-end">
								<button
									class="btn btn-primary"
									type="button"
									(click)="submitAssessment()"
									[disabled]="schedulerFormItem.invalid || this.assessmentSubmitted"
								>
									Create Assessment
								</button>
							</div>
						</ng-container>
						<ng-container *ngSwitchCase="NavigationStepCases.COMMUNICATIONS">
							<h6 class="text-primary">Step 3 of 3: Communications</h6>
							<div class="form-column">
								<div class="internal-header text-primary">WhatsApp Notes</div>
								<app-textarea-control
									class="h-100"
									[resizable]="false"
									[height100]="true"
									name="whatsappNotes"
									[formGroup]="schedulerFormItem"
									label="WhatsApp Notes"
									placeholder="WhatsApp Notes"
								></app-textarea-control>
							</div>
							<div class="form-row justify-content-end">
								<button class="btn btn-success btn-whatsapp" type="button">
									<i class="bi bi-whatsapp"></i>
									Share to WhatsApp
								</button>
							</div>
						</ng-container>
					</ng-container>
					<app-step-navigator [stepConfig]="navigationConfig" [(selectedStep)]="selectedStep"></app-step-navigator>
				</div>
			</div>
		</div>
	</div>
</ng-template>
