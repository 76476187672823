<div class="modal-header">
	<h5 class="modal-title text-primary">Select Client</h5>
	<button type="button" class="btn-close" (click)="closeModal()" aria-label="Close"></button>
</div>
<div class="modal-body">
	<app-dropdown-control
		[formGroup]="formGroup"
		name="selectedClient"
		placeholder="Select an client"
		label="Client"
		[options]="clientOptions"
		[searchable]="true"
		[customOptionTemplate]="clientSelectTemplate"
		[customSearchFn]="clientDropdownSearchFn"
		[clearable]="true"
	></app-dropdown-control>

	<ng-container *ngIf="selectedClient.value">
		<h6 class="text-primary">Select Property</h6>
		<app-radio-group-control
			[formGroup]="formGroup"
			name="propertySelectType"
			[options]="propertyTypeOptions"
		></app-radio-group-control>

		<ng-container *ngIf="propertySelectType.value === PropertySelectType.EXISTING">
			<ul class="list-group">
				<ng-container *ngFor="let property of selectedClient.value?.properties">
					<button
						class="list-group-item list-group-item-action"
						[class.active]="selectedClientProperty.value === property"
						(click)="setSelectedClientProperty(property)"
					>
						{{ property | property }}
					</button>
				</ng-container>
			</ul>
		</ng-container>
	</ng-container>
</div>
<div class="modal-footer">
	<button
		type="button"
		class="btn btn-primary btn-width"
		(click)="submit()"
		data-id="submit"
		[disabled]="formGroup.invalid || formGroup.pristine"
	>
		Confirm
	</button>
</div>

<ng-template #clientSelectTemplate let-item="item">
	<div>
		<ng-container *ngIf="item.icon">
			<i class="bi {{ item.icon }}"></i>
		</ng-container>
		{{ item.label }}
		<br />
		<small><i class="bi bi-envelope"></i> {{ item.object.clientEmail }}</small
		>&nbsp;
		<small><i class="bi bi-telephone"></i> {{ item.object.clientNumber | mobileNumber }}</small>
	</div>
</ng-template>
