import { Routes } from '@angular/router';
import { SettingsComponent } from './pages/settings/settings.component';
import { accessLevelGuard } from '@organisations/guards/access-level/access-level.guard';
import { SettingsUserComponent } from './pages/settings-user/settings-user.component';
import { SettingsOrganisationComponent } from './pages/settings-organisation/settings-organisation.component';
import { BusinessHoursComponent } from './components/organisation/business-hours/business-hours.component';
import { ServiceTypesComponent } from './components/organisation/service-types/service-types.component';
import { AssessmentFormComponent } from './components/organisation/assessment-form/assessment-form.component';
import { LeadsLinkComponent } from './components/organisation/leads-link/leads-link.component';

export enum SettingsRoutes {
	ORGANISATION = 'organisation',
	USER = 'user',
}

export enum OrganisationSettingsRoutes {
	BUSINESS_HOURS = 'business-hours',
	SERVICE_TYPES = 'service-types',
	ASSESSMENT_FORM = 'assessment-form',
	LEADS_LINK = 'leads-link',
}

export const settingsRoutes: Routes = [
	{
		path: '',
		component: SettingsComponent,
		title: 'ServeOx | Settings',
		children: [
			{
				path: '',
				redirectTo: SettingsRoutes.USER,
				pathMatch: 'full',
			},
			{
				path: SettingsRoutes.USER,
				component: SettingsUserComponent,
			},
			{
				path: SettingsRoutes.ORGANISATION,
				component: SettingsOrganisationComponent,
				canActivate: [accessLevelGuard],
				children: [
					{
						path: '',
						redirectTo: OrganisationSettingsRoutes.BUSINESS_HOURS,
						pathMatch: 'full',
					},
					{
						path: OrganisationSettingsRoutes.BUSINESS_HOURS,
						component: BusinessHoursComponent,
					},
					{
						path: OrganisationSettingsRoutes.SERVICE_TYPES,
						component: ServiceTypesComponent,
					},
					{
						path: OrganisationSettingsRoutes.ASSESSMENT_FORM,
						component: AssessmentFormComponent,
					},
					{
						path: OrganisationSettingsRoutes.LEADS_LINK,
						component: LeadsLinkComponent,
					},
					{
						path: '**',
						redirectTo: OrganisationSettingsRoutes.BUSINESS_HOURS,
					},
				],
			},
			{
				path: '**',
				redirectTo: SettingsRoutes.USER,
			},
		],
	},
	{
		path: '**',
		redirectTo: '',
	},
];
