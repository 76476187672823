<div class="modal-header">
	<h5 class="modal-title">Installation Address</h5>
	<button type="button" class="btn-close" (click)="closeModal()" aria-label="Close"></button>
</div>
<div class="modal-body">
	<app-address-with-search [formGroup]="propertyForm"></app-address-with-search>
</div>
<div class="modal-footer">
	<ng-container *ngIf="showRemove">
		<button type="button" [disabled]="propertyForm.invalid" class="btn btn-sm btn-danger btn-width" (click)="remove()" data-id="submit">
			Remove Property
		</button>
	</ng-container>
	<button type="button" [disabled]="propertyForm.invalid" class="btn btn-sm btn-primary btn-width" (click)="submit()" data-id="submit">
		Save Property
	</button>
</div>
