export interface ToastEvent {
	/**
	 * @param type Type of Toast to display
	 */
	type: ToastTypes;
	/**
	 * @param title Give the Toast a title
	 */
	title: string;
	/**
	 * @param message Main body of the Toast
	 */
	message: string;
	/**
	 * @param innerHtml Append innerHtml to the body of the Toast
	 */
	innerHtml?: string;
	/**
	 * @param autoHide True if you want the Toast to auto close itself. Default value is true.
	 * Error Toasts will have always be set to false regardless of this settings.
	 */
	autoHide?: boolean;
	/**
	 * @param yes If provided, a `Yes` button will be displayed in the body. Provide a Callback function to execute on click.
	 */
	yes?: () => void;
	/**
	 * @param no If provided, a `No` button will be displayed in the body. Provide a Callback function to execute on click.
	 */
	no?: () => void;
}

export enum ToastTypes {
	SUCCESS = 'success',
	INFO = 'info',
	WARNING = 'warning',
	ERROR = 'danger',
}
