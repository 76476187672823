import { environment } from 'src/environments/environment';

import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, NgZone, Output, ViewChild } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { IGoogleProperty } from '@shared/models/property';

@Component({
	selector: 'app-google-places-autocomplete',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './google-places-autocomplete.component.html',
	styleUrl: './google-places-autocomplete.component.scss',
})
export class GooglePlacesAutocompleteComponent {
	@Output() placeSelected = new EventEmitter<IGoogleProperty>();
	@ViewChild('searchInput', { static: true }) searchInput!: ElementRef;

	loader = new Loader({
		apiKey: environment.googleMapsApiKey,
		version: 'weekly',
		libraries: ['places'],
	});

	constructor(private ngZone: NgZone) {}

	ngOnInit(): void {
		this.loader.importLibrary('places').then((value) => {
			this.initializeAutocomplete();
		});
	}

	initializeAutocomplete(): void {
		const autocomplete = new google.maps.places.Autocomplete(this.searchInput.nativeElement, {
			componentRestrictions: { country: 'ZA' },
			types: ['address'],
		});

		autocomplete.addListener('place_changed', () => {
			this.ngZone.run(() => {
				const place = autocomplete.getPlace();
				if (place && place.address_components) {
					const address = this.parseAddressComponents(place.address_components);
					this.placeSelected.emit(address);
				}
			});
		});
	}

	parseAddressComponents(components: google.maps.GeocoderAddressComponent[]): IGoogleProperty {
		let streetName: string = '';
		let streetNumber: string = '';
		let property: IGoogleProperty = {
			city: '',
			country: '',
			postalCode: '',
			province: '',
			suburb: '',
			fullAddress: '',
		};

		components.forEach((component) => {
			const types = component.types;
			if (types.includes('street_number')) {
				streetNumber = component.long_name;
			}
			if (types.includes('route')) {
				streetName = component.long_name;
			}
			if (types.includes('postal_code')) {
				property.postalCode = component.long_name;
			}
			if (types.includes('locality')) {
				property.city = component.long_name;
			}
			if (types.includes('administrative_area_level_1')) {
				property.province = component.long_name;
			}
			if (types.includes('sublocality')) {
				property.suburb = component.long_name;
			}
			if (types.includes('country')) {
				property.country = component.long_name;
			}
		});

		return {
			...property,
			fullAddress: `${streetNumber} ${streetName}`,
		};
	}
}
