import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-confirmation-modal',
	standalone: true,
	imports: [],
	templateUrl: './confirmation-modal.component.html',
	styleUrl: './confirmation-modal.component.scss',
})
export class ConfirmationModalComponent {
	@Input() title: string;
	@Input() contentText: string;
	@Output() confirmAction = new EventEmitter<void>();
	private activeModal = inject(NgbActiveModal);

	closeModal(): void {
		this.activeModal.close();
	}

	confirm(): void {
		this.confirmAction.emit();
		this.closeModal();
	}
}
