import { inject, Injectable } from '@angular/core';
import { FirestoreService } from '@shared/services/firestore/firestore.service';
import { ILeadRequestForm } from '@leads/customer-facing/models/lead-request';
import { FireStoreCollection } from '@shared/models/firestore';
import { doc, Timestamp } from '@angular/fire/firestore';
import { FormGroup } from '@angular/forms';
import { IClient, IClientProperty } from '@clients/models/clients';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ClientsService } from '@clients/services/clients/clients.service';
import { EmailService } from '@shared/services/email/email.service';
import { ILeadRequestConfirmationMail } from '@shared/models/mail';
import { environment } from 'src/environments/environment';
import { AppRoutes } from '@app/app.routes';
import { CustomerLeadRequestRoutes } from '@leads/leads.routes';
import { ILeadRequest, LeadOnSiteVisitDateTimeType, LeadRequestType, LeadStatus, LeadTimeWindow } from '@leads/shared/models/lead.models';

@Injectable({
	providedIn: 'root',
})
export class LeadRequestsService extends FirestoreService<Partial<ILeadRequest>> {
	private clientsService = inject(ClientsService);
	private emailService = inject(EmailService);
	constructor() {
		super(FireStoreCollection.LEAD_REQUESTS);
	}

	async createLeadRequest(leadRequestForm: FormGroup<ILeadRequestForm>, organisationId: string, organisationName: string) {
		try {
			const organisationRef = doc(this.firestore, `${FireStoreCollection.ORGANISATIONS}/${organisationId}`);
			const existingClients = await this.clientsService.getClientByMobileNumber(
				organisationRef,
				leadRequestForm.controls.mobileNumber.value as string,
			);

			let clientId: string;
			const clientProperty: IClientProperty = leadRequestForm.controls.installationAddress.value as IClientProperty;

			if (existingClients.size === 0) {
				const client: IClient = {
					clientEmail: leadRequestForm.controls.contactDetails.controls.emailAddress.value as string,
					clientName: leadRequestForm.controls.contactDetails.controls.name.value as string,
					clientNameLower: leadRequestForm.controls.contactDetails.controls.name.value?.toLocaleLowerCase() as string,
					clientNumber: {
						countryCode: '+27',
						number: leadRequestForm.controls.mobileNumber.value as string,
					},
					organisation: organisationRef,
					internalNotes: null,
				};
				let properties: Array<IClientProperty> = [];
				if (!Object.values(clientProperty).every((value) => value === null)) {
					properties.push(clientProperty);
				}
				const clientResult = await this.clientsService.createClient(client, properties);
				clientId = clientResult.id;
			} else {
				clientId = existingClients.docs[0].id;

				const existingClientProperties = await this.clientsService.searchClientProperty(clientId, clientProperty);

				if (existingClientProperties.empty) {
					await this.clientsService.addClientProperty(clientId, clientProperty);
				}
			}

			const email = leadRequestForm.controls.contactDetails.controls.emailAddress.value as string;
			const clientName = leadRequestForm.controls.contactDetails.controls.name.value as string;
			const requestType = leadRequestForm.controls.requestType.value as LeadRequestType;
			const serviceType = leadRequestForm.controls.onsiteAssessment.controls.serviceType.value as string;
			const timeWindow = leadRequestForm.controls.onsiteAssessment.controls.requestedTimeWindow.value as LeadTimeWindow;

			const leadRequest: ILeadRequest = {
				createdDate: Timestamp.now(),
				updatedDate: Timestamp.now(),
				status: LeadStatus.NEW,
				organisation: organisationRef,
				client: doc(this.firestore, `${FireStoreCollection.CLIENTS}/${clientId}`),
				mobileNumber: {
					countryCode: '+27',
					number: leadRequestForm.controls.mobileNumber.value as string,
				},
				personalDetails: {
					emailAddress: email,
					name: clientName,
					nameLower: clientName.toLocaleLowerCase(),
					installationAddress: leadRequestForm.controls.installationAddress.value as IClientProperty,
				},
				requestType: requestType,
				callAssessment: {
					callNotes: leadRequestForm.controls.callAssessment.controls.notes.value as string,
				},
				onSiteVisitAssessment: {
					notes: leadRequestForm.controls.onsiteAssessment.controls.notes.value as string,
					serviceType: leadRequestForm.controls.onsiteAssessment.controls.serviceType.value as string,
					dateTime: {
						type: leadRequestForm.controls.onsiteAssessment.controls.dateType.value as LeadOnSiteVisitDateTimeType,
						requestedTimeWindow: timeWindow,
						requestedDate: leadRequestForm.controls.onsiteAssessment.controls.requestedDate.value as NgbDateStruct,
					},
				},
				internalNotes: null,
			};
			const leadRequestResult = await super.create(leadRequest);

			const day = leadRequestForm.controls.onsiteAssessment.controls.requestedDate.value?.day;
			const month = leadRequestForm.controls.onsiteAssessment.controls.requestedDate.value?.month;
			const year = leadRequestForm.controls.onsiteAssessment.controls.requestedDate.value?.year;
			let formattedDate: string;
			if (day && month && year) {
				formattedDate = `${day}/${month}/${year}: ${timeWindow}`;
			} else {
				formattedDate = 'N/A';
			}

			const leadRequestConfirmation: ILeadRequestConfirmationMail = {
				clientName: clientName,
				organisationName: organisationName,
				requestType: requestType,
				serviceType: serviceType ?? 'N/A',
				formattedDate: formattedDate,
				leadRequestLink: `${environment.baseUrl}/${AppRoutes.CUSTOMER_LEAD_REQUEST.replace(':organisationId', organisationId)}/${CustomerLeadRequestRoutes.VIEW_REQUEST.replace(':leadRequestId', leadRequestResult.id)}`,
			};

			await this.emailService.leadRequestConfirmation(email, leadRequestConfirmation);
			return leadRequestResult;
		} catch (error) {
			console.error(error);
			return null;
		}
	}

	getLeadRequest(leadRequestId: string) {
		return super.getDocumentData(leadRequestId);
	}

	updateStatus(leadRequestId: string, leadStatus: LeadStatus) {
		return super.update(leadRequestId, { status: leadStatus });
	}
}
