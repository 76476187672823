import { takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';

import { EventEmitter, inject, Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganisationSelectedService } from '@organisations/services/organisation-selected/organisation-selected.service';
import { ToastTypes } from '@shared/models/toast';
import { ToastService } from '@shared/services/toast/toast.service';

@Injectable({
	providedIn: 'root',
})
export class LeadRequestFormLinkService implements OnDestroy {
	private organisationSelectedService = inject(OrganisationSelectedService);
	private router = inject(Router);
	private route = inject(ActivatedRoute);

	private destroyed$ = new EventEmitter<void>();
	private toastService = inject(ToastService);
	private leadRequestUrl: string;

	constructor() {
		this.organisationSelectedService.selectedOrganisation.pipe(takeUntil(this.destroyed$)).subscribe((selectedOrganisation) => {
			if (selectedOrganisation) {
				this.leadRequestUrl = `${environment.baseUrl}/${selectedOrganisation.organisation.id}/lead-request`;
			}
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}

	getNewLeadLink(): string {
		return this.leadRequestUrl;
	}

	public copyNewLeadLink(): void {
		if (this.leadRequestUrl) {
			navigator.clipboard
				.writeText(this.leadRequestUrl)
				.then(() => {
					this.toastService.showToast({
						title: 'Success',
						message: 'Lead request form link copied to clipboard!',
						type: ToastTypes.SUCCESS,
					});
				})
				.catch(() => {
					this.toastService.showToast({
						title: 'Failed',
						message: 'Failed to copy the Lead request form link to clipboard.',
						type: ToastTypes.ERROR,
					});
				});
		} else {
			this.toastService.showToast({
				title: 'Failed',
				message: 'Sorry the link is not available at this time',
				type: ToastTypes.ERROR,
			});
		}
	}

	existingAssessment(assessmentId: string): string {
		return `${this.leadRequestUrl}/view/${assessmentId}`;
	}
}
