<div class="user-container">
	<button type="button" class="btn btn-primary w-100 p-2" (click)="openInviteUser()">
		<i class="bi bi-person-plus"></i>
		Create New User
	</button>

	<ul class="list-group">
		<ng-container *ngFor="let member of organisationMembers">
			<li class="list-group-item text-primary" role="button" (click)="openEditUser(member)">
				<div class="list-item d-flex">
					<div class="left-side">
						<div class="fw-bold">{{ member.displayName ?? member.email }}</div>
						<div>{{ member.accessLevel | titlecase }}</div>
					</div>
					<div>
						<span class="badge rounded-pill" [ngClass]="organisationStatusBackground(member)">{{
							member.status | titlecase
						}}</span>
					</div>
				</div>
			</li>
		</ng-container>
	</ul>
</div>
