import { filter, takeUntil } from 'rxjs';

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, OnInit } from '@angular/core';
import { DocumentReference } from '@angular/fire/firestore';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { LayoutService } from '@layout/services/layout/layout.service';
import { BillingTableComponent } from '@organisations/components/billing-table/billing-table.component';
import { IAvailableOrganisation, IOrganisationBill, OrganisationUserAccessLevel } from '@organisations/models/organisation-user';
import { OrganisationRoutes } from '@organisations/organisation.routes';
import { OrganisationSelectedService } from '@organisations/services/organisation-selected/organisation-selected.service';
import { OrganisationUserService } from '@organisations/services/organisation-user/organisation-user.service';
import { LoadingStateComponent } from '@shared/components/loading-state/loading-state.component';
import { UnauthorisedBannerComponent } from '@shared/components/ui/unauthorised-banner/unauthorised-banner.component';

@Component({
	selector: 'app-organisation-billing',
	standalone: true,
	imports: [
		CommonModule,
		UnauthorisedBannerComponent,
		RouterLink,
		ReactiveFormsModule,
		FormsModule,
		BillingTableComponent,
		LoadingStateComponent,
	],
	templateUrl: './organisation-billing.component.html',
	styleUrl: './organisation-billing.component.scss',
})
export class OrganisationBillingComponent implements OnInit {
	destroyed$ = new EventEmitter<void>();
	isMobileSize = true;
	userHasAccess = false;
	isLoading = true;
	selectedOrganisation: IAvailableOrganisation;
	OrganisationRoutes = OrganisationRoutes;
	organisationBill: IOrganisationBill = {
		invoices: [],
		totalAmount: 0,
	};

	billingMonth: string = new Date().toISOString().substring(0, 7);

	private layoutService = inject(LayoutService);
	private organisationUserService = inject(OrganisationUserService);
	private organisationSelectedService = inject(OrganisationSelectedService);

	ngOnInit(): void {
		this.layoutService.isMobileSize$.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
			this.isMobileSize = value;
		});

		this.subscribeToSelectedOrganisation();
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}

	subscribeToSelectedOrganisation(): void {
		this.organisationSelectedService.selectedOrganisation
			.pipe(
				takeUntil(this.destroyed$),
				filter((o) => o !== null),
			)
			.subscribe((selectedOrg) => {
				if (selectedOrg?.accessLevel === OrganisationUserAccessLevel.OWNER) {
					this.userHasAccess = true;
					this.selectedOrganisation = selectedOrg;
					this.aggregateBillingData(selectedOrg.organisation);
				} else {
					this.userHasAccess = false;
					this.isLoading = false;
				}
			});
	}

	onBillingMonthChange(): void {
		this.isLoading = true;
		this.aggregateBillingData(this.selectedOrganisation.organisation);
	}

	aggregateBillingData(orgRef: DocumentReference): void {
		const dateSplit = this.billingMonth.split('-');
		const year = Number(dateSplit[0]);
		const month = Number(dateSplit[1]);
		this.organisationUserService
			.billableUsersByOrganisation(orgRef, month, year)
			.pipe(takeUntil(this.destroyed$))
			.subscribe((organisationBill) => {
				this.organisationBill = organisationBill;
				this.isLoading = false;
			});
	}
}
