import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IContactDetailsForm } from '@leads/customer-facing/models/domain/lead-request.domain';
import { IOrganisation } from '@organisations/models/organisations';
import { StandardControlComponent } from '@shared/components/forms/standard-control/standard-control.component';
import { HeaderPopoverComponent } from '@shared/components/sections/header-popover/header-popover.component';
import { IPopover } from '@shared/models/popover';

import { CustomFormDetailsComponent } from '../custom-form-details/custom-form-details.component';

@Component({
	selector: 'app-lead-contact-information',
	standalone: true,
	imports: [CommonModule, FormsModule, ReactiveFormsModule, HeaderPopoverComponent, StandardControlComponent, CustomFormDetailsComponent],
	templateUrl: './lead-contact-information.component.html',
	styleUrl: './lead-contact-information.component.scss',
	host: {
		class: 'h-100',
	},
})
export class LeadContactInformationComponent {
	@Input() form: FormGroup<IContactDetailsForm>;
	@Input() organisation: IOrganisation;

	infoPopOver: IPopover = {
		triggerText: {
			desktop: "What's this?",
			mobile: "What's this?",
		},
		title: 'Contact Information',
		description: 'Please provide your name and email address so we can attach these to your request and keep you updated.',
		icon: 'bi-info-circle-fill',
	};
}
