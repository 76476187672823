import { Component, inject } from '@angular/core';
import { LeadRequestFormLinkService } from '@leads/shared/services/lead-request-form-link/lead-request-form-link.service';
import { AlertComponent } from '@shared/components/alerts/alert/alert.component';

@Component({
	selector: 'app-leads-link',
	standalone: true,
	imports: [AlertComponent],
	templateUrl: './leads-link.component.html',
	styleUrl: './leads-link.component.scss',
})
export class LeadsLinkComponent {
	service = inject(LeadRequestFormLinkService);

	copyToClipboard() {
		this.service.copyNewLeadLink();
	}
}
