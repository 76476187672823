import { Injectable } from '@angular/core';
import { FirestoreService } from '../firestore/firestore.service';
import { ILeadRequestConfirmationMail, IMail, MailTemplate } from '@shared/models/mail';
import { FireStoreCollection } from '@shared/models/firestore';
import { environment } from 'src/environments/environment';
import { AppRoutes } from 'src/app/app.routes';
import { DocumentReference } from '@angular/fire/firestore';

@Injectable({
	providedIn: 'root',
})
export class EmailService extends FirestoreService<IMail> {
	constructor() {
		super(FireStoreCollection.MAIL);
	}

	async inviteUserToOrganisation(email: string, organisationName: string): Promise<DocumentReference<IMail>> {
		return this.create({
			to: [email],
			from: 'Serveox Team <noreply@serveox.com>',
			template: {
				name: MailTemplate.ORGANISATION_INVITATION,
				data: {
					invitationLink: `${environment.baseUrl}/${AppRoutes.DASHBOARD}`,
					organisationName: organisationName,
				},
			},
		});
	}

	async leadRequestConfirmation(email: string, leadRequestConfirmation: ILeadRequestConfirmationMail): Promise<DocumentReference<IMail>> {
		return this.create({
			to: [email],
			from: `${leadRequestConfirmation.organisationName} <noreply@serveox.com>`,
			template: {
				name: MailTemplate.LEAD_REQUEST_SUBMISSION,
				data: leadRequestConfirmation,
			},
		});
	}
}
