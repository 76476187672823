import { Component, inject, Input } from '@angular/core';
import { IAvailableOrganisation } from '@organisations/models/organisation-user';
import { OrganisationUserService } from '@organisations/services/organisation-user/organisation-user.service';
import { ConfirmationModalComponent } from '@shared/components/modals/confirmation-modal/confirmation-modal.component';
import { ToastTypes } from '@shared/models/toast';
import { ModalService } from '@shared/services/modal/modal.service';
import { ToastService } from '@shared/services/toast/toast.service';
import { take } from 'rxjs';

@Component({
	selector: 'app-organisation-invitation-banner',
	standalone: true,
	imports: [],
	templateUrl: './organisation-invitation-banner.component.html',
	styleUrl: './organisation-invitation-banner.component.scss',
})
export class OrganisationInvitationBannerComponent {
	@Input() organisationInvitation: IAvailableOrganisation;

	private organisationUserService = inject(OrganisationUserService);
	private toastService = inject(ToastService);
	private modalService = inject(ModalService);

	acceptInvitation(): void {
		this.organisationUserService.acceptInvitation(this.organisationInvitation)?.then(() => {
			this.toastService.showToast({
				title: 'Organisation Invitation',
				message: `You are now a part of ${this.organisationInvitation.companyName}.`,
				type: ToastTypes.SUCCESS,
			});
		});
	}

	rejectInvitation(): void {
		const modalRef = this.modalService.open(ConfirmationModalComponent, false, 'md');
		modalRef.componentInstance.title = 'Are you sure you want to reject this organisation invitation?';
		modalRef.componentInstance.contentText = this.organisationInvitation.companyName;
		modalRef.componentInstance.confirmAction.pipe(take(1)).subscribe(() => {
			this.organisationUserService.rejectInvitation(this.organisationInvitation)?.then(() => {
				this.toastService.showToast({
					title: 'Organisation Invitation',
					message: `You declined the invitation to join ${this.organisationInvitation.companyName}.`,
					type: ToastTypes.INFO,
				});
			});
		});
	}
}
