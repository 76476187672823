import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { IPropertyForm } from '@clients/models/domain/client.domain';
import { IClientProperty } from '@clients/models/firebase/client.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddressWithSearchComponent } from '@shared/components/forms/address-with-search/address-with-search.component';

@Component({
	selector: 'app-edit-property-modal',
	standalone: true,
	imports: [CommonModule, FormsModule, ReactiveFormsModule, AddressWithSearchComponent],
	templateUrl: './edit-property-modal.component.html',
	styleUrl: './edit-property-modal.component.scss',
})
export class EditPropertyModalComponent implements OnInit {
	@Input() clientProperty: IClientProperty;
	@Input() showRemove: boolean = true;
	@Output() propertySelected = new EventEmitter<IClientProperty>();
	@Output() propertyRemoved = new EventEmitter<string>();
	private activeModal = inject(NgbActiveModal);

	propertyForm: FormGroup<IPropertyForm>;

	ngOnInit() {
		this.propertyForm = new FormGroup<IPropertyForm>({
			fullAddress: new FormControl(null, [Validators.required, Validators.minLength(2)]),
			suburb: new FormControl(null, [Validators.required, Validators.minLength(2)]),
			city: new FormControl(null, [Validators.required, Validators.minLength(2)]),
			province: new FormControl(null, [Validators.required, Validators.minLength(2)]),
			country: new FormControl(null, [Validators.required, Validators.minLength(2)]),
			postalCode: new FormControl(null, [Validators.required, Validators.minLength(2)]),
		});

		this.propertyForm.patchValue(this.clientProperty);
	}

	closeModal(): void {
		this.activeModal.close();
	}

	remove(): void {
		this.propertyRemoved.emit(this.clientProperty.id);
		this.closeModal();
	}

	submit(): void {
		if (this.propertyForm.invalid) {
			this.propertyForm.markAllAsTouched();
			return;
		}
		this.propertySelected.emit({ ...(this.propertyForm.value as IClientProperty), id: this.clientProperty.id });
		this.closeModal();
	}
}
