import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextareaControlComponent } from '@shared/components/forms/textarea-control/textarea-control.component';
import { HeaderPopoverComponent } from '@shared/components/sections/header-popover/header-popover.component';
import { IPopover } from '@shared/models/popover';

@Component({
	selector: 'app-lead-notes',
	standalone: true,
	imports: [CommonModule, FormsModule, ReactiveFormsModule, HeaderPopoverComponent, TextareaControlComponent],
	templateUrl: './lead-notes.component.html',
	styleUrl: './lead-notes.component.scss',
})
export class LeadNotesComponent {
	@Input() title: string;
	@Input() form: FormGroup;
	@Input() showAssistance: boolean = true;

	infoPopover: IPopover = {
		triggerText: {
			desktop: "What's this?",
			mobile: "What's this?",
		},
		title: 'Notes',
		description:
			'Type any important details or instructions here for the specialist. This helps them understand your specific needs better.',
		icon: 'bi-info-circle-fill',
	};
}
