import { takeUntil } from 'rxjs';

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, OnDestroy, OnInit } from '@angular/core';
import { DocumentReference } from '@angular/fire/firestore';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { AppRoutes } from '@app/app.routes';
import { LayoutService } from '@layout/services/layout/layout.service';
import { ClientLeadsRoutes } from '@leads/client-facing/client-facing.routes';
import { CustomFormDetailsComponent } from '@leads/customer-facing/components/forms/custom-form-details/custom-form-details.component';
import { DesktopLeadAssessmentDetailsComponent } from '@leads/customer-facing/components/forms/desktop-lead-assessment-details/desktop-lead-assessment-details.component';
import { LeadNotesComponent } from '@leads/customer-facing/components/forms/lead-notes/lead-notes.component';
import { MobileLeadAssessmentDetailsComponent } from '@leads/customer-facing/components/forms/mobile-lead-assessment-details/mobile-lead-assessment-details.component';
import { ILeadRequestForm } from '@leads/customer-facing/models/domain/lead-request.domain';
import { LeadRequestFormService } from '@leads/customer-facing/services/lead-request-form/lead-request-form.service';
import { CustomerLeadRequestRoutes } from '@leads/leads.routes';
import { LeadRequestType } from '@leads/shared/models/domain/lead-request.domain';
import { ILeadRequest } from '@leads/shared/models/firebase/lead-request.model';
import { LeadRequestService } from '@leads/shared/services/lead-request/lead-request.service';
import { IOrganisation } from '@organisations/models/organisations';
import { OrganisationService } from '@organisations/services/organisation/organisation.service';
import { convertBusinessHoursToDisabledDaysOfTheWeek, IDisabledDaysOfTheWeek } from '@shared/components/calendar/models';
import { IDropdownOption } from '@shared/components/forms/dropdown-control/dropdown-control.component';
import { LoadingStateComponent } from '@shared/components/loading-state/loading-state.component';
import { ToastTypes } from '@shared/models/toast';
import { ToastService } from '@shared/services/toast/toast.service';

@Component({
	selector: 'app-lead-request-assessment-details',
	standalone: true,
	imports: [
		CommonModule,
		RouterLink,
		LeadNotesComponent,
		DesktopLeadAssessmentDetailsComponent,
		MobileLeadAssessmentDetailsComponent,
		LoadingStateComponent,
		CustomFormDetailsComponent,
	],
	templateUrl: './lead-request-assessment-details.component.html',
	styleUrl: './lead-request-assessment-details.component.scss',
})
export class LeadRequestAssessmentDetailsComponent implements OnInit, OnDestroy {
	private layoutService = inject(LayoutService);
	private toastService = inject(ToastService);
	private leadRequestService = inject(LeadRequestService);
	private leadRequestFormService = inject(LeadRequestFormService);
	private organisationService = inject(OrganisationService);
	private route = inject(ActivatedRoute);
	private router = inject(Router);

	organisationId: string;
	organisation: IOrganisation;
	disabledDaysOfTheWeek: IDisabledDaysOfTheWeek;
	serviceTypes: Array<IDropdownOption> = [];

	isLoading = true;
	isSubmitting = false;

	destroyed$ = new EventEmitter<void>();
	isMobileSize = true;
	leadRequestForm: FormGroup<ILeadRequestForm>;
	CustomerLeadRequestRoutes = CustomerLeadRequestRoutes;
	LeadRequestType = LeadRequestType;

	ngOnInit(): void {
		this.leadRequestForm = this.leadRequestFormService.getForm();
		this.validatePreviousStep();

		this.organisationId = this.route.parent?.parent?.snapshot.params.organisationId;
		this.fetchOrganisation();

		this.layoutService.isMobileSize$.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
			this.isMobileSize = value;
		});
	}

	private fetchOrganisation() {
		this.organisationService.getDocSnapshot(this.organisationId).then((doc) => {
			if (doc.exists()) {
				this.organisation = doc.data();

				this.disabledDaysOfTheWeek = convertBusinessHoursToDisabledDaysOfTheWeek(this.organisation.settings.businessHours);
				this.serviceTypes = this.organisation.settings.serviceTypes.map((s, index) => ({
					id: index + 1,
					value: s,
					label: s,
					icon: 'bi-wrench',
				}));
				this.isLoading = false;
			}
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}

	validatePreviousStep(): void {
		if (this.leadRequestForm.controls.contactDetails.invalid) {
			this.router.navigate(['../' + CustomerLeadRequestRoutes.NEW], { relativeTo: this.route });
		}

		if (
			this.leadRequestForm.controls.requestType.value === LeadRequestType.ON_SITE_VISIT &&
			this.leadRequestForm.controls.installationAddress.invalid
		) {
			this.router.navigate(['../' + CustomerLeadRequestRoutes.NEW], { relativeTo: this.route });
		}
	}

	get requestType(): FormControl<LeadRequestType | null> {
		return this.leadRequestForm.controls.requestType;
	}

	private redirectTo(result: DocumentReference<ILeadRequest>) {
		if (this.leadRequestFormService.getRedirectToLeads()) {
			this.router.navigate([
				`${AppRoutes.CLIENT_LEADS_DASHBOARD}/${ClientLeadsRoutes.ASSESSMENT_REVIEW.replace(':leadRequestId', result.id)}`,
			]);
		} else {
			this.router.navigate(['../' + CustomerLeadRequestRoutes.SUCCESS.replace(':leadRequestId', result.id)], {
				relativeTo: this.route,
			});
		}
	}

	submit(): void {
		if (this.leadRequestForm.invalid) {
			return;
		}

		this.isSubmitting = true;

		this.leadRequestService
			.createLeadRequest(this.leadRequestForm, this.organisationId, this.organisation.companyName)
			.then((result) => {
				this.isSubmitting = false;

				if (result) {
					this.leadRequestForm.reset();
					this.redirectTo(result);
				}
			})
			.catch((error) => {
				console.error(error);
				this.isSubmitting = false;
				this.toastService.showToast({
					title: 'Request',
					message: 'Failed to submit request. Please contact support if the issue persists.',
					type: ToastTypes.ERROR,
				});
			});
	}
}
