import { Pipe, PipeTransform } from '@angular/core';
import { MobileNumber } from '@shared/models/mobile-number';

@Pipe({
	standalone: true,
	name: 'mobileNumber',
})
export class MobileNumberPipe implements PipeTransform {
	transform(value: MobileNumber): string {
		if (!value) {
			return '';
		}
		return `(${value.countryCode}) ${value.number}`;
	}
}
