<form [formGroup]="formGroup">
	<ng-container *ngIf="showLabel && label">
		<label [for]="name" class="form-label"
			>{{ label }}<ng-container *ngIf="control.hasValidator(Validators.required)">*</ng-container>
		</label>
	</ng-container>
	<ng-select
		[items]="options"
		[formControlName]="name"
		[class.is-invalid]="control.invalid && control.touched"
		bindLabel="label"
		bindValue="value"
		[placeholder]="placeholder"
		[multiple]="multiple"
		[searchable]="searchable"
		[clearable]="clearable"
		[searchFn]="customSearchFn"
	>
		<!-- @see available directives (ng-label-tmp, ng-option-tmp) -->
		<!-- https://github.com/ng-select/ng-select/blob/master/src/ng-select/lib/ng-templates.directive.ts -->

		<!-- Selected item template -->
		<ng-template ng-label-tmp let-item="item">
			<ng-template [ngTemplateOutlet]="customLabelTemplate || defaultTemplate" [ngTemplateOutletContext]="{ item: item }">
			</ng-template>
		</ng-template>

		<!-- Option item template -->
		<ng-template ng-option-tmp let-item="item">
			<ng-template [ngTemplateOutlet]="customOptionTemplate || defaultTemplate" [ngTemplateOutletContext]="{ item: item }">
			</ng-template>
		</ng-template>
	</ng-select>
	<div *ngIf="control.invalid && control.errors" class="invalid-feedback">
		<p *ngIf="control.errors?.required">{{ label }} required.</p>
	</div>
</form>

<ng-template #defaultTemplate let-item="item">
	<div>
		<ng-container *ngIf="item.icon">
			<i class="bi {{ item.icon }}"></i>
		</ng-container>
		{{ item.label }}
	</div>
</ng-template>
