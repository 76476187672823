<app-loading-state [isLoading]="isLoading" [loadedContent]="loadedContent"></app-loading-state>
<ng-template #loadedContent>
	<div class="page-container">
		<div class="heading">
			<app-page-title
				[backPath]="'/' + AppRoutes.CLIENT_LEADS_DASHBOARD"
				title="{{ leadRequest.status | titlecase }} Assessment Request "
			></app-page-title>
		</div>

		<div class="content">
			<ng-container *ngIf="isMobileSize; else desktopSize">
				<app-mobile-assessment-request-details
					[leadRequest]="leadRequest"
					[client]="client"
					[internalNotesForm]="internalNotesForm"
					[schedulerForm]="schedulerForm"
					(assessmentRemoved)="removeAssessment($event)"
					(onSiteAssessmentAdd)="addOnSiteAssessment()"
					(callAssessmentAdd)="addCallAssessment()"
				></app-mobile-assessment-request-details>
			</ng-container>
			<ng-template #desktopSize>
				<app-desktop-assessment-request-details
					[leadRequest]="leadRequest"
					[client]="client"
					[internalNotesForm]="internalNotesForm"
					[schedulerForm]="schedulerForm"
					(assessmentRemoved)="removeAssessment($event)"
					(onSiteAssessmentAdd)="addOnSiteAssessment()"
					(callAssessmentAdd)="addCallAssessment()"
				></app-desktop-assessment-request-details>
			</ng-template>
		</div>

		<div class="footer">
			<ng-container *ngIf="leadRequest.status === LeadStatus.NEW">
				<button class="btn btn-sm btn-outline-danger btn-width" type="button" (click)="rejectAssessment()">
					<ng-container *ngIf="isUpdatingStatus; else notUpdating">
						<div class="spinner-border spinner-border-sm" role="status">
							<span class="visually-hidden">Loading...</span>
						</div>
					</ng-container>
					<ng-template #notUpdating>Reject Assessment</ng-template>
				</button>
			</ng-container>
			<ng-container *ngIf="leadRequest.status === LeadStatus.REJECTED">
				<button class="btn btn-sm btn-outline-warning btn-width" type="button" (click)="recoverAssessment()">
					<ng-container *ngIf="isUpdatingStatus; else notUpdating">
						<div class="spinner-border spinner-border-sm" role="status">
							<span class="visually-hidden">Loading...</span>
						</div>
					</ng-container>
					<ng-template #notUpdating>Recover Assessment</ng-template>
				</button>
			</ng-container>
			<button
				class="btn btn-sm btn-outline-primary btn-width"
				type="button"
				[disabled]="internalNotesForm.invalid || internalNotesForm.pristine || isSavingNotes"
				(click)="saveNotes()"
			>
				<ng-container *ngIf="isSavingNotes; else notSaving">
					<div class="spinner-border spinner-border-sm" role="status">
						<span class="visually-hidden">Loading...</span>
					</div>
				</ng-container>
				<ng-template #notSaving>Save Notes</ng-template>
			</button>
		</div>
	</div>
</ng-template>
