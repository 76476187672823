<div class="calendar-cell">
	<div class="calendar-cell-top">
    <span class="calendar-day-number" [ngClass]="{'active': day.isToday}">
      {{ day.date | calendarDate:'monthViewDayNumber':locale }}
    </span>
	</div>
	<div class="calendar-cell-events">
		<ng-container *ngIf="day.events.length < eventDisplayThreshold">
			<ng-container *ngFor="let event of day.events; let i = index">
				<ng-container *ngTemplateOutlet="eventTemplate; context: { event: event, day: day }"></ng-container>
			</ng-container>
		</ng-container>
		<ng-container *ngIf="day.events.length >= eventDisplayThreshold">
			<ng-container *ngFor="let event of day.events.slice(0, eventDisplayThreshold - 1); let i = index">
				<ng-container *ngTemplateOutlet="eventTemplate; context: { event: event, day: day }"></ng-container>
			</ng-container>
			<div class="calendar-cell-event remaining-events" (click)="togglePopup($event)">
				<div class="event">
					<div class="event-title text-truncate">{{ day.events.length - (eventDisplayThreshold - 1) }} more</div>
				</div>
			</div>
		</ng-container>
	</div>

	<div #popup class="popup" [ngClass]="{ 'd-none': !isPopupVisible }">
		<div class="popup-header">
		  <span class="popup-day">{{ day.date | date: 'EEE' | uppercase }}</span>
		  <span class="popup-date">{{ day.date | date: 'd' }}</span>
		  <button class="close-button" (click)="togglePopup($event)">X</button>
		</div>
		<div class="popup-body">
		  <div class="calendar-cell-events">
			<ng-container *ngFor="let event of day.events">
			  <ng-container *ngTemplateOutlet="eventTemplate; context: { event: event, day: day }"></ng-container>
			</ng-container>
		  </div>
		</div>
	  </div>
</div>

<ng-template #eventTemplate let-event="event" let-day="day">
	<div
		class="calendar-cell-event"
		mwlDraggable
		[dropData]="{event: event}"
		[ngClass]="eventClasses(event, day)"
		[ngbPopover]="eventPopoverTemplate"
		[touchStartLongPress]="{ delay: 300, delta: 30 }"
		popoverClass="popover-body"
		placement="auto"
		triggers="manual"
		#p1="ngbPopover"
		(click)="toggleEventPopover(p1, event, day)"
		(mwlKeydownEnter)="toggleEventPopover(p1, event, day)"
	>
		<div class="event">
			<div class="event-circle"></div>
			<div class="event-time">{{ event.start | calendarDayCellTime | lowercase }}</div>
			<div class="event-title text-truncate">{{ event.title }}</div>
		</div>
	</div>
</ng-template>

<ng-template #eventPopoverTemplate let-event="event">
	<app-calendar-popover [event]="event"></app-calendar-popover>
</ng-template>
