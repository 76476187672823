<ng-container *ngIf="isMobileSize; else desktopSize">
	<div class="m-layout-container">
		<div class="m-header">
			<app-mobile-header></app-mobile-header>
		</div>
		<div class="m-content">
			<router-outlet></router-outlet>
		</div>
		<div class="m-footer">
			<app-mobile-footer></app-mobile-footer>
		</div>
	</div>
</ng-container>

<ng-template #desktopSize>
	<div class="layout-container">
		<app-sidebar></app-sidebar>

		<div class="content-container">
			<div class="border-container">
				<div class="page-container">
					<router-outlet></router-outlet>
				</div>
			</div>
		</div>
	</div>
</ng-template>
