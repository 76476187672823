import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink, RouterLinkActive } from '@angular/router';
import { INavigationItem } from '@layout/models/navigation';
import { CustomerLeadRequestRoutes } from '@leads/leads.routes';
import { Offcanvas } from 'bootstrap';
import { takeUntil } from 'rxjs';

@Component({
	selector: 'app-menu-customer',
	standalone: true,
	imports: [CommonModule, RouterLink, RouterLinkActive],
	templateUrl: './menu-customer.component.html',
	styleUrl: './menu-customer.component.scss',
})
export class MenuCustomerComponent implements OnInit, OnDestroy {
	@Input() isCollapsed = false;
	destroyed$ = new EventEmitter<void>();

	private route = inject(ActivatedRoute);

	navigationItems: Array<INavigationItem> = [];
	organisationId: string;

	ngOnInit(): void {
		this.route.params.pipe(takeUntil(this.destroyed$)).subscribe((params) => {
			this.organisationId = params.organisationId;
			this.setNavigationItems();
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}

	setNavigationItems(): void {
		this.navigationItems = [
			{
				icon: 'bi-grid-1x2-fill',
				label: 'New Request',
				route: [CustomerLeadRequestRoutes.NEW],
				disabled: false,
				visible: true,
			},
		];
	}

	dismissOffcanvas(): void {
		const mobileOffcanvas = Offcanvas.getInstance('#mobileNavbar');
		mobileOffcanvas?.hide();
	}
}
