import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { IAuthContent } from '@auth/models/auth-layout';
import { PolicyFooterComponent } from '../policy-footer/policy-footer.component';

@Component({
	selector: 'app-auth-layout',
	standalone: true,
	imports: [RouterOutlet, CommonModule, PolicyFooterComponent],
	templateUrl: './auth-layout.component.html',
	styleUrl: './auth-layout.component.scss',
})
export class AuthLayoutComponent {
	content: Array<IAuthContent> = [
		{
			title: 'Automate Lead Capture',
			description: 'Convert WhatsApp Enquiries into Potential Clients.',
			icon: 'assets/app-icons/people-group.svg',
		},
		{
			title: 'Simplified Scheduling',
			description: 'Manage Every Interaction with Streamlined Scheduling.',
			icon: 'assets/app-icons/chart-timeline.svg',
		},
		{
			title: 'Connect Your Calendars',
			description: 'Sync Your Business and Personal Life Effortlessly Across All Platforms.',
			icon: 'assets/app-icons/briefcase-outline.svg',
		},
	];
}
