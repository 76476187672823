import { Injectable } from '@angular/core';
import { IAvailableOrganisation } from '@organisations/models/organisation-user';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class OrganisationSelectedService {
	selectedOrganisation: BehaviorSubject<IAvailableOrganisation | null>;
	constructor() {
		this.selectedOrganisation = new BehaviorSubject<IAvailableOrganisation | null>(null);
	}

	setSelectedOrganisation(availableOrganisation: IAvailableOrganisation): void {
		this.selectedOrganisation.next(availableOrganisation);
		localStorage.setItem('selectedOrganisationId', availableOrganisation.id as string);
	}

	clearSelectedOrganisation(): void {
		this.selectedOrganisation.next(null);
	}
}
