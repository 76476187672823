import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { OrganisationRoutes } from '@organisations/organisation.routes';
import { PageTitleComponent } from '@shared/components/sections/page-title/page-title.component';
import { StepNavigatorComponent } from '@shared/components/ui/step-navigator/step-navigator.component';
import { IStepNavigatorConfig, StepModes, StepUI } from '@shared/models/step-navigator';

@Component({
	selector: 'app-organisation',
	standalone: true,
	imports: [CommonModule, PageTitleComponent, RouterOutlet, StepNavigatorComponent],
	templateUrl: './organisation.component.html',
	styleUrl: './organisation.component.scss',
})
export class OrganisationComponent {
	stepConfig: IStepNavigatorConfig = {
		ui: StepUI.WIZARD,
		mode: StepModes.ROUTER_OUTLET,
		showStepChevrons: false,
		showStepNumbers: false,
		showNavButtons: true,
		steps: [
			{
				title: {
					desktop: 'Company Details',
					mobile: 'Details',
				},
				icon: 'bi-briefcase',
				route: [OrganisationRoutes.DETAILS],
				disabled: false,
			},
			{
				title: {
					desktop: 'Users',
					mobile: 'Users',
				},
				icon: 'bi-clipboard',
				route: [OrganisationRoutes.USERS],
				disabled: false,
			},
			{
				title: {
					desktop: 'Billing',
					mobile: 'Billing',
				},
				icon: 'bi-receipt',
				route: [OrganisationRoutes.BILLING],
				disabled: false,
			},
		],
	};
}
