<app-loading-state [isLoading]="isLoading" [loadedContent]="loadedContent"> </app-loading-state>

<ng-template #loadedContent>
	<ng-container *ngIf="userHasAccess; else noAccess">
		<div class="billing-container">
			<div class="content">
				<app-billing-table
					[isMobile]="isMobileSize"
					[organisationBill]="organisationBill"
					[(billingMonth)]="billingMonth"
					(billingMonthChange)="onBillingMonthChange()"
				></app-billing-table>
			</div>
			<div class="footer">
				<button type="button" class="btn btn-sm btn-outline-primary btn-width" [routerLink]="'../' + OrganisationRoutes.USERS">
					<i class="bi bi-arrow-left"></i>
					Previous
				</button>
			</div>
		</div>
	</ng-container>

	<ng-template #noAccess>
		<app-unauthorised-banner
			headingText="It looks like you do not have access to this organisation or do not have the required access level."
		></app-unauthorised-banner>
	</ng-template>
</ng-template>
