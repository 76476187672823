import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { LayoutService } from '@layout/services/layout/layout.service';
import { IPopover } from '@shared/models/popover';
import { Popover } from 'bootstrap';
import { takeUntil } from 'rxjs';

@Component({
	selector: 'app-popover',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './popover.component.html',
	styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit, OnDestroy, AfterViewInit {
	@Input() popover: IPopover;

	destroyed$ = new EventEmitter<void>();
	isMobileSize = false;

	private layoutService = inject(LayoutService);

	ngOnInit(): void {
		this.layoutService.isMobileSize$.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
			this.isMobileSize = value;
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}

	ngAfterViewInit(): void {
		const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]');
		popoverTriggerList.forEach(
			(popoverTriggerEl) =>
				new Popover(popoverTriggerEl, {
					trigger: 'hover focus',
				}),
		);
	}
}
