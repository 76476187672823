<div class="client-details-container">
	<app-page-subtitle title="Basic Client Details"></app-page-subtitle>

	<form [formGroup]="clientForm">
		<div class="form-container">
			<div class="form-column">
				<app-standard-control
					type="text"
					name="clientName"
					[formGroup]="clientForm"
					label="Client Name"
					placeholder="Client Name"
				></app-standard-control>

				<app-standard-control
					type="text"
					name="clientNumber"
					[formGroup]="clientForm"
					label="Client Contact Number"
					placeholder="Client Contact Number"
					patternMessage="Client Contact must be a valid phone number."
				></app-standard-control>

				<app-standard-control
					type="email"
					name="clientEmail"
					[formGroup]="clientForm"
					label="Client Contact Email"
					placeholder="Client Contact Email"
				></app-standard-control>
			</div>
			<div class="form-column">
				<h5 class="text-primary">Internal Notes</h5>

				<app-textarea-control
					class="h-100"
					body
					[resizable]="false"
					[height100]="true"
					name="internalNotes"
					[formGroup]="clientForm"
					label="Internal Notes"
					placeholder="Internal Notes"
				></app-textarea-control>
			</div>
		</div>
	</form>
</div>
