<div class="accordion-item">
	<h4 class="accordion-header">
		<button
			class="accordion-button"
			[class.collapsed]="!openOnInit"
			type="button"
			data-bs-toggle="collapse"
			[attr.data-bs-target]="'#' + itemId"
			[attr.aria-expanded]="openOnInit"
			[attr.aria-controls]="itemId"
		>
			<ng-content select="[header]"></ng-content>
		</button>
	</h4>
	<div [id]="itemId" class="accordion-collapse collapse" [class.show]="openOnInit" [attr.data-bs-parent]="'#' + parentId">
		<div class="accordion-body">
			<ng-content select="[body]" class="body"></ng-content>
		</div>
	</div>
</div>
