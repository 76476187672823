import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IOrganisationForm, OrganisationEmployeeSize, OrganisationIndustry } from '@organisations/models/organisations';
import { SelectControlComponent } from '@shared/components/forms/select-control/select-control.component';
import { StandardControlComponent } from '@shared/components/forms/standard-control/standard-control.component';
import { PageSubtitleComponent } from '@shared/components/sections/page-subtitle/page-subtitle.component';
import { ISelectOption } from '@shared/models/form-controls';

@Component({
	selector: 'app-desktop-organisation-details',
	standalone: true,
	imports: [FormsModule, ReactiveFormsModule, CommonModule, PageSubtitleComponent, StandardControlComponent, SelectControlComponent],
	templateUrl: './desktop-organisation-details.component.html',
	styleUrl: './desktop-organisation-details.component.scss',
})
export class DesktopOrganisationDetailsComponent {
	@Input() organisationForm: FormGroup<IOrganisationForm>;
	industryOptions: Array<ISelectOption> = Object.values(OrganisationIndustry).map((industry) => ({
		label: industry,
		value: industry,
	}));
	companySizeOptions: Array<ISelectOption> = Object.values(OrganisationEmployeeSize).map((size) => ({
		label: size,
		value: size,
	}));
}
