import { takeUntil } from 'rxjs';

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { IClientProperty } from '@clients/models/firebase/client.model';
import { ILeadAddProperty, ILeadAddPropertyForm, PropertySelectType } from '@leads/client-facing/models/domain/lead-request.domain';
import { IInstallationAddressForm } from '@leads/customer-facing/models/domain/lead-request.domain';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertComponent } from '@shared/components/alerts/alert/alert.component';
import { AddressWithSearchComponent } from '@shared/components/forms/address-with-search/address-with-search.component';
import { RadioGroupControlComponent } from '@shared/components/forms/radio-group-control/radio-group-control.component';
import { IRadioOption } from '@shared/models/form-controls';
import { PropertyPipe } from '@shared/pipes/property.pipe';

@Component({
	selector: 'app-lead-add-property-modal',
	standalone: true,
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		PropertyPipe,
		RadioGroupControlComponent,
		AddressWithSearchComponent,
		AlertComponent,
	],
	templateUrl: './lead-add-property-modal.component.html',
	styleUrl: './lead-add-property-modal.component.scss',
})
export class LeadAddPropertyModalComponent implements OnInit, OnDestroy {
	@Input() clientProperties: Array<IClientProperty>;
	@Input() showContextAlert: boolean = false;
	@Output() propertySelected = new EventEmitter<ILeadAddProperty>();

	formGroup: FormGroup<ILeadAddPropertyForm>;
	propertyTypeOptions: Array<IRadioOption> = [
		{
			label: 'Existing',
			value: PropertySelectType.EXISTING,
			id: 'existing',
		},
		{
			label: 'New',
			value: PropertySelectType.NEW,
			id: 'new',
		},
	];

	destroyed = new EventEmitter<void>();
	PropertySelectType = PropertySelectType;

	private activeModal = inject(NgbActiveModal);

	ngOnInit(): void {
		this.formGroup = new FormGroup<ILeadAddPropertyForm>({
			propertySelectType: new FormControl<PropertySelectType>(PropertySelectType.EXISTING, [Validators.required]),
			selectedClientProperty: new FormControl<IClientProperty | null>(null),
			newProperty: new FormGroup<IInstallationAddressForm>({
				fullAddress: new FormControl(null, [Validators.required, Validators.minLength(3)]),
				suburb: new FormControl(null, [Validators.required, Validators.minLength(3)]),
				city: new FormControl(null, [Validators.required, Validators.minLength(3)]),
				country: new FormControl(null, [Validators.required, Validators.minLength(3)]),
				postalCode: new FormControl(null, [Validators.required, Validators.minLength(3)]),
				province: new FormControl(null, [Validators.required, Validators.minLength(3)]),
			}),
		});

		if (this.clientProperties.length) {
			this.propertySelectType.setValue(PropertySelectType.EXISTING);
			this.setSelectedClientProperty(this.clientProperties[0]!);
			this.newProperty.disable();
			this.propertyTypeOptions[0].disabled = false;
		} else {
			this.propertySelectType.setValue(PropertySelectType.NEW);
			this.selectedClientProperty.disable();
			this.propertyTypeOptions[0].disabled = true;
		}

		this.formGroup.controls.propertySelectType.valueChanges.pipe(takeUntil(this.destroyed)).subscribe((value) => {
			if (value === PropertySelectType.NEW) {
				this.setSelectedClientProperty(null);
				this.selectedClientProperty.disable();
				this.newProperty.enable();
			}
			if (value === PropertySelectType.EXISTING) {
				this.setSelectedClientProperty(this.clientProperties[0]!);
				this.newProperty.disable();
				this.selectedClientProperty.enable();
			}
		});
	}

	ngOnDestroy(): void {
		this.destroyed.emit();
	}

	closeModal(): void {
		this.activeModal.close();
	}

	get propertySelectType(): FormControl<PropertySelectType | null> {
		return this.formGroup.controls.propertySelectType;
	}

	get selectedClientProperty(): FormControl<IClientProperty | null> {
		return this.formGroup.controls.selectedClientProperty;
	}

	get newProperty(): FormGroup<IInstallationAddressForm> {
		return this.formGroup.controls.newProperty;
	}

	setSelectedClientProperty(property: IClientProperty | null): void {
		this.formGroup.controls.selectedClientProperty.setValue(property);
	}

	submit(): void {
		if (this.formGroup.invalid) {
			return;
		}

		if (this.propertySelectType.value === PropertySelectType.EXISTING) {
			this.propertySelected.emit({
				propertySelectType: PropertySelectType.EXISTING,
				selectedClientProperty: this.selectedClientProperty.value!,
			});
			this.closeModal();
		}

		if (this.propertySelectType.value === PropertySelectType.NEW) {
			this.propertySelected.emit({
				propertySelectType: PropertySelectType.NEW,
				selectedClientProperty: this.newProperty.value as IClientProperty,
			});
			this.closeModal();
		}
	}
}
