import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { AuthService } from '@auth/services/auth.service';

@Component({
	selector: 'app-google-signin',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './google-signin.component.html',
	styleUrl: './google-signin.component.scss',
})
export class GoogleSigninComponent {
	authService = inject(AuthService);
	isLoading = false;

	signUpWithGoogle(): void {
		this.isLoading = true;
		this.authService.signInWithGoogle().then((result) => {
			this.isLoading = false;
		});
	}
}
