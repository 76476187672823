import { Routes } from '@angular/router';
import { LeadsDashboardComponent } from '@leads/client-facing/pages/leads-dashboard/leads-dashboard.component';
import {
    LeadAssessmentRequestComponent
} from '@leads/client-facing/pages/lead-assessment-request/lead-assessment-request.component';

export enum ClientLeadsRoutes {
    DASHBOARD = '',
	ASSESSMENT_REVIEW = ':leadRequestId/assessment-request',

}

export const clientFacingLeadsRoutes: Routes = [
    {
        path: ClientLeadsRoutes.DASHBOARD,
        component: LeadsDashboardComponent,
        title: 'ServeOx | Leads'
    },
    {
        path: ClientLeadsRoutes.ASSESSMENT_REVIEW,
        component: LeadAssessmentRequestComponent,
        title: 'ServeOx | Assessment Request'
    }
];
