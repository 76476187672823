<div class="heading">
	<div class="left">
		<ng-container *ngIf="showBackButton">
			<button class="btn btn-sm btn-outline-primary border-0" type="button" (click)="goBack()">
				<i class="bi bi-chevron-left"></i>
			</button>
		</ng-container>
		<h4 class="text-start text-primary fw-semibold">{{ title }}</h4>
		<ng-container *ngIf="!isMobileSize && showWhatsAppButton">
			<button class="btn btn-sm btn-whatsapp btn-success" (click)="openClientWhatsApp()">
				<i class="bi bi-whatsapp"></i>
			</button>
		</ng-container>
	</div>
	<div class="right">
		<ng-container *ngIf="isMobileSize; else desktopSize">
			<ng-container *ngIf="showWhatsAppButton">
				<button class="btn btn-sm btn-whatsapp btn-success" (click)="openClientWhatsApp()">
					<i class="bi bi-whatsapp"></i>
				</button>
			</ng-container>
			<ng-container *ngIf="showLeadFormLink">
				<button class="btn btn-sm btn-primary" type="button" (click)="copyToClipboard()">
					<i class="bi bi-person-lines-fill"></i>
				</button>
			</ng-container>
		</ng-container>
		<ng-template #desktopSize>
			<ng-container *ngIf="showLeadFormLink">
				<button class="btn btn-sm btn-primary" type="button" (click)="copyToClipboard()">
					<i class="bi bi-person-lines-fill"></i>
					Lead Form Link
				</button>
			</ng-container>
		</ng-template>
	</div>
</div>
