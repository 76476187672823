import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { IAvailableOrganisation } from '@organisations/models/organisation-user';
import { OrganisationSelectedService } from '@organisations/services/organisation-selected/organisation-selected.service';
import { OrganisationUserService } from '@organisations/services/organisation-user/organisation-user.service';
import { map, take, tap } from 'rxjs';

export const selectedOrganisationGuard: CanActivateFn = (route, state) => {
	const organisationUserService = inject(OrganisationUserService);
	const organisationSelectedService = inject(OrganisationSelectedService);

	return organisationUserService.availableOrganisations().pipe(
		take(1),
		tap((availableOrganisations: IAvailableOrganisation[]) => {
			const storedOrganisationId = localStorage.getItem('selectedOrganisationId');
			let selectedOrganisation = availableOrganisations[0];

			if (storedOrganisationId) {
				const matchedOrganisation = availableOrganisations.find((o) => o.id === storedOrganisationId);
				if (matchedOrganisation) {
					selectedOrganisation = matchedOrganisation;
				}
			}

			organisationSelectedService.setSelectedOrganisation(selectedOrganisation);
		}),
		map(() => true),
	);
};
